import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { branch } from 'baobab-react/higher-order';
import rest from '../../data/restWrapper';
import { reservationStatusColorsHEX } from '../../data/reservationStatusEnums';
import moment from 'moment/min/moment-with-locales';
import getRoomSRCStatusIcon, { getRoomSRCStatus } from '../../data/controllers/SRCStatus';
import ActivityFilterToolbar from './activityFilterToolbar';
import BasicSRCSettings from '../roomInfo/components/basicSRCSettingsModal';
import { setModalContent, closeModal } from '@components/modal';
import { translate } from '../../data/translations';
import PageLoader from '../../components/loaders/loader1';
import ConfirmButtons from '@components/buttons/confirmButtons';
const {ReservationStatus} = require('@common/modelDefinition');

// function getPos(el) {
//     // yay readability
//     for (var lx = 0, ly = 0; el != null; lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent);
//     return { x: lx, y: ly };
// }

const activityStatus = {
    0: 'Early bird',
    1: 'Confirmed',
    2: 'Check in',
    3: 'Canceled',
    4: 'Check out',
    5: 'Checked out',
    6: 'Stay over',
    7: 'Late check in',
    8: 'Checked in',
    9: 'Autoblocked',
};

const cleaningStatusEnums = {
    0: translate('cl_noActionTaken'),
    1: translate('cl_cleaningRequested'),
    2: translate('cl_stagedForCleaning'),
    3: translate('cl_cleaningStarted'),
    4: translate('cl_cleaningFinished'),
    5: translate('cl_cleaningPostponed'),
    6: translate('cl_cleaningSkipped'),
};

const ActivityView = (props) => {
    const { cleaningStatusHeaders } = props;
    const [loadingData, setLoadingData] = useState(true);
    const [lastCleaningForRooms, setLastCleaningForRooms] = useState([]);
    const [activityTypes, setActivityTypes] = useState([0,2,4,6,7]);
    const [reservations, setReservations] = useState(null);
    const presentDayCleaningStatusHeader = cleaningStatusHeaders[0];
    const cleaningStatuses = presentDayCleaningStatusHeader
        ? presentDayCleaningStatusHeader.CleaningStatuses
        : [];
    useEffect(() => {
        const loadData = async () => {
            try {
                const _reservations = await rest('/getReservationActivityStatus');
                let roomInfoIds = _reservations.map((r) => r.roomInfoId);
                roomInfoIds = _.difference(roomInfoIds, [null, undefined, '']);
                let roomInfoIdsString = null;
                if (roomInfoIds.length > 0) {
                    roomInfoIdsString = _.uniq(roomInfoIds).join(',');
                }
                const _lastCleaningForRooms = await rest(
                    '/getLastCleaningForRooms/' + 4 + '?rooms=' + roomInfoIdsString
                );
               
                setLoadingData(false);
                setLastCleaningForRooms(_lastCleaningForRooms);
                setReservations(_reservations);
            } catch (err) {
                console.warn(err);
            }
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const handleShowBasicOptions = (_reservation, e) => {
        const roomName = _reservation.roomName;
        const basicSRCSettings = (
            <BasicSRCSettings
                roomInfoId={_reservation.roomInfoId}
                key={_.uniqueId('asd')}
                reservation={_reservation}
                remoteDeviceId={_reservation.remoteDeviceId}
            />
        );
        setModalContent(
            basicSRCSettings,
            <span>
                {window.translate('Room settings') + ' - '} <b>{roomName}</b>
            </span>,
            true,
            'modal-xl'
        );
    };

    const getLastCleaningTimeForRoom = (roomInfoId) => {
        let lastCleaningTime = null;
        lastCleaningForRooms.forEach((lc) => {
            if (lc.roomInfoId === roomInfoId && lc.cleaningFinishedTimestamp) {
                lastCleaningTime = moment(lc.cleaningFinishedTimestamp).fromNow();
                return false;
            }
        });
        return lastCleaningTime;
    };

    const getLastCleaningStatusForRoom = (roomInfoId) => {
        let lastCleaningStatus = null;
        cleaningStatuses.forEach((lc) => {
            if (lc.roomInfoId === roomInfoId) {
                lastCleaningStatus = lc.cleaningStatus;
                return false;
            }
        });
        return lastCleaningStatus;
    };

    const toggleActivityFilter = (activityType) => {
        let _activityTypes = [...activityTypes];
        if (activityType === null) {
            setActivityTypes([]);
        } else {
            if (activityType === 'allCheckIn') {
                let stringifyActivityTypes = JSON.stringify(_activityTypes);
                if (stringifyActivityTypes === '[0,2,7]') {
                    _activityTypes = [];
                } else {
                    _activityTypes = [0, 2, 7];
                }
            } else if (activityType === 'allCheckOut') {
                let stringifyActivityTypes = JSON.stringify(_activityTypes);
                if (stringifyActivityTypes === '[4,6]') {
                    _activityTypes = [];
                } else {
                    _activityTypes = [4, 6];
                }
            } else {
                if (_activityTypes.includes(activityType)) {
                    _activityTypes = _.filter(_activityTypes, (at) => at !== activityType);
                } else {
                    _activityTypes.push(activityType);
                }
            }
            setActivityTypes(_activityTypes);
        }
    };

    const filterRoomsByActivityType = (_reservations) => {
        let filteredReservations = _reservations;
        if (activityTypes.length > 0) {
            filteredReservations = _reservations.filter((r) => {
                if (activityTypes.includes(r.activityStatus)) {
                    return r;
                }
                return null
            });
        }
        return filteredReservations;
    };

    const getRoomMode = (reservation) => {
        let roomModeText = '';
        let roomMode = props.activeEventTickets.find((ev) => {
            return (
                ev.roomInfoId === reservation.roomInfoId &&
                (ev.ruleName === 'alarm_ecoRoomMode' ||
                    ev.ruleName === 'alarm_comfortRoomMode' ||
                    ev.ruleName === 'alarm_preComfortRoomMode')
            );
        });

        if (roomMode === undefined) {
            roomModeText = 'Unknown';
        } else if (roomMode.ruleName === 'alarm_ecoRoomMode') {
            roomModeText = 'ECO';
        } else if (roomMode.ruleName === 'alarm_comfortRoomMode') {
            roomModeText = 'COMFORT';
        } else if (roomMode.ruleName === 'alarm_preComfortRoomMode') {
            roomModeText = 'PRECOMFORT';
        }
        return roomModeText;
    };

    const changeRoomMode = async (modeToSet, remoteDeviceId) => {
        if (!remoteDeviceId) {
            throw new Error("Remote id missing")
        }
        setModalContent(
            <div className="container-fluid">
                <div className="text-center">
                    {translate('Are you sure you want to change room mode?')}
                    <hr />
                </div>

                <div className="text-center">
                    <ConfirmButtons
                        onConfirm={async () => {
                            const next24Hours = new Date().getTime() + 24 * 60 * 60 * 1000
                            const cardKeys = ["guest1", "guest2", "guest3"]

                            let payload = {};

                            if (modeToSet === "PRECOMFORT") {
                                payload = {
                                    code: 1,
                                    validToTimestamp: next24Hours,
                                    cardCodeType: "guest1",
                                    reservationId: null,
                                }
                                await rest('/src/setCardCodeWithValidToTime/' + remoteDeviceId, 'POST', payload);
                            } else if (modeToSet === "ECO") {
                                for (let i = 0; i < cardKeys.length; i++) {
                                    payload = {
                                        code: 0,
                                        validToTimestamp: new Date().getTime(),
                                        cardCodeType: cardKeys[i],
                                        reservationId: null,
                                    }
                                    await rest('/src/setCardCodeWithValidToTime/' + remoteDeviceId, 'POST', payload);
                                }
                            }
                            closeModal();
                        }}
                        onCancel={() => {
                            closeModal();
                        }}
                    />
                </div>
            </div>,

            translate('Confirm'), // header
            false, // showCloseButton
            'modal-md'
        );
    }

    const { rooms } = props;
    if (loadingData) {
        return <PageLoader />;
    }

    let _reservations = reservations ? reservations : [];

    _reservations = filterRoomsByActivityType(_reservations);
    _reservations = _reservations.filter((r) => rooms.map((r) => r.id).includes(r.roomInfoId));

    //Ovdje možda ifiltrirati istu sobu po prioritetu
    return (
        <div>
            <hr className="mt-0 mb-2" />
            <ActivityFilterToolbar
                toggleActivityFilter={toggleActivityFilter.bind(this)}
                activityTypes={activityTypes}
            />

            <div>
                {_reservations.length === 0 ? (
                    <div className="text-center pt-5 mt-5 text-dark">
                        <h4>
                            {window.translate('There is no rooms with activity today that match selected criteria')}
                        </h4>
                    </div>
                ) : null}

                <div className=" mt-1 mb-2">
                    {_reservations.map((r, key) => {
                        // const reservation = r.reservation;
                        const roomSRCStatusIcon = getRoomSRCStatusIcon(r);
                        const srcRoomStatus = getRoomSRCStatus(r);
                        const lastCleaningTime = getLastCleaningTimeForRoom(r.roomInfoId);
                        const lastCleaningStatus = getLastCleaningStatusForRoom(r.roomInfoId);

                        return (
                            <div className="  col-6 col-md-3 col-lg-3 col-xl-2 pt-0 pb-2 pr-2 pl-0 pull-left" key={key}>
                                <div className="shadow-sm bg-white border rounded-sm pb-2">
                                    <div className="text-center text-primary p-1 elipsis">
                                        <b>{r.roomName}</b>
                                    </div>
                                    {
                                        <span style={{ position: 'absolute', top: '5px', right: '25px' }}>
                                            {roomSRCStatusIcon}
                                        </span>
                                    }

                                    <div
                                        style={{
                                            height: '4px',
                                            backgroundColor: reservationStatusColorsHEX[r.statusEnum],
                                        }}
                                    ></div>
                                    <div style={{ height: '176px', position: 'relative' }}>
                                        <small>
                                            <div className="p-2">
                                                <div>
                                                    {window.translate('Room mode')}: {getRoomMode(r)}
                                                </div>
                                                <div className="elipsis">
                                                    {window.translate('Cleaned')}:{' '}
                                                    {lastCleaningTime ? lastCleaningTime : 'unknown'}
                                                </div>
                                                <div className="elipsis">
                                                    {window.translate('Household')}:{' '}
                                                    {lastCleaningStatus || lastCleaningStatus === 0
                                                        ? cleaningStatusEnums[lastCleaningStatus]
                                                        : 'unknown'}
                                                </div>
                                                {r.lastReservation && r.activityStatus === 2 ? (
                                                    <div className="">
                                                        {window.translate('Previous reservation checkout Date')}:{' '}
                                                        {moment(r.lastReservation.checkOutTimestamp).format('lll')}
                                                    </div>
                                                ) : null}
                                                {r.lastReservation && r.activityStatus === 2 ? (
                                                    <div className="">
                                                        {window.translate('Previous reservation status')}:{' '}
                                                        {r.lastReservation.isRoomEmptyStatus === 0
                                                            ? 'Not checked out'
                                                            : r.lastReservation.isRoomEmptyStatus === 1
                                                                ? 'Late check in'
                                                                : 'Checked out'}
                                                    </div>
                                                ) : null}
                                                {r.roomStatus && _.includes([6, 4], r.activityStatus) ? (
                                                    <div className="">
                                                        {window.translate('Incoming guest at')}:{' '}
                                                        {moment(r.roomStatus).format('lll')}
                                                    </div>
                                                ) : [6, 4].includes(r.activityStatus) ? (
                                                    <div className="">
                                                        {window.translate('Incoming reservations: No')}
                                                    </div>
                                                ) : null}
                                                {r.earlyBirdGuest && _.includes([6, 4], r.activityStatus) ? (
                                                    <div className="">
                                                        {window.translate('Ealrly bird at')}:{' '}
                                                        {moment(r.earlyBirdGuest).format('lll')}
                                                    </div>
                                                ) : [6, 4].includes(r.activityStatus) ? (
                                                    <div className="">
                                                        {window.translate('Early bird reservations: No')}
                                                    </div>
                                                ) : null}
                                                {r.autoBlockEarlyBirdAfter && r.activityStatus === 0 ? (
                                                    <div className="">
                                                        {window.translate('Autoblock')}:{' '}
                                                        {moment(r.autoBlockEarlyBirdAfter).format('lll')}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </small>
                                        {r.statusEnum === ReservationStatus.checkedIn || srcRoomStatus !== 1  ? null : <div
                                            className="w-100"
                                            style={{
                                                position: 'absolute',
                                                bottom: '5px',
                                                display: 'flex',
                                                justifyContent: 'space-around',
                                            }}
                                        >
                                            <div onClick={() => { 
                                                if(getRoomMode(r) !== 'ECO'){
                                                    changeRoomMode("ECO", r.remoteDeviceId)
                                                }  }} 
                                                className="btn btn-sm btn-light border pointer">
                                                <i
                                                    className={
                                                        'fa fa-check-circle ' +
                                                        (getRoomMode(r) === 'ECO'
                                                            ? 'text-success fa fa-check-circle'
                                                            : 'fa fa-circle-o')
                                                    }
                                                ></i>
                                                {' '}
                                                <small>ECO</small>
                                            </div>
                                            <div onClick={() => { 
                                                if(getRoomMode(r) !== 'PRECOMFORT'){
                                                    changeRoomMode("PRECOMFORT", r.remoteDeviceId)
                                                }  }} 
                                                className="btn btn-sm btn-light border pointer">
                                                <i
                                                    className={
                                                        'fa fa-check-circle ' +
                                                        (getRoomMode(r) === 'PRECOMFORT'
                                                            ? 'text-success fa fa-check-circle'
                                                            : 'fa fa-circle-o')
                                                    }
                                                ></i>
                                                {' '}
                                                <small>PRECOMFORT</small>
                                            </div>
                                        </div>}
                                    </div>
                                    <small>
                                        <div className="pl-2">
                                            {[1, 2].includes(srcRoomStatus) ? (
                                                <button
                                                    onClick={() => {
                                                        handleShowBasicOptions(r);
                                                    }}
                                                    className="btn btn-sm btn-light border mr-1"
                                                >
                                                    <i className="fa fa-cog text-info" />
                                                </button>
                                            ) : null}

                                            <button
                                                className="btn btn-sm btn-light border mr-1"
                                                onClick={() => {
                                                    props.history.push('/reservations/' + r.id + '/edit');
                                                }}
                                            >
                                                <i className="fa fa-calendar text-info" />
                                            </button>

                                            <div className="pull-right mr-2 mt-2">
                                                {window.translate(activityStatus[r.activityStatus])}
                                            </div>
                                        </div>
                                    </small>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default branch(
    {
        activeEventTickets: ['model', 'RoomEventTicket'],
        cleaningStatusHeaders: ['monkeys', 'cleaningStatusHeadersWithCleaningStatuses'],
    },
    ActivityView
);
