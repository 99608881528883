import React from 'react';
import _ from 'lodash';
import { branch } from 'baobab-react/higher-order';
import baobabTree from '../../data/state';

function Toolbar(props) {
    const toggleSidebarButtons = (sidebarType) => {
        let temp = _.clone(baobabTree.root.select(['uiStates', 'dashboard', 'showComponents']).get());
        temp = _.filter(temp, (t) => t === sidebarType || t === 'timeline' || t === 'tiles' || t === 'activity' || t === 'journal');
        temp.push(sidebarType);
        baobabTree.root.select(['uiStates', 'dashboard', 'showComponents']).set(temp);
    };

    let sidebarButtonCheckedRule = 'sidebar_dailyInfo';
    if (props.showComponents.includes('sidebar_dailyInfo')) {
        sidebarButtonCheckedRule = 'sidebar_dailyInfo';
    }
    if (props.showComponents.includes('sidebar_week/month')) {
        sidebarButtonCheckedRule = 'sidebar_week/month';
    }
    if (props.showComponents.includes('sidebar_incomming')) {
        sidebarButtonCheckedRule = 'sidebar_incomming';
    }
    if (props.showComponents.includes('sidebar_checkedIn')) {
        sidebarButtonCheckedRule = 'sidebar_checkedIn';
    }
    if (props.showComponents.includes('sidebar_canceled')) {
        sidebarButtonCheckedRule = 'sidebar_canceled';
    }

    return (
        <div className="">
            {/* <nav className="navbar mb-0 pb-0 pt-0 rounded row">
                <div className="col-4">
                    <div className="form-inline align-items-center ">
                        
                    </div>
                </div>
                <div className="col-8 d-sm-none  pr-0">
                    <input
                        onChange={props.setSearchTerm}
                        placeholder={window.translate('Reservations search...')}
                        type="text"
                        className="form-control form-control-sm pull-left"
                    />
                </div>
            </nav> */}

<div
                            style={{ display: 'flex' }}
                            className=""
                            aria-label="Sidebar filter"
                        >
                            <div style={{flex:1}}
                                title={window.translate('Daily info')}
                                onClick={toggleSidebarButtons.bind(this, 'sidebar_dailyInfo')}
                                className={
                                    sidebarButtonCheckedRule === 'sidebar_dailyInfo'
                                        ? 'tab active'
                                        : 'tab'
                                }
                            >
                                <i className="fa fa-pie-chart " />
                            </div>
                            <div style={{flex:1}}
                                title={window.translate('Weekly and monthly info')}
                                onClick={toggleSidebarButtons.bind(this, 'sidebar_week/month')}
                                className={
                                    sidebarButtonCheckedRule === 'sidebar_week/month'
                                        ? 'tab active'
                                        : 'tab'
                                }
                            >
                                <i className="fa fa-area-chart " />
                            </div>
                            <div style={{flex:1}}
                                title={window.translate('Incomming reservations')}
                                onClick={toggleSidebarButtons.bind(this, 'sidebar_incomming')}
                                className={
                                    sidebarButtonCheckedRule === 'sidebar_incomming'
                                        ? 'tab active'
                                        : 'tab'
                                }
                            >
                                <i className="fa fa-calendar-check-o " />
                            </div>
                            <div style={{flex:1}}
                                title={window.translate('Checked in reservations')}
                                onClick={toggleSidebarButtons.bind(this, 'sidebar_checkedIn')}
                                className={
                                    sidebarButtonCheckedRule === 'sidebar_checkedIn'
                                        ? 'tab active'
                                        : 'tab'
                                }
                            >
                                <i className="fa fa-sign-out " />
                            </div>
                            <div style={{flex:1}}
                                title={window.translate('Canceled reservations')}
                                onClick={toggleSidebarButtons.bind(this, 'sidebar_canceled')}
                                className={
                                    sidebarButtonCheckedRule === 'sidebar_canceled'
                                        ? 'tab active'
                                        : 'tab'
                                }
                            >
                                <i className="fa fa-eraser " />
                            </div>
                        </div>
        </div>
    );
}

export default branch(
    {
        roomTags: ['monkeys', 'roomTags'],
        reservations: ['model', 'Reservation'],
    },
    Toolbar
);
