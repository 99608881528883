export default {
    Save: '',
    Cancel: '',
    'GO BACK': '',
    Change: '',
    Confirm: '',
    Name: '',
    Address: '',
    'Post office number': '',
    Town: '',
    Country: '',
    'Country/State': '',
    Notes: '',
    'ADD NEW': '',
    prev: '',
    next: '',
    page: '',
    Select: '',
    CREATE: '',
    'First name': '',
    'Last name': '',
    VIEW: '',
    Close: '',
    Delete: '',
    Update: '',
    ADD: '',
    CANCEL: '',
    View: '',
    Yes: '',
    No: '',
    'Are you sure you want to delete?': '',
    'Invalid value': '',
    'Tag already exist!': '',
    ' EXPORT': '',
    CONFIRM: '',
    SAVE: '',
    'CLOSE VIEW': '',

    //Access control
    'Access point name': '',
    Description: '',
    'Allow guest access, only from rooms with tags': '',
    'no filter': '',
    whitelist: '',
    none: '',
    Guests: '',
    reserved1: 'Reserved keyword which allow all guest to have an access to selected door',
    reserved2: 'Reserved keyword which allow all holders of temporary self check in code to have an access to selected door',
    Reserved: '',
    'Tag picker': '',
    'Available tags': '',
    'Pinned tags': '',
    'No pinned tags': '',

    //All room events
    'ROOM LOCAL': '',
    GUEST: '',
    'GUEST PHONE': '',
    'CUSTOM ALERT MESSAGE': '',
    'HW MUTE ALARM': 'MUTE ALARM ON HARDWARE',
    'SW MUTE ALARM': 'FORCE MUTE ALARM',
    "NOTE: Sensor alarms can not be muted": "NOTE: Sensor state alarms can't be muted",
    'NOTE: This will force close ticket. Use only if hardware is malfunctioning': '',
    'Please enter the reason for force closing this ticket': '',
    'FORCE CLOSE TICKET': 'Force closing the ticket because of device unresponsiveness',
    'RESPOND TO EVENT': '',
    'Situation center is disabled': '',
    Alarms: '',
    'Room Service': '',
    'Cleaning tracker': '',
    'Loading room events': '',
    'No data': '',
    'SUGGEST RESPONDER': '',
    'CLAIM RESPONSE': '',
    'SELECT RESPONDER': '',
    'EVENT RESPONDER': '',
    'Please, fill the following informations after you have returned from the location': '',
    'EVENT NOTES': '',
    'CLOSE EVENT LOG': '',
    'Event time': '',
    'Event accepted': '',
    Receptionist: '',
    Responder: '',
    'Event closed': '',
    'DISMISS SOS CALL': '',
    'Missing SOS response record. Close this event?': '',
    'TRY TO CLOSE EVENT': '',
    'no responder info': '',

    //Company
    "Field: '": '',
    'VAT or ID number': '',
    "' is not unique!!!!": '',
    Web: '',
    'Add company': '',
    'Edit company': '',
    'Company has ': '',
    ' reseravation(s). Are you sure you want delete?': '',
    'SEARCH FOR COMPANY (by name, town, idNumber)': '',

    //Customers
    'Visa Type And Number': '',
    'Visa Validity Period': '',
    'Date entry in country': '',
    'Entry place': '',
    'Document Validity Period': '',
    'Personal document id': '',
    'Add Customer': '',
    'Edit customer': '',
    Company: '',
    'New Company': '',
    'Birth Date': '',
    'Birth Place': '',
    Gender: '',
    Email: '',
    'Mobile number': '',
    Citizenship: '',
    'Newsletter subscriptions': '',
    Male: '',
    Female: '',
    Undefined: '',
    'Customer has ': '',
    'SEARCH FOR CUSTOMER (by first and last name, email, document id)': '',

    //household
    'Room cleaning orders are generated automatically. Do you want to generate new accounts anyway?': '',
    'Drag employee card from the left and drop them on the room placeholder which needs to be cleaned or inspected. Room access for assigned employee will be granted for the current day and in working hours.':
        '',
    'LAST 10 CYCLES': '',
    'GENERATE NEW LOGS': '',
    'Maid list is empty': '',
    'CLEANING SCHEDULE FOR ': '',
    'ROOM/STATUS': '',
    MISSING: '',
    'LOST/FOUND': '',
    MALFUNCTIONS: '',
    'Change cleaning status': '',
    "You can't change the past, but you can learn from it": '',
    'Stage for cleaning': '',
    'Finish cleaning': '',
    'Start cleaning': '',
    'Postpone cleaning': '',
    'Skip cleaning': '',
    'Check minibar': '',
    'Minibar management': '',
    'Save note': '',
    'ITEM NAME': '',
    QUANTITY: '',
    ACTION: '',
    'Minibar from ': '',
    'Active reservation': '',
    From: '',
    To: '',
    Guest: '',
    'Attach to reservation': '',
    'NO ACTIVE RESERVATIONS': '',
    'CONSUMPTION LOG': '',
    'Cleaning requested': '',
    Consumption: '',
    Stock: '',
    'Undo consumption': '',
    Manage: '',

    //Visa Info
    "Nationalities who need visa to enter in country": '',

    //users
    'Add new user': '',
    'Edit user info': '',
    'Card number': '',
    'Select role': '',
    Experience: '',
    'Activate user': '',
    'After user is created, please set the password using the button': '',
    'API KEYS': '',
    ROLES: '',
    "(TEMPORARY KEY! DON'T USE!)": '',
    'Generate token': '',
    Token: '',
    'Valid to': '',
    'User uuid': '',
    'Minimum 6 character is required for password': '',
    'Passwords does not match! Please try again.': '',
    'Password changed': '',
    'New password for this user would be required on next login!': '',
    'Current password': '',
    Password: '',
    'Repeat password': '',

    //whitelist
    'Whitelist name': '',
    Tags: '',
    'New White List': '',
    'Edit White List': '',
    'Delete White List': '',
    'Please, enter all form values!': '',
    'CARD HOLDER NAME': '',
    'changed on ': '',
    'Tags filed can not be empty': '',
    'Add tag...': '',
    'Add tag, then press enter to confirm': '',

    //priceList
    'Room Id': '',
    'Room name': '',
    Pricelist: '',
    'Select a pricelist': '',
    'Accommodation tax': '',
    'No tax': '',
    'Apply per day': '',
    'Multiply with adults number': '',
    'Multiply with children number': '',
    'Person count': '',
    Price: '',
    'PLU is mandatory, min 3 digits': '',
    'PLU must be unique': '',
    'Taken PLUs': '',
    'Please, before continiue, enter at least one tax group.': '',
    'PLU Base': '',
    Tax: '',
    'Max. billable persons': '',
    'Name is mandatory': '',
    'No prices . You should delete this price list and create a new one.': '',
    'Valid from': '',
    'Repeat rules': '',
    'Valid on days': '',
    Weight: '',
    'Parent PLU Base': '',
    Infinite: '',
    'Is active': '',
    'New price list': '',
    'Assign to room': '',
    'NOTE: For every room type, you should create a seperate accommodation price list. You can add sessional and promotional tarrifs by stacking them visualy on infinite timeline - so you can easily track and change pricing and promotion policies.':
        '',
    '*Any modification of pricing policies, have no impact on already saved offers.': '',
    'Price rules not defined': '',
    'Fixed price': '',
    'Up to': '',
    'Update baseline price list': '',
    'Update price list': '',
    'Price per person': '',
    'Accommodation price list': '',
    'Conflicting dates': '',
    'Conflict detected': '',
    'Resolve confilcts by shifting the weight slots on your price lists or by adjusting dates to avoid conflicting price lists. Conflicts affects the automated decision making on accommodation prices':
        '',
    'Stacked price lists on timeline': '',
    'Price lists with a higher weight on the same day, will have a greater importance': '',
    Basis: '',
    'Gross amount': '',
    'Saved successfully': '',
    'Deleted successfully': '',
    'Exchange rates on ': '',
    'Please setup default currency in general settings first.': '',
    'EX. RATES ON DAY': '',
    'Default currency': '',
    'Default currency must have exchange rate of 1 (one).': '',
    'Exchange rate': '',
    'Name:': '',
    Acronym: '',
    'Is enabled': '',
    'New currency': '',
    PLU: '',
    'Next available PLU: ': '',
    Category: '',
    Image: '',
    'Unit of measure': '',
    'Service type': '',
    Enabled: '',
    'Enable item at POS': '',
    'Add new service': '',
    'Edit service': '',
    'Item exist in minibar': '',
    TAX: '',
    'Name or barcode': '',
    'Name or code': '',
    Piece: '',
    'L (liter)': '',
    'KG (kilogram)': '',
    Percentage: '',
    'Tax included in price': '',
    Amount: '',
    'Fixed tax': '',
    'New Tax': '',
    'Update fiscal memory': '',
    Id: '',
    'Netto price': '',
    'Brutto price': '',
    'No repeat rules': '',
    'Always valid.': '',

    //reports
    rejected: '',
    pass: '',
    Reservations: '',
    "Get ghant view of reservations by month. Find out your 'reservation index'.": '',
    'View Report': '',
    'Customers data reports': '',
    Invoices: '',
    'Select and explore invoices.': '',
    'Explore all events for every room. Filter them by date or get CSV export.': '',
    'Room events': '',
    'SOS log': '',
    'Minibar report': '',
    'Access control report': '',
    'Explore door access events. Hotel rooms are not included, only doors.': '',
    'Print number of guests': '',

    //menus
    Rooms: '',
    Controllers: '',
    'SRC templates': '',
    'Alarm settings':'',
    'SOS groups': '',
    'Doors & rules': '',
    Whitelists: '',
    Customers: '',
    Companies: '',
    'Visa Info': '',
    Settings: '',
    Users: '',
    'Notification service settings': '',
    Updates: '',
    Licence: '',
    Taxes: '',
    Accommodation: '',
    'Price lists': '',
    'Create dynamic accommodation price list and bind them to rooms. Beside accommodation, you can create price lists for minibar, goods and services.':
        '',
    Currencies: '',
    'Pricelist lookup': '',
    'POS goods': '',
    'Fiscal printers': '',
    'Rooms and hardware': '',
    'Create or update hotel rooms informations. Declare SRC proxyes and bind their controllers with designated room. Create predefined SRC configurations and download them to selected rooms.':
        '',
    'Access control': '',
    'Define door, lifts or parking access rules. You can define access whitelist and combine them with any door. Hotel rooms are not managed here.':
        '',
    'Data folders': 'Database',
    'Browse and create customers and companies required by reservation system.': '',
    'Advanced settings': '',
    'Setup language, company informations, currency and modems configuration. Manage API keys.': '',
    'Software updates and licence': '',
    'Update your software. Manage licence keys and view licence informations.': '',

    //notificationTemplate
    'Email service settings': '',
    'SMS service settings': '',

    //pagenotFound
    'PAGE NOT FOUND ERROR': '',

    //roominfo
    'Active room events': '',
    'Nothing to show': '',
    'Room events history': '',
    'Checkout overdue': '',
    'Edit reservation': '',
    'Reservation note': '',
    'Save note update': '',
    'Check out': '',
    'OUT OF SERVICE': '',
    'Room is available': '',
    'Reservation status': '',
    'Customer/Company was deleted': '',
    'Inbound reservations': '',
    'Past 30 days': '',
    'Make a reservation': '',
    'Room settings': '',
    'Detailed room events': '',
    'ADD/REMOVE ITEM': '',
    SERVICE: '',
    STOCK: '',
    'Add/remove': '',
    'Add or remove': '',
    Legend: '',
    'Assign attributes to room ': '',
    'Room does not exist': '',
    'Nothing selected': '',
    'Edit room data': '',
    'Add room': '',
    'Basic room informations': '',
    'Room phone/local': '',
    'Assign room attributes': '',
    'Select atributes': '',
    'You can assign room attributes later on editing room': '',
    'Bed count': '',
    'Spare beds': '',
    'Hardware controller reference (configure at': '',
    'Room price list': '',
    References: '',
    'Search by room name': '',
    Generate: '',
    'New attribute': '',
    "The room has a reference to the reservation. It can't be deleted.": '',
    'Room attributes': '',
    'No tags': '',
    'Extended room settings': '',
    'Dashboard config': '',
    MINIBAR: '',

    //sosgroups
    'ADD GROUP': '',

    //userProfile
    Role: '',
    'CHANGE PASSWORD': '',

    //compontents
    'Choose a file': '',
    Dashboard: '',
    Household: '',
    'Situation center': '',
    Reports: '',
    'System menu': '',
    'Log out': '',
    'Loading ...': '',
    'Not selected': '',
    total: '',
    ' DATE': '',

    //search
    'Search customer or company name, reservation id or room name': '',
    'Search...': '',
    'FIRST NAME': '',
    'LAST NAME': '',
    'COMPANY NAME': '',
    'CHECK IN': '',
    'CHECK OUT': '',
    ROOM: '',
    'Detailed information': '',
    'Reservation details': '',
    Room: '',
    'Check in': '',
    'Adults/childer': '',
    'Invoiced status': '',
    'Invoice id': '',
    'Total value': '',
    Currency: '',
    Reclamation: '',
    'Birth date': '',
    'Birth place': '',
    'E-mail': '',
    'Guest informations': '',
    'Company informations': '',
    'Invoices informations': '',
    'Visa info': '',
    RESERVATIONS: '',
    CUSTOMERS: '',
    'VISA INFO': '',
    COMPANIES: '',
    INVOICES: '',

    //checkoutAndInvoice
    Edit: '',
    // Details: '',
    'Invoice nr': '',
    'Reservation id': '',
    'Invoice issued by': '',
    'Reservation made by': '',
    Date: '',
    'ROOM NUMBER': '',
    Adults: '',
    Children: '',
    // 'Advance payment': '',
    Quantity: '',
    'Unit price': '',
    'Retail price': '',
    'Tax rate': '',
    // 'Unit tax': '',
    // 'Unit discount': '',
    Discount: '',
    // 'Person number': '',
    // 'Days total': '',
    // Subtotal: '',
    // 'Total discount': '',
    Total: '',
    'TAX RECAPITULATION': '',
    'Tax group': '',
    'NEW PRO FORMA': '',
    // ' has no exchange rate defined': '',
    // 'HARDWARE OPTIONS': '',
    'CLEAR CARD CODES': '',
    "ADD CARD CODE": "",
    // "NOTE: 'MAKE CLEANING REQUEST' is available 2 seconds after 'CLEAR CARD CODES' is successefuly executed.": '',
    // 'CLOSE RESERVATION': '',
    // 'Close reservation and save the invoice?': '',
    // 'Are you sure you want to close?': '',
    // 'All advance invoices from this reservation will be storned': '',
    // 'Payment info': '',
    // 'This reservation is already closed!': '',
    // 'Calculating, please wait ...': '',
    // 'APPLY CURRENT PRICE': '',
    // 'ENTER YOUR COMPANY INFO': '',
    // 'Invoice date': '',
    'Type of payment': '',
    'Fiscal printer': '',
    // 'Unpaid Amount': '',
    // 'Partial amount': '',
    'Issue receipt': '',
    // 'Save and preview': '',
    // 'Select currency': '',
    // 'Save and preview proforma invoice': '',
    // 'Advance payment details': '',
    // 'Save invoice': '',
    'Add your company info': '',
    // 'PDV amount': '',
    // 'Payment amount': '',
    // 'Total value without VAT': '',
    // 'Value with VAT': '',
    // 'Remaining to pay': '',
    // 'If greater then 0, fixed tax remaining': '',

    //dashboard
    'Stay over': '',
    'Late check in': '',
    'Checked In': '',
    'Checked Out': '',
    'Room mode': '',
    Cleaned: '',
    'Room available at': '',
    'Incoming guest at': '',
    'There is no rooms with activity today that match selected criteria': '',
    RENTED: '',
    FREE: '',
    'Setup dashboard or add room description': '',
    'SRC settings are not available': '',
    'Display inputs': '',
    Icon: '',
    'Show on dashboard': '',
    'Contact Open color': '',
    'Contact Closed color': '',
    'Blink if Open ': '',
    'Blink if Closed ': '',
    'Make a group reservation': '',
    DAY: '',
    Activity: '',
    Search: '',
    'AVAILABLE FROM': '',
    GROUP: '',
    MAKE: '',
    'GROUP VIEW': '',
    Beds: '',
    DAYS: '',
    TO: '',
    'Paid:Partialy': '',
    Paid: '',
    ' Fully': '',
    'Reservations search...': '',
    Autoblock: '',
    'Ealrly bird at': '',
    'All check-in': '',
    'All check-out': '',

    //reservation
    Days: '',
    // 'Current accommodation offer:': '',
    // 'Remembered accommodation price': '',
    // 'REMEMBER CURRENT OFFER': '',
    // 'No accommodation price list is defined for this room. Please go to room information and select one.': '',
    // "Don't forget to press SAVE on the reservation form after the price offer modification.": '',
    // 'Offer mismatch detected': '',
    // 'When you remember an offer, current accommodation prices are REMEMBERED and used for the invoice on checkout. Any changes on pricing policy, will have no effect on offered prices. However, any difference will be shown at the checkout - so you can choose which one should be invoiced.':
    //     '',
    'Available rooms': '',
    SELECT: '',
    'SEARCH CUSTOMERS': '',
    'SEARCH COMPANIES': '',
    'APPLY CURRENT TIME & DATE': '',
    'Validation error. Check required inputs.': '',
    error: '',
    // 'Autocorrected CHECK IN conflict': '',
    // 'Autocorrected CHECK OUT conflict': '',
    // 'Reservation editing is disabled when reservation is in CLOSED state!': '',
    DETAILS: '',
    'POSSIBLE CONFLICTS': '',
    'Room reservation': '',
    'Send check in e-mail again': '',
    'Enable self check-in': '',
    'New Guest': '',
    'Group reservation holder': '',
    'Early bird': '',
    Confirmed: '',
    'Checked in': '',
    Canceled: '',
    'Review by rooms': '',
    Status: '',
    'Auto blocked': '',
    'SHOW DETAILS': '',
    'Auto block early bird after': '',
    'ADD RESERVATION HOLDER': '',
    'Reservation notes': '',
    COUNT: '',
    PRICE: '',
    TOTAL: '',
    'Daily total': '',
    'Minibar consumption': '',
    'No recorded minibar consumption': '',
    'This reservation belongs to group, issue advance invoice and remove from group.': '',
    'Select invoice type': '',
    AVANS: '',
    //'Reservations from this group': '',
    'unknown?': '',
    'Access control history': '',
    'Reservation Details': '',
    'Customer Name': '',
    'Company Name': '',
    ACCEPT: '',
    DISMISS: '',
    'Beds/Spare beds': '',
    'Adults/Children': '',
    'VISA AND DOCUMENT DATA FOR  ': '',
    'List is empty': '',
    'VISA AND DOCUMENT DATA': '',
    'Guests informations': '',
    'Go to room view': '',
    'New reservation': '',
    Warning: '',
    'Leave anyway': '',
    'Send self check in code after': '',
    'Self check in valid from': '',
    'Self check in valid to': '',
    'Confirm cancel reservation': '',
    'Are you sure you want change status to CHECK IN': '',
    'Do you want to move this reservation to CONFIRMED state? ': '',
    'NOTE: Current time will be applyed as check in time': '',
    'Confirm checkin': '',
    'Are you sure you want to cancel this reservation?': '',
    // 'This reservation belongs to group, checkout and stay in group or checkout and invoice and remove from group.': '',
    CHECKOUT: '',
    'ISSUE INVOICE': '',
    // 'ASSIGN PRICE LIST': '',
    'CHANGE ROOM': '',
    // 'This reservation belongs to group, continue and remove from group.': '',
    'PRO FORMA INVOICE': '',
    INVOICE: '',
    Closed: '',

    //generalSettings
    'YOUR COMPANY': '',
    'LICENCE HOLDER': '',
    'WARNING: Information from this form will be used to generate licence. After licence is generated, system wide company name could not be changed until new licence is reissued.':
        '',
    'Fiscal POS': '',
    'Enable POS update on pricelist Save': '',
    Enable: '',
    Disable: '',
    'Apply daylight saving time': '',
    'Daylight saving time': '',
    'The command can be executed only 2 times per year!!!': '',
    'Receipt footer': '',
    'Apply footer (Max 4 lines)': '',
    'Gateway integration': '',
    'Find more about IOT Gateway': '',
    here: '',
    'Enable GW 1': '',
    'GW 1 connection': '',
    CLOUD: '',
    'GW1 address (http://address)': '',
    'GW1 location name': '',
    'Enable GW 2': '',
    connection: '',
    'address (http://address)': '',
    'location name': '',
    'LAN address (http://address)': '',
    LOCALISATION: '',
    Locale: '',
    'Other settings': '',
    'Check in time': '',
    'Check out time': '',
    'Enable household warent generation': '',
    'Autogenerate household warrants': '',
    'Daily cleaning start hour': '',
    'Daily cleaning end hour': '',
    'Auto invalidate CONFIRMED reservation after planned CHECK IN (in hours, 0 = disabled)': '',
    'Move invalidate reservation to state': '',
    'AUTO BLOCK': '',
    'Auto BLOCK EARLY BIRD reservations before planned CHECK IN (in hours)': '',
    'Document header (HTML allowed)': '',
    'Document footer (HTML allowed)': '',
    'Invoice notes (HTML allowed)': '',
    'Relaying server (Remote control)': '',
    'QR code validation time after first consumption': '',
    'SMTP configuration': '',
    Disabled: '',
    'SMTP host': '',
    Secure: '',
    User: '',
    Pass: '',
    'Locale and currency': '',
    'Company info': '',
    'Company name': '',
    'COST ESTIMATE': '',
    ' per month': '',
    ' activation fee': '',
    'FISCAL CERTIFICATE': '',
    Region: '',
    'Certificate Hash': '',
    Key: '',
    'Issuer Name': '',
    'Serial Number': '',
    'Fiscal certificate': '',

    //licence
    'No licence key': '',
    'Licence form': '',
    'Licence path is not valid!': '',
    'UPDATE LICENCE': '',
    'Remote licence is active !': '',
    'Licence key': '',
    'Paste licence key here ...': '',
    'Encoded licence': '',
    'Paste licence here ...': '',
    'SET LOCAL LICENCE': '',
    'SET REMOTE LICENCE PATH': '',
    'REQUEST LICENCE': '',
    'Licence request data': '',
    'COPY TO CLIPBOARD': '',
    'Company name which will be used system wide (invoices, displays etc.):': '',
    'Please enter email, where the licence should be sent:': '',
    'Administrative email': '',
    'Number of licences (users)': '',
    'Number of rooms': '',
    'Include hardware licences': '',
    'DO NOT INCLUDE': '',
    'INCLUDE HW SUPPORT': '',
    'Devices count (SRC/SOS/GATES)': '',
    'Activation price per device ': '',
    'NEXT STEP': '',
    'SEND REQUEST': '',
    monthly: '',

    //fiscalPrinters
    'Daily fiscal closure': '',
    'Z REPORT': '',
    'X REPORT': '',
    'X report is daily fiscal closure without registry clear': '',
    'Z report is daily fiscal closure with registry clear': '',
    'Delete all articles from Fiscal Printer': '',
    'Create duplicate of fiscal printer document': '',
    'Select date period or document number': '',
    'Query type': '',
    'Document type': '',
    'All documents': '',
    'Fiscal receipts': '',
    'Reclamation receipts': '',
    'Z report': '',
    'X report': '',
    'Periodic report': '',
    'Issue duplicate': '',
    'Fiscal memory report by date': '',
    'Start date – defines the starting date for the report': '',
    'End date – defines the ending date for the report': '',
    'Select Date': '',
    'Issue report': '',
    'Issue fiscal closure': '',
    'Issue report by date': '',
    'Issue document duplicate': '',
    'Input cash to fiscal printer': '',
    'Output cash from fiscal printer': '',
    'Select fiscal printer...': '',
    'Fiscal request timed out...': '',
    'Input and output of cash': '',
    Type: '',
    'Apply to fiscal printer': '',
    'Input': '',
    'Output': '',
    'Issue daily report': '',

    //SRC
    'NO ACTIVE SRC REFERENCE': '',
    'Select mode': '',
    'MODE:': '',
    HEATING: '',
    COOLING: '',
    'Automatic changeover': '',
    DISABLED: '',
    'Expires:': '',
    'Thermostat ': '',
    'Edit SRC proxy': '',
    'Add SRC proxy': '',
    'API key': '',
    'Connected to fiscal': '',
    'BIND SRC 2 ROOMS': '',
    'BROADCAST SETTINGS': '',
    'PLEASE WAIT': '',
    'Migrate devices and rooms from ': '',
    ' to': '',
    'SELECT ONE': '',
    'Are you sure': '',
    'SELECT UNATACHED ROOM': '',
    'Select all': '',
    'ATTACH ROOM': '',
    'Select room': '',
    Attach: '',
    'ATTACH HOTEL ROOM': '',
    'MIGRATE PROXY': '',
    'HOTEL ROOMS': '',
    UNBIND: '',
    Detach: '',
    'UNBINDED SRC CONTROLLERS': '',
    BIND: '',
    'ACCEPT CURRENT RESERVATION EXP.': '',
    'Access code:': '',
    'ACCESS CODES': '',
    'SRC DEVICE COMMUNICATION ERROR!': '',
    'ROOM SETTINGS': '',
    'Room mode adjustment': '',
    'AUTO ADJUSTMENT': '',
    MANUAL: '',
    'Active room mode': '',
    'Enable IO state emitting (RMCU)': '',
    DISABLE: '',
    ENABLE: '',
    'Welcome light duration in seconds (15-60 seconds)': '',
    'General electric ON, after card is pulled off from dispenser (10-60 seconds). It works only if forced check RFID is DISABLED':
        '',
    'Force checking disposed RFID card': '',
    'Debounce entrance for MAID when guest is in the room': '',
    'DISABLE DEBOUNCING': '',
    'ONE EXTRA PASS': '',
    'TWO EXTRA PASSES': '',
    'THREE EXTRA PASSES': '',
    'Enable Mandeks 3D light': '',
    'Door relay holding time (2-15 seconds)': '',
    'Temperature output offset (0-4 °C)': '',
    'BATHROOM HEATING': '',
    'Bathroom wire sensor': '',
    'Target temperature (°C)': '',
    'THERMOSTAT OPTIONS': '',
    'Thermostat Dead Zone': '',
    'Thermostat setpoint differential (for additional heaters, or two step modes)': '',
    'Minimum valve output ON time (minutes)': '',
    'Minimum valve output OFF time (minutes)': '',
    'Minimum FAN ON time (minutes)': '',
    'Fan start booster': '',
    'Proportional band switching point - HIGH fan (80-100)': '',
    'Proportional band switching point - MED fan (30-75)': '',
    'Proportional band switching point - LAW fan (1-15)': '',
    'Fan kick interval (0-90 minutes, 0 = always fan1 running in dead zone, 90-fan kick disabled)': '',
    'Fan start delay (0-180 seconds, 0 is off)': '',
    'EXTERNAL THERMOSTAT': '',
    'GENERAL SETTINGS': '',
    'SRC settings not available for this device. Probably SOS Calling Device.': '',
    'READING EXTENDED SRC SETTINGS': '',
    '(please wait)': '',
    'MAIN THERMOSTAT': '',
    'EMULATION DISABLED': '',
    CLOSED: '',
    OPEN: '',
    inputs: '',
    outputs: '',
    'SRC IO CONFIGURATION': '',
    'Room clock': '',
    'Wire sensor 1': '',
    'Wire sensor 2': '',
    'NOT CONNECTED': '',
    READY: '',
    'NOT AVAILABLE': '',
    'CORRUPTED SETTINGS - PLEASE FIX': '',
    FUNCTION: '',
    'SWITCHING DIFFERENTIAL - HEATING': '',
    'SWITCHING DIFFERENTIAL - COOLING': '',
    'MODBUS ADDRESS': '',
    'IR MODBUS ADDRESS': '',
    'ECO MODE': '',
    'PRECOMFORT MODE': '',
    'COMFORT MODE': '',
    'DEFAULT TEMP.': '',
    'FLOOR TEMPERATURE LIMIT (20-30 Celsius)': '',
    'FIX TO DEFAULT': '',
    '(click on header to expand)': '',
    'Please, select a valid template.': '',
    'EDIT SRC TEMPLATE': '',
    'ADD SRC TEMPLATE': '',
    'Create from config': '',
    'Select room with configured SRC as a template': '',
    'EDIT BASIC SRC TEMPLATE INFO': '',
    'PUSH TEMPLATE': '',
    'Upload failed after ': '',
    ' repeats! Please try again or consult the issue with the supplier!': '',
    'MULTICASTING SRC CONFIGURATION': '',
    "please be patient and don't do anything until done": '',
    'SRC configuration multicasting may take a while. It depends on number of selected rooms and underlaying modems. Data collisions may occure, so please use late at night or on any other low usage period. In worst case, operation should be repeated.':
        '',
    'All tags': '',
    'PUSH CONFIGURATION': '',
    'Please confirm software update from binary': '',
    'Software will auto restart after updating is finished.': '',
    'Paste link to software package here:': '',
    'Repository updates': '',
    'Binary download': '',
    'Please confirm software update to': '',
    'Please confirm software update': '',
    'AVAILABLE UPDATES': '',
    'REMEMBER ADDRESS': '',
    'FETCH UPDATES LIST': '',

    //otherTranslates
    LOGIN: '',
    'Log in': '',
    ' uploading file': '',
    'Incomming reservations': '',
    'Checked in reservations': '',
    'Canceled reservations': '',
    Note: '',
    'SELECT ROLE': '',
    'RESTORE DEFAULTS': '',
    'User Interface': '',
    'Force reload': '',
    // 'Issue fiscal receipt': '',
    'Issue Reclamation': '',
    Cancelled: '',
    Storno: '',
    POS: '',
    // 'RESERVATION DETAILS': '',
    STORNO: '',
    // 'No invoices in selected date range.': '',
    // VAL: '',
    VAT: '',
    // 'Reservation id; Invoice id; Customer name; Company name; CheckIn; CheckOut; Value; VAT': '',
    'Select fiscal printer': '',
    'Reclamation reason': '',
    Register: '',
    REGISTRATION: '',
    'NEW HOTEL': '',
    'Previous reservation checkout Date': '',
    'Previous reservation status': '',
    'Incoming reservations: No': '',
    'Early bird reservations: No': '',
    'Tags: Filtering by tag remove filters by previously selected tag': '',
    'Attributes: if checked, return rooms that have any of selected attributes, else only rooms that have all selected attributes':
        '',
    CONSUMATION: '',
    KEYWORDS: '',
    'General Tax': '',
    Actions: '',
    'Add items to minibar': '',
    'Minibar disabled': '',
    'No privileges': '',
    'System administrator': '',
    'HW administrator': '',
    'Full manager': '',
    'Business manager': '',
    'Household manager': '',
    'Security manager': '',
    'Maid worker': '',
    'GDPR officer': '',
    'Janitor worker': '',
    'Security worker': '',
    'Report access only': '',
    'NO HARDWARE': '',
    'ADD SRC': '',
    CONFIGURATION: '',
    'Hotel rooms and HW': '',
    UPDATE: '',
    'Settings and updates': '',

    //router
    'Dashboard settings': '',
    'Accommodation price list stack': '',
    'Assign pricelist to room': '',
    'Exchange rates': '',
    'POS artikli': '',
    Minibar: '',
    'Household management': '',
    'Your profile': '',
    'Building Management System': '',
    'Room informations': '',
    'Group reservation': '',
    'Reservation administration': '',
    Checkout: '',
    'Pro forma invoice': '',
    'SRC settings broadcast & multicast': '',
    'SRC Proxy configuration': '',
    'Notification Service Settings': '',
    'Notification Templates': '',
    'QR Generator': '',
    'Rooms management': '',
    'API keys management': '',
    'User management': '',
    'General settings': '',
    'Software updates': '',
    'Licence informations': '',
    'Access Control': '',
    'White list access': '',
    'Invoices report': '',
    'Reservations report': '',
    'Access contol': "Passage access control",
    'System audit': '',
    'CUSTOMERS DATA REPORT': '',
    'SOS response log': '',
    'Door access report': '',
    TEST: '',
    'Reports menu': '',
    'MINIBAR LOG BOOK': '',
    'Group Reservations': '',
    'Roles management': '',

    'ADVANCE INVOICE': '',
    'PROFORMA INVOICE': '',
    'STORNED INVOICE': '',
    'STORNO INVOICE': '',
    'STORNO ADVANCE INVOICE': '',
    'STORNED ADVANCE INVOICE': '',
    'STORNED PROFORMA INVOICE': '',
    'STORNO PROFORMA INVOICE': '',

    // 'ISSUE PROFORMA INVOICE': '',
    // 'ISSUE ADVANCE INVOICE': '',
    // 'INVOICE AND CLOSE RESERVATION': '',

    'SOS assistance required': '',
    'Fire alarm': '',
    'Smoke detected': '',
    'Flood detected': '',
    'Motion detected': '',
    'Intrudor detected': '',
    'Minibar opened': '',
    'Room safe opened': '',
    'Do not disturb': '',
    'Cleaning service required': '',
    'Room service required': '',
    'Assistance pending': '',
    'ECO mode': '',
    'COMFORT mode': '',
    'PRE COMFORT mode': '',
    'CLEANING mode': '',
    "Minibar is open more than 5 minutes!": "",

    // 'Proforma invoice preview': '',
    // 'Advance invoice preview': '',
    // 'Invoice preview': '',

    general_output_name: 'General output',
    welcome_light_output_name: 'Welcome light output',
    door_lock_output_name: 'Door lock output',
    do_not_disturb_output_name: 'Do not disturb output',
    room_service_output_name: 'Room service output',
    thermostat1_cooling_output_name: 'Thermostat 1 cooling output',
    thermostat1_output_name: 'Thermostat 1 output',
    alarm_output_name: 'Alarm output',
    sos_output_name: 'SOS output',
    bathroom_thermostat_output_name: 'Bathroom thermostat output',
    balcony_light_output_name: 'Balcony light output',
    cleaning_service_output_name: 'Cleaning service output',
    sos_input_name: 'SOS input',
    window_state_input_name: 'Window state input',
    thermostat1_input_name: 'Thermostat 1 input',
    thermostat1_cooling_input_name: 'Thermostat 1 cooling input',
    door_state_input_name: 'Door state input',
    balcony_door_input_name: 'Balcony door input',
    do_not_disturb_input_name: 'Do not disturb input',
    room_service_input_name: 'Room service input',
    card_holder_input_name: 'Card holder input',
    fire_alarm_input_name: 'Fire alarm input',
    smoke_alarm_input_name: 'Smoke alarm input',
    motion_alarm_input_name: 'Motion alarm input',
    flood_alarm_input_name: 'Flood alarm input',
    minibar_state_input_name: 'Minibar state input',
    fan1_input_name: 'Fan 1 input',
    welcome_light_input_name: 'Welcome light input',
    cleaning_service_input_name: 'Cleaning service input',

    gsettings_name: 'Name',
    gsettings_hotelName: 'Hotel name',
    gsettings_address: 'Address',
    gsettings_postOfficeNumber: 'Post office number',
    gsettings_town: 'Town',
    gsettings_country: 'Country',
    gsettings_contactEmail: 'E-mail',
    gsettings_idNumber: 'Id number',
    gsettings_notes: 'Notes',

    cl_noActionTaken: 'No action taken',
    cl_cleaningRequested: 'Cleaning requested',
    cl_stagedForCleaning: 'Staged for cleaning',
    cl_cleaningStarted: 'Cleaning in progress',
    cl_cleaningFinished: 'Finished',
    cl_cleaningPostponed: 'Postponed',
    cl_cleaningSkipped: 'Cleaning skipped',
    cl_cleaningInspectionRequired: 'Room inspection required',
    cl_stagedForDeepCleaning: 'Staged for deep cleaning',


    leave_guard_note: 'You have unsaved changes, are you sure you want to leave?',
    leave_guard_note_visa: 'You have uncompleted visa or document data for guest(s), are you sure you want to leave?',

    // no_accommodation_pricelist_defined:
    // 'No accommodation price list is assiged for this reservation. Please assign one.',
    // remember_current_prices_note:
    // 'When you remember an offer, current accommodation prices are REMEMBERED and used for the invoice on checkout. Any changes on pricing policy, will have no effect on offered prices. However, any difference will be shown at the checkout - so you can choose which one should be invoiced.',
    // dont_forget_press_Save_note:
    // "Don't forget to press SAVE on the reservation form after the price offer modification.",

    'Card code search results': '',
    'Search by ID': '',
    // 'GDPR Encrypted': '',
    'Created at': '',
    'POS Terminal': '',
    // Params: '',
    // 'POS Terminal Items': '',
    'Made by': '',
    'GROUP DETAILS': '',
    selected: '',
    customerDocumentIdConflictMessage:
        'There is another entry with this document id. Please, change this field or load existing customer data.',
    'LOAD EXISTING DATA': '',
    'POS Terminal Invoice': '',
    'OPEN TERMINAL': '',
    'ALL ITEMS': '',
    'List of consumed items is empty...': '',
    'Search room...': '',
    // 'Issue invoice and preview': '',
    TERMINAL: '',
    'Items from terminal': '',
    'Items added to reservation ': '',
    'Reservation holder: ': '',
    'SUCCESSFULLY ADDED': '',
    'Select room to save on reservation or issue invoice': '',
    // 'SAVE ON RES.': '',
    'Terminal is disabled... Enable it before use!': '',
    // "Search price lists by tags...": "",
    // "ADD PRICE LIST": "",
    // "HIDE PRICE LISTS": "",
    "OIB": "",
    "Business space label": "",
    "Toll device label": "",
    "Sequence marker": "",
    "VAT payer": "",
    "Tax 2": "",
    "Tax 3": "",
    "ATTACH TO GROUP RES.": "",
    "Pin code": "",
    "Invoice discount": "",
    "Enable fiscalisation": "",
    "FISCAL GENERAL SETTINGS": "",
    "READ FROM SCANNER": "",
    "Select document scan": "",
    "noDocumentScansNote": "No document scans available. Please put document on document scaner and ensure that reading application is up and running.",
    'Enable document scanner for QR Self Check In': "",
    // "Do you want to generate a new qr code for this access point?": "",
    // "Do you want to proceed?": "",
    "Regenerate QR": "",
    "Generate QR": "",
    "After document scanner": "",
    "Message after successfull scan": "",
    'Slice pricelist by tags': '',
    'Select accommodation pricelist': '',
    // 'The advance payment is disabled for the selected amount, issue regular invoice instead!': '',
    // 'Allowed advance payment': '',
    // 'Tax value': '',
    'Value': "",
    'Value with discount': '',
    'Additional discount': '',
    // "EDIT GROUP RES.": "",
    "ADD MORE RESERVATIONS": "",
    'Partial payment': '',
    // 'Issue storno invoice': '',
    // 'Storno reason is required': '',
    'Total VAT': '',
    'Total delivered value': '',
    "Valid documents for countries who don't need visa": "",
    "Valid documents for countries requiring visa:": "",
    "Select options...": "",
    "Processing...": "",
    "HOME": "",
    "Qty.": "",
    "To pay": "",
    "All": "",
    "Search reservation by room or guest card code...": "",
    "ATTACH TO RES.": "",
    "Discount on items": "",
    "Operator": "",
    "Attach proforma invoice to room": "",
    "room ": "",
    'Update reservation': '',
    "Remove from group": "",

    'Invoice value': '',
    'Advance payments': '',
    "DELETE RES.": "",
    "Do you really want to remove this reservation from group? Please confirm and reservation will become standalone!": "",
    "HOUSEHOLD REPORT": "",
    'Fully': '',
    "Rooms and pricelists": "",
    "Tag added": "",
    "ROOMS": "",
    "PRICELISTS": "",
    "Tag deleted": "",
    'Previous advance payments': '',
    'Apply': '',
    'Ignore': "",
    'Check out now and move the next reservation.': '',
    'Check out before the start of the next reservation.': '',
    'Check out at initial checkout.': '',
    'Confirm the current date and move the next reservation.': '',
    'Set reservations manually.': '',
    "Do you want to use same pricelist on new room or select new one?": "",
    "Keep pricelist": "",
    "Change pricelist": "",
    "NOTIFICATIONS": "",
    "Notification subscriptions": "",
    "Select the events you want to subscribe to": "",
    "Given price offer": "",
    "Given price offer change detected": "",
    "person(s)": "",
    "per day": "",
    "Apply new price": "",
    "checkoutDateMissmatchNote": "Todays date is different from the one planned in reservation checkout ({X}). Would you like to apply current date ({Z}) as checkout date to the reservation ({Y})?",
    "Reservations from the group": "",
    "Apply to all": "",
    "Reload page": "",
    "reloadPageNote1": "Data loading is taking more than usual.",
    "reloadPageNote2": "Wait a little more or try to reload the page.",
    "reloadPageNote3": "If problem persist, conntact the support.",


    reservationsPanel: "Reservation panel",
    invoicesPanel: "Invoices panel",
    roomEventsPanel: "Room events panel",
    accessControlPanel: "Access control panel",
    reservationsPanel_general: "Reservations Report",
    reservationsPanel_customerReports: "Customers data reports",
    reservationsPanel_reservationsConclusion: "Reservations conclusion",
    reservationsPanel_registeredGuests: "Registered guests report",
    invoicesPanel_general: "Invoices Report",
    invoicesPanel_financialReport: "Financial Report",
    invoicesPanel_cashRegistarsReport: "Cash Registars Report",
    invoicesPanel_cashRegistarPaymentsReport: "Cash registar Payments",
    roomEventsPanel_posTerminalReport: "Pos Terminal report",
    roomEventsPanel_householdReport: "Household report",
    roomEventsPanel_general: "Rooms Report",
    roomEventsPanel_SOSLog: "SOS log",
    roomEventsPanel_minibarReport: "Minibar report",
    accessControlPanel_systemAudit: "System Audit",
    accessControlPanel_general: "Access Control Report",
    roomsPanel: "Rooms panel",
    pricePanel: "Price panel",
    foldersPanel: "Folders panel",
    advancedSettingsPanel: "Advanced settings panel",
    licencePanel: "Licence panel",
    businessConclusionPanel: "Business conclusion",
    roomsPanel_rooms: "Rooms",
    roomsPanel_sosGroups: "SOS groups",
    roomsPanel_controllers: "Controllers",
    roomsPanel_posTerminal: "POS Terminal",
    roomsPanel_cashRegistar: "Cash registar",
    posConfigurationPanel_tablesLayout: "Tables layout",
    businessConclusionPanel_posTerminal: "POS Terminal",
    businessConclusionPanel_cashRegistar: "Cash registar",
    posConfigurationPanel_posTerminal: "POS Terminal",
    goodsManagementReportPanel_goodsManagement: "Goods management report",
    goodsManagementReportPanel_entryCalculations: "Entry calculations report",
    goodsManagementPanel: 'Goods management',
    goodsManagementPanel_documents: "Documents",
    goodsManagement_entryCalculation: "Entry calculation",
    goodsManagementPanel_warehouses: 'Warehouses',
    goodsManagementPanel_stockKeepingUnits: "SKU Items",
    goodsManagementPanel_skuPluRate: "SKU PLU Rate",
    roomsPanel_SRCTemplates:"SRC templates",
    roomsPanel_AlarmAndRoomMode: "Alarm settings",
    pricePanel_taxes:"Taxes",
    pricePanel_accomodation:"Accommodation",
    pricePanel_currencies:"Currencies",
    pricePanel_pricelistLookup:"Pricelist lookup",
    businessConclusionPanel_reservationConclusion: "Reservations conclusion",

    posConfigurationPanel_posGoods: "POS goods",
    pricePanel_fiscalPrinters: "Fiscal printers",
    foldersPanel_customers: "Customers",
    foldersPanel_companies: "Companies",
    foldersPanel_reservations: "Reservations",
    advancedSettingsPanel_settings: "Settings",
    advancedSettingsPanel_users: "Users",
    advancedSettingsPanel_notificationServiceSettings: "Notification service settings",
    advancedSettingsPanel_visaInfo: "Visa Info",
    posConfigurationPanel_displayConfigInfo: "POS Display Config",
    advancedSettingsPanel_selfCheckInAdministration: "Self check in administration",
    licencePanel_licence: "Licence",
    accessControlPanel_doorsAndRules: "Doors & rules",
    accessControlPanel_whitelists: "Whitelists",
    accessControlPanel_documentScannerDevices: "Document Scanners",
    "Rooms linking": "",
    "Pricelist linking": "",

    "Issue invoice": "",
    "Issue advance invoice": "",
    "Issue proforma": "",
    "Close reservation": "",
    "REGULAR INVOICE": "",
    "ADVANCE": "",
    "PROFORMA": "",
    "of": "",
    "Invoice": "",
    "More than one reservation in conflict, please adjust them manualy!": "",
    "CLEAR ROOM CODES": "",
    "Fiscal certificate settings are not available!": "",
    "Toll device sellection list is empty!": "",
    "Fiscal printer list is empty!": "",
    "Business space sellection list is empty!": "",
    "Linked invoices": "",
    "click to expand": "",
    "Advance invoices": "",
    "Proforma invoices": "",
    "POS terminal invoices": "",
    "Issue storno invoice": "",
    "Storno invoices": "",
    "(invoice)": "",
    "(advance)": "",
    "(proforma)": "",
    "Genuine invoices": "",
    "Search for a customer or company name": "",
    "CLOSED GROUP RESERVATIONS": "",
    "No reservations": "",
    "Active": "",
    "Checked out": "",
    "Unknown": "",
    "Guest arrival": "",
    "Due out": "",
    "Stayover": "",
    "not paid": "",
    "fully paid": "",
    "Entry date": "",
    "Nationality": "",
    "Phone": "",
    "Document Id": "",
    "Document Valid Period": "",
    "Reclamation type": "",
    "Action": "",
    "Regular": "",
    "Advance": "",
    "Proforma": "",
    "Event id": "",
    "Rule name": "",
    "Start time": "",
    "Day of month": "",
    "Duration minutes": "",
    "End time": "",
    "Time": "",
    "Source": "",
    "Event duration": "",
    "Assistance duration": "",
    "Room entry after start (min)": "",
    "Reaction time (min)": "",
    "MinibarLog id": "",
    "Item name": "",
    "Previous state": "",
    "New state": "",
    "Log type": "",
    "Reservation": "",
    "Created At": "",
    "Total price": "",
    "Cleaning Started": "",
    "Cleaning Finished": "",
    "Duration (m)": "",
    "Cleaning Status": "",
    "Cleaned by": "",
    "Pricelists by tags": "",
    "*drag and drop tags to rooms or pricelists": "",
    "Filter rooms and pricelists by tags": "",
    "Rooms by tags": "",

    pricePanel_posGoods:"POS goods",
    "Goods management":"",
    "All about goods management process.":"",
    "Goods management reports":"",
    "All about goods management reports.":"",
    "Stock headers": "",
    "SKU Items": "",
    "Goods management report":"",
    "Entry calculations report":"",
    "Warehouses": "",
    "Entry calculation":"",   

    "Initial setup required":"",
    "SAVE AND NEXT":"",
    "Does your hotel have multiple floors or buildings?":"",
    "Number of floors":"",
    "Number of rooms ":"",
    "Accept or change rooms name, bed and spare bed count":"",
    "floor":"",
    "Mark common attributes for all rooms":"",
    atr_airConditioning:"Air conditioning",
    atr_kitchen_kitchenette:"Kitchen/kitchenette",
    atr_privateBathroom:"Private bathroom",
    atr_balcony:"Balcony",
    atr_privatePool:"Private pool",
    atr_terrace:"Terrace",
    atr_washingMachine:"Washing machine",
    atr_flatScreenTV:"Flat screen TV",
    atr_view:"View",
    atr_coffeeTeaMaker:"Coffee tea maker",
    atr_kettle:"Kettle",
    atr_bathtub:"Bathtub",
    atr_hydromassageBath:"Hydromassage bath",
    atr_bedForOnePerson:"Bed for one person",
    atr_doubleBed:"Double bed",
    atr_minibar: "Minibar",
    "Apply new checkout date":"",
    "Insert accomodation price for persons number":"",
    "Number of persons":"",
    "Price for ":"",
    "Select notification language":"",
    "EMAIL SERVICE SETTINGS":"",
    "SMS SERVICE SETTINGS":"",
    IsEnabled:"Is enabled",
    ApiPath:"API path",
    ApiObject:"API object",
    Sender:"Sender",
    Title:"Title",
    Body:"Body",
    SELFSERVICECHECKINHARDWARE:"HARDWARE SELF SERVICE CHECK IN TEMPLATES",
    SELFSERVICECHECKINSOFTWARE:"SOFTWARE SELF SERVICE CHECK IN TEMPLATES",
    "Other languages":"",
    "Financial report":"",
    "taxGroup": "Tax group",
    "base": "Base",
    "taxValue": "Tax value",
    "totalValueByTax": "Total value by tax",
    "totalBaseValueGenuine": "Total base",
    "totalTaxValueGenuine": "Total tax",
    "totalValueGenuine": "Total",
    "from": "From",
    "to": "To",
    "date": "Date",
    "totalBaseValueAdvance": "Total base",
    "totalTaxValueAdvance": "Total tax",
    "totalValueAdvance": "Total",
    "totalGenuine": "Total (Genuine)",
    "totalAdvance": "Total (Advance)",
    "invoiceNumberAdvance": "Number of advance invoices issued",
    "invoiceNumberGenuine": "Number of invoices issued",
    "Document Scanners": "",
    "Method": "",
    "Path": "",
    "DOCUMENT SCANNER DEVICES": "",
    "NEXT CHECKIN/CHECKOUT": "",
    "Everybody": "",
    "Rookie": "",
    "Intermediate": "",
    "Experienced": "",
    "Household report": "",
    "Please, select a price list or apply custom price!": "",
    "Please, select customer or company!": "",
    "Please, select a room!": "",
    "Reservation is closed!": "",
    "Upload a file": "",
    "Basic consent": "",
    "Preferences": "",
    "GDPR OPTIONS": "",
    "API keys access disabled": "",
    "Enter room IDs separated with comma. Example: 1,2,12": "",
    "Other rooms": "",
    "Manual backup": "",
    "WARNING: If using manual mode, make sure you have fast connection with server.": "",
    "Database restoring in progress. Please wait.": "",
    "DOWNLOAD DATABASE": "",
    "Database dumping in progress...": "",
    "active": "",
    "inactive": "",
    "pricePanel_depositRegister":"Deposit register",
    "Deposit register":"",
    "Undo last offer":"",
    "Previous checkout date":"",
    "Discount value":"",
    "After invoice discount applied":"",
    "Current week":"",
    "Current month":"",
    "Today":"",
    "Rooms with requiring attention":"",
    "Welcome to our hotel!":"",
    "We wish you a pleasant stay.":"",
    "To enter, please enter the pin code first.":"",
    "You have successfully checked in to the hotel!":"",
    "You can proceed to your room ":"",
    "Pleasant stay!":"",
    "FINISH":"",
    "Self check in logs":"",
    "Your reservation is ":"",
    "Please select one of the following guests first, then scann his document data.":"",
    "Other guests: ":"",
    "Guest ":"",
    "Scanned data":"",
    "DECLINE":"",  
    "Tomorow":"",
    "Potential hotel occupancy":"",
    "days":"",
    "Next week":"",
    "Next month":"",
    "Accomplished":"",
    "Incomings":"",
    "Departures":"",
    "Occupied rooms":"",
    "Method successfully passed":"",
    "Error message":"",
    "SELF CHECK IN LOGS":"",
    "Number of days for encripting guests":"",
    "Include accommodation tax on invoice":"",
    "Tax2":"",
    "Daily report":"",
    "Invoice notes (Tax free)":"",
    "Invoice number":"",
    "Invoicing time":"",
    "Method of payment":"",
    "Search by door acces point name":"",
    "NOTIFICATION SETTINGS":"",
    "Set email and sms service settings":"",
    "This is ":"",
    "primary step":"",
    " for any of self check in types":"",
    "CARD SELF CHECK IN":"",
    "Set":"",
    "hardware notification template":"",
    "for card self check in":"",
    "DOCUMENT SCANNER SELF CHECK IN":"",
    "Initialize":"",
    "document scanner device":"",
    "for self check in":"",
    "Connect document scanner device to the some":"",
    "access point":"",
    "QR CODE SELF CHECK IN":"",
    "software notification template":"",
    "for qr self check in":"",
    "No assignments":"",
    "Reservation in progress":"",
    "Number of schedule days":"",
    "Clear":"",
    "TAGS":"",
    "Clear tags":"",
    "Select personel":"",
    "Please select one or more rooms to make a cleaning schedule, or select personel to view their assigned cleaning schedule.":"",
    "Now select a cleaning personel to make a schedule for selected rooms.":"",
    "Stay":"",
    "Res.":"",
    "Select rooms":"",
    "My reservations":"",
    "Occupancy overview":"",
    "Hotel name":"",
    "Book reg.num.":"",
    "Reference Res.id":"",
    "Birth town":"",
    "Daily":"",
    "Monthly":"",
    "Attributes":"",
    "Select Icon":"",
    "Reference to document scanner device":"",
    "Add tags":"",
    "Is Tax included in price":"",
    "Tax is included in price":"",
    "Tax not included in price":"",
    "Personal days for CONDO user per year":"",
    "Select role...":"",
    "Role selection":"",
    "Group reservation name":"",
    "Group name":"",
    "Acces control QR Generator":"",
    "Self check-in type":"",
    "SELECT GROUP":"",
    "Please select group reservation holder!":"",
    "Checkin date can't be greater than checkout date!":"",
    "Reservations group does not exists!":"",
    "Group does not have any reservation.":"",
    "Reservation is not valid! Please ensure validity before sending to the server!":"",
    "Condo user":"",
    "Admin":"",
    "Document scanner":"",
    "Maid":"",
    "Security":"",
    "Are you sure you want to change room mode?":"",
    
    "DocumentId":"",
    "Contact":"",
    "First and last name":"",
    "ENABLED Terminal!":"",
    "DISABLED Terminal...":"",
    "Acc. price rules":"",
    "Short name":"",
    "Reservation is not checked in or closed.":"",
    "First add items in room minibar.":"",
    "Include occupied rooms":"",
    "Access only": "",
    "Room successfully added!": "",
    "Room deleted!": "",
    "Terminal added!": "",
    "Whitelist added!": "",
    "Whitelist deleted!": "",
    "Document scanner device is deleted!": "",
    "Document scanner device added!": "",
    "User is deleted!": "",
    "User successfully added!": "",
    "Tax deleted!": "",
    "Tax added!": "",
    "Accomodation price list deleted!": "",
    "Accomodation price list added!": "",
    "Currency deleted!": "",
    "Currency added! Please set exchange rate for created currency!": "",
    "PosItem inactivated!": "",
    "PosItem added!": "",
    "Customer created!": "",
    "Company created!": "",
    "Company deleted!": "",
    "Notification on failure": "",
    "Minibar invoices": "",
    "unknown": "",
    "noReservation": "",
    "arrival": "",
    "dueOut": "",
    "stay": "",

    "Cash Registars": "",
    "Cash Registar": "",
    "Open registar": "",
    "payIn": "Pay in",
    "payOut": "Pay out",
    "Pay IN": "",
    "Pay OUT": "",
    "Prev state": "",
    "Purpose": "",
    "Cash registar report num. ": "",
    "Cash Registar ": "",
    "Id num.": "",
    "Num.": "",
    "New payment purpose": "",
    "New report": "",
    "CREATE REPORT": "",
    "ADD NEW PAYMENT PURPOSE": "",
    "Are you sure you want make report from ": "",
    " to ": "",
    "Type of purpose": "",
    "Pay ins and pay outs": "",
    "New cash registar": "",
    "Edit cash registar": "",
    "Open cash registar": "",
    "Cash registars report": "",
    "Cash registar payments": "",
    "Uncollected Bills": "",
    "Change payment status": "",
    "Are you sure you want to set payment status of selected invoices to PAID and create payments?": "",
    "Select period": "",
    "Are you sure you want create proforma?": "",
    "Create proforma": "",

    "Require household inspection after cleaning is complete": "",
    "Fiscal General Settings": "",
    "New item": "",
    "Edit item": "",
    "Doc Type": "",
    "Document number": "",
    "Scan time": "",
    "Bussiness manager": "",
    "Restore default permissions": "",
    "Self check in administration": "",
    noActionTaken: "No action taken",
    cleaningRequested: "Cleaning requested",
    stagedForCleaning: "Staged for cleaning",
    cleaningStarted: "Cleaning in progress",
    cleaningFinished: "Finished",
    cleaningPostponed: "Postponed",
    cleaningSkipped: "Cleaning skipped",
    cleaningInspectionRequired: 'Room inspection required',
    cleaningInspectionStarted: 'Room inspection started',
    cleaningInspectionFailed: 'Room inspection failed',
    cleaningInspectionPassed: 'Room inspection passed',
    "Filter by tag": "",
    "Filter by attributes": "",
    "List empty...": "",
    "Daily info": "",
    "Weekly and monthly info": "",
    "any selected attribute": "",
    "all selected attributes": "",
    RESERVATIONCONFIRMATION: "RESERVATION CONFIRMATION TEMPLATE",
    ROOMFAILURE: "ROOM FAILURE TEMPLATE",
    "Use html template": "",
    RESERVATIONCANCELLATION: "RESERVATION CANCELLATION TEMPLATE",
    "Comercial reservation": "",
    "Before check-out, you need to go to the reception for invoicing!": "",
    "Available days": "",
    "Used days": "",
    "Reservations total": "",
    "Events": "",
    "Room is NOT encoded with condo owner card code!": "",
    "My apartments": "",
    "Are you sure you want to close reservation?": "",
    "If not checked, body and title use system defined values": "",
    "If checked, email is sent like html page": "",
    "Message": "",
    "Availability range": "",
    "Select a period on the dashboard toolbar!": "",
    "Next 15 days": "",
    "Next 60 days": "",
    "Preferred language": "",
    "Room failure receiver email": "",
    "Send confirmation email again": "",
    "Send self check in email again": "",
    "Confirm reservation": "",
    "Other language": "",
    "Room price": "",
    "Price per night": "",
    "Accommodation tax rate": "",
    "Accommodation Tax already exist!": "",
    "discountedaccommodationtax": "Discounted accommodation tax",
    "Apply footer (Max 2 lines)": "",

    "All guests": "",
    "Domestic guests": "",
    "Foreign guests": "",
    "Total days": "",
    "Maximum number of days for condo reservations in this room exceeded": "",
    "Remaining number days for condo reservations": "",
    "Current room tags": "",
    "Current room attributes": "",

    "Filter by statuses": "",
    paymentbyInvoice: "Payment by invoice",
    travelExpenses: "Travel expenses",
    maintenance: "Maintenance",
    procurementOfSmallInventory: "Procurement of small inventory",
    invoiceStorno: "Invoice storno",
    invoiceIssued: "Invoice issued",
    "There are no controllers associated with this SRC proxy": "",
    "Select rooms for notification on failure": "",
    "No connected rooms": "",
    "Slack service settings": "",
    "SLACK SERVICE SETTINGS": "",
    "Accommodation invoice": "",
    "The cost of canceling a reservation": "",
    "Cancellation fee": "",
    "Removed from group": "",
    "Send room failure notification for the room ": "",
    "Don't send room failure notification for the room ": "",
    "Cash registar maximum: ": "",
    "Cash registar: ": "",
    "Restart device": "",
    "Full price": "",
    "Partial fee": "",
    "No charge": "",
    "Invoicing method": "",
    "To: (Optionally, if skipped, only a copy of the entered document will be printed)": "",
    "Fiscal tools": "",
    "Cash maximum": "",
    "ADD NEW PAYMENT": "",
    "CONCLUDE CASH REGISTAR REPORT": "",
    "Payment type": "",
    "Payment purpose": "",
    "UNCOLLECTED BILLS": "",
    "Guest/Res": "",
    "Payment status": "",
    "Check to change status": "",
    "Max value is invoice amount": "",
    "Search by invoice id": "",
    "PAY": "",
    "New payment": "",
    "virman_cashRegistar": "Virman cash registar",
    "Business conclusion": "",


    "If the square is marked the price can be changed in the terminal": "",
    "Add new price or confirm current:": "",
    "New price": "",
    "Select template for CSV download file": "",
    "Report num.": "",
    "EXPORT CSV": "",
    "CONCLUDE RESERVATIONS": "",
    "Search by item name": "",
    "Predefined notes": "",
    "Please confirm.": "",
    "Remove invoice": "",
    "Tax exemption": "",

    "CONCLUDE FISCALS": "",
    "CONCLUDE CASH REGISTARS": "",
    "SHOW RESERVATION REPORT": "",
    'Adults days': "",
    'Child days': "",
    'Acc.value': "",
    'Acc.VAT': "",
    'AccTax.value': "",
    'AccTax.VAT': "",
    'Other value': "",
    'Other VAT': "",
    "Are you sure you want to conclude reservations? Once you conclude you will not be able to get same reservations again, only in reports.": "",
    "Reservations conclusion report": "",
    "Report Header id": "",
    "Accommodation value": "",
    "Accommodation VAT": "",
    "Acc.Tax VAT": "",
    "Persons": "",
    "Registered guests report": "",
    "Registered guests": "",
    "Reservation report items": "",
    "UNINVOICED": "",
    "Item": "",
    "Create invoice": "",
    "Calculate": "",
    "Select terminal...": "",
    "Download csv": "",
    "Get export data": "",
    "Attach items to pos terminal ": "",
    "Tables Layout": "",
    "New table layout": "",
    "Edit table layout": "",
    "Background image": "",
    "TABLE ARRANGEMENT": "",
    "Table arrangement ": "",
    "Associated terminals": "",
    "Select terminals": "",
    "Subscribed tags": "",
    "Select tags": "",
    'Create new': '',
    'CREATE NEW': '',
    'Number of tables': '',
    Table: '',
    'SHOW ARRANGEMENT': '',
    'Num. of seats': '',
    'Table layout': '',
    'Background position': '',
    "Number of seats": "",
    "Edit table": "",
    "SHOW TABLES LIST": "",

    "Pos items table view": "",

    "Search by invoice number or invoice id": "",
    "INV. NUM.": "",
    "DATE": "",
    "TOTAL VALUE": "",
    "Accommodation invoices": "",
    "Update customer": "",
    "Do you want to update the customer with new document id?": "",
    "Passport": "",
    "Identity card": "",
    "Visa": "",
    "Driving licence": "",
    "Other": "",
    "Reservation should be checkouted!": "",
    "Min. Stock": "",
    "Reservations conclusion": "",
    "FISCAL TOOLS": "",
    "CASH REGISTARS": "",
    "After click, ": "",
    "for every PosTerminal select ": "",
    "for every Cash Registar select ": "",
    "then click on ": "",
    " to conclude": "",
    "Conclude reservations": "",
    "Conclude cash registar report": "",
    "select ": "",
    'Set min. stock': "",
    "Total currencies": "",
    "Exc.rate": "",
    "Are you sure you want to delete this payment?": "",
    "CREATE NEW GROUP": "",
    "Group Invoices": "",
    "Group View": "",
    "CLOSED GROUP INVOICES": "",
    "Group invoice holder": "",
    "Group invoice name": "",
    "Search invoices": "",
    "Search group by groupName...": "",
    "Attach proforma invoice to group": "",
    "Update group": "",
    "ATTACH TO GROUP": "",
    "Customer && Company": "",
    "Activate": "",
    "Accommodation tax is calculated on the invoice and pro forma invoice as a separate item. It is not calculated on the advance invoice. (Sugested approach)": "",
    "Accommodation tax is calculated only on the invoice as a separate item. It is not calculated on the pro forma invoice and advance invoice. Note (on the pro forma invoice): Accommodation tax is not included in the price of accommodation and is charged at the reception, after the realization of the service.": "",
    "Accommodation tax is calculated on all types of invoices as a separate item.": "",
    "Accommodation tax is calculated on all types of invoices within the room price. Note (on the invoice and pro forma invoice): Accommodation tax is included in the price of accommodation. Internal note: The hotel charges for the service at the net price, and the accommodation tax is treated as their internal cost. Therefore, the fee item is not charged directly to the client at all, and no invoice is issued for it at all. The hotel later registers the guests of the services for foreigners, on the basis of which they will receive an invoice for the accommodation tax.": "",
    "Invoicing settings": "",
    "Approach": "",
    "The expected (entered) price of the room includes the price of the accommodation tax. After entering the expected price, the price of accommodation will be adjusted depending on the price of the accommodation tax. Note: the estimated price of the room will be calculated in full and in case of charged unused overnight stay. (earlier check-out)": "",
    "The expected (entered) price of the room includes the price of the accommodation tax. After entering the expected price, the price of accommodation will be adjusted depending on the price of the accommodation tax. Note: the estimated price of the room will be reduced by the value of the accommodation tax in case of charged unused overnight stay (earlier check-out).": "",
    "The expected (entered) price of the room does not include the price of the accommodation tax.": "",
    "Pricing": "",
    "Checkout options": "",
    "Apply current time": "",
    "Apply the planned time": "",
    "NOTE: Please select time that will be applyed as check in time": "",
    "Please select a check-out time": "",
    "Checkout confirmation": "",
    "Cash registar and business conclusion": "",
    "All business conclusions in one place.": "",
    "Accom. value": "",
    "Accom. VAT": "",
    "Accom. tax": "",
    "Accom.Tax VAT": "",
    "Group invoice": "",
    "Group made by": "",
    "Invoice header": "",
    "Number of items on a one-sided invoice": "",
    "Number of items on the first page": "",
    "Number of items on the last page": "",
    "Number of items on the middle pages": "",
    "Issuer company name": "",
    "Set note": "",
    "Ref. invoice": "",
    "WARNING!!! You have some reservations that shoud be checked in or checked out. Please edit them if you want to include them in export. Remember - only checked in and checkouted reservations are included in csv export.": "",
    "Acommodation Tax Value": "",
    "Are you sure you want to conclude reservations? Once you conclude you will not be able to get same reservations again.": "",
    "View details": "",
    "View guests": "",
    "Show codebook": "",
    "Download edited csv": "",
    "genderTypes": "Gender types",
    "visaTypes": "Visa types",
    "documentTypes": "Document types",
    "placesTypes": "Places types",
    "countryTypes": "Country types",
    "Select codebook": "",
    "Checked in guests": "Checked in",
    "Checked out guests": "Checked out",
    "Domestic": "",
    "Foreign": "",
    "Export": "",
    "Select filter for export data": "",
    "Show all": "",
    "Enable POS print": "",
    "Print width (Number of characters)": "",
    "Proxy name": "",
    "SAVE EDITED DATA": "",
    "Customer(s) edited!": "",
    "password reset": "",
    "user": "",
    "api key": "",
    "Delete ": "",
    "Confirm ": "",
    "src template": "",
    "proxy restart": "",
    "proxy reset": "",
    "room attribute": "",
    "Restore": "",
    "Add room to reservation": "",
    "reservation guest": "",
    "minibar data": "",
    "reservation holder": "",
    "reservation": "",
    "_tax":"tax",
    "pos item": "",
    "currency": "",
    "exchange rate": "",
    "accommodation price list": "",
    "pos terminal": "",
    "company": "",
    "door access point": "",
    "smart room controller": "",
    "Nights":"",
    "By countries":"",
    "Please add serial book number and foreign service username":"",
    "Serial Book Number":"",
    "Foreign service username":"",
    'Pos configuration': '',
    'Set terminal settings.': '',
    "Enable side menu":"",
    "Rooms and price lists":"",
    "The guests should have been checked out":"",
    "The guests should have been checked in":"",
    "The arrival of guests is today":"",
    "The departure of guests is today":"",
    "Arrival":"",
    "Departure":"",
    "Journal":"",
    REMINDEREMAILRESERVATION:"REMINDER CONFIRMATION EMAIL TEMPLATE",
    "day before check in":"",
    "days before check in":"",
    "Don't send":"",
    "Check in reminder email":"",
    "Guest data":"",
    "Minibar general":"",
    "Select rooms and items to apply same minibar and add min stock":"",
    "Clear access codes":"",
    "Skipp cleaning":"",
    "Cleaning note":"",
    "Inspection note":"",
    "Order num.":"",
    "Doc.id":"",
    "Select export type":"",
    "Default":"",
    "Guest book":"",
    "Guest list":"",
    "Acc.Tax Value":"",
    "Acc.Tax Value Upon Reg.Guests":"",
    "Invoice num.":"",
    "Group uuid":"",
    "Created":"",
    "Prev. state":"",
    "Cash registar":"",
    "Registar max.":"",
    "Next":"",
    "Name to display":"",
    "False state label":"",
    "True state label":"",
    "False state icon color":"",
    "True state icon color":"",
    "False state animation blink":"",
    "True state animation blink":"",
    "Self check in test mode":"",
    "Send reservation confirmation to guest":"",
    "Terminal type":"",
    "CHECK USER":"",
    "There is a few similar guests. Click on card that match with your data or create new with scanned data if no data match.":"",
    "CANCEL ALL":"",
    "Cancel all reservations":"",
    "Do you really want to cancel all this reservations?":"",
    "Are you sure you want to reset room remotely" : "",
    "Remote room reset": "",
    "Reset room": "",
    "Never": "",
    "Select filter type":"",
    "By registered guests":"",
    "Show reservation conclusions":"",
    "Show registered guests":"",
    "Doc.num.":"",
    "Doc.type":"",
    "Visa Type":"",
    "Visa Num.":"",
    "Availability":"",
    'Z reports': '',
    "Count day stay as night":"",
    'Reservation holder': '',

    "Edit warehouse":"",
    "New warehouse":"",
    "warehouse":"",
    "Barcode":"",
    "Measure code":"",
    "Sales price":"",
    "Active item":"",
    "Add category":"",
    "Warehouse added!":"",
    "Item inactivated!":"",
    "item":"",
    "Create a norm":"",
    "Sku item":"",
    "Set multiplier":"",
    "Documents":"",
    "Items count":"",
    "Items value":"",
    "Created by":"",
    "Warehouse":"",
    "goodsReceivingNote":"Goods receiving note",
    "goodsDespatchNote":"Goods despatch note",
    "writeOffGoods":"Write-off goods",
    "Document header":"",
    "CONCLUDE DOCUMENT":"",
    "You can not change code!":"",
    "Item does not exist!":"",
    'skuItem':'Item',
    'barcode':'Barcode',
    'quantity':'Quantity',
    'price':'Price',
    'purchasePrice':'Purchase price',
    "This document number already exist!":"",
    "totall":"TOTAL",
    "Purchase price":"",
    "Commited by":"",
    "Commited date":"",
    "Created date":"",
    "Show stock":"",
    "There is no active documents.":"",
    "CONCLUDED DOCUMENTS":"",
    "Normative":"",
    "CREATE NORMATIVE":"",
    "taxId1":"Tax1",
    "taxId2":"Tax2",
    "taxId3":"Tax3",
    "skuId":"Item id",
    "Please add code first!":"",
    "grossMargin":"Gross margin",
    "New entry calculation":"",
    "Edit entry calculation":"",
    "There is no active entry calculations.":"",
    "CONCLUDED ENTRY CALCULATIONS":"",
    'code':'Code',
    'name':'Name',
    'entryPrice':'Entry price',
    'entryVAT':'Entry VAT',
    'entryVATInfo':'Entry VAT Info',
    'grossMarginPercentage':'Gross margin percentage',
    'grossMarginValue':'Gross margin value',
    'tax1Total':'Tax1 total',
    'tax2Total':'Tax2 total',
    'tax3Total':'Tax3 total',
    'exitPrice':'Exit price',
    'itemId':'Item id',
    "Select conclude type":"",
    "CONCLUDE DOCUMENT AND CREATE ENTRY CALCULATION":"",
    "Edit document":"",
    "New document":"",
    "Document is concluded!":"",
    "Please first add document header data!":"",
    "Entry calculation header":"",
    "Entry calculation is concluded!":"",
    "Please first add entry calculation doc number data!":"",
    "CONCLUDE ENTRY CALCULATION":"",
    "CONCLUDE ENTRY CALCULATION AND CREATE GOODS RECEIVING NOTE":"",
    'Barcode already exists!':'',
    "Barcode is not valid!":"",
    "tax1":"Tax1",
    "tax2":"Tax2",
    "tax3":"Tax3",
    "Multiplier":"",
    "Name, code, measure code":"",
    "measureUnit":"Measure unit",
    "with entry calculation":"",
    "Supplier":"",
    "Total transportation":"",
    "Total shipping":"",
    "Total customs":"",
    "Total other costs":"",
    "transportation":"Transportation",
    "shipping":"Shipping", 
    "customs":"Customs",
    "otherCosts":"Other costs",
    "salePrice":"Sale price",
    "Occupancy":"",
    'Home':'',
    'Property Management':'',
    'Config':'',
    'Details':'',
    'Reset password':'',
    'Subscriptions':'',
    'Hotel guests':'',
    'You need help? Check some of frequently asked questions': '',
    'Frequently asked questions': '',
    'You still need help? Contact as at':'',
    "New room":"",
    "Edit room":"",
    "New plan":"",
    "Edit plan":"",
    "New virtual plan":"",
    "Edit virtual plan":"",
    "Plans":"",
    "Room Type":"",
    "Save changes":"",
    "Update to":"",
    "Update availability":"",
    "Map to room(s)":"",
    "Select pricelist":"",
    "Virtual rooms":"",
    "Pricing plans":"",
    "Apartment":"",
    "Bed":"",
    "Unit":"",
    "No board":"",
    "Breakfast":"",
    "Half board":"",
    "Full board":"",
    "All inclusive":"",
    "Shortname":"",
    "Number of guests":"",
    "Default price":"",
    "Default availability":"",
    "Default board":"",
    "Fixed discount (- -)":"",
    "Fixed Increase (+ +)" :"",
    "Percentage discount (- %)":"",
    "Percentage Increase (+ %)":"",
    "Variation":"",
    "Select variation":"",
    "Closure":"",
    "Open":"",
    "Min. Stay":"",
    "Max. Stay":"",
    "No min stay restriction (1)":"",
    "No max stay restriction (1)":"", 
    "From/To":"",
    "Work on":"",
    "Restriction Plan":"",
    "mon":"",
    "tue":"",
    "wed":"",
    "thu":"",
    "fri":"",
    "sat":"",
    "sun":"",
    "DailyPlan":"Daily",
    "New daily plan":"",
    "Manage restrictions":"",
    "Room type is for example a Double Room and it is not a real room. A hotel that wants to sell 10 doubles of same standard would need to create a typology Double Room and then specify its availability as 10, which would mean that you have 10 double rooms that you are selling.":"",
    "Map to rooms you want to sell online. If you map, for example, three rooms, the availability of the Room Type will automatically become 3. You can change the availability in the 'Manage' section of the interface. Note: if you set the number of guests to 2 when creating the Room Type, then you should map your rooms that are for two people.":"",
    "A virtual room is used when you want to sell an existing room with different options and price. A virtual room will share its availability with its `mother room`. A typical example is when you have already a `Double Room`: You can create a virtual room `Double room for single use` as a virtual room of the `Double room`.":"",
    "Daily plan - When creating a daily plan, you only need to enter the name of the plan. We determine the prices for that plan in the 'Rooms' interface, where we select the price list for a specific plan. Virtual Plan - A Virtual Plan has the same prices of a 'parent plan', with a percentage variation.":"",
    "Setup the same values for one or multiple rooms between a range of dates. You can choose to update: Closure, Availability or Restrictions.":"",
    "Guest = the occupancy of the room":"",
    "Map to pricelist(s)":"",
    'HW LAN View': '',
    'Tools':'',
    "The guest's stay does not matches with the duration of the reservation":"",
    "accommodation":"Accommodation",
    "tax":"Tax",
    "posTerminal":"Pos item",
    "Split invoice":"",
    'Next invoice':"",
    "Split to":"",
    "Name, code or barcode":"",
    "interWarehouseTransfer":"Inter warehouse transfer",
    "stockMeasureUnit":"Measure unit",
    "prevAggWarehouseGoodsQty":"Last state",
    "goodsReceivingQty":"Goods receiving",
    "goodsDespatchQty":"Goods despatch",
    "tempInvoiceQty":"Invoice goods",
    "aggStockRecQty":"Last state (aggregated)",
    "tempStockRecQty":"Goods received",
    "salesPrice":"Sales price",
    "totalQty":"Total quantity",
    "todaysState":"Todays state",
    "itemPrice":"Item price",
    "stockQuantity":"Quantity (total - todays)",
    "stockPrice":"Price",
    "inventoryState":"Inventory difference",
    "stockDocItemId":"Doc.item id",
    "orderToTheSupplier":"Order to the supplier",
    "offerNote":"Offer note",
    "inventory":"Inventory",
    "adjustmentByInventory":"Adjustment by inventory",
    "inventoryGoodsReceivingNote":"Inventory goods receiving note",
    "BELOW THE MINIMUM":"",
    "ABOVE REPLENISHMENT":"",
    "invoiceGoods":"Invoice goods",
    "aggregatedWarehouseGoodsData":"Aggregated warehouse goods data",
    "The guest's stay does not match the duration of the reservation":"",
    "Document view":"",
    "Code":"",
    "Stock measure code":"",
    "Minimum stock":"",
    "Replenish stock":"",
    "ACTIVE":"",
    "INACTIVE":"",
    "Is combo item":"",
    "Warehouse stock":"",
    "State":"",
    "SHOW WAREHOUSES":"",
    "REPLENISH":"",
    "BELOW THE MIN.":"",
    "ABOVE REPL.":"",
    "purchaseMeasureUnit":"Purchase measure unit",
    "purchaseMeasureUnit_stockUnitMultiplier":"Mulitplier",
    "Purchase measure unit not equal stock measure code":"",
    "Purchase measure unit":"",
    "Document":"",
    "piecesQuantity":"Quantity (pieces)",
    "Purchase unit to stock unit multiplier":"",
    "aggregatedWarehouseGoodsCounter":"Aggregated warehouse goods counter",    
    'Not paid':"",
    'Fully paid':"",
    'Duration':"",
    'Connected terminals':"",
    'Invoice type':"",
    'Customers data report': '',
    'Search on page': '',
    'Client':"",
    'ID number': '',
    'Search for company (by name, town, idNumber)':'',
    'Search for customer (by first and last name)':'',
    "not configured":"Not configured",
    "online":"Online",
    "fading":"Fading",
    "disconnected":"Disconnected",
    'Layers':"",
    'There are no Pos Terminals connected':"",

    "Reservation table":"",
    "By status":"",
    "By tag":"",
    "By attributes":"",
    "Group":"",
    "Notification info":"",
    "change room":"",
    "Groups":"",
    "No active group reservations":"",
    "No group invoices":"",
    "Group reservations":"",
    "Group invoices":"",
    "Group reservation note":"",
    "Holder":"",
    "Group status":"",
    "Pay. status":"",
    admin:'Admin',
    condoUser:'Condo user',
    documentScanner:'Document scanner',
    gdprOfficer:'GDPR officer',
    hwAdmin:'HW Admin',
    managerFull:'Full manager',
    managerBussiness:'Bussiness manager',
    managerHousehold:'Household manager',
    managerSecurity:'Security manager',
    receptionist:'Receptionist',
    maid:'Maid',
    janitor:'Janitor',
    security:'Security',
    reporting:'Reporting',
    default:'Default',
    sos:'SOS',
    hwLANView:'HW LAN View',
    posBartender:'Pos bartender',
    posKitchen:'Pos kitchen',
    posSupervisor:'Pos Admin',
    "Document id":"",
    "Contacts":"",
    "Page":"",
    "Default pricelist":"",
    "Cash registars payments":"",
    "About":"",
    "Current software version":"",
    "Frist and last name":"",
    "Read":"",
    "Cash":"",
    "Card":"",
    "Cheque":"",
    "Virman":"",

}   
