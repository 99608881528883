import React, { Component } from 'react';
import Navbar from '../components/header/navbar';
// import Foother from '../components/footer/footer';
import Toaster from '../components/toaster';
import ModalController from '../components/modal';
import BackendDrivenToaster from './backendDrivenToaster';
import { GlobalLoading } from '../components/loaders/globalLoading';
import SideMenu from '../components/sideMenu';
import rest from '../data/restWrapper';

class MasterLayout extends Component {
    constructor() {
        super();
        this.myRef = React.createRef();
        this.state = {
            otherSettings: {},
            disableSidebarMenu: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname && this.myRef.current) {
            this.myRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
        if (prevProps.fixedRoute !== this.props.fixedRoute) {
            this.redrawComponent();
        }
    }

    async redrawComponent() {
        try {
            if (this.props.fixedRoute && this.props.fixedRoute !== '') {
                this.setState({ disableSidebarMenu: true });
            }
        } catch (error) {
            console.log(error);
        }
    }

    async componentDidMount() {
        try {
            // const navbarEL = document.getElementById('app-navbar');

            // if (window.location?.origin?.includes(`test`) || window.location?.origin?.includes(`demo`)) {
            //     navbarEL.classList.add('special-class-for-demo-and-test');
            // }
            const data = await rest('/getGeneralSettingByKey/otherSettings');
            if (data?.params) {
                this.setState({ otherSettings: JSON.parse(data.params) });
            }
            if (this.props.fixedRoute && this.props.fixedRoute !== '') {
                this.setState({ disableSidebarMenu: true });
            }
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        if (
            this.props.location.pathname.lastIndexOf('/invoiceView') !== -1 ||
            this.props.location.pathname.lastIndexOf('/proformaView') !== -1 ||
            this.props.location.pathname.lastIndexOf('/printPreview') !== -1 ||
            this.props.location.pathname.lastIndexOf('/cashRegReportView') !== -1 ||
            this.props.location.pathname.startsWith('/posTerminal/') ||
            this.props.location.search.lastIndexOf('printJournal') !== -1
        ) {
            return (
                <div>
                    {' '}
                    <ModalController />
                    <GlobalLoading />
                    {this.props.children} <Toaster />
                </div>
            );
        } else if (this.props.location.pathname.startsWith('/documentScanner')) {
            const bgCl =
                this.props.location.search === ''
                    ? 'docScann-bg-step1'
                    : this.props.location.search === '?step2'
                    ? 'docScann-bg-step2'
                    : 'docScann-bg-step3';
            return (
                <div>
                    <div className={bgCl + ' docScanner-background  mb-2 bg-light'}>
                        <Navbar {...this.props} />
                        {this.props.children}
                    </div>
                    <Toaster />
                    <BackendDrivenToaster />
                </div>
            );
        } else {
            return (
                <div ref={this.myRef} /* style={{ userSelect: 'none' }} */>
                    <GlobalLoading />
                    {/* <Navbar {...this.props} showSideMenu={this.state?.otherSettings?.showSideMenu} /> */}
                    <ModalController />
                    <div
                        className={
                            // ' reception-background  bg-light w-100' + (!showSideMenu ? ' container-fluid' : '')
                            ' reception-background  bg-light w-100' 
                        }
                    >
                        <div className="d-flex h-100 app-flex-div">
                            {/* {showSideMenu ? <SideMenu {...this.props} /> : null} */}
                            <SideMenu {...this.props} />
                            <div className='scrollX' id="main-app-div" style={{ width: '100%'}}>
                                {!this.props.authTokenInfo?.user?.role ? (
                                    <div>Insufficient permissions</div>
                                ) : (
                                    this.props.children
                                )}
                            </div>

                            <div className="clear pt-2">&ensp;</div>
                        </div>
                    </div>

                    <Toaster />
                    {/* <Foother {...this.props} /> */}
                    <BackendDrivenToaster />
                </div>
            );
        }
    }
}

export default MasterLayout;
