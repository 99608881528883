import baobabTree, { saveCachedBaobabData } from '../../data/state/index';
// import { logout as keycloakLogout } from '../../../src/keycloak'


const clearCache = () => {
  return new Promise((resolve, reject) => {
    window.localStorage.removeItem("baobab");
    window.localStorage.clear();
    return resolve(true);
  });
};

const reloadLocation = () => {
  window.location.reload(true)
}

const logout = () => {
  console.log('loging out');
  // keycloakLogout()
  clearCache().then(res => {
    window.location.hash = '#/';    // window.location.reload()
    reloadLocation()
    baobabTree.root.select('authTokenInfo').set(null);
    baobabTree.root.select('keycloak').set({authenticated:false});
    saveCachedBaobabData(baobabTree);
  })
};

export { logout };
