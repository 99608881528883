import React, { useState } from 'react';
import _ from 'lodash';
import { branch } from 'baobab-react/higher-order';
import PageLoader from '@components/loaders/loader1';
import Sidebar from './sidebar';
import FeatureFlag, { isFeatureEnabled } from '@components/FeatureFlag';
import TimelineView from './reservationsTimeline';
import HwRoomView from './hwRoomView';
import Toolbar from './toolbar';
import baobabTree from '@data/state';
import ToolbarReservationMode from './toolbarReservationMode';
import SidebarToolbar from './sidebarToolbar';
import ActivityView from './activityView';
import Journal from './journal';
import JournalWithActivity from './journalWithActivity';
// import moment from "moment"
// import { reservationStatusColors, reservationStatusEnumsDesc } from '@data/reservationStatusEnums';

function Dashboard(props) {
    const [availableFromTimestamp, setAvailableFromTimestamp] = useState(null);
    const [availableToTimestamp, setAvailableToTimestamp] = useState(null);
    const [stayInDays, setStayInDays] = useState(null);
    const [groupReservations, setGroupReservations] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [numberOfBeds, setNumberOfBeds] = useState('');
    const [numberOfSpareBeds, setNumberOfSpareBeds] = useState('');
    const [tagsFilter, setTagsFilter] = useState([]);
    const [filterOutRoomsById, setFilterOutRoomsById] = useState([]);
    const [selectedRoomsForReservations, setSelectedRoomsForReservations] = useState([]);
    const [attributesFilter, setAttributesFilter] = useState([]);
    const [attributesFilteringType, setAttributesFilteringType] = useState(true);
    const [showOccupiedRooms, setShowOccupiedRooms] = useState(false);
    const [cleaningStatusesFilter, setCleaningStatusesFilter] = useState([]);
    const [showReservationsList, setShowReservationsList] = useState(false);

    const reservations_ = props.reservations;
    const printJournal = props.location.search.lastIndexOf('printJournal') !== -1 ? true : false;

    const myRef = React.useRef(null);
    const executeScrollTop = () => myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    

    

    React.useEffect(() => {

        const loadData =  () => {           
            
            // let showComponentsFromBaobab =   baobabTree.root.select(['uiStates', 'dashboard', 'showComponents']).get();
            let showComponents = []
            if (props.history?.location?.search) {
                switch (props.history.location.search) {
                    case "?reservations":
                        showComponents = ["sidebar_dailyInfo", "timeline"]
                        break;
                    case "?journal":
                        showComponents = ["sidebar_dailyInfo", "journal"]
                        break;
                    case "?rooms":
                        showComponents = ["sidebar_dailyInfo", "tiles"]
                        break;
                    case "?printJournal":
                        showComponents = ["sidebar_dailyInfo","journal"]
                        break;
                    case "?activity":
                        showComponents = ["sidebar_dailyInfo", "activity"]
                        break;
                    case "?occupancy":
                        showComponents = ["sidebar_dailyInfo", "occupancy"]
                        break;
                    case "?jourWithAct":
                        showComponents = ["sidebar_dailyInfo", "jourWithAct"]
                        break;
                        
                    default:
                        showComponents = [""]
                        break;
                }
                baobabTree.root.select(['uiStates', 'dashboard', 'showComponents']).set(showComponents);

            }


            if (printJournal && !showComponents.includes('journal')) {
                props.history.push(``);
            }
        };

        loadData()
        // eslint-disable-next-line
    }, [printJournal, props.history]);
    
    const toggleTagFilter = (tag) => {
        if (tag === null) {
            setTagsFilter([]);
        } else {
            let _tagsFilter = [...tagsFilter];
            if (_tagsFilter.includes(tag)) {
                _tagsFilter = _tagsFilter.filter((t) => t !== tag);
            } else {
                _tagsFilter.push(tag);
                _tagsFilter = _tagsFilter.filter((t) => t === tag);
            }
            setTagsFilter(_tagsFilter);
        }
    };

    const toggleCleaningStatusesFilter = (clStatus) => {
        if (clStatus === null) {
            setCleaningStatusesFilter([]);
        } else {
            let _cleaningStatusesFilter = [...cleaningStatusesFilter];
            if (_cleaningStatusesFilter.includes(clStatus)) {
                _cleaningStatusesFilter = _cleaningStatusesFilter.filter((t) => t !== clStatus);
            } else {
                _cleaningStatusesFilter.push(clStatus);
                _cleaningStatusesFilter = _cleaningStatusesFilter.filter((t) => t === clStatus);
            }
            setCleaningStatusesFilter(_cleaningStatusesFilter);
        }
    };

    const toggleGroupReservations = (forcedValue = undefined) => {
        if (forcedValue !== undefined) {
            setGroupReservations(forcedValue);
        } else {
            setGroupReservations(!groupReservations);
        }
    };

    const setNumOfBeds = (e) => {
        if (e === '') {
            setNumberOfBeds('');
        } else if (e.target.value === '') {
            setNumberOfBeds('');
        } else {
            if (e.target.value === '0') {
                setNumberOfBeds('');
            } else {
                setNumberOfBeds(Number(e.target.value));
            }
        }
    };

    const setNumOfSpareBeds = (e) => {
        if (e === '') {
            setNumberOfSpareBeds('');
        } else if (e.target.value === '') {
            setNumberOfSpareBeds('');
        } else {
            if (e.target.value === '0') {
                setNumberOfSpareBeds('');
            } else {
                setNumberOfSpareBeds(Number(e.target.value));
            }
        }
    };

    const _setSearchTerm = (e) => {
        setSearchTerm(e.target.value);
    };

    const toggleShowComponent = (componentName, forceShow = undefined, e) => {
        let temp = _.clone(props.uiStates.showComponents);

        if (props.uiStates.showComponents.includes(componentName)) {
            temp = temp.filter((t) => t !== componentName);
        } else {
            temp.push(componentName);
        }

        if (forceShow !== undefined) {
            if (forceShow === true) {
                temp.push(componentName);
                temp = _.uniq(temp);
            } else if (forceShow === false) {
                temp = temp.filter((t) => t !== componentName);
            }
        }

        baobabTree.root.select(['uiStates', 'dashboard', 'showComponents']).set(temp);
    };

    const setAvailableFromAndToTimestamps = (availableFromTimestamp, _availableToTimestamp, stayInDays) => {
        const availableToTimestamp_ = _availableToTimestamp - 8 * 60 * 60 * 1000; //add some tollerance on filtering since this will always be at 12 o clock (noon)
        setAvailableFromTimestamp(availableFromTimestamp);
        setAvailableToTimestamp(availableToTimestamp_);
        setStayInDays(stayInDays);
    };

    const toggleSelectRoomForReservation = (roomInfoId) => {
        if (roomInfoId === null) {
            setSelectedRoomsForReservations([]);
        } else {
            let _selectedRoomsForReservations = [...selectedRoomsForReservations];
            if (_selectedRoomsForReservations.includes(roomInfoId)) {
                //remove it
                _.remove(_selectedRoomsForReservations, (r) => r === roomInfoId);
            } else {
                _selectedRoomsForReservations.push(roomInfoId);
            }
            setSelectedRoomsForReservations(_selectedRoomsForReservations);
        }
    };

    const handleAttributesSelect = (values) => {
        if (values === null) {
            setAttributesFilter([]);
        } else {
            const attrFilter = values.map((v) => v.value);
            setAttributesFilter(attrFilter);
        }
    };

    const filterRoomsByAttribute = (rooms) => {
        let _attributesFilter = [...attributesFilter];
        let filteredRooms = rooms.map((r) => {
            if (_attributesFilter.length > 0) {
                let eject = false;
                _attributesFilter.forEach((att) => {
                    if (r.roomAttributes !== null) {
                        if (!JSON.parse(r.roomAttributes).includes(att)) {
                            eject = true;
                            return true;
                        }
                    } else {
                        eject = true;
                    }
                });
                if (eject) {
                    return null;
                } else {
                    return r;
                }
            } else {
                return r;
            }
        });
        return filteredRooms;
    };

    const filterRoomsByCleaningStatuses = (rooms, cleaningStatusHeaders) => {
        let _cleaningStatusesFilter = [...cleaningStatusesFilter];

        let filteredRooms = rooms.map((r) => {
            if (_cleaningStatusesFilter.length > 0) {
                let statusObj = cleaningStatusHeaders?.CleaningStatuses?.find((sh) => {
                    return sh?.RoomInfo?.id === r?.id;
                });
                let cleaningStatus = statusObj?.cleaningStatus;
                if (cleaningStatus === _cleaningStatusesFilter[0]) {
                    return r;
                } else {
                    return null;
                }
            } else {
                return r;
            }
        });
        return filteredRooms;
    };

    const filterRoomsByAnyAttribute = (rooms) => {
        let _attributesFilter = [...attributesFilter];
        let filteredRooms = [];
        if (_attributesFilter.length > 0) {
            rooms.forEach((r) => {
                const roomAttributes = JSON.parse(r.roomAttributes) ? JSON.parse(r.roomAttributes) : [];
                _attributesFilter.forEach((a) => {
                    if (roomAttributes.includes(a)) {
                        if (!filteredRooms.map((r) => r.id).includes(r.id)) {
                            filteredRooms.push(r);
                        }
                    }
                });
            });
        } else {
            filteredRooms = rooms;
        }
        return filteredRooms;
    };

    const { parsedRoomInfo } = props,
        roomsMap = {};

    for (const r of parsedRoomInfo) {
        roomsMap[r.id] = r;
    }

    if (props.roomsByTags.length === 0) {
        return <PageLoader />;
    }

    let showOutOfServiceLegend = false;
    //in first step, filter all rooms by tag....
    let filteredRooms = parsedRoomInfo.map((r) => {
        if (tagsFilter.length > 0) {
            let eject = false;
            tagsFilter.forEach((t) => {
                if (!r.tags.includes(t)) {
                    eject = true;
                    return true;
                }
            });
            if (eject) {
                return null;
            } else {
                return r;
            }
        } else {
            return r;
        }
    });

    filteredRooms = filteredRooms.filter((i) => (i ? i.availableForRent : null));

    if (props.cleaningStatusHeaders[0]) {
        filteredRooms = filterRoomsByCleaningStatuses(filteredRooms, props.cleaningStatusHeaders[0]);
    }

    //implement room name search
    filteredRooms = filteredRooms.map((roomInfo) => {
        if (roomInfo && roomInfo.name.toLowerCase().includes(searchTerm.toLowerCase())) {
            return roomInfo;
        } else {
            return null;
        }
    });

    filteredRooms = filteredRooms.filter((f) => f !== null);
    //bed search params...
    if (numberOfBeds) {
        filteredRooms = filteredRooms.filter((r) => r.bedCount === Number(numberOfBeds));
    }

    if (numberOfSpareBeds) {
        filteredRooms = filteredRooms.filter((r) => r.spareBedCount === Number(numberOfSpareBeds));
    }

    filteredRooms = filteredRooms.filter((f) => f !== null);

    //aditional room removal....
    if (filterOutRoomsById.length > 0) {
        filteredRooms = !showOccupiedRooms
            ? filteredRooms.filter((r) => !filterOutRoomsById.includes(r.id))
            : filteredRooms;
    }

    //if search term was a card code number
    let cardNumberSearch = parseInt(searchTerm, 10);
    if (!_.isNaN(cardNumberSearch) && _.isNumber(cardNumberSearch)) {
        let reservations = reservations_.filter((r) => {
            return (
                (r.guest1CodeCopy === cardNumberSearch ||
                    r.guest2CodeCopy === cardNumberSearch ||
                    r.guest3CodeCopy === cardNumberSearch) &&
                Number(r.statusEnum) === 2
            ); //checkedIn
        });
        if (reservations.length > 0) {
            filteredRooms = []; //RESETING filteredRooms
        }
        reservations.forEach((reservation) => {
            let room = props.parsedRoomInfo.find((r) => r.id === reservation.roomInfoId);
            if (room) {
                filteredRooms.push(room);
            }
        });
    } else if (searchTerm) {
        const filteredRoomMap = {};
        for (const r of filteredRooms) {
            filteredRoomMap[r.id] = r.id;
        }

        reservations_.forEach((res) => {
            const guest = res.Customer;
            const guestNameFirst = guest ? `${guest.firstName} ${guest.lastName}` : '';
            const guestSurnameFirst = guest ? `${guest.lastName} ${guest.firstName}` : '';

            if (
                guest &&
                (guestNameFirst.toLowerCase().lastIndexOf(searchTerm.toLowerCase()) !== -1 ||
                    guestSurnameFirst.toLowerCase().lastIndexOf(searchTerm.toLowerCase()) !== -1)
            ) {
                if (!filteredRoomMap[res.roomInfoId]) {
                    filteredRoomMap[res.roomInfoId] = roomsMap[res.roomInfoId];
                    filteredRooms.push(roomsMap[res.roomInfoId]);
                }
            }

            if (
                res.Company &&
                res.Company.name &&
                res.Company.name.toLowerCase().lastIndexOf(searchTerm.toLowerCase()) !== -1
            ) {
                if (!filteredRoomMap[res.roomInfoId]) {
                    filteredRoomMap[res.roomInfoId] = roomsMap[res.roomInfoId];
                    filteredRooms.push(roomsMap[res.roomInfoId]);
                }
            }
        });
    }

    if (attributesFilteringType) {
        filteredRooms = filterRoomsByAnyAttribute(filteredRooms);
    } else {
        filteredRooms = filterRoomsByAttribute(filteredRooms);
    }


    //remove all nulls
    filteredRooms = filteredRooms.filter((f) => f !== null);

    filteredRooms = _.orderBy(filteredRooms, ['name'], ['asc']);

    var { showComponents } = props.uiStates;
    if (
        (showComponents.includes('timeline') && !isFeatureEnabled('reservationsTimelineDashboard')) ||
        props.loggedUser.role === 'hwAdmin' || props.loggedUser.role === "hwLANView"
    ) {
        showComponents = ['tiles'];
    }

    const state = {
        availableFromTimestamp,
        availableToTimestamp,
        stayInDays,
        groupReservations,
        searchTerm,
        numberOfBeds,
        numberOfSpareBeds,
        tagsFilter,
        filterOutRoomsById,
        selectedRoomsForReservations,
        attributesFilter,
        attributesFilteringType,
        showOccupiedRooms,
        cleaningStatusesFilter,
    };

    const searchParam = props.history.location.search
    return (
        <div ref={myRef}>
            {!printJournal && (searchParam === '?reservations' || searchParam === '') ? (
                <Toolbar
                    showComponents={showComponents}
                    history={props.history}
                    {...state}
                    setNumberOfBeds={setNumOfBeds.bind(this)}
                    setNumberOfSpareBeds={setNumOfSpareBeds.bind(this)}
                    setHideRoomByIdFilter={(array) => {
                        setFilterOutRoomsById(array);
                    }}
                    toggleShowComponent={toggleShowComponent.bind(this)}
                    toggleTagFilter={toggleTagFilter.bind(this)}
                    setAvailableFromAndToTimestamps={setAvailableFromAndToTimestamps.bind(this)}
                    handleAttributesSelect={handleAttributesSelect.bind(this)}
                    toggleAttributesCheck={() => setAttributesFilteringType(!attributesFilteringType)}
                    setSearchTerm={_setSearchTerm.bind(this)}
                    tagsFilter={tagsFilter}
                    attributesFilter={attributesFilter}
                    cleaningStatusesFilter={cleaningStatusesFilter}
                    setCleaningStatusesFilter={setCleaningStatusesFilter}
                    toggleCleaningStatusesFilter={toggleCleaningStatusesFilter.bind(this)}
                />
            ) : null}

            <div className=" unselectable px-0 mx-0">
                <div className="scrollbar-custom">
                    {showComponents.includes('timeline') ? (
                        <FeatureFlag flag="reservationsTimelineDashboard">
                            <ToolbarReservationMode
                                selectedRoomsForReservations={selectedRoomsForReservations}
                                toggleSelectRoomForReservation={toggleSelectRoomForReservation.bind(this)}
                                toggleGroupReservations={toggleGroupReservations.bind(this)}
                                groupReservations={groupReservations}
                                filterOutRoomsById={filterOutRoomsById}
                                showComponents={showComponents}
                                toggleShowComponent={toggleShowComponent.bind(this)}
                                setNumberOfBeds={setNumOfBeds.bind(this)}
                                setNumberOfSpareBeds={setNumOfSpareBeds.bind(this)}
                                setHideRoomByIdFilter={(array) => {
                                    setFilterOutRoomsById(array);
                                }}
                                setAvailableFromAndToTimestamps={setAvailableFromAndToTimestamps.bind(this)}
                                toogleOccupiedRooms={() => setShowOccupiedRooms(!showOccupiedRooms)}
                                setShowReservationsList={setShowReservationsList}
                                showReservationsList={showReservationsList}
                                {...state}
                                {...props}
                            />
                        </FeatureFlag>
                    ) : null}

                    {
                        showComponents.includes('journal') ? (
                            <FeatureFlag flag="reservationsTimelineDashboard">
                                <Journal
                                    {...props}
                                    reservations={reservations_}
                                    rooms={filteredRooms}
                                    executeScrollTop={executeScrollTop}
                                />
                            </FeatureFlag>
                        ) : null
                        //journal
                    }

{
                        showComponents.includes('jourWithAct') ? (
                            <FeatureFlag flag="reservationsTimelineDashboard">
                                <JournalWithActivity
                                    {...props}
                                    reservations={reservations_}
                                    rooms={filteredRooms}
                                    executeScrollTop={executeScrollTop}
                                />
                            </FeatureFlag>
                        ) : null
                        //journal
                    }

                    {showComponents.includes('tiles') ? (
                        <div className="clear w-100" style={{ overflowX: 'auto', paddingBottom: '20px' }}>
                            <HwRoomView rooms={filteredRooms} {...props} />
                        </div>
                    ) : null}

                    {
                        showComponents.includes('activity') ? (
                            <FeatureFlag flag="activityDashboard">
                                <ActivityView
                                    history={props.history}
                                    reservations={reservations_}
                                    rooms={filteredRooms}
                                />
                            </FeatureFlag>
                        ) : null
                        //activity
                    }

                    {showComponents.includes('timeline') && showReservationsList ? (
                        <div className="mb-1 clear" style={{ position: 'relative' }}>
                            <TimelineView
                                selectedRoomsForReservations={selectedRoomsForReservations}
                                showComponents={showComponents}
                                availableFromTimestamp={availableFromTimestamp}
                                availableToTimestamp={availableToTimestamp}
                                numberOfBeds={numberOfBeds}
                                numberOfSpareBeds={numberOfSpareBeds}
                                history={props.history}
                                reservations={reservations_}
                                toggleSelectRoomForReservation={toggleSelectRoomForReservation.bind(this)}
                                groupReservations={groupReservations}
                                rooms={filteredRooms}
                            />
                            {/* <div className="mb-1" style={{width:"200px", position:"absolute", right:0, top:0}}>
                         <div className="custom-list-header">
                             <div>{window.translate("Id")}</div>
                             <div>{window.translate("Holder")}</div>
                             <div>{window.translate("Room")}</div>
                             <div>{window.translate("Check in")}</div>
                             <div>{window.translate("Check out")}</div>
                             <div>{window.translate("Status")}</div>
                         </div>
                         <ReservationsList resList={reservations_} statusEnum={1} history={props.history} />
                         <ReservationsList resList={reservations_} statusEnum={2} history={props.history} />
                         <ReservationsList resList={reservations_} statusEnum={3} history={props.history} />
                     </div> */}
                        </div>
                    ) : null}

                    {showComponents.includes('timeline') && !showReservationsList ? (
                        <div className="mb-1 clear" style={{ position: 'relative', display: 'flex' }}>
                            <div style={{ width: '80%', display: 'inline-block' }}>
                                <TimelineView
                                    selectedRoomsForReservations={selectedRoomsForReservations}
                                    showComponents={showComponents}
                                    availableFromTimestamp={availableFromTimestamp}
                                    availableToTimestamp={availableToTimestamp}
                                    numberOfBeds={numberOfBeds}
                                    numberOfSpareBeds={numberOfSpareBeds}
                                    history={props.history}
                                    reservations={reservations_}
                                    toggleSelectRoomForReservation={toggleSelectRoomForReservation.bind(this)}
                                    groupReservations={groupReservations}
                                    rooms={filteredRooms}
                                />
                            </div>

                            <SidebarWrapper>
                         
                                {/* <div className="">
                                    <div
                                        className="btn-group rounded  mr-2 "
                                        style={{display: "flex"}}
                                    >
                                        <button title="Daily info" type="button" className="btn btn-sm btn-light border">
                                            <i className="fa fa-pie-chart text-info"></i>
                                        </button>
                                        <button
                                            title="Weekly and monthly info"
                                            type="button"
                                            className="btn btn-sm btn-light border"
                                        >
                                            <i className="fa fa-area-chart text-info"></i>
                                        </button>
                                        <button
                                            title="Incomming reservations"
                                            type="button"
                                            className="btn btn-sm btn-light active border"
                                        >
                                            <i className="fa fa-calendar-check-o text-info"></i>
                                        </button>
                                        <button
                                            title="Checked in reservations"
                                            type="button"
                                            className="btn btn-sm btn-light border"
                                        >
                                            <i className="fa fa-sign-out text-info"></i>
                                        </button>
                                        <button
                                            title="Canceled reservations"
                                            type="button"
                                            className="btn btn-sm btn-light border"
                                        >
                                            <i className="fa fa-eraser text-info"></i>
                                        </button>
                                    </div>
                                </div>
                                <ReservationsList resList={reservations_} statusEnum={1} history={props.history} />
                                <ReservationsList resList={reservations_} statusEnum={2} history={props.history} />
                                <ReservationsList resList={reservations_} statusEnum={3} history={props.history} />
 */}

                                <Sidebar
                            {...props}
                            searchTerm={searchTerm}
                            rooms={filteredRooms}
                            availableFromTimestamp={availableFromTimestamp}
                            availableToTimestamp={availableToTimestamp}
                        >
                            <SidebarToolbar setSearchTerm={_setSearchTerm.bind(this)} showComponents={showComponents} />
                        </Sidebar> 
                            
                            </SidebarWrapper>

                            
                        </div>
                    ) : null}
                </div>

                {/* {props.history?.location?.search === '?occupancy' ? (
                    <div className="col-sm-12 mt-2 mt-md-0">
                        <Sidebar
                            {...props}
                            searchTerm={searchTerm}
                            rooms={filteredRooms}
                            availableFromTimestamp={availableFromTimestamp}
                            availableToTimestamp={availableToTimestamp}
                        >
                            <SidebarToolbar setSearchTerm={_setSearchTerm.bind(this)} showComponents={showComponents} />
                        </Sidebar>
                    </div>
                ) : null} */}
            </div>

            {showOutOfServiceLegend ? (
                <div className="text-white mt-3 ml-1">
                    <small>(*) - {window.translate('OUT OF SERVICE')}</small>
                </div>
            ) : null}
        </div>
    );
}

// const ReservationsList = (props) => {
//     const { resList, statusEnum, history } = props
//     const filteredResList = resList?.filter((r)=>r.statusEnum===statusEnum)

//     return (
//         <div className="my-2">
//             <span className="bold-letters">
//                 {statusEnum===1 ? window.translate("Incomming reservations") 
//                     : statusEnum===2 ? window.translate("Checked in reservations") 
//                     : statusEnum===3 ? window.translate("Canceled reservations") : ""}
//             </span>
//             <div>
//                 {filteredResList?.length===0 ? 
//                     <div className="">{window.translate("List is empty")}</div> 
//                     : filteredResList?.map((r, index)=>{
//                         if(r.statusEnum===statusEnum){
//                             return(
//                                 <div className=" pointer" key={index}
//                                     onClick={()=>history?.push('/reservations/' + r.id + '/edit')}
//                                     style={{    border: "1px solid green",
//                                         margin: "8px", borderRadius:"4px"}}
//                                 >
//                                     <div>{r.Customer?.firstName ? (r.Customer.firstName + " " + (r.Customer.lastName ? r.Customer.lastName : "")) : r.Company?.name ? r.Company.name :  ""} - {r.RoomInfo?.name ? r.RoomInfo.name : ""}</div>
//                                     <div>#{r.id} | {moment(r.checkInTimestamp).format("MM/DD/YYYY")} - {moment(r.checkOutTimestamp).format("MM/DD/YYYY")}</div>
//                                     <div className={reservationStatusColors[r.statusEnum]}>{reservationStatusEnumsDesc[r.statusEnum]}</div>
//                                 </div>
//                             )
//                         }
//                     })}
//             </div>
//         </div>
//     )
// }


const SidebarWrapper = (props) => {
    const [pageHeight, setPageHeight] = useState(300);


    React.useEffect(() => {
        setPageHeight(getTableHeight())
        // eslint-disable-next-line
    }, [])
    

    function getPos(el) {
        // yay readability
        for (var lx = 0, ly = 0; el != null; lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent);
        return { x: lx, y: ly };
    }

    const getTableHeight =() => {
        let el = document.getElementById('timeline');
        // let footer = document.getElementById('main-footer');
        let mainAppDiv = document.getElementById('main-app-div');

        let positionOfTableHeaderElement = getPos(el);
        // let positionOfDocumentFooterElement = getPos(footer);
        // let heightOfTableHeaderElement = el.offsetHeight;

        const heightOfTheSpaceFromTableHeaderToDocumentFooter =
        mainAppDiv.scrollHeight - positionOfTableHeaderElement.y;
        const tableContentHeight = heightOfTheSpaceFromTableHeaderToDocumentFooter - 50 - 23;
        return tableContentHeight
    }
    return (
        <div id='sidebar'
            style={{
                width: '20%',
                display: 'inline-block',
                height: `${pageHeight}px`,
                overflow: 'auto',
            }}
        >
            {props.children}

        </div>
    )
}

export default branch(
    {
        reservations: ['model', 'Reservation'],
        roomsByTags: ['monkeys', 'parsedAndTagedRoomInfo'],
        parsedRoomInfo: ['monkeys', 'parsedRoomInfo'],
        smartRoomControllers: ['model', 'SmartRoomController'],
        uiStates: ['uiStates', 'dashboard'],
        loggedUser: ['authTokenInfo', 'user'],
        cleaningStatusHeaders: ['monkeys', 'cleaningStatusHeadersWithCleaningStatuses'],

        // priceListItems: ["model","PriceListItem"]
    },
    Dashboard
);
