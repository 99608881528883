import * as React from 'react';
import { RoomInfoEditModel, ReservationModelWithModelsIncluded } from '@common/modelDefinition';
import { translate } from '@data/translations';
import PageTitle from '@components/pageTitle';

//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import './style.css';

import moment from 'moment';

interface JournalProps {
    history: any;
    match: any;
    reservations: ReservationModelWithModelsIncluded[];
    rooms: RoomInfoEditModel[];
    systemLocale: string;
    reservationRoute?: string;
    location: any;
    executeScrollTop: Function;
    defaultCurrency: string;
}

function Journal(props: JournalProps) {
    const { reservations, rooms, location, executeScrollTop } = props;

    const headerHeightRef: any = React.useRef(null);
    const footerHeightRef: any = React.useRef(null);

    const [split, setSplit] = React.useState<boolean>(false);
    const [headerHeight, setHeaderHeight] = React.useState<number>(0);
    const [footerHeight, setFooterHeight] = React.useState<number>(0);

    const checkedInReservationIds: number[] = [];
    const checkedInRoomInfoIds: number[] = [];

    for (const reservation of reservations) {
        if (reservation.statusEnum === 2) {
            checkedInReservationIds.push(reservation.id);
            checkedInRoomInfoIds.push(reservation.roomInfoId);
        }
    }
    const checkedInReservations = reservations.filter((r) => checkedInReservationIds.includes(r.id));

    const arrivalReservationIds: number[] = [];
    const arrivalRoomInfoIds: number[] = [];
    for (const reservation of reservations) {
        if (
            reservation.statusEnum === 1 &&
            (moment(reservation.checkInTimestamp).isSame(moment(), 'day') ||
                reservation.checkInTimestamp < moment().valueOf())
        ) {
            arrivalReservationIds.push(reservation.id);
            arrivalRoomInfoIds.push(reservation.roomInfoId);
        }
    }

    const arrivalReservations = reservations.filter(
        (r) => arrivalReservationIds.includes(r.id) && !checkedInRoomInfoIds.includes(r.roomInfoId)
    );

    const relevantReservations: ReservationModelWithModelsIncluded[] =
        checkedInReservations.concat(arrivalReservations);
    const sortedReservations = relevantReservations.sort(
        (a: ReservationModelWithModelsIncluded, b: ReservationModelWithModelsIncluded) =>
            Number(b.checkOutTimestamp) < Number(a.checkOutTimestamp) ? 1 : -1
    );

    const filteredRoomsIds: number[] = [];
    for (const room of rooms) {
        filteredRoomsIds.push(room.id);
    }

    const filteredReservations = sortedReservations.filter(
        (r) => r.RoomInfo && filteredRoomsIds.includes(r.RoomInfo.id)
    );

    const printJournal: boolean = location.search.lastIndexOf('printJournal') !== -1 ? true : false;

    React.useEffect(() => {
        if (!split) {
            return;
        }

        window.addEventListener('beforeprint', () => {
            setSplit(false);
        });
        return () => {
            window.removeEventListener('beforeprint', () => {
                return;
            });
        };
    }, [split]);

    React.useEffect(() => {
        if (printJournal) {
            const _headerHeight = headerHeightRef?.current?.scrollHeight;
            const _footerHeight = footerHeightRef?.current?.scrollHeight;
            setHeaderHeight(_headerHeight);
            setFooterHeight(_footerHeight);
        }
    }, [printJournal]);

    const splitReservationsIndex = Math.ceil(filteredReservations.length / 2);
    const firstHalfReservations: ReservationModelWithModelsIncluded[] = [];
    const secondHalfReservations: ReservationModelWithModelsIncluded[] = [];
    filteredReservations.forEach((r, index) => {
        if (index < splitReservationsIndex) {
            firstHalfReservations.push(r);
        } else {
            secondHalfReservations.push(r);
        }
    });

    return (
        <div className="mb-4">
            <button
                className="button-white-default d-print-none"
                onClick={() => {
                    executeScrollTop();
                }}
                style={{
                    backgroundColor: 'black',
                    color: 'white',
                    opacity: 0.5,
                    position: 'fixed',
                    bottom: printJournal ? '20px' : '45px',
                    right: printJournal ? '20px' : `20px`,
                    // left: printJournal ? '' : `20px`,

                    zIndex: 2222,
                }}
            >
                <i className="fa fa-arrow-up"></i>
            </button>
            {printJournal ? (
                !split ? (
                    <div style={{ minWidth: '600px' }}>
                        <div className="journal-header" ref={headerHeightRef}>
                            <JournalHeader printJournal={printJournal} {...props} split={split} setSplit={setSplit} />
                        </div>
                        <div className="journal-footer" ref={footerHeightRef}>
                            <div className="ml-2 text-white" style={{ opacity: 0 }}>
                                {translate('Journal')}
                            </div>
                        </div>
                        <table className="w-100">
                            <thead>
                                <tr>
                                    <td>
                                        <div style={{ height: `${headerHeight}px` }}></div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div /*style={{ top: `${headerHeight}px` }}*/>
                                            <JournalItems
                                                printJournal={printJournal}
                                                {...props}
                                                reservations={filteredReservations}
                                                arrivalRoomInfoIds={arrivalRoomInfoIds}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>
                                        <div style={{ height: `${footerHeight}px` }}></div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                ) : (
                    <div className="col-12" style={{ minWidth: '600px' }}>
                        <div className="d-flex">
                            <div className="col-6">
                                <JournalHeader
                                    printJournal={printJournal}
                                    {...props}
                                    split={split}
                                    setSplit={setSplit}
                                    hideButtons={true}
                                />
                                <JournalItems
                                    printJournal={printJournal}
                                    {...props}
                                    reservations={firstHalfReservations}
                                    arrivalRoomInfoIds={arrivalRoomInfoIds}
                                />
                            </div>
                            <div className="col-6">
                                <JournalHeader
                                    printJournal={printJournal}
                                    {...props}
                                    split={split}
                                    setSplit={setSplit}
                                />
                                <JournalItems
                                    printJournal={printJournal}
                                    {...props}
                                    reservations={secondHalfReservations}
                                    arrivalRoomInfoIds={arrivalRoomInfoIds}
                                />
                            </div>
                        </div>
                    </div>
                )
            ) : (
                <div style={{ minWidth: '600px' }}>
                    <JournalHeader printJournal={printJournal} {...props} />
                    <JournalItems
                        printJournal={printJournal}
                        {...props}
                        reservations={filteredReservations}
                        arrivalRoomInfoIds={arrivalRoomInfoIds}
                    />
                </div>
            )}
        </div>
    );
}

interface JournalPreviewProps extends JournalProps {
    printJournal: boolean;
    arrivalRoomInfoIds?: number[];
    setSplit?: Function;
    split?: boolean;
    hideButtons?: boolean;
}

const JournalHeader = (props: JournalPreviewProps) => {
    const { printJournal, history, setSplit, split, hideButtons } = props;

    return (
        <div>
            <div>
                <div className="title-with-options display-flex space-between">
                    <PageTitle title={translate("Journal")} />
                    <div className="page-title-button">
                        {!printJournal ? (
                            <div
                                onClick={() => {
                                    history.push(`?printJournal`);
                                }}
                                className="button-white-default  d-print-none"
                            >
                                <i className={'fa fa-expand '}></i>
                            </div>
                        ) : (
                            <div
                                className="btn-group d-print-none"
                                style={hideButtons ? { opacity: 0, pointerEvents: 'none' } : {}}
                            >
                                <div
                                    onClick={() => {
                                        history.push(`?journal`);
                                    }}
                                    className="button-white-default "
                                >
                                    <i className={'fa fa-compress  '}></i>
                                </div>
                                {!split ? (
                                    <div
                                        onClick={() => {
                                            window.print();
                                        }}
                                        className="button-white-default "
                                    >
                                        <i className={'fa fa-print'}></i>
                                    </div>
                                ) : null}
                                {setSplit ? (
                                    <div
                                        onClick={() => {
                                            setSplit(!split);
                                        }}
                                        className="button-white-default "
                                    >
                                        {split ? (
                                            <i className={'fa fa-file-o'}></i>
                                        ) : (
                                            <i className={'fa fa-columns'}></i>
                                        )}
                                    </div>
                                ) : null}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div>
                <div className="custom-list-header">
                    <div>{translate('Room')}</div>
                    <div>{translate('Guest')}</div>
                    <div>{translate('Guests')}</div>
                    <div>{translate('Arrival')}</div>
                    <div>{translate('Departure')}</div>
                    <div>{translate('Price')}</div>
                    <div>{translate('Info')}</div>
                    <div>{translate('Note')}</div>
                </div>
            </div>
        </div>
    );
};

const JournalItems = (props: JournalPreviewProps) => {
    const { reservations } = props;

    return (
        <div>
            {reservations.map((r) => {
                return <ItemRow key={r.id} reservation={r} {...props} />;
                //  else {
                //         return (
                //             <button
                //                 key={r.id}
                //                 className={`list-group-item list-group-item-action`}
                //                 onClick={() => {
                //                     if (reservationRoute === 'reservations') {
                //                         history.push(`/reservations/${r.id}/edit`);
                //                     } else {
                //                         history.push(`/${reservationRoute}/${r.roomInfoId}/${r.id}/edit`);
                //                     }
                //                 }}
                //             >
                //                 <ItemRow
                //                     reservation={r}
                //                     arrivalRoomInfoIds={arrivalRoomInfoIds}
                //                     systemLocale={systemLocale}
                //                     defaultCurrency={defaultCurrency}
                //                 />
                //             </button>
                //         );
                //     }
            })}
        </div>
    );
};

interface ItemRowProps extends JournalPreviewProps {
    reservation: ReservationModelWithModelsIncluded;
}

const ItemRow = (props: ItemRowProps) => {
    const {
        reservation,
        arrivalRoomInfoIds = [],
        systemLocale,
        defaultCurrency,
        printJournal,
        history,
        reservationRoute = 'reservations',
    } = props;
    const { notes } = reservation;
    const adultsNumber = reservation.adultsNumber ? reservation.adultsNumber : 1;
    const customerName = reservation.Customer
        ? `${reservation.Customer.firstName} ${reservation.Customer.lastName}`
        : '';
    const companyName = reservation.Company ? reservation.Company.name : '';
    const guest =
        companyName.length > 0 && customerName.length > 0 ? (
            <div className="">
                {companyName}
                <br />
                {customerName}
            </div>
        ) : customerName.length > 0 ? (
            <div className="">{customerName}</div>
        ) : (
            <div className="">{companyName}</div>
        );

    const { checkoutWarning, guestArivalReminder, checkInWarning, guestArivalWarning, guestDepartureReminder } =
        getWarning(reservation, arrivalRoomInfoIds);

    const total = getReservationPrice(reservation);
    return (
        <div
            className="custom-list-item"
            onClick={() => {
                if (printJournal) {
                    return null;
                } else {
                    if (reservationRoute === 'reservations') {
                        history.push(`/reservations/${reservation.id}/edit`);
                    } else {
                        history.push(`/${reservationRoute}/${reservation.roomInfoId}/${reservation.id}/edit`);
                    }
                }
            }}
        >
            <div className="">{reservation.RoomInfo?.name}</div>
            {guest}
            <div className="">{adultsNumber}</div>
            <div className="" style={{ position: 'relative'}}>
                <span className="ml-4p">{moment(reservation.checkInTimestamp).locale(systemLocale).format('ll')}</span>
            </div>
            <div className="" style={{ position: 'relative'}}>
                <span className="ml-4p">{moment(reservation.checkOutTimestamp).locale(systemLocale).format('ll')}</span>
            </div>
            <div className="">
                {total} {defaultCurrency}
            </div>
            <div className='position-relative'>
                {checkoutWarning}
                 {guestArivalReminder}
                 {checkInWarning}
                 {guestArivalWarning}
                 {guestDepartureReminder}
                 </div>
            <div className="">
                <span className="ml-4p">{notes}</span>
            </div>
        </div>
    );
};

const getReservationPrice = (reservation: ReservationModelWithModelsIncluded) => {
    let total = 0;
    const adultsNumber = reservation.adultsNumber ? reservation.adultsNumber : 1;
    const reservationOfferArr = reservation.rememberedAccommodationPriceInfo;
    const reservationOffer =
        reservationOfferArr && reservationOfferArr.length > 0
            ? reservationOfferArr[reservationOfferArr.length - 1]
            : null;

    const priceOfferIntervals =
        reservationOffer && reservationOffer.priceOfferIntervals ? reservationOffer.priceOfferIntervals : [];
    priceOfferIntervals.forEach((p) => {
        const { fromToDays } = p;
        let adultsNumberPriceIndex = adultsNumber === 0 ? 0 : adultsNumber - 1;
        if (adultsNumber >= p.apRules.length) {
            adultsNumberPriceIndex = p.apRules.length - 1;
        }
        const unitPrice = p.apRules[adultsNumberPriceIndex];
        total += Number(unitPrice) * Number(fromToDays);
    });
    return total;
};

const getWarning = (reservation: ReservationModelWithModelsIncluded, arrivalRoomInfoIds: number[]) => {
    let checkoutWarning: any = '';
    let guestArivalReminder: any = '';
    let checkInWarning: any = '';
    let guestArivalWarning: any = '';
    let guestDepartureReminder: any = '';
    if (reservation.checkOutTimestamp < moment().valueOf()) {
        checkoutWarning = (
        <i
                    title={translate('The guests should have been checked out')}
                    className="fa fa-sign-out text-danger px-1"
                    aria-hidden="true"
                ></i>
            );
    }

    if (reservation.checkInTimestamp < moment().valueOf() && reservation.statusEnum === 1) {
        checkInWarning = (
        <i
                    title={translate('The guests should have been checked in')}
                    className="fa fa-sign-in text-danger px-1"
                    aria-hidden="true"
                ></i>
            );
    }
    if (moment(reservation.checkInTimestamp).isSame(moment(), 'day') && reservation.statusEnum === 1) {
        guestArivalReminder = (
        <i
                    title={translate('The arrival of guests is today')}
                    className="fa fa-sign-in text-primary px-1"
                    aria-hidden="true"
                ></i>
            );
    }

    if (
        moment(reservation.checkOutTimestamp).isSame(moment(), 'day') &&
        reservation.statusEnum === 2 &&
        reservation.checkOutTimestamp > moment().valueOf()
    ) {
        guestDepartureReminder = (
        <i
                    title={translate('The departure of guests is today')}
                    className="fa fa-sign-out text-secondary px-1"
                    aria-hidden="true"
                ></i>
            );
    }

    if (reservation.statusEnum === 2 && arrivalRoomInfoIds.includes(reservation.roomInfoId)) {
        guestArivalWarning = (
        <i
                    title={translate('The arrival of guests is today')}
                    className="fa fa-sign-in text-primary px-1"
                    aria-hidden="true"
                ></i>
            ); }

    return { checkoutWarning, guestArivalReminder, checkInWarning, guestArivalWarning, guestDepartureReminder };
};

export default branch(
    {
        systemLocale: ['locale'],
        defaultCurrency: ['defaultCurrency'],
    },
    Journal
);
