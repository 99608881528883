import React, { Component, useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import { branch } from 'baobab-react/higher-order';
import baobabTree from '../../data/state';
import '../roomInfo/style.css';
import Tooltip from '../../components/tooltip/tooltip';
import SearchField from '../search/searchField';
// import Select from 'react-select';
import { CleaningStatusEnum } from '@pages/household/interfaces';

import { ReactComponent as ChevronDown } from '../../assets/custom/icons/chevron_down.svg';
import { ReactComponent as Close } from '../../assets/custom/icons/close.svg';

export function CustomSelect(props) {
    const { isMulti, options, value, placeholder = 'Select option' } = props;
    const [showOptions, setShowOptions] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [inputWidth, setInputWidth] = useState('2ch');
    const wrapperRef = useRef(null);
    const inputRef = React.useRef();

    let filteredOptions = [];

    if (options) {
        options.forEach((element) => {
            if (
                !value?.find(({ value }) => value === element.value) &&
                element?.label?.toLowerCase().includes(searchString)
            ) {
                filteredOptions.push(element);
            }
        });
    }

    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (!showOptions) {
                        setShowOptions(false);
                    }
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }

    function addOrRemoveItem(item) {
        let valuesCopy = value ? [...value] : [];
        const index = valuesCopy.indexOf(item);
        if (index > -1) {
            // only splice array when item is found
            valuesCopy.splice(index, 1); // 2nd parameter means remove one item only
        } else {
            valuesCopy.push(item);
        }
        props.onChange(valuesCopy.length > 0 ? valuesCopy : null);
    }

    function selectThisOption(itm) {
        addOrRemoveItem(itm);
        setShowOptions(false);
        setSearchString('');
        inputRef.current.focus();
    }

    function removeThisItem(item) {
        addOrRemoveItem(item);
    }

    function searchForOption(e) {
        if (!showOptions) {
            setShowOptions(true);
        }
        setSearchString(e.target.value);
        setInputWidth(e.target.value.length + 1 + 'ch');
    }

    //  console.log('inputWidth', value.length> 0, inputWidth);

    return (
        <div ref={wrapperRef} className="custom-select-wrapper" style={{ width: props.width ? props.width : null }}>
            <div
                className="custom-select-flex"
                onClick={(e) => {
                    e.stopPropagation();
                    inputRef.current.focus();
                    setShowOptions(!showOptions);
                }}
            >
                <div className="custom-select-selected-items-wrapper">
                    {value && value.length > 0
                        ? value.map((val) => {
                              return (
                                  <span key={val.value} className="selected-item">
                                      <span>{val.label}</span>
                                      {val && isMulti ? (
                                          <i
                                              onClick={(e) => {
                                                  e.stopPropagation();
                                                  removeThisItem(val);
                                              }}
                                              className="fa fa-close"
                                          ></i>
                                      ) : null}
                                  </span>
                              );
                          })
                        : //  <div  onClick={(e) => {e.stopPropagation();  setShowOptions(!showOptions) }}>{placeholder}</div>
                          null}

                    <input
                        id="search-string"
                        ref={inputRef}
                        placeholder={value?.length > 0 ? '' : placeholder}
                        style={{ width: value?.length > 0 ? inputWidth : placeholder.length + 'ch' }}
                        value={searchString}
                        onChange={(e) => {
                            searchForOption(e);
                        }}
                        type="text"
                    ></input>
                </div>

                <div className="display-flex">
                    {value && value.length > 0 ? (
                        <Close
                            style={{ height: '26px', width: '26px' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                props.onChange(null);
                            }}
                        />
                    ) : (
                        <ChevronDown />
                    )}
                </div>
            </div>

            {showOptions && filteredOptions?.length > 0 ? (
                <div className="options-wrapper">
                    {filteredOptions.map((opt, ind) => {
                        return (
                            <div
                                className="option"
                                key={ind}
                                onClick={() => {
                                    selectThisOption(opt);
                                }}
                            >
                                {opt.label}
                            </div>
                        );
                    })}
                </div>
            ) : null}
        </div>
    );
}

export const customStyles = {
    control: (provided, state) => ({
        ...provided,
        background: '#fff',
        borderColor: '#D8E0EC',
        minHeight: '32px',
        // height: '32px',
        minWidth: '178px',
        boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
        ...provided,
        // height: '32px',
        padding: '0 6px',
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: (state) => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        // height: '32px',
    }),
    menu: (base) => ({ ...base, zIndex: 9999 }),
};

class Toolbar extends Component {
    clearReservationsFilterFields() {
        this.props.setHideRoomByIdFilter([]); //set as empty
        this.props.setAvailableFromAndToTimestamps(null, null, null);
        this.props.setNumberOfBeds('');
        this.props.setNumberOfSpareBeds('');
    }

    toggleMainViewButtons(mainViewType) {
        let temp = _.clone(baobabTree.root.select(['uiStates', 'dashboard', 'showComponents']).get());
        if (_.includes(temp, 'timeline')) {
            _.remove(temp, (t) => t === 'timeline');
        }
        if (_.includes(temp, 'tiles')) {
            _.remove(temp, (t) => t === 'tiles');
        }
        if (_.includes(temp, 'activity')) {
            _.remove(temp, (t) => t === 'activity');
        }
        if (_.includes(temp, 'journal')) {
            _.remove(temp, (t) => t === 'journal');
        }

        temp.push(mainViewType);
        baobabTree.root.select(['uiStates', 'dashboard', 'showComponents']).set(temp);
        setTimeout(() => {
            this.clearReservationsFilterFields();
        }, 10);
    }

    render() {
        const tagsOptions = this.props.roomTags.map((tag) => {
            return {
                value: tag,
                label: tag,
            };
        });

        tagsOptions.sort((a, b) => (a.label > b.label ? 1 : -1));

        const tagsValues = this.props.tagsFilter.map((tag) => {
            return {
                value: tag,
                label: tag,
            };
        });

        // const customStyles = {
        //     control: (base, state) => ({
        //         ...base,
        //         // height: '34px',
        //         // 'min-height': '24px',
        //         zIndex: 1000,
        //     }),
        //     menu: (base) => ({ ...base, zIndex: 9999 }),
        // };

        const attributesOptions = this.props.attributes.map((attr) => {
            return {
                value: attr.id,
                label: window.translate(attr.name),
            };
        });

        attributesOptions.sort((a, b) => (a.label > b.label ? 1 : -1));

        const attributesValues = this.props.attributesFilter.map((attrId) => {
            const attr = this.props.attributes.find((at) => at.id === attrId);
            return {
                value: attr.id,
                label: window.translate(attr.name),
            };
        });

        let clStatusesOptions = Object.keys(CleaningStatusEnum).map((cleaningStatusString) => {
            if (isNaN(Number(cleaningStatusString))) {
                const cleaningStatusValue = cleaningStatusString ? CleaningStatusEnum[cleaningStatusString] : null;
                return {
                    value: cleaningStatusValue,
                    label: window.translate(cleaningStatusString),
                };
            } else {
                return null;
            }
        });

        clStatusesOptions = clStatusesOptions.filter(function (element) {
            return element !== undefined;
        });
        clStatusesOptions = clStatusesOptions.filter(Boolean);

        const clStatusesValues = this.props.cleaningStatusesFilter.map((clStatuses) => {
            const cl = Object.keys(CleaningStatusEnum).find(
                (cleaningStatusString) => clStatuses === CleaningStatusEnum[cleaningStatusString]
            );
            const cleaningStatusValue = cl ? CleaningStatusEnum[cl] : null;
            return {
                value: cleaningStatusValue,
                label: window.translate(cl),
            };
        });

        return (
            <div className="dashboard-filters-wrapper">
                <h1>{window.translate('Reservation table')}</h1>
                <div className="dashboard-filters">
                    <div className="flex-center-center">
                        {/* <Select
                                        isMulti
                                        styles={customStyles}
                                        className=""
                                        placeholder={window.translate('By status')}
                                        options={clStatusesOptions}
                                        onChange={(values) => {
                                            if (values === null) {
                                                this.props.toggleCleaningStatusesFilter(null);
                                            } else {
                                                this.props.toggleCleaningStatusesFilter(values[values.length - 1].value);
                                            }
                                        }}
                                        value={clStatusesValues}
                                    ></Select> */}
                        <CustomSelect
                            isMulti
                            styles={customStyles}
                            className=""
                            placeholder={window.translate('By status')}
                            options={clStatusesOptions}
                            onChange={(values) => {
                                if (values === null) {
                                    this.props.toggleCleaningStatusesFilter(null);
                                } else {
                                    this.props.toggleCleaningStatusesFilter(values[values.length - 1].value);
                                }
                            }}
                            value={clStatusesValues}
                        ></CustomSelect>
                    </div>

                    <div className="flex-center-center">
                        <span className="pr-1 text-center">
                            {!_.isEmpty(this.props.roomTags) ? (
                                <Tooltip
                                    message={window.translate(
                                        'Tags: Filtering by tag remove filters by previously selected tag'
                                    )}
                                >
                                    <i className="fa fa-info-circle text-primary"></i>
                                </Tooltip>
                            ) : null}
                        </span>
                        {
                            <CustomSelect
                                isMulti
                                styles={customStyles}
                                className=""
                                placeholder={window.translate('By tag')}
                                options={tagsOptions}
                                onChange={(values) => {
                                    if (values === null) {
                                        this.props.toggleTagFilter(null);
                                    } else {
                                        this.props.toggleTagFilter(values[values.length - 1].value);
                                    }
                                }}
                                value={tagsValues}
                            ></CustomSelect>
                        }
                    </div>
                    <div className="flex-center-center">
                        {!_.isEmpty(this.props.attributes) ? (
                            <Tooltip
                                message={window.translate(
                                    'Attributes: if checked, return rooms that have any of selected attributes, else only rooms that have all selected attributes'
                                )}
                            >
                                <i
                                    className="fa fa-info-circle text-primary ml-1  pr-1"
                                    /* title="Attributes: if checked, return rooms that have any of selected attributes, else only rooms that have all selected attributes" */
                                ></i>
                            </Tooltip>
                        ) : null}
                        {
                            // <i
                            //     onClick={this.props.toggleAttributesCheck.bind(this)}
                            //     name="attributesCheck"
                            //     value={this.props.attributesFilteringType}
                            //     className={
                            //         this.props.attributesFilteringType
                            //             ? 'fa fa-check-square-o   fa-lg pr-1'
                            //             : 'fa fa-square-o  fa-lg pr-1'
                            //     }
                            // ></i>

                            <input
                                checked={this.props.attributesFilteringType}
                                onChange={this.props.toggleAttributesCheck.bind(this)}
                                className="checkbox-primary"
                                type="checkbox"
                            ></input>
                        }

                        <div className="mulitple-select-wrapper">
                            {/* <Select
                                    isMulti
                                    styles={customStyles}
                                    className=""
                                    placeholder={window.translate('By attributes')}
                                    options={attributesOptions}
                                    onChange={(values) => {
                                        if (values === null) {
                                            this.props.handleAttributesSelect(null);
                                        } else {
                                            this.props.handleAttributesSelect(values);
                                        }
                                    }}
                                    value={attributesValues}
                                ></Select> */}
                            <CustomSelect
                                isMulti
                                styles={customStyles}
                                className=""
                                placeholder={window.translate('By attributes')}
                                options={attributesOptions}
                                onChange={(values) => {
                                    if (values === null) {
                                        this.props.handleAttributesSelect(null);
                                    } else {
                                        this.props.handleAttributesSelect(values);
                                    }
                                }}
                                value={attributesValues}
                            ></CustomSelect>
                        </div>
                    </div>

                    <div className="flex-center-center">
                        <SearchField
                            onChangeHandler={this.props.setSearchTerm}
                            className="form-control mt-1 mb-2"
                            history={this.props.history}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default branch(
    {
        roomTags: ['monkeys', 'roomTags'],
        reservations: ['model', 'Reservation'],
        attributes: ['model', 'RoomAttribute'],
        pageTitle: ['pageTitle'],
    },
    Toolbar
);
