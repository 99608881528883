export enum ReservationInvoicedStatus {
    notPaid = 0,
    fullyPaid = 1,
    partialPayed = 2,
}

export enum GroupInfoStatus {
    active = 1,
    inactive = 0,
}

export enum CashRegPaymentsEnum {
    payIn = 0,
    payOut = 1,
}

export enum ReservationStatus {
    earlyBird = 0,
    confirmed = 1,
    checkedIn = 2,
    canceled = 3,
    closed = 4,
    autoBlocked = 5,
}

export enum ReservationStatusForCleaning {
    unknown = 0,
    noReservation = 1,
    arrival = 2,
    dueOut = 3,
    stay = 4,
}

export enum InvoiceType {
    geniue = 0,
    advance = 1,
    proforma = 2,
}

export enum HotelUserRole {
    admin = 'admin',
    condoUser = 'condoUser',
    gdprOfficer = 'gdprOfficer',
    hwAdmin = 'hwAdmin',
    janitor = 'janitor',
    maid = 'maid',
    managerBussiness = 'managerBussiness',
    managerFull = 'managerFull',
    managerHousehold = 'managerHousehold',
    managerSecurity = 'managerSecurity',
    recepcionist = 'recepcionist',
    reporting = 'reporting',
    security = 'security',
    sos = 'SOS',
    posKitchen = 'posKitchen',
    posBartender = 'posBartender',
    posSupervisor = 'posSupervisor',
}

export interface CustomerModel {
    id?: number;
    documentId?: string | null;
    documentType?: string | null;
    cardNumber?: string | null;
    firstName: string | null;
    lastName: string | null;
    email?: string | null;
    gender?: string | null;
    dateOfBirth?: Date | null;
    birthTown?: string | null;
    poNumber?: string | null;
    address?: string | null;
    town?: string | null;
    country?: string | null;
    nationality?: string | null;
    phoneOrMobile?: string | null;
    params?: any | null;
    preferredLanguage?: string | null;
    otherLanguage?: string | null;
    gdprBasicConsent: boolean;
    gdprMarketing?: boolean;
    gdprPreferences?: boolean;
    readonly gdprDecoded?: boolean;
    companyId?: number | null;
    documentScanId?: number | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
    emailHash?: string | null;
}

export interface CompanyModel {
    id?: number;
    name: string;
    idNumber?: string | null;
    address?: string | null;
    poNumber?: string | null;
    town?: string | null;
    country?: string | null;
    notes?: string | null;
    web?: string | null;
    params?: any | null;
    oib?: string | null;
    taxFree?: boolean;
}

export interface RoomInfoModel {
    id?: number;
    uuid?: string | null;
    name: string;
    remoteDeviceId?: string | null;
    roomPhone?: string | null;
    srcProxyId?: number | null;
    tags?: string | null;
    description?: string | null;
    accommodationPriceListId: number | null;
    roomAttributes?: string | null;
    SRCIOStates?: number | null;
    SRCSettings?: any | null;
    availableForRent?: boolean;
    bedCount: number;
    spareBedCount: number | null;
    posItemIds?: string[] | null;
    params?: string | null;
    cleaningModeActivatedTs?: number | null;
}

export interface RoomInfoEditModel extends RoomInfoModel {
    id: number;
}

export interface ReservationModel {
    id?: number;
    checkOutTimestamp: number;
    checkInTimestamp: number;
    additionalServicesJson?: any | null;
    statusEnum: ReservationStatus;
    autoBlockCount?: number | null;
    earlyBirdValidTo?: number | null;
    statusChangeNotes?: string | null;
    notes?: string | null;
    adultsNumber: number;
    childrenNumber?: number | null;
    rememberedAccommodationPriceInfo?: PriceOffer[];
    enableSelfCheckIn: boolean | null;
    guest1CodeCopy?: number | null;
    guest2CodeCopy?: number | null;
    guest3CodeCopy?: number | null;
    documents?: string | null;
    receptionistUuid?: string | null;
    //groupReservationStatus - ovo izbrisati
    discountPercentage?: number | null;
    pendingSelfCheckIn?: boolean;
    accommodationPriceListId: number | null;
    tags?: string | null;
    pendingQRSelfCheckIn?: boolean;
    pendingHardwareSelfCheckIn?: boolean;
    groupUuid: string | null;
    roomInfoId: number;
    customerId?: number | null;
    companyId?: number | null;
    invoicedStatus: number;
    createdAt?: Date;
    updatedAt?: Date;
    previousGroupUuid?: string | null;
    providerReservation?: boolean;
    condoUserUuid?: string | null;
    genuineInvoiceIssued?: number;
    openPrice: number | null;
    canceledReservationInvoicingStatus?: number;
    concludedType?: number | null;
   // board?: string | null;
}

export interface PriceOffer {
    accommodationPriceListId: number | null;
    checkInTimestamp: number;
    checkOutTimestamp: number;
    adultsNumber: number;
    childrenNumber?: number | null;
    priceOfferIntervals: PriceOfferInterval[];
    timestamp?: number;
    openPrice: number | null;
    statusEnum?: number;
    canceledReservationInvoicingStatus?: number;
    id?: number;
}

export interface PriceListShort {
    pluBase: number | null;
}

export interface PriceOfferInterval {
    from: string;
    to: string;
    fromToDays: number;
    apRules: number[];
    id?: number;
    priceList: PriceListShort;
    taxPercentage1?: number;
    taxPercentage2?: number;
    taxPercentage3?: number;
    taxFixedAmount?: number;
}

export interface PriceOfferIntervalsGroupByReservationId {
    [key: string]: PriceOfferInterval[];
}

export interface ReservationEditModel extends ReservationModel {
    id: number;
}

export interface CustomerEditModel extends CustomerModel {
    id: number;
}

export interface CompanyEditModel extends CompanyModel {
    id: number;
}

export interface GroupInfoModel {
    uuid: string;
    note?: string | null;
    discountPercentage?: number | null;
    status: GroupInfoStatus;
    invoicedStatus: ReservationInvoicedStatus;
    receptionistUuid?: string | null;
    checkOutTimestamp: number | null;
    checkInTimestamp: number | null;
    customerId?: number | null;
    companyId?: number | null;
    createdAt?: Date;
    updatedAt?: Date;
    groupName?: string;
    genuineInvoiceIssued?: number;
    type: string;
}

export interface GroupInfoWithReservations extends GroupInfoModel {
    Reservations: ReservationModelWithModelsIncluded[];
}

export interface ReservationGuestReferenceModel {
    id?: number;
    customerId: number;
    reservationId: number;
    documentValidityPeriod?: string | null;
    documents?: string | null;
    isLocked?: boolean; //WTF ???
    note?: string | null;
    placeEntry?: string | null;
    visaEntryDate?: string | null;
    visaTypeAndNumber?: string | null;
    visaValidityPeriod?: string | null;
    applyAccommodationTax?: boolean;
    fixedTaxId?: number;
    documentId?: string; //dok se napravi sve maknut ?
    documentType?: string; //dok se napravi sve maknut ?
    checkOutTimestamp?: number | null;
    checkInTimestamp?: number | null;
}

export interface ReservationGuestReferenceEditModel extends ReservationGuestReferenceModel {
    id: number;
}

export interface ReservationGuestReferenceWithModelsIncluded extends ReservationGuestReferenceModel {
    Customer?: CustomerModel;
}

export interface SelfServiceCheckInModel {
    id?: number;
    uuid?: string;
    pinCode: number | null;
    selfCheckInStatus?: string | null;
    selfCheckInValidFrom: number;
    selfCheckInValidTo: number;
    sendSelfCheckInCodeAfterTimestamp: number;
    notificationSent?: boolean;
    notificationIds?: string | null;
    consumptionTime?: number | null;
    issuedRoomCardCode?: number | null;
    gatewayNotificationStatus?: string | null;
    selfCheckInType: string;
    notificationLanguage: string | null;
    reservationId?: number;
}

export interface SelfServiceCheckInModelWithIncludedReservationModel extends SelfServiceCheckInModel {
    Reservation: ReservationModelWithModelsIncluded;
}
//provjerit sta moze bit null i sta ne mora nuzno bit u modelu
export interface DoorAccessPointModel {
    id?: number;
    remoteDeviceId: string;
    srcProxyId: number;
    serialPort: string | null;
    portConfigId: number;
    tags?: string | null;
    name: string;
    description?: string | null;
    whitelistTagCSV?: string | null;
    params: string | null;
    lastSeenTimestamp: number | null;
    qrCode: string | null;
    messageAfterSuccessfullScan?: string | null;
    documentScannerDeviceReference?: string | null;
}

export interface UserInfo {
    uuid: string;
    name: string;
    surname: string;
    email: string;
    role: HotelUserRole;
    enabled: boolean;
    cardNumber: string;
    level: number;
    oib: string;
    condoRoomInfoIds: number[];
    customerId: number;
    condoUserPersonalDaysPerYear: number;
    settings: any;
}

export interface MinibarLogModel {
    id?: number;
    previousState: number;
    newState: number;
    logType: number;
    parentId?: number | null;
    recordedAt?: number | null;
    roomInfoId?: number | null;
    posItemId: number;
    userUUID?: string | null;
    params?: string | null;
    reservationId?: number | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface PosItemModel {
    id?: number;
    barcode?: string | null;
    plu: number;
    type?: string | null;
    name: string;
    description?: string | null;
    category?: string | null;
    image?: string | null;
    thumbnail?: string | null;
    enabled?: boolean | null;
    tags?: string | null;
    unitMeasure?: string | null;
    price?: number | null;
    taxId: number;
    tax2Id?: number | null;
    tax3Id?: number | null;
    params?: string | null;
    composites?: string | null;
    flexiblePrice?: boolean | null;
    subcategory?: string | null;
    isComboItem?: boolean;
    createdAt?: Date | null;
    updatedAt?: Date | null;
    preparationRequired: boolean | null;
    normative?:boolean
}

export interface SelfCheckInLogModel {
    id?: number;
    timestamp: number;
    reservationId: number;
    description?: string;
    methodName?: string | null;
    methodSuccessfullyPassed?: boolean;
    errorMessage?: string | null;
    params?: string | null;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface NotificationTemplateModel {
    id?: number;
    uniqueName: string;
    type?: string | null;
    title?: string | null;
    body?: string | null;
    isEnabled?: boolean;
    params?: string | null;
    useHtmlTemplate?: boolean;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface CleaningStatusHeaderModel {
    id?: number;
    note: string;
    validOnTimestamp: number;
}

export interface CleaningStatusHeaderEditModel extends CleaningStatusHeaderModel {
    id: number;
}

export interface CleaningStatusModel {
    id?: number;
    cleaningStatus: number;
    reservationId: number | null;
    reservationStatus: number;
    cleaningStartedTimestamp: number | null;
    cleaningFinishedTimestamp: number | null;
    cleaningNote: string | null;
    cleanedByUserUuid: string | null;
    cleanedByUserUuid2: string | null;
    cleanedByUserUuid3: string | null;
    user1SrcEncoded: boolean | null;
    user2SrcEncoded: boolean | null;
    user3SrcEncoded: boolean | null;
    cleaningStatusHeaderId: number | null;
    roomInfoId: number | null;
    inspectionStatus: number | null;
    inspectionNote: string | null;
}

export interface CleaningStatusEditModel extends CleaningStatusModel {
    id: number;
}

export interface CleaningStatusExtendedWithIncludedModels extends CleaningStatusModel {
    Maid1: UserInfo | undefined;
    Maid2: UserInfo | undefined;
    Maid3: UserInfo | undefined;
    RoomInfo: RoomInfoEditModel | undefined;
}

export interface CleaningStatusHeaderExtendedWithCleaningStatusModel extends CleaningStatusHeaderEditModel {
    CleaningStatuses: CleaningStatusExtendedWithIncludedModels[];
}

export interface RoleModel {
    key: string;
    value: string | null;
    isActive: boolean;
    params: string | null;
    sessionDurationInMinutes: number | null;
    dashboardRoute: string | null;
    fixedRoute: string | null;
    externalDashboardRoute: string | null;
    externalFixedRoute: string | null;
}

export interface PermissionModel {
    id?: number;
    name: string;
    description: string | null;
    targetId: number | null;
}

export interface RoleWithPermissions extends RoleModel {
    Permissions: PermissionModel[];
}

export interface PermissionRoleModel {
    PermissionId: number;
    RoleKey: string;
}

export interface TaxModel {
    id?: number;
    percentageAmount: number;
    fixedAmount?: number | null;
    name: string;
    plu?: number | null;
    isActive?: boolean | null;
    params?: string | null;
    isGeneralTax: boolean;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface AccommodationPricelistModel {
    id?: number;
    parentId: number | null;
    name: string;
    description: string;
    tags: string;
    pluBase: string;
    weight: number;
    infinite: boolean;
    validFrom: number | null;
    validTo: number | null;
    blockPromotions: boolean;
    repeatRules: string;
    accomodationPriceRules: string;
    discountRules: string;
    isActive: boolean;
    isDeleted: boolean;
    condoPricelist: boolean;
    params: string;
    generalTaxCode: number | null; // mislim da je suvisno
    // pricePerChild: number | null;
    // boards: string | null;
    // defaultBoard: string | null;
}

export interface ChannelManagerMap {
    id?: number;
    provider: string;
    model: string;
    remoteId: string | null;
    remoteUuid: string | null;
    modelId: string | null;
    modelUuid: string | null;
    tags: string | null;
    roomPriceValidTo: number | null;
    remoteName: string | null;
    data: string | null;
    roomInfoIds: number[];
    parentId?: string | null;
    vpType?: number | null,
    vpValue?: number | null,
}

export interface CashRegistarModel {
    id?: number;
    name: string;
    currency: string | null;
    posTerminalId: number | null;
    cashMax: number | null;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface CashRegistarPaymentPurposeModel {
    id?: number;
    name: string;
    type: number;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface CashRegistarPaymentsModel {
    id?: number;
    paymentNumber?: number;
    date: number;
    amount: number;
    currency: string | null;
    OIB?: string | null;
    description?: string | null;
    customerId: number | null;
    companyId: number | null;
    paymentPurposeId: number;
    createdAt?: Date;
    updatedAt?: Date;
    invoiceId: number | null;
    cashRegistarReportId: number;
    currencyExchangeRate: number;
}

export interface CashRegistarReportModel {
    id?: number;
    reportNumber?: number;
    fromTimestamp: number;
    toTimestamp: number | null;
    previousState: number;
    payIn: number;
    payOut: number;
    newState: number;
    total: number;
    cashRegistarId: number;
    createdAt?: Date | string;
    updatedAt?: Date | string;
}

// export interface CashRegPaymentsExtendedModel extends CashRegistarPaymentsModel, CustomerModel, CompanyModel {}

export interface CashRegReportEditModel extends CashRegistarReportModel {
    id: number;
}

export interface CurrencyModel {
    id?: number;
    shortName?: string | null;
    name: string;
    params?: string;
    isEnabled: boolean;
    createdAt?: Date | null;
    updatedAt?: Date | null;
}

export interface ReservationWithIncludedModels extends ReservationEditModel {
    RoomInfo: RoomInfoModel;
    Customer: CustomerModel;
    Company: CompanyModel;
}

export interface MinibarLogWithIncludedModels extends MinibarLogModel {
    RoomInfo: RoomInfoModel;
    PosItem: PosItemModel;
    InvoiceItem: any; //sad za sad
}

export interface InitialAndlatestMinibarLogWithIncludedModels {
    filterEnabledPosItemsMinibarLogs: MinibarLogWithIncludedModels[];
    initialfilterEnabledPosItemsMinibarLogs: MinibarLogWithIncludedModels[];
}
export interface InvoiceItemsModel {
    id?: number;
    bruttoUnitPrice: number | null;
    bruttoValueWithDiscount: number | null;
    discountPercentage: number | null;
    discountValue: number | null;
    group: string | null;
    invoiceId: number;
    itemName: string;
    minibarLogId: number | null;
    nettoUnitPrice: number | null;
    nettoValueWithDiscount: number | null;
    plu: number | null;
    posItemId: number | null;
    quantity: number | null;
    recordedAt: number | null;
    tax1ValueWithDiscount: number | null;
    tax2ValueWithDiscount: number | null;
    tax3ValueWithDiscount: number | null;
    taxIncludedInPrice: boolean;
    taxPercentage1?: number | null;
    taxPercentage2?: number | null;
    taxPercentage3?: number | null;
    totalTaxValue: number | null;
    type: string;
    unitPrice: number | null;
    valueWithDiscount: number | null;
    taxFreeCompany?: boolean;
}

export interface AdvancePaymentsInterface {
    id: number;
    itemName: string;
    invoiceNumber: number;
    paymentAmount: number;
}

export interface InvoiceModel {
    id?: number;
    advancePayments: AdvancePaymentsInterface[];
    advancePaymentsValue: number | null;
    checkInTimestamp: number;
    checkOutTimestamp: number;
    companyId: number | null;
    companyInfo: string | null;
    connectedInvoiceProcessed: number | null;
    connectedInvoices: number[];
    currency: string;
    currencyExchangeRate: number;
    customerId: number | null;
    customerInfo: string | null;
    defaultCurrency: string;
    deliveredValue: number | null;
    exchangeRates: string | null;
    fiscal: number;
    fiscalId: number | null;
    fiscalSRCProxy: string | null;
    generalDiscountPercentage: number;
    generalDiscountValue: number;
    invoiceNumber: number | null;
    invoiceType: number;
    invoiceValue: number | null;
    issuerCompanyInfo: string | null;
    issuerSecurityCode: string | null;
    locale: string;
    note: string | null;
    operatorName: string | null;
    orderNumber: number | null;
    params: string | null;
    partialPayment: boolean;
    paymentAmount: number | null;
    pendingInvoice: number;
    posTerminalId: number | null;
    posTerminalName: string | null;
    reclamation: number;
    reclamationFiscalId: number | null;
    reclamationReason: string | null;
    refInvoice: number | null;
    groupUuid: string | null;
    reservationId: number | null;
    businessSpaceLabel: string | null;
    sfJIR: string | null;
    tollDeviceLabel: string | null;
    taxIncludedInPrice: boolean;
    taxRecapitulation: string | null;
    terminalType: string;
    timestamp: number | null;
    totalBaseVAT: number | null;
    totalBaseVAT_BD: number | null;
    totalBaseValue: number | null;
    totalBaseValue_BD: number | null;
    totalLineItemDiscountValue: number | null;
    totalVAT: number | null;
    totalValue: number | null;
    typeOfPayment: string | null;
    vatRegistered: boolean;
    createdAt?: Date;
    updatedAt?: Date;
    InvoiceItems?: InvoiceItemsModel[];
    receivedPayment?: number;
    paymentStatus?: number;
    invoiceNumberFull: string | null;
    taxFreeCompany?: boolean;
    operatorUuid: string | null;
    posOrderHeaderIds:number []
    invoiceConclusionId?:number
    stockDespatched?:boolean
}


export interface InvoiceModelWithInvoiceItems extends InvoiceModel {
    InvoiceItems: InvoiceItemsModel[];
}

export interface InvoiceControlerModel {
    invoice: InvoiceModelWithInvoiceItems;
    invoiceItems: InvoiceItemsModel[];
    advanceInvoices: InvoiceModelWithInvoiceItems[];
    proformaInvoices: InvoiceModelWithInvoiceItems[];
    stornoInvoices: InvoiceModelWithInvoiceItems[];
    posTerminalInvoices: InvoiceModelWithInvoiceItems[];
    genuineInvoices: InvoiceModelWithInvoiceItems[];
    checkoutDateMismatchs: number[];
    minibarInvoices: InvoiceModelWithInvoiceItems[];
    availableForAdvancePayment: number;
}

export interface InvoicePreviewObject {
    reservationId: number;
    customerInfo: string;
    companyInfo: string;
    checkInTimestamp: number;
    checkOutTimestamp: number;
    params: string;
}

export interface InvoicePreview {
    invoice: InvoicePreviewObject;
    invoiceItems: InvoiceItemsModel[];
}

export interface InvoiceBaobabModel extends InvoiceControlerModel {
    reservation: ReservationEditModel | null;
    groupReservationsArray: ReservationModelWithModelsIncluded[];
    stayoverOverlap: StayOverOverlap;
    invoicePreview?: InvoicePreview;
    invoiceStornoPreview: InvoiceModelWithInvoiceItems | null;
    accommodationInvoice: InvoiceModelWithInvoiceItems | null;
}

export interface StayOverOverlap {
    overlapReservations: ReservationModelWithModelsIncluded[];
    option: number | null;
    type: string | null;
}

export interface AccommodationOffer {
    invoiceItems: InvoiceItemsModel[];
    priceOfferByLinkedIntervals: PriceOfferIntervalsGroupByReservationId;
}

export interface PosTerminalModel {
    id?: number;
    name: string;
    isEnabled: boolean;
    terminalType: number;
    fiscalInfo: string | FiscalInfoInterface;
    warehouseId?: number;
    params: string;
    tableLayoutIds?: string | null;
  //  itemsView?: boolean | null;
    mandatoryTableSelection?:boolean
    connectedPosTerminals?:string | number[] 
    parentPosTerminalId?:number | null
}

export interface FiscalInfoInterface{
    srcProxyName?:string
    SRCProxyUUID?:string
    businessSpace?:string
    tollDevice?:string
    printToPos?:boolean
    charLength?:number
 }

export interface CustomerInfoModel {
    documentId?: string;
    firstName?: string;
    lastName?: string;
    address?: string;
    poNumber?: string;
    town?: string;
    country?: string;
    type?: string;
}

export interface CompanyInfoModel {
    idNumber?: string;
    oib?: string | null;
    name?: string;
    address?: string;
    poNumber?: string;
    town?: string;
    country?: string;
    type?: string;
}

export interface FiscalGeneralSettings {
    enableFiscalisation: boolean;
    country: string;
}
export interface RoomInfoMap {
    [key: string]: RoomInfoEditModel;
}
export interface ReservationModelWithModelsIncluded extends ReservationEditModel {
    RoomInfo?: RoomInfoEditModel;
    Customer?: CustomerModel;
    Company?: CompanyModel;
    Invoices?: InvoiceModel; // nakon merge ima invoice model
    MinibarLogs?: MinibarLogModel;
    ReservationGuestReferences?: ReservationGuestReferenceModel[];
}

export interface IssuerCompanyInfo {
    name: string;
    hotelName: string;
    address: string;
    postOfficeNumber: string;
    town: string;
    contactEmail: string;
    idNumber: string;
    notes: string;
}

export interface InvoicePresenter {
    invoice: InvoiceModelWithInvoiceItems;
    invoiceItems: InvoiceItemsModel[];
    refInvoice?: InvoiceModelWithInvoiceItems | null;
    invoicePreview?: InvoicePreview;
    groupReservationsArray?: ReservationModelWithModelsIncluded[];
    stornoInvoices?: InvoiceModelWithInvoiceItems[];
    groupInvoiceDetails?: GroupInvoiceDetails;
    translate?: any;
    location?: any;
    print?: boolean;
    padding?: number;
    setInvoiceToSplit?:Function

}

export interface GroupInvoiceDetails {
    [key: string]: InvoicePreview;
}

export interface TaxRecapitulation {
    base: number;
    taxValue1: number;
    taxValue2: number;
    taxValue3: number;
    totalTaxValue: number;
    taxPercentage1: number | null;
    taxPercentage2: number | null;
    taxPercentage3: number | null;
    taxGroup: string;
}
export interface MessageBrokerInformationModel {
    id?: number;
    channelDriver: string;
    timestamp: number;
    senderInfo: string;
    receiverInfo: string;
    subject: string;
    message: string;
    model?: string;
    modelId?: number;
    statusFlag: number | null;
    notBeforeTimestamp: number | null;
    rules?: string;
    params?: string;
}
export interface MessageBrokerLogModel {
    id?: number;
    request: string;
    response: string;
    timestamp: number;
    remoteReference?: string | null;
    messageBrokerInformationId: number;
}

export interface CalculatedInvoiceLineItem {
    unitPrice: number;
    quantity: number;
    nettoUnitPrice: number;
    bruttoUnitsValue: number;
    brutoValueDiscount: number;
    nettoValueWithDiscount: number;
    valueWithDiscount: number;
    bruttoUnitPrice: number;
    bruttoValueWithDiscount: number;
    tax1ValueWithDiscount: number;
    tax2ValueWithDiscount: number;
    tax3ValueWithDiscount: number;
    totalTaxValue: number;
    discountValue: number;
    discountPercentage: number;
    taxPercentage1: number;
    taxPercentage2: number;
    taxPercentage3: number;
    type?: string | null | undefined;
    itemName?: string | null;
    plu?: string | null;
}

export interface GeneralSettingModel {
    uuid: string;
    key: string;
    params: string;
}
export interface ReservationReportHeaderModel {
    id?: number;
    reportNumber: number;
    fromTimestamp: number;
    toTimestamp: number | null;
    userUuid?: string | null;
    date?: string;
}

export interface ReservationReportItemsModel {
    id?: number;
    reservationStatusEnum: number;
    persons: number;
    adults: number;
    children: number;
    days?: number;
    adultDays?: number;
    childDays?: number;
    accommodationTaxValue: number;
    accommodationTaxVAT?: number;
    reservationId: number;
    reservationHeaderId: number;
    warningNote?: string | null; //ovo se samo koristi za upozorenje za rezervacije koje su se trebale checkoutat
}

export interface ReservationReportGuestInfosModel {
    id?: number;
    firstName: string;
    lastName: string;
    gender: string | null;
    birthDate: Date | null;
    birthPlace?: string | null;
    birthCountry?: string | null;
    citizenship: string;
    checkInDate: number;
    checkOutDate: number;
    documentType: string | null; //??
    documentNumber: string | null;
    documentValidityPeriod?: Date | null;
    documentPublisher?: string | null;
    visaType?: string | null;
    visaNumber?: string | null;
    visaValidityPeriod?: Date | null;
    dateEntry?: Date | null;
    placeEntry?: string | null;
    phoneOrMobile?: string | null;
    email?: string | null;
    notes: string | null;
    resReportItemId: number | null;
    customerId: number;
    resGuestReferenceId: number;
    foreignServiceUsername?: string | null;
    bookSerialNumber?: string | null;
    accTaxValuePerPeson: number | null;
}

export interface EStranacExportModel {
    //?? valjda je ovako, jer tako dobijem na primjeru exporta
    'Redni broj pod kojim je stranac zaveden u knjigu stranaca': number | string;
    'Serijski broj knjige stranaca u koju je unesen stranac': number | string;
    'Korisničko ime zaposlenog koji je izvršio prijavu stranca': string;
    'Ime stranca': string;
    'Prezime stranca': string;
    'Pol stranca': string;
    'Datum rođenja stranca': string;
    'Država rođenja stranca': string;
    'Mjesto rođenja stranca': string;
    'Državljanstvo stranca': string;
    'Vrsta putne isprave stranca': string;
    'Broj putne isprave stranca': number | string;
    'Rok važenja putne isprave stranca': string;
    'Izdavač putne isprave stranca': string;
    'Vrsta vize stranca': string;
    'Broj vize stranca': string;
    'Rok važenja vize stranca': string;
    'Datum ulaska u državu stranca': string;
    'Mjesto ulaska u državu stranca': string;
    'Broj izdatog fiskalnog računa za stranca': string;
    'Broj telefona stranca': string;
    'Email stranca': string;
    Napomena: string;
    'Datum prijave stranca u smještajnu jedinicu': string;
    'Datum odjave stranca iz smještajne jedinice': string;
    'Korisničko ime zaposlenog koji je izvršio odjavu stranca': string;
    ''?: string;
}

export interface TableModel {
    id?: number;
    backgroundPosition: string | null;
    name: string | null;
    numberOfSeats: number | null;
    tablesLayoutId: number;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface TablesLayoutModel {
    id?: number;
    name: string;
    description?: string | null;
    backgroundImage?: string | null;
    createdAt?: Date;
    updatedAt?: Date;
}



export interface PosDisplayConfigModel {
    id?: number;
    name: string;
    associatedUserUuids: string[] | null;
    associatedPrinterUuids: string[] | null;
    associatedTerminalIds: number[] | null;
    posSubscribedTags: string | null;
    type: string | null;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface PosOrderHeaderModel {
    id?: number;
    note?: string | null;
    statusEnum: string;
    startTimestamp: number;
    endTimestamp: number | null;
    tableId: number | null;
    customerId: number | null;
    companyId: number | null;
    invoiceId: number | null;
    createdAt?: Date;
    updatedAt?: Date;
    posTerminalId: number;
    createdByUser: string;
}
export interface AuthTokenInfo{
    token:string
    user:UserInfo
}

export interface ChannelManagerRoom {
    id?: number;
    name: string;
    occupancy: number;
    men: number;
    children: number;
    shortname: string;
    subroom: number;
    board: string;
    price: number;
    availability: number;
    woodoo: number;
    rtype: number;
    rtype_name: string;
    boards: string;
    anchorate: number;
    dec_avail: number;
    min_price: number;
    max_price: number;
}
export interface DebuggingLogModel {
    id?: number;
    timestamp: number;
    type: string;
    statusType: string | null;
    route: string | null;
    payload: string | null;
    message: string | null;
}

export interface PosTerminalConsumption {
    posItemId: number;
    quantity: number;
    newPrice: number;
    type: string;
    itemName?: string;
    unitPrice?: number;
    total?:number
    maxQuantity?:number
}
export interface StockDocHeaderModel {
    id?: number;
    docNumber: string;
    type: number;
    date: number;
    commitedDate: number | null;
    itemsCount: number;
    itemsValue: number;
    createdByUser: string | null;
    commitedByUser: string | null;
    companyId: number | null;
    warehouseId: number | null;
    receivingWarehouseId?: number | null; // za medjuskladisnicu
    locked: boolean;
    currency: string | null;
    note?: string | null;
    connectedStockDocHeader?: number;
    aggregatedWarehouseGoodsDataId: number | null;
    params?: string | null;
}

export interface StockDocItemModel {
    id?: number;
    skuId: number;
    quantity: number;
    todaysState?: number;
    purchasePrice: number;
    taxId1: number | null;
    taxId2?: number | null;
    taxId3?: number | null;
    grossMargin?: number; //mora biti al racunam na backendu
    price: number;
    stockValue?: number | null;
    stockDocHeaderId?: number; //mora ga biti al nemam ako tek dodajem header
    params?: string | null;
}

export interface WarehouseModel {
    id?: number;
    name: string;
    description?: string | null;
}

export interface StockKeepingUnitModel {
    id?: number;
    barcode: string | null;
    code: string;
    name: string;
    description?: string;
    // measureCode: string;
    category: string;
    taxId1: number | null;
    taxId2?: number | null;
    taxId3?: number | null;
    salesPrice: number;
    grossMarginPercentage: string;
    calculateSalesPriceOnStockIncrease: boolean;
    averagePurchasePrice: number;
    active: boolean;
    stockMeasureUnit: string;
    purchaseMeasureUnit: string | null;
    purchaseMeasureUnit_stockUnitMultiplier: number | null;
    minimumStock: number;
    replenishStock: number;
    isCounter: boolean;
    params?: string | null;
}

export interface SkuPLURateModel {
    id?: number;
    skuId: number;
    skuMeasureCode: string;
    pluMeasureCode: string;
    multiplier: number;
    posItemId: number;
    description?: string | null;
    params?: string | null;
}

export interface TempStockRecordModel {
    id?: number;
    warehouseId: number | null;
    quantity: number;
    dateTime: number;
    stockDocItemId: number | null;
    invoiceItemId: number | null;
    skuId: number;
    params?: string | null;
}

export interface EntryCalculationHeaderModel {
    id?: number;
    docNumber: string;
    date: number;
    commitedDate: number | null;
    createdByUser: string | null;
    commitedByUser: string | null;
    stockDocHeaderId: number | null;
    locked: boolean;
    currency: string | null;
    totalTransportation: number;
    totalShipping: number;
    totalCustoms: number;
    totalOtherCosts: number;
    params?: string | null;
}

export interface EntryCalculationItemModel {
    id?: number;
    barcode: string | null;
    code: string;
    name: string;
    quantity: number;
    entryPrice: number;
    entryVAT: number;
    entryVATInfo: number;
    transportation: number;
    shipping: number;
    customs: number;
    otherCosts: number;
    grossMarginPercentage: number;
    grossMarginValue: number;
    tax1: number | null;
    tax2: number | null;
    tax3: number | null;
    tax1Total: number | null;
    tax2Total: number | null;
    tax3Total: number | null;
    exitPrice: number;
    salePrice: number;
    entryCalcHeaderId: number;
    skuId: number;
    params?: string | null;
}

export interface EntryCalculationHeaderEditModel {
    id: number;
    docNumber: string;
    date: number;
    commitedDate: number | null;
    createdByUser: string | null;
    commitedByUser: string | null;
    stockDocHeaderId: number | null;
    params?: string | null;
}

export interface StockDocHeaderEditModel {
    id: number;
    docNumber: string;
    type: number;
    date: number;
    commitedDate: number | null;
    itemsCount: number;
    itemsValue: number;
    createdByUser: string | null;
    commitedByUser: string | null;
    companyId: number | null;
    warehouseId: number;
    receivingWarehouseId?: number | null; // za medjuskladisnicu
    locked: boolean;
    currency: string | null;
    note?: string | null;
    connectedStockDocHeader?: number;
    aggregatedWarehouseGoodsDataId: number | null;
    params?: string | null;
}

export interface CommonTempStockRecord {
    dateTime: number;
    stockDocItemId: number | null;
    invoiceItemId: number | null;
    params?: string | null;
}

export interface TempStockRecordExtended extends TempStockRecordModel {
    StockDocItem: {
        id: number;
        stockDocHeaderId: number;
        StockDocHeader: {
            id: number;
            type: number;
        };
        StockKeepingUnit: StockKeepingUnitModel;
    };
}

export interface WarehouseStock {
    warehouseId: number;
    skuId: number;
    name: string;
    code: string;
    barcode: string | null;
    isCounter: boolean;
    salesPrice: number;
    stockMeasureUnit: string;
    minimumStock: number;
    replenishStock: number;
    taxId1: number | null;
    prevAggWarehouseGoodsQty: number;
    goodsReceivingQty: number;
    goodsDespatchQty: number;
    tempInvoiceQty: number;
    lastAggQty: number;
    //jos ovo za potrebe inventure
    quantity?: number;
    totalQuantity?: number;
    todaysState?: number;
    stockQuantity?: number;
    stockPrice?: number;
    stockDocItemId?: number | null;
    inventoryState?: number;
}

export interface StockDocItemsExtended extends StockDocItemModel {
    StockKeepingUnit: StockKeepingUnitModel;
}

export interface TempStockRecordExtended extends TempStockRecordModel {
    StockKeepingUnit: StockKeepingUnitModel;
}

export interface StockDocItemsExtendedWithTaxes extends StockDocItemModel {
    StockKeepingUnit: StockKeepingUnitModel;
    tax1: TaxModel;
    tax2: TaxModel;
    tax3: TaxModel;
}

export interface EntryCalculationItemExtended extends EntryCalculationItemModel {
    StockKeepingUnit: StockKeepingUnitModel;
}

export interface StockDocHeadersExtended extends StockDocHeaderModel {
    Company: CompanyEditModel | null;
    CreatedByUser1: UserInfo | null;
    CommitedByUser: UserInfo | null;
}

export interface EntryCalcHeaderExtended extends EntryCalculationHeaderModel {
    CreatedByUser1: UserInfo | null;
    CommitedByUser1: UserInfo | null;
    StockDocHeader: StockDocHeaderEditModel | null;
}

export interface StockDocHeadersWithItems extends StockDocHeaderModel {
    StockDocItems: StockDocItemsExtended[];
    Company: CompanyEditModel | null;
    CreatedByUser: UserInfo | null; //za template
    CommitedByUser: UserInfo | null;
    docEntryCalcId: number | null;
    goodsReceivingNote?: number | null;
    goodsDespatchNote?: number | null;
    interWarehouseTransfer?: number | null;
    adjustmentByInventory?: number | null;
    inventoryGoodsReceivingNote?: number | null;
    inventory?: number | null;
    prevAggregatedWarehouseDataId?: StockDocHeadersWithItems | null;
}

export interface StockDocHeaderAndItems extends StockDocHeaderModel {
    StockDocItems: StockDocItemsExtended[];
}

export interface EntryCalcHeadersWithItems extends EntryCalcHeaderExtended {
    EntryCalculationItems: EntryCalculationItemExtended[];
}

export interface SkuPluRateWithItem extends SkuPLURateModel {
    StockKeepingUnit: StockKeepingUnitModel;
    PosItem?: PosItemModel;
}

// export interface InventoryModel {
//     skuId: number;
//     barcode: string | null;
//     code: string;
//     skuItem: string;
//     stockMeasureUnit: string;
//     aggStockRecQty: number;
//     tempStockRecQty: number;
//     quantity: number;
//     totalQty: number;
//     todaysState: number;
//     itemPrice: number;
//     stockQuantity: number;
//     stockPrice: number;
//     taxId1: number | null;
//     stockDocItemId: number | null;
//     inventoryState: number;
// }

export interface InventoryWithExistingAndOtherItems {
    existingSkuItems: WarehouseStock[];
    otherSkuItems: WarehouseStock[];
}

export enum StockDocumentTypesEnum {
    goodsReceivingNote = 1, // primitak
    goodsDespatchNote = 2, // otpis
    writeOffGoods = 3, // otpis
    interWarehouseTransfer = 4,
    orderToTheSupplier = 5,
    offerNote = 6,
    inventory = 7,
    adjustmentByInventory = 8,
    inventoryGoodsReceivingNote = 9,
    invoiceGoods = 10,
    aggregatedWarehouseGoodsData = 11,
    aggregatedWarehouseGoodsCounter = 12,
}

export const Operations: any = {
    addition: 'addition', // primka
    substraction: 'substraction', // otpremnica
    additionAndSubstraction: 'additionAndSubstraction',
};

export const GoodsRecevingOrDespatch: any = {
    goodsReceivingNote: Operations.addition, // primitak
    goodsDespatchNote: Operations.substraction, // otpis
    writeOffGoods: Operations.substraction, // otpis
    interWarehouseTransfer: Operations.additionAndSubstraction,
    adjustmentByInventory: Operations.addition,
    inventoryGoodsReceivingNote: Operations.addition,
    invoiceGoods: Operations.substraction,
};



export interface SRCProxyModel {
    id?: number;
    SRCProxyUUID: string;
    name: string;
    description: string;
    address: string;
    apyKey: string;
    printerProxy: boolean;
    lastSeenTimestamp: number;
    printerLineCharLength:number
    createdAt?: Date;
    updatedAt?: Date;
}

export interface InvoiceConclusionModel{
    id?: number
    timestamp: number
    cash: number
    card: number
    cheque: number
    virman: number
    otherPaymentTypes: number
    total: number
    totalVAT: number
    proformaTotal: number
    advanceTotal: number
    connectedPosTerminals: number[]
    operatorUuid: string | null
    operatorName: string | null
    posTerminalName: string | null
    note: string | null
    cashRegistarReportId: number | null
    posTerminalId:number
}

export interface SRCTemplateModel{
    id?:number
    name:string | null
    description: string | null
    SRCSettings:string | null | any //any moze biti model SRCSettingsModel
}