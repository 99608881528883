import React from 'react';
import baobabTree from './data/state';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { branch } from 'baobab-react/higher-order';
import asyncComponent from "./components/asyncComponent";

import PageNotFound from './pages/pageNotFound';
import PageLoader from './components/loaders/loader1';

import MasterLayout from './pages/masterLayout';
import Dashboard from './pages/dashboard';

import FeatureFlagsList from "./pages/featureFlags"

const AboutPage = asyncComponent(()=> import("./pages/generalSettings/about"))

const BMS = asyncComponent(() => import('./pages/BMS'));

const PosItem = asyncComponent(() => import('./pages/priceList/pos/posItem/index'));
const Tax = asyncComponent(() => import('./pages/priceList/tax/index'));
const AccommodationPriceList = asyncComponent(() => import('./pages/priceList/accommodation/index'));
const PricelistLookup = asyncComponent(() => import('./pages/priceList/pricelistLookup'));
const AccommodationPriceListStack = asyncComponent(() => import('./pages/priceList/accommodation/index2'));

const Currencies = asyncComponent(() => import('./pages/priceList/currencies/index'));
const ExchangeRate = asyncComponent(() => import('./pages/priceList/currencies/exchangeRate'));
const ReservationsConclusion = asyncComponent(() => import('./pages/priceList/businessConclusion/reservationsConclusion'));

const RoomsAdministration = asyncComponent(() => import('./pages/roomInfo/roomsAdministration'));
const RoomInfoForm = asyncComponent(() => import('./pages/roomInfo/form'));
const RoomAttributes = asyncComponent(() => import('./pages/roomInfo/roomAttributes'));

const ReservationDetails = asyncComponent(() => import('./pages/reservations2/reservationDetails'));
const ReservationsAdmin = asyncComponent(() => import('./pages/reservations2/administration'));

const AccessControl = asyncComponent(() => import('./pages/accessControl'));
const Whitelist = asyncComponent(() => import('./pages/whitelist'));
const WhiteListItems = asyncComponent(() => import('./pages/whitelist/whiteListItems'));

const SRCProxyForm = asyncComponent(() => import('./pages/SRCProxy/form'));
const SRCProxy = asyncComponent(() => import('./pages/SRCProxy/index'));
const SRCProxyAdministration = asyncComponent(() => import('./pages/SRCProxy/controllerAdministration'));
const SRCRoomBinder = asyncComponent(() => import('./pages/SRCProxy/roomBinder'));
const SRCBroadcastMulticast = asyncComponent(() => import('./pages/SRCBroadcastMulticast/index.js'));

const RoomInfo = asyncComponent(() => import('./pages/roomInfo'));

const SrcTemplate = asyncComponent(() => import('./pages/srcTemplate/index'));
const SrcTemplateForm = asyncComponent(() => import('./pages/srcTemplate/form'));

const AllRoomEvents = asyncComponent(() => import('./pages/allRoomEvents'));
const RoomEventHistory = asyncComponent(() => import('./pages/allRoomEvents/roomEventHistory'));

const SRCSettings = asyncComponent(() => import('./pages/SRCSettings'));
const NotificationTemplateSettings = asyncComponent(() => import('./pages/notificationTemplateSettings'));
const NotificationTemplates = asyncComponent(() => import('./pages/notificationTemplateSettings/templates.tsx'));
const QrCodeGenerator = asyncComponent(() => import('./pages/roomInfo/qrGenerator'));
const AccessControlQRGenerator = asyncComponent(() => import('./pages/accessControl/qrGenerator'));

const Login = asyncComponent(() => import('./authPages/login/index'));
const ApiKeys = asyncComponent(() => import('./pages/users/apiKeys'));

const CheckoutAndInvoice = asyncComponent(() => import('./pages/checkoutAndInvoice/checkout/CheckoutAndInvoice'));
const InvoiceView = asyncComponent(() => import('./pages/checkoutAndInvoice/printView/PrintView'));
//import ProFormaView from './pages/checkoutAndInvoice/proforma';

const Users = asyncComponent(() => import('./pages/users'));
const UserForm = asyncComponent(() => import('./pages/users/form'));

const ItemsList = asyncComponent(() => import('./pages/posTerminal/admin/itemsList'));
const PosDisplayConfig = asyncComponent(() => import('./pages/posDisplayConfig'));
const TablesLayout = asyncComponent(() => import('./pages/posTables'));
const TableArrangement = asyncComponent(() => import('./pages/posTables/newTableArrangement'));
const PosItemsTableView = asyncComponent(() => import('./pages/priceList/pos/posItem/tableView'));
// import PosInvoice from "./pages/posTerminal/posInvoice"

const Licence = asyncComponent(() => import('./pages/licence'));
const RequestLicence = asyncComponent(() => import('./pages/licence/requestLicence'));

// import GeneralSettingsForm from './pages/generalSettings';

const CheckRoomMinibar = asyncComponent(() => import('./pages/household/minibar/checkRoomMinibar2'));
const MinibarLogBook = asyncComponent(() => import('./reports/minibarLogBook'));
const VisaInfo = asyncComponent(() => import('./pages/visaInfo'));
const Search = asyncComponent(() => import('./pages/search/results'));
const PosTerminalAdmin = asyncComponent(() => import('./pages/posTerminal/admin/admin'));
const PosTerminalView = asyncComponent(() => import('./pages/posTerminal/posView/posView'));
const DocScannerDevices = asyncComponent(() => import('./pages/documentScannerDevices'));

const GeneralSettings = asyncComponent(() => import( "./pages/generalSettings"))
const RoomsAndPriceLists = asyncComponent(() => import( "./pages/roomPriceListTags"))
const FirstTimeSetup = asyncComponent(() => import( "./pages/generalSettings/firstTimeSetup"))
const DocumentScanner = asyncComponent(() => import( "./pages/documentScanner/index"))
const SelfCheckInAdministrative = asyncComponent(() => import( "./pages/selfCheckIn"))

const CashRegistars = asyncComponent(() => import( "./pages/cashRegistar"))
const CashRegistarPage = asyncComponent(() => import( "./pages/cashRegistar/cashRegistar"))
const UncollectedBills = asyncComponent(() => import( "./pages/cashRegistar/parts/uncollectedBills"))
const CashRegPaymentPrintView = asyncComponent(() => import( "./pages/cashRegistar/parts/printPreview"))

//REPORTS
const InvoicesReport = asyncComponent(() => import( './reports/invoices'))
const FinancialReport = asyncComponent(() => import( "./reports/financialReport/financialReport"))
const ReservationsReport = asyncComponent(() => import( './reports/reservations'))
const ReservationCustomerData = asyncComponent(() => import( './reports/reservationsCustomerData'))
const AuditReport = asyncComponent(() => import( './reports/audit'))
const SosResponsesLog = asyncComponent(() => import( "./reports/sosReports"))
const SelfCheckInLogs = asyncComponent(() => import( './reports/selfCheckInLogs'))
const ReservationsConclusionReport = asyncComponent(() => import( "./reports/reservationsConclusion"))
const RegisteredGuests = asyncComponent(() => import( "./reports/registeredGuests"))
const ReservationReportItems = asyncComponent(() => import( "./reports/reservationReportItems"))

const RoomEventsReport = asyncComponent(() => import( './reports/roomsEvents'))
const AccessControlReport = asyncComponent(() => import( './reports/accessControl/doorAccessPoint'))
const AccessControlDetails = asyncComponent(() => import( './reports/accessControl/doorAccessLogs'))

const SosGroups = asyncComponent(() => import( "./pages/sosGroups"))
const HouseholdReport = asyncComponent(() => import( "./reports/household"))
const CashRegistarsReport = asyncComponent(() => import( "./reports/cashRegistars"))
const CashRegistarPaymentsReport = asyncComponent(() => import( "./reports/cashRegistarPayments"))

const GoodsManagement = asyncComponent(()=> import("./reports/goodsManagement"))
const EntryCalculations = asyncComponent(()=> import("./reports/entryCalculations"))

//page menus
const SystemMenu = asyncComponent(() => import( './pages/menus/system'))
const ReportsMenu = asyncComponent(() => import( './pages/menus/reports'))

const Test = asyncComponent(() => import( './pages/test'))

//END REPORTS

const Household = asyncComponent(() => import( './pages/household/householdDashboard'))

const HouseholdManagePrintPreview = asyncComponent(() => import( './pages/household/householdAdmin/printPreview'))

const UserProfile = asyncComponent(() => import( './pages/userProfile'))

const RolesManagement = asyncComponent(() => import( './pages/rolesManagement/index'))

const Minibar = asyncComponent(() => import( './pages/roomInfo/minibar/'))

const FiscalPrinterTools = asyncComponent(() => import( './pages/fiscalPrinterTools'))


const DepositRegister = asyncComponent(() => import( './pages/depositRegister/index'))


const DatabaseManagement = asyncComponent(() => import( "./pages/administrative/database"))

//GROUP RESERVATIONS

const  CondoOwnerDashboard = asyncComponent(() => import( './pages/reservations2/condoOwnerView/dashboard'))
const CondoReservationForm = asyncComponent(() => import("./pages/reservations2/condoOwnerView/condoReservationForm"))

const HouseholdPlanner = asyncComponent(() => import("./pages/household/planner/householdPlanner"))

const ReservationForm2 = asyncComponent(() => import("@pages/reservations2/reservationForm"))

const GroupReservations2 = asyncComponent(() => import("@pages/groupInfo/groupReservations2/groupReservationsList"));
const GroupInvoices = asyncComponent(() => import("@pages/groupInfo/groupInvoices/groupInvoicesList"));
const GroupInfoList = asyncComponent(() => import("@pages/groupInfo/selectGroupList"));

const Companies = asyncComponent(() => import("@pages/company"));
const CompanyForm = asyncComponent(() => import("@pages/company/form"));

const Customers = asyncComponent(() => import("@pages/customers"));
const CustomerForm = asyncComponent(() => import("@pages/customers/form"));

const AlarmAndRoomModeInterpreter = asyncComponent(() => import("@pages/generalSettings/forms/alarmAndRoomModeInterpreter"));
const ChannelManager = asyncComponent(() => import("@pages/channelManager"));

//GOODS MANAGEMENT

const Documents = asyncComponent(() => import("@pages/goodsManagement/documents"))
const DocumentForm = asyncComponent(() => import("@pages/goodsManagement/documents/form"))
const SkuItems = asyncComponent(() => import("@pages/goodsManagement/skuItems"))
const Warehouses = asyncComponent(()=> import("@pages/goodsManagement/warehouses"))
const WarehouseCondition = asyncComponent(()=>import("@pages/goodsManagement/warehouses/warehouseStock"))
const EntryCalculation = asyncComponent(()=> import("@pages/goodsManagement/entryCalculation"))
const EntryCalcFrom = asyncComponent(() => import("@pages/goodsManagement/entryCalculation/form"))


var MasterLayoutWithRouter = withRouter(MasterLayout);

const PublicRoutes = (props) => {
	function onEnterRouteHandler(pageTitle, nextRouteObj) {
		baobabTree.root.select("pageTitle").set(pageTitle);
		if (props.fixedRoute && props.fixedRoute !== "" && nextRouteObj.location.pathname !== props.fixedRoute) {
			return <Redirect to={props.fixedRoute} />;
		} else if (props.dashboardRoute && props.dashboardRoute !== "" && props.dashboardRoute !== "/" && nextRouteObj.location.pathname === "/") {
			return <Redirect to={props.dashboardRoute} />;
		} else if((!props.locale || !props.hotelName || !props.defaultCurrency) && window.location.hash.lastIndexOf("firstTimeSetup")===-1){
			return <Redirect to={"/firstTimeSetup"} />;
		} else {
			return null;
		}
		//console.log("TU SAM", pageTitle);
	}
	return (
		<Router {...props}  >

			<MasterLayoutWithRouter {...props}  >
				<Switch {...props}>

					<Route ignoreScrollBehavior exact path="/" component={Dashboard} children={onEnterRouteHandler.bind(this, window.translate("Dashboard"))} />
					<Route ignoreScrollBehavior exact path="/about" component={AboutPage} children={onEnterRouteHandler.bind(this, window.translate("About"))} />

					<Route ignoreScrollBehavior exact path="/userProfile" component={UserProfile} children={onEnterRouteHandler.bind(this, window.translate("Your profile"))} />



					<Route ignoreScrollBehavior exact path="/priceLists/tax" component={Tax} children={onEnterRouteHandler.bind(this, window.translate("Taxes"))} />
					<Route ignoreScrollBehavior exact path="/priceLists/accommodation" component={AccommodationPriceList} children={onEnterRouteHandler.bind(this, window.translate("Accommodation price list"))} />
					<Route ignoreScrollBehavior exact path="/priceLists/accommodation/stack/:accommodationPriceListId" component={AccommodationPriceListStack} children={onEnterRouteHandler.bind(this, window.translate("Accommodation price list stack"))} />
					<Route ignoreScrollBehavior exact path="/priceLists/posItem/tableView" component={PosItemsTableView} children={onEnterRouteHandler.bind(this, window.translate("Pos items table view"))} />

					<Route ignoreScrollBehavior exact path="/priceLists/currencies" component={Currencies} children={onEnterRouteHandler.bind(this, window.translate("Currencies"))} />
					<Route ignoreScrollBehavior exact path="/priceLists/currencies/exchangeRates" component={ExchangeRate} children={onEnterRouteHandler.bind(this, window.translate("Exchange rates"))} />
					<Route ignoreScrollBehavior exact path="/priceLists/priceListLookup" component={PricelistLookup} children={onEnterRouteHandler.bind(this, window.translate("Pricelist lookup"))} />
					<Route ignoreScrollBehavior exact path="/priceLists/posItem" component={PosItem} children={onEnterRouteHandler.bind(this, window.translate("POS goods"))} />
					<Route ignoreScrollBehavior exact path="/fiscalTools/:srcProxyName" component={FiscalPrinterTools} children={onEnterRouteHandler.bind(this, window.translate("Blagajna"))} />
					<Route ignoreScrollBehavior exact path="/priceLists/depositRegister" component={DepositRegister} children={onEnterRouteHandler.bind(this, window.translate("Deposit register"))} />
					{/* <Route ignoreScrollBehavior exact path="/priceLists/businessConclusion" component={BusinessConclusion} children={onEnterRouteHandler.bind(this, window.translate("Business conclusion"))} /> */}
					<Route ignoreScrollBehavior exact path="/tablesLayout" component={TablesLayout} children={onEnterRouteHandler.bind(this, window.translate("Tables layout"))} />
					<Route ignoreScrollBehavior exact path="/tableArrangement/:tablesLayoutId" component={TableArrangement} children={onEnterRouteHandler.bind(this, window.translate("Table arrangement"))} />
					<Route ignoreScrollBehavior exact path="/reservationsConclusion" component={ReservationsConclusion} children={onEnterRouteHandler.bind(this, window.translate("Reservations conclusion"))} />

					<Route ignoreScrollBehavior exact path="/roomsAdministration/minibar/:roomInfoId" component={Minibar} children={onEnterRouteHandler.bind(this, window.translate("Minibar"))} />
					

					<Route ignoreScrollBehavior exact path="/household" component={Household} children={onEnterRouteHandler.bind(this, window.translate("Household"))} />
				
					<Route ignoreScrollBehavior exact path="/household/minibar/:roomInfoId" component={CheckRoomMinibar} children={onEnterRouteHandler.bind(this, window.translate("Minibar"))} />


					<Route ignoreScrollBehavior exact path="/household/manage/printPreview/:cleaningStatusHeaderId" component={HouseholdManagePrintPreview} children={onEnterRouteHandler.bind(this, window.translate("Household management"))} />

					<Route ignoreScrollBehavior exact path="/household/manage/planner" component={HouseholdPlanner}/>


					<Route ignoreScrollBehavior exact path="/bms" component={BMS} children={onEnterRouteHandler.bind(this, window.translate("Building Management System"))} />

					<Route ignoreScrollBehavior exact path="/sosGroups" component={SosGroups} children={onEnterRouteHandler.bind(this, window.translate("SOS groups"))} />

					<Route ignoreScrollBehavior exact path="/reservationDetails/:reservationId" component={ReservationDetails} children={onEnterRouteHandler.bind(this, window.translate("Reservation details"))} />


					<Route ignoreScrollBehavior exact path="/roomInfo/:roomInfoId/:remoteDeviceId" component={RoomInfo} children={onEnterRouteHandler.bind(this, window.translate("Room informations"))} />
					<Route ignoreScrollBehavior exact path="/roomInfo/:roomInfoId" component={RoomInfo} children={onEnterRouteHandler.bind(this, window.translate("Room informations"))} />
					{/*<Route ignoreScrollBehavior exact path="/reservations/:roomInfoId" component={ReservationForm2} children={onEnterRouteHandler.bind(this, window.translate("Reservations"))} />
					*/}
					<Route ignoreScrollBehavior exact path="/reservations/:roomInfoId/new" component={ReservationForm2} children={onEnterRouteHandler.bind(this, window.translate("Reservations"))} />

					<Route ignoreScrollBehavior exact path="/groupReservationForm" component={ReservationForm2} children={onEnterRouteHandler.bind(this, window.translate("Group reservation"))} />

				{/*ovaj reservation edit sa referencom na sobu izbrisati ččp ttb - kad se malo kod dolje počisti
					<Route ignoreScrollBehavior exact path="/reservations/:roomInfoId/:reservationId/edit" component={ReservationForm2} children={onEnterRouteHandler.bind(this, window.translate("Reservations"))} />
					*/}
					<Route ignoreScrollBehavior exact path="/reservations/:reservationId/edit" component={ReservationForm2} children={onEnterRouteHandler.bind(this, window.translate("Reservations"))} />
					
					<Route ignoreScrollBehavior exact path="/reservations" component={ReservationsAdmin} children={onEnterRouteHandler.bind(this, window.translate("Reservation administration"))} />



					<Route ignoreScrollBehavior exact path="/condoReservation/:roomInfoId/new" component={CondoReservationForm} children={onEnterRouteHandler.bind(this, window.translate("Reservations"))} />
					<Route ignoreScrollBehavior exact path="/condoReservation/:roomInfoId/:reservationId/edit" component={CondoReservationForm} children={onEnterRouteHandler.bind(this, window.translate("Reservations"))} />



					<Route ignoreScrollBehavior exact path="/checkoutAndInvoice/:reservationId" component={CheckoutAndInvoice} children={onEnterRouteHandler.bind(this, window.translate("Checkout"))} />
					<Route ignoreScrollBehavior exact path="/issueGroupInvoice/:groupUuid" component={CheckoutAndInvoice} children={onEnterRouteHandler.bind(this, window.translate("Checkout"))} />
					<Route ignoreScrollBehavior exact path="/issueTerminalInvoice/:invoiceRefUuid" component={CheckoutAndInvoice} children={onEnterRouteHandler.bind(this, window.translate("Issue invoice"))} />

					<Route ignoreScrollBehavior exact path="/invoiceView/:invoiceId" component={InvoiceView} children={onEnterRouteHandler.bind(this, window.translate("Invoices"))} />

					<Route ignoreScrollBehavior exact path="/allRoomEvents" component={AllRoomEvents} children={onEnterRouteHandler.bind(this, window.translate("Situation center"))} />
					<Route ignoreScrollBehavior exact path="/roomEventHistory/:roomInfoId" component={RoomEventHistory} children={onEnterRouteHandler.bind(this, window.translate("Room events history"))} />


					<Route ignoreScrollBehavior exact path="/srcTemplates" component={SrcTemplate} children={onEnterRouteHandler.bind(this, window.translate("SRC templates"))} />
					<Route ignoreScrollBehavior exact path="/srcTemplate/new" component={SrcTemplateForm} children={onEnterRouteHandler.bind(this, window.translate("SRC templates"))} />
					<Route ignoreScrollBehavior exact path="/srcTemplate/:srcTemplateId/edit" component={SrcTemplateForm} children={onEnterRouteHandler.bind(this, window.translate("SRC templates"))} />


					<Route ignoreScrollBehavior exact path="/SRCBroadcastMulticast" component={SRCBroadcastMulticast} children={onEnterRouteHandler.bind(this, window.translate("SRC settings broadcast & multicast"))} />
					<Route ignoreScrollBehavior exact path="/srcProxies" component={SRCProxy} children={onEnterRouteHandler.bind(this, window.translate("SRC Proxy configuration"))} />
					<Route ignoreScrollBehavior exact path="/srcProxies/:srcProxyId" component={SRCProxy} children={onEnterRouteHandler.bind(this, window.translate("SRC Proxy configuration"))} />
					<Route ignoreScrollBehavior exact path="/srcRoomBinder/:srcProxyId" component={SRCRoomBinder} children={onEnterRouteHandler.bind(this, window.translate("SRC Proxy configuration"))} />

					<Route ignoreScrollBehavior exact path="/srcProxy/new" component={SRCProxyForm} children={onEnterRouteHandler.bind(this, window.translate("SRC Proxy configuration"))} />
					<Route ignoreScrollBehavior exact path="/srcProxy/:srcProxyId/edit" component={SRCProxyForm} children={onEnterRouteHandler.bind(this, window.translate("SRC Proxy configuration"))} />
					<Route ignoreScrollBehavior exact path="/srcProxy/:srcProxyId/administration" component={SRCProxyAdministration} children={onEnterRouteHandler.bind(this, window.translate("SRC Proxy management"))} />
					<Route ignoreScrollBehavior exact path="/srcSettings/:roomInfoId/:remoteDeviceId" component={SRCSettings} children={onEnterRouteHandler.bind(this, window.translate("SRC Proxy configuration"))} />
					<Route ignoreScrollBehavior exact path="/notificationTemplateSettings" component={NotificationTemplateSettings} children={onEnterRouteHandler.bind(this, window.translate("Notification Service Settings"))} />

					<Route ignoreScrollBehavior exact path="/notificationTemplates" component={NotificationTemplates} children={onEnterRouteHandler.bind(this, window.translate("Notification Templates"))} />
					<Route ignoreScrollBehavior exact path="/qrCodeGenerator" component={QrCodeGenerator} children={onEnterRouteHandler.bind(this, window.translate("QR Generator"))} />
					<Route ignoreScrollBehavior exact path="/accessControlQRGenerator" component={AccessControlQRGenerator} children={onEnterRouteHandler.bind(this, window.translate("Acces control QR Generator"))} />


					<Route ignoreScrollBehavior exact path="/newRoomInfo" component={RoomInfoForm} children={onEnterRouteHandler.bind(this, window.translate("Rooms management"))} />
					<Route ignoreScrollBehavior exact path="/editRoomInfo/:roomInfoId" component={RoomInfoForm} BRISIME />
					<Route ignoreScrollBehavior exact path="/roomAttributes" component={RoomAttributes} children={onEnterRouteHandler.bind(this, window.translate("Room attributes"))} />


					<Route ignoreScrollBehavior exact path="/roomsAdministration" component={RoomsAdministration} children={onEnterRouteHandler.bind(this, window.translate("Rooms management"))} />
					<Route ignoreScrollBehavior exact path="/roomsAdministration/:roomInfoId" component={RoomInfoForm} children={onEnterRouteHandler.bind(this, window.translate("Rooms management"))} />

					<Route ignoreScrollBehavior exact path="/apiKeys" component={ApiKeys} children={onEnterRouteHandler.bind(this, window.translate("API keys management"))} />
					<Route ignoreScrollBehavior exact path="/users" component={Users} children={onEnterRouteHandler.bind(this, window.translate("User management"))} />
					<Route ignoreScrollBehavior exact path="/users/new" component={UserForm} children={onEnterRouteHandler.bind(this, window.translate("User management"))} />
					<Route ignoreScrollBehavior exact path="/users/edit/:uuid" component={UserForm} children={onEnterRouteHandler.bind(this, window.translate("User management"))} />

					<Route ignoreScrollBehavior exact path="/customers" component={Customers} children={onEnterRouteHandler.bind(this, window.translate("Customers"))} />
					<Route ignoreScrollBehavior exact path="/customers/new" component={CustomerForm} children={onEnterRouteHandler.bind(this, window.translate("Customers"))} />
					<Route ignoreScrollBehavior exact path="/customers/:customerId/edit" component={CustomerForm} children={onEnterRouteHandler.bind(this, window.translate("Customers"))} />

					<Route ignoreScrollBehavior exact path="/companies" component={Companies} children={onEnterRouteHandler.bind(this, window.translate("Companies"))} />
					<Route ignoreScrollBehavior exact path="/companies/new" component={CompanyForm} children={onEnterRouteHandler.bind(this, window.translate("Companies"))} />
					<Route ignoreScrollBehavior exact path="/companies/:companyId/edit" component={CompanyForm} children={onEnterRouteHandler.bind(this, window.translate("Companies"))} />

					<Route ignoreScrollBehavior exact path="/generalSettings" component={GeneralSettings} children={onEnterRouteHandler.bind(this, window.translate("General settings"))} />
					<Route ignoreScrollBehavior exact path="/licence" component={Licence} children={onEnterRouteHandler.bind(this, window.translate("Licence informations"))} />
					<Route ignoreScrollBehavior exact path="/requestLicence" component={RequestLicence} children={onEnterRouteHandler.bind(this, window.translate("REQUEST LICENCE"))} />

					<Route ignoreScrollBehavior exact path="/accessControl" component={AccessControl} children={onEnterRouteHandler.bind(this, window.translate("Access Control"))} />
					<Route ignoreScrollBehavior exact path="/whiteLists" component={Whitelist} children={onEnterRouteHandler.bind(this, window.translate("White list access"))} />
					<Route ignoreScrollBehavior exact path="/whiteListItems/:whiteListId" component={WhiteListItems} children={onEnterRouteHandler.bind(this, window.translate("White list access"))} />

					<Route ignoreScrollBehavior exact path="/report/invoices" component={InvoicesReport} children={onEnterRouteHandler.bind(this, window.translate("Invoices report"))} />
					<Route ignoreScrollBehavior exact path="/report/invoices/financialReport" component={FinancialReport} children={onEnterRouteHandler.bind(this, window.translate("Financial report"))} />
					
					<Route ignoreScrollBehavior exact path="/report/reservations" component={ReservationsReport} children={onEnterRouteHandler.bind(this, window.translate("Reservations report"))} />
					<Route ignoreScrollBehavior exact path="/report/reservationsConclusion" component={ReservationsConclusionReport} children={onEnterRouteHandler.bind(this, window.translate("Reservations conclusion report"))} />
					<Route ignoreScrollBehavior exact path="/report/reservationReportItems/:headerId" component={ReservationReportItems} children={onEnterRouteHandler.bind(this, window.translate("Reservation report items"))} />
					<Route ignoreScrollBehavior exact path="/report/registeredGuests/:headerId" component={RegisteredGuests} children={onEnterRouteHandler.bind(this, window.translate("Registered guests report"))} />

					<Route ignoreScrollBehavior exact path="/report/audit" component={AuditReport} children={onEnterRouteHandler.bind(this, window.translate("System audit"))} />

					<Route ignoreScrollBehavior exact path="/report/reservationCustomerData" component={ReservationCustomerData} children={onEnterRouteHandler.bind(this, window.translate("CUSTOMERS DATA REPORT"))} />
					<Route ignoreScrollBehavior exact path="/report/selfCheckIn" component={SelfCheckInLogs} children={onEnterRouteHandler.bind(this, window.translate("SELF CHECK IN LOGS"))} />

					<Route ignoreScrollBehavior exact path="/report/sosResponseLog" component={SosResponsesLog} children={onEnterRouteHandler.bind(this, window.translate("SOS response log"))} />

					<Route ignoreScrollBehavior exact path="/report/roomsEvents" component={RoomEventsReport} children={onEnterRouteHandler.bind(this, window.translate("Room events"))} />
					<Route ignoreScrollBehavior exact path="/report/accessControl" component={AccessControlReport} children={onEnterRouteHandler.bind(this, window.translate("Access control report"))} />
					<Route ignoreScrollBehavior exact path="/report/accessControl/:doorControlPointId" component={AccessControlDetails} children={onEnterRouteHandler.bind(this, window.translate("Door access report"))} />
					<Route ignoreScrollBehavior exact path="/report/invoices/cashRegistars" component={CashRegistarsReport} children={onEnterRouteHandler.bind(this, window.translate("Cash registars report"))} />
					<Route ignoreScrollBehavior exact path="/report/invoices/cashRegistarPayments" component={CashRegistarPaymentsReport} children={onEnterRouteHandler.bind(this, window.translate("Cash registar payments"))} />

					<Route ignoreScrollBehavior exact path="/report/goodsManagement" component={GoodsManagement} children={onEnterRouteHandler.bind(this, window.translate("Goods management report"))} />
					<Route ignoreScrollBehavior exact path="/report/entryCalculations" component={EntryCalculations} children={onEnterRouteHandler.bind(this, window.translate("Entry calculations report"))} />

					<Route ignoreScrollBehavior exact path="/test" component={Test} children={onEnterRouteHandler.bind(this, window.translate("TEST"))} />
					<Route ignoreScrollBehavior exact path="/menu/system" component={SystemMenu} children={onEnterRouteHandler.bind(this, window.translate("System menu"))} />
					<Route ignoreScrollBehavior exact path="/menu/reports" component={ReportsMenu} children={onEnterRouteHandler.bind(this, window.translate("Reports menu"))} />

					<Route ignoreScrollBehavior exact path="/report/minibarlogBook" component={MinibarLogBook} children={onEnterRouteHandler.bind(this, window.translate("MINIBAR LOG BOOK"))} />
					<Route ignoreScrollBehavior exact path="/groupReservations" component={GroupReservations2} children={onEnterRouteHandler.bind(this, window.translate("Group Reservations"))} />
					<Route ignoreScrollBehavior exact path="/groupReservations/:reservationUuid" component={GroupReservations2} children={onEnterRouteHandler.bind(this, window.translate("Group Reservations"))} />
					<Route ignoreScrollBehavior exact path="/visaInfo" component={VisaInfo} children={onEnterRouteHandler.bind(this, window.translate("Visa Info"))} />
					<Route ignoreScrollBehavior exact path="/search" component={Search} children={onEnterRouteHandler.bind(this, window.translate("Search..."))} />

					<Route ignoreScrollBehavior exact path="/roles" component={RolesManagement} children={onEnterRouteHandler.bind(this, window.translate("Roles management"))} />

					<Route ignoreScrollBehavior exact path="/database" component={DatabaseManagement} children={onEnterRouteHandler.bind(this, window.translate("Database management"))} />
					<Route ignoreScrollBehavior exact path="/posTerminal" component={PosTerminalAdmin} children={onEnterRouteHandler.bind(this, window.translate("POS Terminal"))} />
					<Route ignoreScrollBehavior exact path="/posTerminal/:posTerminalId" component={PosTerminalView} children={onEnterRouteHandler.bind(this, window.translate(""))} />
					<Route ignoreScrollBehavior exact path="/attachItemsToPosTerminal/:posTerminalId" component={ItemsList} children={onEnterRouteHandler.bind(this, window.translate(""))} />
					<Route ignoreScrollBehavior exact path="/posDisplayConfig" component={PosDisplayConfig} children={onEnterRouteHandler.bind(this, window.translate("POS display config"))} />

					
					<Route ignoreScrollBehavior exact path="/report/household" component={HouseholdReport} children={onEnterRouteHandler.bind(this, window.translate("HOUSEHOLD REPORT"))} />
					
					<Route ignoreScrollBehavior exact path="/docScannerDevices" component={DocScannerDevices} children={onEnterRouteHandler.bind(this, window.translate("DOCUMENT SCANNER DEVICES"))} />

					<Route ignoreScrollBehavior exact path="/fflags" component={FeatureFlagsList} children={onEnterRouteHandler.bind(this, "FEATURE FLAGS LIST")} />
					<Route ignoreScrollBehavior exact path="/condoView" component={CondoOwnerDashboard} children={onEnterRouteHandler.bind(this, window.translate("My apartments"))} />
					<Route ignoreScrollBehavior exact path="/roomsAndPriceLists" component={RoomsAndPriceLists} children={onEnterRouteHandler.bind(this, window.translate("Rooms and pricelists"))} />

					<Route ignoreScrollBehavior exact path="/firstTimeSetup" component={FirstTimeSetup} children={onEnterRouteHandler.bind(this, window.translate("Setup"))} />
					<Route ignoreScrollBehavior exact path="/documentScanner" component={DocumentScanner} children={onEnterRouteHandler.bind(this, window.translate("Document scanner"))} />

					<Route path="/selfCheckInAdministrative" component={SelfCheckInAdministrative} children={onEnterRouteHandler.bind(this, window.translate("Self check in administration"))}  />
					<Route path="/channelManagerMapping/:providerName" component={ChannelManager} children={onEnterRouteHandler.bind(this, window.translate("Channel Manager"))}  />

					<Route path="/alarmAndRoomModeInterpreter" component={AlarmAndRoomModeInterpreter} children={onEnterRouteHandler.bind(this, window.translate("Alarm and room mode interpreter"))}  />
					<Route path="/cashRegistars" component={CashRegistars} children={onEnterRouteHandler.bind(this, window.translate("Cash Registars"))}  />
					<Route path="/cashRegistarPage/:cashRegistarId" component={CashRegistarPage} children={onEnterRouteHandler.bind(this, window.translate("Cash Registar"))}  />
					<Route path="/uncollectedBills" component={UncollectedBills} children={onEnterRouteHandler.bind(this, window.translate("Uncollected Bills"))}  />
					<Route path="/cashRegReportView/:reportId" component={CashRegPaymentPrintView} children={onEnterRouteHandler.bind(this, window.translate("Cash registar report"))}  />
					<Route ignoreScrollBehavior exact path="/groupInvoices" component={GroupInvoices} children={onEnterRouteHandler.bind(this, window.translate("Group Invoices"))} />
					<Route ignoreScrollBehavior exact path="/groupInfos" component={GroupInfoList} children={onEnterRouteHandler.bind(this, window.translate("Groups"))} />
					
					<Route ignoreScrollBehavior exact path="/goodsManagement/documents" component={Documents} children={onEnterRouteHandler.bind(this, window.translate("Documents"))} />
					<Route ignoreScrollBehavior exact path="/goodsManagement/documents/new" component={DocumentForm} children={onEnterRouteHandler.bind(this, window.translate("New document"))} />
					<Route ignoreScrollBehavior exact path="/goodsManagement/documents/edit/:headerId" component={DocumentForm} children={onEnterRouteHandler.bind(this, window.translate("Edit document"))} />
					<Route ignoreScrollBehavior exact path="/goodsManagement/skuItems" component={SkuItems} children={onEnterRouteHandler.bind(this, window.translate("SKU Items"))} />
					<Route ignoreScrollBehavior exact path="/goodsManagement/warehouses" component={Warehouses} children={onEnterRouteHandler.bind(this, window.translate("Warehouses"))} />
					<Route ignoreScrollBehavior exact path="/goodsManagement/warehouse/:warehouseId" component={WarehouseCondition} children={onEnterRouteHandler.bind(this, window.translate("Warehouse stock"))} />
					<Route ignoreScrollBehavior exact path="/goodsManagement/entryCalculation" component={EntryCalculation} children={onEnterRouteHandler.bind(this, window.translate("Entry calculation"))} />
					<Route ignoreScrollBehavior exact path="/goodsManagement/entryCalc/new" component={EntryCalcFrom} children={onEnterRouteHandler.bind(this, window.translate("New entry calculation"))} />
					<Route ignoreScrollBehavior exact path="/goodsManagement/entryCalc/edit/:entryCalcId" component={EntryCalcFrom} children={onEnterRouteHandler.bind(this, window.translate("Edit entry calculation"))} />


					<Route pageTitle="Page not found" component={PageNotFound} />

				</Switch>
			</MasterLayoutWithRouter>

		</Router>
	);
}


const UnauthorizedRouter = (props) => (
	<Router {...props} ignoreScrollBehavior>
		<Switch {...props}>
			<Route
				exact
				path="/"
				render={(_props) => {
					return <Login {..._props} {...props} showRegistration={props?.about?.adminUsersCount === 0} />
				}}
			/>
			<Route pageTitle="Login"
			 render={(_props) => {
				return <Login {..._props} {...props} showRegistration={props?.about?.adminUsersCount === 0} />
			}}
			 />
		</Switch>
	</Router>
);


const ExportRoutes = (props) => {
	if (props.authTokenInfo || props.keycloak?.authenticated) {
		if (props.socketConnection && props.socketConnection.showConnectingScreen) {
			return (
				<PageLoader
					header={props.socketConnection.connectingMessage}
					heading={props.socketConnection.heading}
				/>
			);
		} else {
			return <PublicRoutes {...props} />;
		}
	} else {
		if (props.socketConnection.showConnectingScreen) {
			return (
				<PageLoader
					header={props.socketConnection.connectingMessage}
					heading={props.socketConnection.heading}
				/>
			);
		} else {
			return <UnauthorizedRouter {...props} />;
		}
	}
};

export default branch(
	{
		about: ['about'],
		locale: ['locale'],
		defaultCurrency: ['defaultCurrency'],
		hotelName: ['monkeys', 'companyInfo', 'hotelName'],
		authTokenInfo: ['authTokenInfo'],
		fixedRoute: ['state', 'userPermissions', 'fixedRoute'],
		dashboardRoute: ['state', 'userPermissions', 'dashboardRoute'],
		socketConnection: ['socketConnection'],
		licenceType: ['licence', 'licence', 'decodedLicence', 'licenceType'],
		keycloak: ['keycloak'],
	},
	ExportRoutes
);
