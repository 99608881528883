export default {
    Save: 'Spremi',
    Cancel: 'Odustani',
    'GO BACK': 'NATRAG',
    Change: 'Promijeni',
    Confirm: 'Potvrdi',
    Name: 'Naziv',
    Address: 'Adresa',
    'Post office number': 'Poštanski broj',
    Town: 'Grad',
    Country: 'Država',
    'Country/State': 'Država',
    Notes: 'Bilješke',
    'ADD NEW': 'DODAJ',
    prev: 'prethodna',
    next: 'sljedeća',
    page: 'stranica',
    Select: 'Odaberi',
    CREATE: 'NAPRAVI',
    'First name': 'Ime',
    'Last name': 'Prezime',
    VIEW: 'PREGLED',
    Close: 'Zatvori',
    Delete: 'Izbriši',
    Update: 'Ažuriraj',
    ADD: 'DODAJ',
    CANCEL: 'ODUSTANI',
    View: 'Pregledaj',
    Yes: 'Da',
    No: 'Ne',
    'Are you sure you want to delete?': 'Jeste li sigurni da želite obrisati?',
    'Invalid value': 'Neispravna vrijednost',
    'Tag already exist!': 'Oznaka već postoji!',
    ' EXPORT': ' IZVEZI',
    CONFIRM: 'POTVRDI',
    SAVE: 'SPREMI',
    'CLOSE VIEW': 'ZATVORI PREGLED',

    //Access control
    'Access point name': 'Naziv pristupne točke',
    Description: 'Opis',
    'Allow guest access, only from rooms with tags': 'Dozvoli pristup gostu, samo iz soba s oznakama',
    'no filter': 'bez filtera',
    whitelist: 'whitelist',
    none: 'prazno',
    Guests: 'Gosti',
    'reserved1': 'Rezervirana ključna riječ koja omogućuje svim gostima da imaju pristup odabranim vratima',
    'reserved2': 'Rezervirana ključna riječ koja omogućuje svim gostima sa privremenim self check in kodom da imaju pristup odabranim vratima',
    Reserved: 'Rezervirano',
    'Tag picker': 'Odaberi oznaku',
    'Available tags': 'Dostupne oznake',
    'Pinned tags': 'Prikvačene oznake',
    'No pinned tags': 'Nema prikvačenih oznaka',

    //All room events
    'ROOM LOCAL': 'ROOM LOCAL',
    GUEST: 'GOST',
    'GUEST PHONE': 'Broj telefona gosta',
    'CUSTOM ALERT MESSAGE': 'OBAVIJEST O ALARMU',
    'HW MUTE ALARM': 'HARDVERSKI PONIŠTI ALARM',
    'SW MUTE ALARM': 'PRISILNO PONIŠAVANJE ALARMA',
    "NOTE: Sensor alarms can not be muted": 'PAŽNJA: Senzorski uvjetovatni alarmi, ne mogu se ugasiti',
    'NOTE: This will force close ticket. Use only if hardware is malfunctioning':'PAŽNJA: Ova akcija će prisilno zatvoriti alarm. Koristiti samo u slučaju da uređaj nije ispravan',
    'Please enter the reason for force closing this ticket':'Molimo da unesete razlog za prisilno zatvaranje ove kartice',
    'FORCE CLOSE TICKET': 'Prisilno zatvaranje događaja zbog izostanka odziva sa uređaja',
    'RESPOND TO EVENT': 'ODGOVOR NA DOGAĐAJ',
    'Situation center is disabled': 'Situacijski centar je onemogućen',
    Alarms: 'Alarmi',
    'Room Service': 'Posluga u sobu',
    'Cleaning tracker': 'Praćenje čišćenja',
    'Loading room events': 'Učitavanje događaja u sobi',
    'No data': 'Nema podataka',
    'SUGGEST RESPONDER': 'SUGGEST RESPONDER',
    'CLAIM RESPONSE': 'CLAIM RESPONSE',
    'SELECT RESPONDER': 'SELECT RESPONDER',
    'EVENT RESPONDER': 'EVENT RESPONDER',
    'Please, fill the following informations after you have returned from the location':
        'Molimo vas da popunite sljedeće podatke nakon što se vratite sa pružanja pomoći',
    'EVENT NOTES': 'Bilješke o događaju',
    'CLOSE EVENT LOG': 'ZATVORI ZAPIS',
    'Event time': 'Vrijeme događaja',
    'Event accepted': 'Događaj prihvaćen',
    Receptionist: 'Recepcionist',
    Responder: 'Responder',
    'Event closed': 'Događaj zatvoren',
    'DISMISS SOS CALL': 'ODBACI SOS POZIV',
    'Missing SOS response record. Close this event?': 'Nedostaje zapis SOS odgovora. Zatvoriti ovaj događaj?',
    'TRY TO CLOSE EVENT': 'Pokušajte zatvoriti događaj',
    'no responder info': 'nema podataka o odgovoru',

    //Company
    "Field: '": "Polje: '",
    'VAT or ID number': 'VAT ili ID broj',
    "' is not unique!!!!": "' nije obavezno!!!!",
    Web: 'Web stranica',
    'Add company': 'Dodaj tvrtku',
    'Edit company': 'Uredi tvrtku',
    'Company has ': 'Tvrtka ima ',
    ' reseravation(s). Are you sure you want delete?': ' rezervacija. Jeste li sigurni da želite obrisati?',
    'SEARCH FOR COMPANY (by name, town, idNumber)': 'TRAŽI TVRTKU (po nazivu, gradu, id broju)',

    //Customers
    'Visa Type And Number': 'Tip i broj vize',
    'Visa Validity Period': 'Razdoblje valjanosti vize',
    'Date entry in country': 'Datum ulaska u državu',
    'Entry place': 'Mjesto ulaska',
    'Document Validity Period': 'Rok važenja dokumenta',
    'Personal document id': 'Broj osobnog dokumenta',
    'Add Customer': 'Dodaj gosta',
    'Edit customer': 'Uredi podatke o gostu',
    Company: 'Tvrtka',
    'New Company': 'Dodaj tvrtku',
    'Birth Date': 'Datum rođenja',
    'Birth Place': 'Mjesto rođenja',
    Gender: 'Spol',
    Email: 'Email',
    'Mobile number': '',
    Citizenship: 'Državljanstvo',
    'Newsletter subscriptions': 'Pretplati se na newsletter',
    Male: 'Muško',
    Female: 'Žensko',
    Undefined: 'Ostalo',
    'Customer has ': 'Gost ima ',
    'SEARCH FOR CUSTOMER (by first and last name, email, document id)':
        'TRAŽI GOSTA (po imenu i prezimenu, email-u, oznaki dokumenta)',

    //household
    'Room cleaning orders are generated automatically. Do you want to generate new accounts anyway?':
        'Nalozi za čišćenje soba generiraju se automatski. Želite li ipak generirati nove naloge?',
    'Drag employee card from the left and drop them on the room placeholder which needs to be cleaned or inspected. Room access for assigned employee will be granted for the current day and in working hours.':
        'Povucite karticu zaposlenika s lijeve strane i spustite ih na rezervirano mjesto u sobi koje treba očistiti ili pregledati. Pristup sobi dodijeljenom zaposleniku bit će odobren za tekući dan i u radno vrijeme.',
    'LAST 10 CYCLES': '10 POSLJEDNJIH FAZA',
    'GENERATE NEW LOGS': 'GENERIRAJ NOVE ZAPISE',
    'Maid list is empty': 'Popis sobarica prazan',
    'CLEANING SCHEDULE FOR ': 'Raspored čišćenja za ',
    'ROOM/STATUS': 'SOBA/STATUS',
    MISSING: 'NEDOSTAJE',
    'LOST/FOUND': 'IZGUBLJENO/NAĐENO',
    MALFUNCTIONS: 'NEISPRAVNOST',
    'Change cleaning status': 'Odaberi status',
    "You can't change the past, but you can learn from it": 'Ne možeš promijeniti prošlost, ali možeš naučiti iz nje',
    'Stage for cleaning': 'Spremno za čišćenje',
    'Finish cleaning': 'Završi čišćenje',
    'Start cleaning': 'Počni čišćenje',
    'Postpone cleaning': 'Odgodi čišćenje',
    'Skip cleaning': 'Preskoči čišćenje',
    'Check minibar': 'Provjeri minibar',
    'Minibar management': 'Minibar menadžment',
    'Save note': 'Spremi bilješku',
    'ITEM NAME': 'NAZIV ARTIKLA',
    QUANTITY: 'Količina',
    ACTION: 'Akcija',
    'Minibar from ': 'Minibar u sobi ',
    'Active reservation': 'Aktivna rezervacija',
    From: 'Od',
    To: 'Do',
    Guest: 'Gost',
    'Attach to reservation': 'Spremi na rezervaciju',
    'NO ACTIVE RESERVATIONS': 'NEMA AKTIVNE REZERVACIJE',
    'CONSUMPTION LOG': 'DNEVNIK POTROŠNJE',
    'Cleaning requested': 'Zatraženo čišćenje',
    Consumption: 'Potrošnja',
    Stock: 'Zaliha',
    'Undo consumption': 'Poništi potrošnju',
    Manage: 'Upravljaj',

    //Visa info
    "Nationalities who need visa to enter in country":
        'Državljanstva koja trebaju vizu za ulazak u državu ',

    //users
    'Add new user': 'Dodaj korisnika',
    'Edit user info': 'Izmjeni korisničke podatke',
    'Card number': 'Kartični kod',
    'Select role': 'Korisnička uloga',
    Experience: 'Iskustvo',
    'Activate user': 'Aktivirajte korisnika',
    'After user is created, please set the password using the button':
        'Nakon što kreirate korisnika, postavite mu pristupne podatke preko botuna',
    'API KEYS': 'API ključevi',
    ROLES: 'ULOGE',
    "(TEMPORARY KEY! DON'T USE!)": '(PRIVREMENI KLJUČ! NE KORISTI!)',
    'Generate token': 'Generiraj token',
    Token: 'Token',
    'Valid to': 'Vrijedi do',
    'User uuid': 'Korisnički uuid',
    'Minimum 6 character is required for password': 'Lozinka mora sadržavati minimalno 6 znakova!',
    'Passwords does not match! Please try again.': 'Unosi lozinki se ne podudaraju. Popravite i probajte ponovno!',
    'Password changed': 'Lozinka promijenjena',
    'New password for this user would be required on next login!':
        'Prilikom iduće prijave, od korisnika će se tražiti nova lozinka!',
    'Current password': 'Trenutna lozinka',
    Password: 'Lozinka',
    'Repeat password': 'Ponovi lozinku',

    //whitelist
    'Whitelist name': 'Naziv dozvoljene liste',
    Tags: 'Oznake',
    'New White List': 'Nova lista dozvoljenih',
    'Edit White List': 'Ažuriraj listu dozvoljenih',
    'Delete White List': 'Izbriši listu dozvoljenih',
    'Please, enter all form values!': 'Molimo unesite sve vrijednosti forme!',
    'CARD HOLDER NAME': 'IME KARTIČNOG KODA',
    'changed on ': 'promijenjena na ',
    'Tags filed can not be empty': 'Polje Oznake ne može biti prazno',
    'Add tag...': 'Dodaj oznaku...',
    'Add tag, then press enter to confirm': 'Dodaj oznaku i pritisni enter za potvrdu',
    //priceList
    'Room Id': 'ID Sobe',
    'Room name': 'Naziv sobe',
    Pricelist: 'Cojenik',
    'Select a pricelist': 'Odaberite cjenik',
    'Accommodation tax': 'Boravišna pristojba',
    'No tax': 'Nema',
    'Apply per day': 'Primjeni svakodnevno',
    'Multiply with adults number': 'Pomnoži s brojem odraslih',
    'Multiply with children number': 'Pomnoži s brojem djece',
    'Person count': 'Broj osoba',
    Price: 'Cijena',
    'PLU is mandatory, min 3 digits': 'PLU je obavezan, najmanje 3 znamenke',
    'PLU must be unique': 'PLU mora biti jedinstven',
    'Taken PLUs': 'Zauzeti PLU',
    'Please, before continiue, enter at least one tax group.': 'Prije nastavka unesite barem jednu poreznu skupinu.',
    'PLU Base': 'PLU baza',
    Tax: 'PDV',
    'Max. billable persons': 'Max. broj osoba koje naplaćujete',
    'Name is mandatory': 'Ime je obavezno',
    'No prices . You should delete this price list and create a new one.':
        'Cjenik nema definirane cijene po osobi. Izbrišite ga i napravite novi.',
    'Valid from': 'Vrijedi od',
    'Repeat rules': 'Ponavljaj',
    'Valid on days': 'Vrijedi za dane',
    Weight: 'Težina',
    'Parent PLU Base': 'Parent PLU baze',
    Infinite: 'Beskonačno',
    'Is active': 'U uporabi',
    'New price list': 'Novi cjenik',
    'Assign to room': 'Dodijeli cjenik sobi',
    'NOTE: For every room type, you should create a seperate accommodation price list. You can add sessional and promotional tarrifs by stacking them visualy on infinite timeline - so you can easily track and change pricing and promotion policies.':
        'Napomena: Za svaki tip sobe napravite poseban cjenik smještaja. Možete dodati sezonske i promotivne tarife slažući ih grafički na beskonačnoj vremenskoj crti- tako možete jednostavno pratiti cijene i promotivne ponude.',
    '*Any modification of pricing policies, have no impact on already saved offers.':
        '*Bilo kakva izmjena cjenovne politike nema utjecaja na već spremljene ponude.',
    'Price rules not defined': 'Cjenik nije definiran',
    'Fixed price': 'Jednoznačna cijena',
    'Up to': 'Do',
    'Update baseline price list': 'Ažurirajte početni cjenik',
    'Update price list': 'Ažurirajte cjenik',
    'Price per person': 'Cijena po osobi',
    'Accommodation price list': 'Cjenik smještaja',
    'Conflicting dates': 'Podudarni dani',
    'Conflict detected': 'Otkriveni sukobi',
    'Resolve confilcts by shifting the weight slots on your price lists or by adjusting dates to avoid conflicting price lists. Conflicts affects the automated decision making on accommodation prices':
        'Riješi problem mijenjanjem važnosti cjenika ili promjenom datuma kako se cjenici ne bi podudarali. Problemi utječu na automatizirano donošenje odluka o cijenama smještaja',
    'Stacked price lists on timeline': 'Složeni cjenici na vremenskoj traci',
    'Price lists with a higher weight on the same day, will have a greater importance':
        'Cjenik s većom težinom na isti dan će imati veću važnost',
    Basis: 'Osnovica',
    'Gross amount': 'Bruto iznos',
    'Saved successfully': 'Uspješno spremljeno',
    'Deleted successfully': 'Uspješno izbrisano',
    'Exchange rates on ': 'Tečajevi na ',
    'Please setup default currency in general settings first.':
        'Molimo prvo podesite zadanu valutu u općim postavkama.',
    'EX. RATES ON DAY': 'Tečajevi na dan',
    'Default currency': 'Zadana valuta',
    'Default currency must have exchange rate of 1 (one).': 'Zadana valuta mora imati tečaj 1 (jedan).',
    'Exchange rate': 'Tečaj',
    'Name:': 'Naziv:',
    Acronym: 'Kratki naziv',
    'Is enabled': 'Omogućen',
    'New currency': 'Nova valuta',
    PLU: 'PLU',
    'Next available PLU: ': 'Sljedeći dostupni PLU: ',
    Category: 'Kategorija',
    Image: 'Slika',
    'Unit of measure': 'Jedinica mjere',
    'Service type': 'Tip artikla',
    Enabled: 'Aktiviraj artikal',
    'Enable item at POS': 'Omogući artikl u POS-u',
    'Add new service': 'Dodaj novu uslugu',
    'Edit service': 'Izmjeni uslugu',
    'Item exist in minibar': 'Artikl postoji u minibaru',
    TAX: 'Porez',
    'Name or barcode': 'Naziv ili barkod',
    'Name or code':'Naziv ili kod',
    Piece: 'Komad',
    'L (liter)': 'L (litar)',
    'KG (kilogram)': 'KG (kilogram)',
    Percentage: 'Postotak',
    'Tax included in price': 'Porez uključen u cijenu',
    Amount: 'Iznos',
    'Fixed tax': 'Boravišna pristojba',
    'New Tax': 'Novi porez',
    'Update fiscal memory': 'Ažuriraj fiskalnu memoriju',
    Id: 'Id',
    'Netto price': 'Neto cijena',
    'Brutto price': 'Bruto cijena',
    'No repeat rules': 'Nema ponovljenih pravila',
    'Always valid.': 'Uvijek valjano',

    //reports
    rejected: 'odbijeno',
    pass: 'odobreno',
    Reservations: 'Rezervacije',
    "Get ghant view of reservations by month. Find out your 'reservation index'.":
        "Pogledaj 'ghant' prikaz rezervacija po mjesecu. Saznaj svoj 'Index rezervacija'.",
    'View Report': 'Pregled izvješća',
    'Customers data reports': 'Izvješća o gostima',
    Invoices: 'Računi',
    'Select and explore invoices.': 'Odaberite i istražite račune.',
    'Explore all events for every room. Filter them by date or get CSV export.':
        'Istražite sve događaje za svaku sobu. Filtrirajte ih po datumu ili dohvatite svoj CSV export.',
    'Room events': 'Događaji u sobi',
    'SOS log': 'SOS zapisi',
    'Minibar report': 'Minibar izvješće',
    'Access control report': 'Izvješće kontrole pristupa',
    'Explore door access events. Hotel rooms are not included, only doors.':
        'Istražite događaje o pristupu vratima. Hotelske sobe nisu uključene, samo vrata.',
    'Print number of guests': 'Ispiši broj gostiju',
    //menus
    Rooms: 'Sobe',
    Controllers: 'Kontroleri',
    'SRC templates': 'SRC predložak',
    'Alarm settings': 'Postavke alarma',
    'SOS groups': 'SOS grupe',
    'Doors & rules': 'Vrata & pravila',
    Whitelists: 'Liste dozvoljenih',
    Customers: 'Gosti',
    Companies: 'Tvrtke',
    'Visa Info': 'Viza informacije',
    Settings: 'Postavke',
    Users: 'Korisnici',
    'Notification service settings': 'Postavke servisa notifikacija',
    Updates: 'Ažuriranja',
    Licence: 'Licence',
    Taxes: 'Porezi',
    Accommodation: 'Smještaj',
    'Price lists': 'Cjenici',
    'Create dynamic accommodation price list and bind them to rooms. Beside accommodation, you can create price lists for minibar, goods and services.':
        'Kreirajte dinamički cjenik smještaja i povežite ga na sobe. Osim smještaja, možete kreirati cjenike za minibar, robu i usluge.',
    Currencies: 'Valute',
    'Pricelist lookup': 'Šifrarnik',
    'POS goods': 'POS artikli',
    'Fiscal printers': 'Fiskalni pisači',
    'Rooms and hardware': 'Sobe i uređaji',
    'Create or update hotel rooms informations. Declare SRC proxyes and bind their controllers with designated room. Create predefined SRC configurations and download them to selected rooms.':
        'Kreiraj ili ažuriraj informacije o hotelskim sobama. Declare SRC proxyje i povežite njihove kontrolere s određenom sobom. Stvorite unaprijed definirane konfiguracije SRC-a i preuzmite ih u odabrane sobe.',
    'Access control': 'Kontrola pristupa',
    'Define door, lifts or parking access rules. You can define access whitelist and combine them with any door. Hotel rooms are not managed here.':
        'Definirajte pravila za vrata, dizala ili parking. Možete definirati popis za pristup i kombinirati ih s bilo kojim vratima. Ovdje se ne upravlja hotelskim sobama.',
    'Data folders': 'Mape',
    'Browse and create customers and companies required by reservation system.':
        'Pregledajte i dodajte goste i tvrtke koje zahtjeva rezervacijski sustav.',
    'Advanced settings': 'Napredne postavke',
    'Setup language, company informations, currency and modems configuration. Manage API keys.':
        'Postavite jezik, informacije o tvrtki, konfiguraciju valuta i modema. Upravljajte ključevima API-ja.',
    'Software updates and licence': 'Ažuriranje softwarea i licence',
    'Update your software. Manage licence keys and view licence informations.':
        'Ažurirajte svoj softver. Upravljajte ključevima licence i pogledajte informacije o licenci.',

    //notificationTemplate
    'Email service settings': 'Postavke servisa e-pošte',
    'SMS service settings': 'Postavke servisa SMS poruka',

    //pagenotFound
    'PAGE NOT FOUND ERROR': 'STRANICA NIJE PRONAĐENA',

    //roominfo
    'Active room events': 'Aktivni događaji u sobi',
    'Nothing to show': 'Ništa za prikazati',
    'Room events history': 'Povijest događaja u sobi',
    'Checkout overdue': 'Zakašnjela odjava',
    'Edit reservation': 'Uredi rezervaciju',
    'Reservation note': 'Bilješka o rezervaciji',
    'Save note update': 'Ažuriraj bilješke',
    'Check out': 'Odjava',
    'OUT OF SERVICE': 'Nije u upotrebi',
    'Room is available': 'Soba je dostupna',
    'Reservation status': 'Status rezervacije',
    'Customer/Company was deleted': 'Gost/Tvrtka je izbrisan(a)',
    'Inbound reservations': 'Nadolazeće rezervacije',
    'Past 30 days': 'Posljednjih 30 dana',
    'Make a reservation': 'Napravi rezervaciju',
    'Room settings': 'Postavke sobe',
    'Detailed room events': 'Događaji po sobama - detaljno',
    'ADD/REMOVE ITEM': 'DODAJ/UKLONI ARTIKAL',
    SERVICE: 'ARTIKAL',
    STOCK: 'ZALIHA',
    'Add/remove': 'Dodaj/ukloni',
    'Add or remove': 'Dodaj ili ukloni',
    Legend: 'Atributi',
    'Assign attributes to room ': 'Dodijeli atribute sobi ',
    'Room does not exist': 'Soba ne postoji',
    'Nothing selected': 'Nije odabrano',
    'Edit room data': 'Uredi podatke o sobi',
    'Add room': 'Dodaj sobu',
    'Basic room informations': 'Osnovne informacije o sobi',
    'Room phone/local': 'Broj telefona sobe',
    'Assign room attributes': 'Dodijeli atribute sobi',
    'Select atributes': 'Odaberi atribute',
    'You can assign room attributes later on editing room': 'Možete dodijeliti atribute sobi poslije pri uređivanju',
    'Bed count': 'Broj kreveta',
    'Spare beds': 'Pomoćni ležaji',
    'Hardware controller reference (configure at': 'Referenca na hardware kontroler (konfiguracija na',
    'Room price list': 'Cjenik za sobu',
    References: 'Reference',
    'Search by room name': 'Pretraga po nazivu sobe',
    Generate: 'Generiraj',
    'New attribute': 'Novi atribut',
    "The room has a reference to the reservation. It can't be deleted.":
        'Soba ima reference na rezervacije, ne može biti izbrisana.',
    'Room attributes': 'Atributi sobe',
    'No tags': 'Bez oznaka',
    'Extended room settings': 'Proširene postavke sobe',
    'Dashboard config': 'Konfiguracija nadzorne ploče',
    MINIBAR: 'MINIBAR',

    //sosgroups
    'ADD GROUP': 'DODAJ GRUPU',

    //userprofile
    Role: 'Uloga',
    'CHANGE PASSWORD': 'PROMIJENI LOZINKU',

    //compontents
    'Choose a file': 'Odaberite datoteku',
    Dashboard: 'Nadzorna ploča',
    Household: 'Domaćinstvo',
    'Situation center': 'Stanje alarma',
    Reports: 'Izvješća',
    'System menu': 'Izbornik sustava',
    'Log out': 'Odjava',
    'Loading ...': 'Učitavanje ...',
    'Not selected': 'Nije odabrano',
    total: 'Ukupno',
    ' DATE': ' DATUM',

    //search
    'Search customer or company name, reservation id or room name':
        'Traži ime gosta ili tvrtke, id rezervacije ili naziv sobe',
    'Search...': 'Pretraga...',
    'FIRST NAME': 'IME',
    'LAST NAME': 'PREZIME',
    'COMPANY NAME': 'NAZIV TVRTKE',
    'CHECK IN': 'PRIJAVA',
    'CHECK OUT': 'ODJAVA',
    'Detailed information': 'Detaljne informacije',
    'Reservation details': 'Detalji rezervacije',
    Room: 'Soba',
    'Check in': 'Prijava',
    'Adults/childer': 'Odrasli/djeca',
    'Invoiced status': 'Status plaćanja',
    'Invoice id': 'Id računa',
    'Total value': 'Ukupni iznos',
    Currency: 'Valuta',
    'Type of payment': 'Tip plaćanja',
    Reclamation: 'Reklamacija',
    'Birth date': 'Datum rođenja',
    'Birth place': 'Mjesto rođenja',
    'E-mail': 'E-mail',
    'Guest informations': 'Informacije o gostu',
    'Company informations': 'Informacije o tvrtki',
    'Invoices informations': 'Informacije o računu',
    'Visa info': 'Informacije o vizi',
    RESERVATIONS: 'REZERVACIJE',
    CUSTOMERS: 'GOSTI',
    'VISA INFO': 'INFORMACIJE O VIZI',
    COMPANIES: 'TVRTKE',
    INVOICES: 'RAČUNI',

    //checkoutAndInvoice
    Edit: 'Uredi',
    // Details: 'Detalji',
    'Invoice nr': 'Račun br',
    'Reservation id': 'ID rezervacije',
    'Invoice issued by': 'Račun izdao',
    'Reservation made by': 'Rezervaciju zabilježio',
    Date: 'Datum',
    'ROOM NUMBER': 'BROJ SOBE',
    Adults: 'Odrasli',
    Children: 'Djeca',
    // 'Advance payment': 'Avansna uplata',
    Quantity: 'Količina',
    'Unit price': 'Jed. cijena',
    'Retail price': 'MPC',
    'Tax rate': 'Porezna stopa',
    // 'Unit tax': 'Jed. porez',
    // 'Unit discount': 'Jed. popust',
    Discount: 'Popust',
    // 'Person number': 'Broj osoba',
    // 'Days total': 'Ukupno dana',
    // Subtotal: 'Suma',
    // 'Total discount': 'Ukupni popust',
    Total: 'Ukupno',
    'TAX RECAPITULATION': 'REKAPITULACIJA POREZA',
    'Tax group': 'Porezna grupa',
    'NEW PRO FORMA': 'PREDRAČUN',
    // ' has no exchange rate defined': ' nema definiran tečaj',
    // 'HARDWARE OPTIONS': 'OPCIJE HARDWARE-A',
    'CLEAR CARD CODES': 'OČISTI KODOVE KARTICE',
    "ADD CARD CODE":"DODAJ KOD KARTICE",
    // "NOTE: 'MAKE CLEANING REQUEST' is available 2 seconds after 'CLEAR CARD CODES' is successefuly executed.":
    // "BILJEŠKA: 'POŠALJI ZAHTJEV ZA ČIŠĆENJE' je dostupan 2 sekunde nakon što je 'OČISTI KODOVE KARTICE' uspješno izvršen.",
    // 'CLOSE RESERVATION': 'ZATVORI REZERVACIJU',
    // 'Close reservation and save the invoice?': 'Zatvorite rezervaciju i spremite račun?',
    // 'Are you sure you want to close reservation?': 'Jeste li sigurni da želite zatvoriti rezervaciju?',
    // 'All advance invoices from this reservation will be storned': 'Svi predračuni s ove rezervacije bit će pohranjeni',
    // 'Payment info': 'Informacije o plaćanju',
    // 'This reservation is already closed!': 'Ova je rezervacija već zatvorena!',
    // 'Calculating, please wait ...': 'Izračun u tijeku, pričekajte ...',
    // 'APPLY CURRENT PRICE': 'IMPLEMENTIRAJ TRENUTNU PONUDU',
    // 'ENTER YOUR COMPANY INFO': 'Unesite informacije o tvrtci',
    // 'Invoice date': 'Datum računa',
    'Fiscal printer': 'Fiskalni printer',
    // 'Unpaid Amount': 'Neplaćeni iznos',
    // 'Partial amount': 'Djelomični iznos',
    'Issue receipt': 'Potvrda o izdavanju',
    // 'Save and preview': 'Spremi i pregledaj',
    // 'Select currency': 'Odaberi valutu',
    // 'Save and preview proforma invoice': 'Spremite i pregledajte predračun',
    // 'Advance payment details': 'Podaci o avansnom plaćanju',
    // 'Save invoice': 'Spremi račun',
    'Add your company info': 'Dodajte podatke o tvrtci',
    // 'PDV amount': 'PDV iznos',
    // 'Payment amount': 'Iznos uplate',
    // 'Total value without VAT': 'Ukupna vrijednost bez PDV-a',
    // 'Value with VAT': 'Vrijednost s PDV-om',
    // 'Remaining to pay': 'Preostalo za plaćanje',
    // 'If greater then 0, fixed tax remaining': 'Ako je veći od 0, preostaje fiksni porez',

    //dashboard
    'Stay over': 'Zakašnjela odjava',
    'Late check in': 'Kasna prijava',
    'Checked In': 'Prijavljen',
    'Checked Out': 'Odjavljen',
    'Room mode': 'Sobni način',
    Cleaned: 'Očišćeno',
    'Room available at': 'Soba dostupna od',
    'Incoming guest at': 'Gost dolazi',
    'There is no rooms with activity today that match selected criteria':
        'Danas nema soba s aktivnostima koje odgovaraju odabranim kriterijima',
    RENTED: 'IZDANA',
    FREE: 'SLOBODNA',
    'Setup dashboard or add room description': 'Prilagodi nadzornu ploču ili dodaj opis sobe',
    'SRC settings are not available': 'SRC postavke nisu dostupne',
    'Display inputs': 'Ulazi',
    Icon: 'Ikona',
    'Show on dashboard': 'Prikaži na nadzornoj ploči',
    'Contact Open color': 'Boja za otvoren kontakt',
    'Contact Closed color': 'Boja za zatvoren kontakt',
    'Blink if Open ': 'Treperi kod otvorenog kontakta ',
    'Blink if Closed ': 'Treperi kod zatvorenog kontakta',
    'Make a group reservation': 'Napravi grupnu rezervaciju',
    ROOM: 'SOBA',
    DAY: 'DAN',
    Activity: 'Aktivnosti',
    Search: 'Pretraži',
    'AVAILABLE FROM': 'RASPOLOŽIVO OD',
    GROUP: 'GRUPNA',
    MAKE: 'NAPRAVI',
    'GROUP VIEW': 'GRUPNE REZERVACIJE',
    Beds: 'Kreveti',
    DAYS: 'DANA',
    TO: 'DO',
    'Paid:Partialy': 'Plaćeno djelomično',
    Paid: 'Plaćeno',
    ' Fully': ' potpuno',
    'Reservations search...': 'Pretraga rezervacija ...',
    Autoblock: 'Automatski blokirana',
    'Ealrly bird at': 'Gost najavljen',
    'All check-in': 'Sve prijave',
    'All check-out': 'Sve odjave',

    //reservation
    Days: 'Dana',
    // 'Current accommodation offer:': 'Trenutna ponuda smještaja:',
    // 'Remembered accommodation price': 'Upamćena cijena ponude',
    // 'REMEMBER CURRENT OFFER': 'ZAPAMTI TRENUTNU PONUDU',
    // 'No accommodation price list is defined for this room. Please go to room information and select one.':
    //     'Cijena smještaja za ovu sobu nije definirana. Idite na informacije o sobama i odaberite jedan od cjenika.',
    // "Don't forget to press SAVE on the reservation form after the price offer modification.":
    //     'Ne zaboravite kliknuti SPREMI na obrascu rezervacije nakon izmjene cijena ponude.',
    // 'Offer mismatch detected': 'Razlika u ponudama',
    // 'When you remember an offer, current accommodation prices are REMEMBERED and used for the invoice on checkout. Any changes on pricing policy, will have no effect on offered prices. However, any difference will be shown at the checkout - so you can choose which one should be invoiced.':
    //     'Kada zapamtite ponudu, trenutne cijene smještaja će biti ZAPAMĆENE i koristiti će se za izradu računa prilikom odjave. Bilo kakva promjena cjenika neće imati utjecaja na ponuđene cijene. Međutim, svaka razlika će biti prikazana pri izradi računa - tako da možete odabrati ponudu koji želite fakturirati.',
    'Available rooms': 'Dostupne sobe',
    SELECT: 'ODABERI',
    'SEARCH CUSTOMERS': 'TRAŽI GOSTA',
    'SEARCH COMPANIES': 'TRAŽI TVRTKU',
    'APPLY CURRENT TIME & DATE': 'PRIMJENI TRENUTNI DATUM I VRIJEME',
    'Validation error. Check required inputs.': 'Pogreška provjere valjanosti. Provjerite obavezna polja.',
    error: 'greška',
    // 'Autocorrected CHECK IN conflict': 'Automatski ispravljen konflikt POTVRDE rezervacije',
    // 'Autocorrected CHECK OUT conflict': 'Automatski ispravljen konflikt ODJAVE',
    // 'Reservation editing is disabled when reservation is in CLOSED state!':
    //     'Uređivanje rezervacije je onemogućeno kada je rezervacija u ZATVORENOM stanju!',
    DETAILS: 'DETALJI',
    'POSSIBLE CONFLICTS': 'MOGUĆI KONFLIKTI',
    'Room reservation': 'Rezervacija sobe',
    'Send check in e-mail again': 'Ponovno pošalji e-mail s prijavom.',
    'Enable self check-in': 'Omogući samoprijavu',
    'New Guest': 'Dodaj gosta',
    'Group reservation holder': 'Nositelj grupne rezervacije',
    'Early bird': 'Najava',
    Confirmed: 'Potvrđena',
    'Checked in': 'Gost prijavljen',
    Canceled: 'Otkazana',
    'Review by rooms': 'Provjera rezervacija po sobama',
    Status: 'Status',
    'Auto blocked': 'Automatski blokirana',
    'SHOW DETAILS': 'PRIKAŽI DETALJE',
    'Auto block early bird after': 'Automatski blokiraj najavu nakon',
    'ADD RESERVATION HOLDER': 'DODAJ NOSIOCA REZERVACIJE',
    'Reservation notes': 'Bilješke o rezervaciji',
    COUNT: 'KOLIČINA',
    PRICE: 'CIJENA',
    TOTAL: '',
    'Daily total': 'Ukupni dnevni utrošak',
    'Minibar consumption': 'Konzumirani artikli',
    'No recorded minibar consumption': 'Nema zabilježene konzumacije',
    'This reservation belongs to group, issue advance invoice and remove from group.':
        'Ova rezervacija pripada grupi, izdajte račun unaprijed i uklonite je iz grupe.',
    'Select invoice type': 'Odaberi vrstu računa',
    AVANS: 'AVANS',
    //'Reservations from this group': 'Rezervacije iz ove grupe',
    'unknown?': 'nepoznato?',
    'Access control history': 'Povijest kontrole pristupa',
    'Reservation Details': 'Detalji rezervacije',
    'Customer Name': 'Ime gosta',
    'Company Name': 'Naziv tvrtke',
    ACCEPT: 'PRIHVATI',
    DISMISS: 'ODBACI',
    'Beds/Spare beds': 'Kreveti/rezervni kreveti',
    'Adults/Children': 'Odrasli/djeca',
    'VISA AND DOCUMENT DATA FOR  ': 'VIZA I DOKUMENT PODACI ZA  ',
    'List is empty': 'Lista je prazna',
    'VISA AND DOCUMENT DATA': 'PODACI O VIZI I DOKUMENTU',
    'Guests informations': 'Informacije o gostima',
    'Go to room view': 'Idi na pregled sobe',
    'New reservation': 'Nova rezervacija',
    Warning: 'Upozorenje',
    'Leave anyway': 'Svejedno napusti',
    'Send self check in code after': 'Pošalji notifikaciju za samoprijavu nakon',
    'Self check in valid from': 'Samoprijava vrijedi od',
    'Self check in valid to': 'Samoprijava vrijedi do',
    'Confirm cancel reservation': 'Potvrdi zatvaranje rezervacije',
    'Are you sure you want change status to CHECK IN': 'Jeste li sigurni da želite promijeniti status u PRIJAVLJEN',
    'Do you want to move this reservation to CONFIRMED state? ':
        'Želite li premjestiti ovu rezervaciju u POTVRĐENO stanje? ',
    'NOTE: Current time will be applyed as check in time':
        'Bilješka: trenutno vrijeme će se prikazati kao vrijeme prijave',
    'Confirm checkin': 'Potvrdi prijavu',
    'Are you sure you want to cancel this reservation?': 'Jeste li sigurni da želite otkazati ovu rezervaciju?',
    // 'This reservation belongs to group, checkout and stay in group or checkout and invoice and remove from group.':
    // 'Ova rezervacija pripada grupi, naplata i boravak u grupi ili naplata i račun i uklanjanje iz grupe.',
    CHECKOUT: 'ODJAVA',
    'ISSUE INVOICE': 'IZDAVANJE RAČUNA',
    // 'ASSIGN PRICE LIST': 'Dodijeli cjenik',
    'CHANGE ROOM': 'PROMIJENI SOBU',
    // 'This reservation belongs to group, continue and remove from group.':
    // 'Ova rezervacija pripada grupi, nastavite i uklonite iz grupe.',
    'PRO FORMA INVOICE': 'PREDRAČUN',
    INVOICE: 'FAKTURA',
    Closed: 'Zatvorena',

    //generalSettings
    'YOUR COMPANY': 'VAŠA TVRTKA',
    'LICENCE HOLDER': 'VLASNIK LICENCE',
    'WARNING: Information from this form will be used to generate licence. After licence is generated, system wide company name could not be changed until new licence is reissued.':
        'UPOZORENJE: Informacije iz ove forme će se koristiti za generiranje licence. Nakon što se licenca generira, naziv tvrtke na cijelom sustavu se neće moći promijeniti dok se licenca ponovno ne izda.',
    'Fiscal POS': 'Fiskalni pisači',
    'Enable POS update on pricelist Save': 'Omogući ažuriranje fiskalne memorije pri spremanju cjenika',
    Enable: 'Omogući',
    Disable: 'Onemogući',
    'Apply daylight saving time': 'Potvrdi izmjenu računanja vremena',
    'Daylight saving time': 'Zimsko/ljetno računanje vremena',
    'The command can be executed only 2 times per year!!!': 'Naredba se može izvršiti samo dva puta godišnje!!!',
    'Receipt footer': 'Podnožje računa',
    'Apply footer (Max 4 lines)': 'Primjeni podnožje (Max 4 linije)',
    'Gateway integration': 'Integracija Gateway-a',
    'Find more about IOT Gateway': 'Saznaj više o IOT Gateway-u',
    here: 'ovdje',
    'Enable GW 1': 'Omogući GW 1',
    'GW 1 connection': 'GW 1 veza',
    CLOUD: 'OBLAK',
    'GW1 address (http://address)': 'GW1 adresa (http://adresa)',
    'GW1 location name': 'GW1 naziv lokacije',
    'Enable GW 2': 'Omogući GW 2',
    connection: 'veza',
    'address (http://address)': 'adresa (http://adresa)',
    'location name': 'naziv lokacije',
    'LAN address (http://address)': 'LAN adresa (http://adresa)',
    LOCALISATION: 'LOKALIZACIJA',
    Locale: 'Jezik',
    'Other settings': 'Ostale postavke',
    'Check in time': 'Vrijeme prijave',
    'Check out time': 'Vrijeme odjave',
    'Enable household warent generation': 'Omogući generiranje naloga za domaćinstvo',
    'Autogenerate household warrants': 'Autogeneriranje naloga za domaćinstvo',
    'Daily cleaning start hour': 'Svakodnevno početno vrijeme čišćenja',
    'Daily cleaning end hour': 'Svakodnevno završno vrijeme čišćenja',
    'Auto invalidate CONFIRMED reservation after planned CHECK IN (in hours, 0 = disabled)':
        'Automatski poništi POTVRĐENU rezervaciju nakon planirane PRIJAVE (u satima, 0 = onemogućeno)',
    'Move invalidate reservation to state': 'Premjestite nevaljanu rezervaciju u stanje',
    'AUTO BLOCK': 'AUTOMATSKO BLOKIRANJE',
    'Auto BLOCK EARLY BIRD reservations before planned CHECK IN (in hours)':
        'Automatski BLOKIRAJ NAJAVLJENE rezervacije prije planirane PRIJAVE (u satima)',
    'Document header (HTML allowed)': 'Zaglavlje dokumenta (HTML dozvoljen)',
    'Document footer (HTML allowed)': 'Podnožje dokumenta (HTML dozvoljen)',
    'Invoice notes (HTML allowed)': 'Bilješke računa (HTML dozvoljen)',
    'Relaying server (Remote control)': 'Prenosni poslužitelj (daljinsko upravljanje):',
    'QR code validation time after first consumption': 'Vrijeme trajanja QR-koda za samoprijavu nakon prvog korištenja',
    'SMTP configuration': 'SMTP konfiguracija',
    Disabled: 'Onemogućeno',
    'SMTP host': 'SMTP domaćin',
    Secure: 'Siguran',
    User: 'Korisnik',
    Pass: 'Lozinka',
    'Locale and currency': 'Jezik i valuta',
    'Company info': 'Informacije o tvrtki',
    'FISCAL CERTIFICATE': 'FISKALNI CERTIFIKAT',
    Region: 'Regija',
    'Certificate Hash': 'Certifikacijski hash',
    Key: 'Ključ',
    'Issuer Name': 'Ime izdavača',
    'Serial Number': 'Serijski broj',
    'Fiscal certificate': 'Fiskalni certifikat',

    //licence
    'No licence key': 'Ne postoji ključ licence',
    'Licence form': 'Obrazac licence',
    'Licence path is not valid!': 'Putanja licence nije važeća!',
    'UPDATE LICENCE': 'AŽURIRAJ LICENCU',
    'Remote licence is active !': 'Udaljena licenca je aktivna !',
    'Licence key': 'Ključ licence',
    'Paste licence key here ...': 'Ovdje zalijepi ključ licence ...',
    'Encoded licence': 'Kodirana licenca',
    'Paste licence here ...': 'Ovdje zalijepi licencu ...',
    'SET LOCAL LICENCE': 'POSTAVI LOKALNU LICENCU',
    'SET REMOTE LICENCE PATH': 'POSTAVI PUTANJU UDALJENE LICENCE',
    'REQUEST LICENCE': 'ZATRAŽI LICENCU',
    'Licence request data': 'Podaci o zahtjevu za licencu',
    'COPY TO CLIPBOARD': 'Kopiraj u međuspremnik',
    'Company name which will be used system wide (invoices, displays etc.):':
        'Ime tvrtke koje će se koristiti na cijelom sustavu (računi, prikaz, itd.):',
    'Please enter email, where the licence should be sent:': 'Unesite e-mail na koji će biti poslana licenca:',
    'Administrative email': 'Administrativni email',
    'Number of licences (users)': 'Broj licenci (korisnika',
    'Number of rooms': 'Broj soba',
    'Include hardware licences': 'Uključi hardverske licence',
    'DO NOT INCLUDE': 'NEMOJ UKLJUČITI',
    'INCLUDE HW SUPPORT': 'UKLJUČI HW POTPORU',
    'Devices count (SRC/SOS/GATES)': 'Broj uređaja (SRC/SOS/GATES)',
    'Activation price per device ': 'Cijena aktivacije po uređaju ',
    'NEXT STEP': 'SLJEDEĆI KORAK',
    'SEND REQUEST': 'POŠALJI ZAHTJEV',
    monthly: 'mjesečno',
    'Company name': 'Ime tvrtke',
    'COST ESTIMATE': 'PROCJENA TROŠKOVA',
    ' per month': ' mjesečno',
    ' activation fee': ' naknada za aktivaciju',

    //fiscalPrinters
    'Daily fiscal closure': 'Dnevno fisklano zatvaranje',
    'Z REPORT': 'ZAKLJUČI BLAGAJNU',
    'X REPORT': 'PRESJEK STANJA',
    'X report is daily fiscal closure without registry clear':
        'X izvješće je svakodnevno izvješće bez brisanja registra',
    'Z report is daily fiscal closure with registry clear':
        'Z izvješće je svakodnevno fiskalno zatvaranje blagajne s brisanjem registra',
    'Delete all articles from Fiscal Printer': 'Izbriši sve artikle iz fiskalnog printera',
    'Create duplicate of fiscal printer document': 'Izradi duplikat fiskalnog dokumenta',
    'Select date period or document number': 'Odaberi vremenski interval ili broj fiskalnog dokumenta',
    'Query type': 'Tip upita',
    'Document type': 'Tip dokumenta',
    'All documents': 'Svi dokumenti',
    'Fiscal receipts': 'Fiskalni računi',
    'Reclamation receipts': 'Reklamirani računi',
    'Z report': 'Zaključi blagajnu',
    'X report': 'Presjek stanja',
    'Periodic report': 'Periodični izvještaj',
    'Issue duplicate': 'Izdaj duplikat',
    'Fiscal memory report by date': 'Izvještaj fiskalne memorije po datumu',
    'Start date – defines the starting date for the report': 'Datum početka – definira datum početka izvještaja',
    'End date – defines the ending date for the report': 'Datum završetka – definira datum završetka izvještaja',
    'Select Date': 'Odaberi datum',
    'Issue report': 'Izdaj izvještaj',
    'Issue fiscal closure': 'Zatvori blagajnu',
    'Issue report by date': 'Izdaj izvještaj po datumu',
    'Issue document duplicate': 'Izdaj duplikat dokumenta',
    'Input cash to fiscal printer': 'Dopuni fiskalnu blagajnu',
    'Output cash from fiscal printer': 'Oduzmi od blagajne',
    'Select fiscal printer...': 'Odaberi fiskalni pisač...',
    'Fiscal request timed out...': 'Vrijeme zahtjeva isteklo...',
    'Input and output of cash': 'Ulaz i izlaz gotovine',
    Type: 'Tip',
    'Apply to fiscal printer': 'Pošalji zahtjev na fiskalni pisač',
    'Input':'Uplata',
    'Output':'Isplata',
    'Issue daily report':'Ispiši dnevni izvještaj',
    "Fiscal tools":"Fiskalni alati",



    //SRC
    'NO ACTIVE SRC REFERENCE': 'NEMA AKTIVNE SRC REFERENCE',
    'Select mode': 'Odaberi način',
    'MODE:': 'NAČIN:',
    HEATING: 'GRIJANJE',
    COOLING: 'HLAĐENJE',
    'Automatic changeover': 'Automatsko prebacivanje',
    DISABLED: 'ONEMOGUĆENO',
    'Expires:': 'Ističe:',
    'Thermostat ': 'Termostat ',
    'Edit SRC proxy': 'Uredi SRC proxy',
    'Add SRC proxy': 'Dodaj SRC proxy',
    'API key': 'API ključ',
    'Connected to fiscal': 'Povezan na fiskal',
    'BIND SRC 2 ROOMS': 'POVEŽI SRC I SOBE',
    'BROADCAST SETTINGS': 'BROADCAST POSTAVKE',
    'PLEASE WAIT': 'MOLIM PRIČEKAJTE',
    'Migrate devices and rooms from ': 'Premjesti uređaje i sobe iz ',
    ' to': ' u',
    'SELECT ONE': 'ODABERI OPCIJU',
    'Are you sure': 'Jeste li sigurni',
    'SELECT UNATACHED ROOM': 'ODABERI UNATACHED SOBU',
    'Select all': 'Odaberi sve',
    'ATTACH ROOM': 'DODAJ SOBU',
    'Select room': 'Odaberi sobu',
    Attach: 'Dodaj',
    'ATTACH HOTEL ROOM': 'DODAJ HOTELSKU SOBU',
    'MIGRATE PROXY': 'PREMJESTI PROXY',
    'HOTEL ROOMS': 'HOTELSKE SOBE',
    UNBIND: 'PREKINI VEZU',
    Detach: 'Ukloni',
    'UNBINDED SRC CONTROLLERS': 'NEPOVEZANI SRC KONTROLERI',
    BIND: 'POVEŽI',
    'ACCEPT CURRENT RESERVATION EXP.': 'PRIHVATI TRENUTNU REZERVACIJU EXP.',
    'Access code:': 'Pristupni kod:',
    'ACCESS CODES': 'PRISTUPNI KODOVI',
    'SRC DEVICE COMMUNICATION ERROR!': 'GREŠKA KOMUNIKACIJE SRC UREĐAJA!',
    'ROOM SETTINGS': 'POSTAVKE SOBE',
    'Room mode adjustment': 'Podešavanje sobnog načina',
    'AUTO ADJUSTMENT': 'AUTOMATSKO',
    MANUAL: 'RUČNO',
    'Active room mode': 'Način aktivne sobe',
    'Enable IO state emitting (RMCU)': 'Omogući IO emitiranje stanja (RMCU)',
    DISABLE: 'ONEMOGUĆI',
    ENABLE: 'OMOGUĆI',
    'Welcome light duration in seconds (15-60 seconds)': 'Trajanje lampice dobrodošlice u sekundama (15-60 sekundi)',
    'General electric ON, after card is pulled off from dispenser (10-60 seconds). It works only if forced check RFID is DISABLED':
        'Općenito električno UKLJUČENO, nakon izvlačenja kartice iz dozatora (10-60 sekundi). Radi samo ako je RFID prisilne provjere ISKLJUČEN',
    'Force checking disposed RFID card': 'Prisilna provjera raspoložive RFID kartice',
    'Debounce entrance for MAID when guest is in the room': 'Odbiti ulaz za spremačicu kada je gost u sobi',
    'DISABLE DEBOUNCING': 'ONEMOGUĆI ODBIJANJE',
    'ONE EXTRA PASS': 'JEDAN DODATNI PROLAZ',
    'TWO EXTRA PASSES': 'DVA DODATNA PROLAZA',
    'THREE EXTRA PASSES': 'TRI DODATNA PROLAZA',
    'Enable Mandeks 3D light': 'Omogući Mandeks 3D svjetlo',
    'Door relay holding time (2-15 seconds)':'Vrijeme držanja releja vrata (2-15 sekundi)',
    'Temperature output offset (0-4 °C)':'Temperaturni pomak termostata (0-4 °C)',
    'BATHROOM HEATING': 'GRIJANJE KUPAONICE',
    'Bathroom wire sensor': 'Žičani senzor kupaonice',
    'Target temperature (°C)': 'Ciljna temperatura (°C)',
    'THERMOSTAT OPTIONS': 'OPCIJE TERMOSTATA',
    'Thermostat Dead Zone': 'Mrtva zona termostata',
    'Thermostat setpoint differential (for additional heaters, or two step modes)':
        'Diferencijal zadane vrijednosti termostata (za dodatne grijače ili dvostupanjska načina rada)',
    'Minimum valve output ON time (minutes)': 'Minimalno vrijeme uključivanja ventila (minute)',
    'Minimum valve output OFF time (minutes)': 'Minimalno vrijeme isključenja ventila (minute)',
    'Minimum FAN ON time (minutes)': 'Minimalno vrijeme UKLJUČIVANJA VENTILATORA (minute)',
    'Fan start booster': 'Pojačivač pokretanja ventilatora',
    'Proportional band switching point - HIGH fan (80-100)':
        'Proporcionalna točka prebacivanja opsega - VISOKI ventilator (80-100)',
    'Proportional band switching point - MED fan (30-75)':
        'Proporcionalna točka prebacivanja opsega - SREDNJI ventilator (30-75)',
    'Proportional band switching point - LAW fan (1-15)':
        'Proporcionalna točka prebacivanja opsega - NISKI ventilator (1-15)',
    'Fan kick interval (0-90 minutes, 0 = always fan1 running in dead zone, 90-fan kick disabled)': '',
    'Fan start delay (0-180 seconds, 0 is off)': 'Odgoda pokretanja ventilatora (0-180 sekundi, 0 je isključeno)',
    'EXTERNAL THERMOSTAT': 'VANJSKI TERMOSTAT',
    'GENERAL SETTINGS': 'OPĆE POSTAVKE',
    'SRC settings not available for this device. Probably SOS Calling Device.':
        'SRC postavke nisu dostupne za ovaj uređaj. Vjerojatno SOS pozivni uređaj.',
    'READING EXTENDED SRC SETTINGS': 'ČITANJE PROŠIRENIH SRC POSTAVKI',
    '(please wait)': '(molimo pričekajte)',
    'MAIN THERMOSTAT': 'OSNOVNI TERMOSTAT',
    'EMULATION DISABLED': 'EMULACIJA ISKLJUČENA',
    CLOSED: 'ZATVORENO',
    OPEN: 'OTVORENO',
    inputs: 'ulazi',
    outputs: 'izlazi',
    'SRC IO CONFIGURATION': 'SRC IO KONFIGURACIJA',
    'Room clock': 'Sobni sat',
    'Wire sensor 1': 'Žični senzor 1',
    'Wire sensor 2': 'Žični senzor 2',
    'NOT CONNECTED': 'NIJE POVEZANO',
    READY: 'SPREMAN',
    'NOT AVAILABLE': 'NIJE DOSTUPNO',
    'CORRUPTED SETTINGS - PLEASE FIX': 'KORUPIRANE POSTAVKE - MOLIMO POPRAVITE',
    FUNCTION: 'FUNKCIJA',
    'SWITCHING DIFFERENTIAL - HEATING': 'UKLJUČIVANJE DIFERENCIJALA - GRIJANJE',
    'SWITCHING DIFFERENTIAL - COOLING': 'UKLJUČIVANJE DIFERENCIJALA - HLAĐENJE',
    'MODBUS ADDRESS': 'MODBUS ADRESA',
    'IR MODBUS ADDRESS': 'IR MODBUS ADRESA',
    'ECO MODE': 'ECO NAČIN',
    'PRECOMFORT MODE': 'PRECOMFORT MODE',
    'COMFORT MODE': '',
    'DEFAULT TEMP.': 'ZADANA TEMP.',
    'FLOOR TEMPERATURE LIMIT (20-30 Celsius)': 'GRANICA TEMPERATURE PODA (20-30 Celzijevih stupnjeva)',
    'FIX TO DEFAULT': 'VRATI NA ZADANO',
    '(click on header to expand)': '(kliknite na zaglavlje za proširenje)',
    'Please, select a valid template.': 'Molimo odaberite važeći predložak.',
    'EDIT SRC TEMPLATE': 'UREDI SRC PREDLOŽAK',
    'ADD SRC TEMPLATE': 'DODAJ SRC PREDLOŽAK',
    'Create from config': 'Kreiraj iz programa config',
    'Select room with configured SRC as a template': 'Kao predložak odaberite sobu s konfiguriranim SRC-om',
    'EDIT BASIC SRC TEMPLATE INFO': 'UREDI OSNOVNE SRC TEMPLATE INFORMACIJE',
    'PUSH TEMPLATE': 'PUSH TEMPLATE',
    'Upload failed after ': 'Učitavanje nije uspjelo nakon ',
    ' repeats! Please try again or consult the issue with the supplier!':
        ' ponavljanja! Pokušajte ponovo ili se konzultirajte s dobavljačem!',
    'MULTICASTING SRC CONFIGURATION': 'VIŠESMJERNO SRC KONFIGURACIJA',
    "please be patient and don't do anything until done":
        'molim vas, budite strpljivi i ne činite ništa dok se ne zavši',
    'SRC configuration multicasting may take a while. It depends on number of selected rooms and underlaying modems. Data collisions may occure, so please use late at night or on any other low usage period. In worst case, operation should be repeated.':
        'Multicasting za konfiguraciju SRC-a može potrajati neko vrijeme. Ovisi o broju odabranih soba i podložnih modema. Do sudara podataka može doći, zato ga koristite kasno u noć ili u bilo kojem drugom razdoblju s malom uporabom. U najgorem slučaju operaciju treba ponoviti.',
    'All tags': 'Sve oznake',
    'PUSH CONFIGURATION': 'PUSH CONFIGURATION',
    'Please confirm software update from binary': 'Molimo vas, potvrdite ažuriranje softvera iz binarnog softvera',
    'Software will auto restart after updating is finished.':
        'Softver će se automatski ponovo pokrenuti nakon završetka ažuriranja.',
    'Paste link to software package here:': 'Ovdje zalijepite link na softverski paket:',
    'Repository updates': 'Repozitorij ažuriranja',
    'Binary download': 'Binarno preuzimanje',
    'Please confirm software update to': 'Potvrdite ažuriranje softvera na',
    'Please confirm software update': 'Molimo potvrdite ažuriranje softvera',
    'AVAILABLE UPDATES': 'DOSTUPNA AŽURIRANJA',
    'REMEMBER ADDRESS': 'ZAPAMTI ADRESU',
    'FETCH UPDATES LIST': 'DOHVATI AŽURIRANU LISTU',

    //otherTranslates
    LOGIN: 'PRIJAVA',
    'Log in': 'Prijavi se',
    ' uploading file': ' prijenos datoteke',
    'Incomming reservations': 'Nadolazeće rezervacije',
    'Checked in reservations': 'Prijavljene rezervacije',
    'Canceled reservations': 'Otkazane rezervacije',
    Note: 'Bilješka',
    'SELECT ROLE': 'ODABERI ULOGU',
    'RESTORE DEFAULTS': 'VRATI ZADANO',
    'User Interface': 'Korisničko sučelje',
    'Force reload': 'Ponovno učitaj',
    // 'Issue fiscal receipt': 'Izdaj fiskalni račun',
    Cancelled: 'Otkazano',
    Storno: 'Storno',
    POS: 'POS',
    // 'RESERVATION DETAILS': 'Detalji rezervacije',
    STORNO: 'STORNO',
    // 'No invoices in selected date range.': 'U odabranom vremenu nema računa.',
    // VAL: 'VAL',
    VAT: 'VAT',
    // 'Reservation id; Invoice id; Customer name; Company name; CheckIn; CheckOut; Value; VAT':
    // 'Rezervacija; Faktura; Ime gosta; Ime firme; Prijava; Odjava; Vrijednost; VAT',
    'Select fiscal printer': 'Odaberi fisklani printer',
    'Reclamation reason': 'Razlog reklamacije',
    'Issue Reclamation': 'Reklamacija',
    Register: 'Registracija',
    REGISTRATION: 'REGISTRACIJA',
    'NEW HOTEL': 'NOVI HOTEL',
    'Previous reservation checkout Date': 'Datum odjave prethodne rezervacije',
    'Previous reservation status': 'Status prethodne rezervacije',
    'Incoming reservations: No': 'Nadolazeće rezervacije: Nema',
    'Early bird reservations: No': 'Najavljene rezervacije: Nema',
    'Tags: Filtering by tag remove filters by previously selected tag':
        'Oznake: Filtriranje prema oznaci uklanja filtre prema prethodno odabranoj oznaci',
    'Attributes: if checked, return rooms that have any of selected attributes, else only rooms that have all selected attributes':
        'Atributi: ako je označeno, vratite sobe koje imaju bilo koji od odabranih atributa, inače samo sobe koje imaju sve odabrane atribute',
    CONSUMATION: 'KONZUMACIJA',
    KEYWORDS: 'KLJUČNE RIJEČI',
    'General Tax': 'Opći porez',
    Actions: 'Akcije',
    'Add items to minibar': 'Dodaj artikle u minibar',
    'Minibar disabled': 'Minibar onemogućen',
    'No privileges': 'Bez privilegija',
    'System administrator': 'Tehnički upravitelj',
    'HW administrator': 'Upravitelj uređaja',
    'Full manager': 'Generalni upravitelj',
    'Business manager': 'Upravitelj poslovanja',
    'Household manager': 'Upravitelj domaćinstva',
    'Security manager': 'Upravitelj osiguranja',
    'Maid worker': 'Spremačica',
    'GDPR officer': 'GDPR ovlaštenik',
    'Janitor worker': 'Domar',
    'Security worker': 'Zaštitar',
    'Report access only': 'Samo pristup izvješću',
    'NO HARDWARE': 'BEZ HARDVERA',
    'ADD SRC': 'DODAJ SRC',
    CONFIGURATION: 'KONFIGURACIJA',
    'Hotel rooms and HW': 'Hotelske sobe i hardver',
    'Settings and updates': 'Postavke i ažuriranja',
    UPDATE: 'AŽURIRAJ',

    //router
    'Dashboard settings': 'Postavke nadzorne ploče',
    'Accommodation price list stack': 'Cjenik smještaja po prioritetu',
    'Assign pricelist to room': 'Dodijeli cjenik sobi',
    'Exchange rates': 'Tečajevi',
    'POS articles': 'POS artikli',
    Minibar: 'Minibar',
    'Household management': 'Upravljanje domaćinstvom',
    'Your profile': 'Vaš profil',
    'Building Management System': 'Building Management System',
    'Room informations': 'Informacije o sob',
    'Group reservation': 'Grupne rezervacije',
    'Reservation administration': 'Administracija rezervacije',
    Checkout: 'Odjava',
    'Pro forma invoice': 'Predračun',
    'SRC settings broadcast & multicast': 'SRC postavke broadcast & multicast',
    'SRC Proxy configuration': 'SRC Proxy konfiguracija',
    'Notification Service Settings': 'Postavke usluge obavijesti',
    'Notification Templates': 'Predlošci obavijesti',
    'QR Generator': 'QR Generator',
    'Rooms management': 'Upravljanje sobama',
    'API keys management': 'API ključevi za upravljanje',
    'User management': 'Upravljanje korisnicima',
    'General settings': 'Opće postavke',
    'Software updates': 'Ažuriranje softvera',
    'Licence informations': 'Informacije o licenci',
    'Access Control': 'Kontrola pristupa',
    'White list access': 'Pristup listama dozvoljenih',
    'Invoices report': 'Izvješće računa',
    'Reservations report': 'Izvješća o rezervacijama',
    'Access contol':"Kontrola pristupa prolazima",
    'System audit': 'Kontrola softverskih akcija',
    'CUSTOMERS DATA REPORT': 'IZVJEŠĆA O GOSTIMA',
    'SOS response log': 'SOS dnevnik odgovora',
    'Door access report': 'Izvješće pristupa vratima',
    TEST: 'TEST',
    'Reports menu': 'Izbornik izvješća',
    'MINIBAR LOG BOOK': 'Minibar dnevnik',
    'Group Reservations': 'Grupne rezervacije',
    'Roles management': 'Upravljanje ulogama',

    'ADVANCE INVOICE': 'AVANSNI RAČUN',
    'PROFORMA INVOICE': 'PREDRAČUN',
    'STORNED INVOICE': 'STORNIRANI RAČUN',
    'STORNO INVOICE': 'STORNO FAKTURA',
    'STORNO ADVANCE INVOICE': 'STORNO AVANSNI RAČUN',
    'STORNED ADVANCE INVOICE': 'STORNIRANI AVANSNI RAČUN',
    'STORNED PROFORMA INVOICE': 'STORNIRANI PREDRAČUN',
    'STORNO PROFORMA INVOICE': 'STORNO PREDRAČUN',

    // 'ISSUE PROFORMA INVOICE': 'IZDAVANJE PREDRAČUNA',
    // 'ISSUE ADVANCE INVOICE': 'IZDAVANJE AVANSNOG RAČUNA',
    // 'INVOICE AND CLOSE RESERVATION': 'IZDAJ RAČUN I ZATVORI REZERVACIJU',

    'SOS assistance required': 'SOS pažnja',
    'Fire alarm': 'VATRA U SOBI',
    'Smoke detected': 'Dim u sobi',
    'Flood detected': 'Poplava u sobi',
    'Motion detected': 'Pokret detektiran',
    'Intrudor detected': 'Neovlašten boravak u sobi',
    'Minibar opened': 'Minibar otvoren',
    'Room safe opened': 'Sobni sef otvoren',
    'Do not disturb': 'Ne ometaj',
    'Cleaning service required': 'Potrebna usluga čišćenja',
    'Room service required': 'Potrebna usluga u sobi',
    'Assistance pending': 'Pružanje pomoći u tijeku',
    'ECO mode': 'ECO način',
    'COMFORT mode': 'COMFORT način',
    'PRE COMFORT mode': 'PRE COMFORT način',
    'CLEANING mode': 'ČIŠĆENJE U TIJEKU',
    "Minibar is open more than 5 minutes!":"Minibar je otvoren više od 5 minuta!",

    // 'Proforma invoice preview': 'Pretpregled predračuna',
    // 'Advance invoice preview': 'Pretpregled avansnog računa',
    // 'Invoice preview': 'Pretpregled računa',

    general_output_name: 'Glavni izlaz',
    welcome_light_output_name: 'Svjetlo dobrodošlice',
    door_lock_output_name: 'Brava',
    do_not_disturb_output_name: 'Ne ometaj',
    room_service_output_name: 'Usluge sobe',
    thermostat1_cooling_output_name: 'Termostat 1 hlađenje',
    thermostat1_output_name: 'Termostat 1',
    alarm_output_name: 'Alarm',
    sos_output_name: 'SOS alarm',
    bathroom_thermostat_output_name: 'Termostat u kupaonici',
    balcony_light_output_name: 'Balkonsko svjetlo',
    cleaning_service_output_name: 'Usluge čišćenja',
    sos_input_name: 'SOS alarm',
    window_state_input_name: 'Stanje prozora',
    thermostat1_input_name: 'Termostat 1',
    thermostat1_cooling_input_name: 'Termostat 1 hlađenje',
    door_state_input_name: 'Stanje vrata',
    balcony_door_input_name: 'Stanje balkonskih vrata',
    do_not_disturb_input_name: 'Ne ometaj',
    room_service_input_name: 'Usluge sobe',
    card_holder_input_name: 'Držač kartica',
    fire_alarm_input_name: 'Protupožarn alarm',
    smoke_alarm_input_name: 'Alarm dima',
    motion_alarm_input_name: 'Alarm kretanja',
    flood_alarm_input_name: 'Alarm poplave',
    minibar_state_input_name: 'Stanje mini-bara',
    fan1_input_name: 'Ventilator 1',
    welcome_light_input_name: 'Svjetlo dobrodošlice',
    cleaning_service_input_name: 'Usluge čišćenja',

    gsettings_name: 'Ime i prezime',
    gsettings_hotelName: 'Naziv hotela',
    gsettings_address: 'Adresa',
    gsettings_postOfficeNumber: 'Poštanski broj',
    gsettings_town: 'Grad',
    gsettings_country: 'Država',
    gsettings_contactEmail: 'E-mail',
    gsettings_idNumber: 'Id broj',
    gsettings_notes: 'Bilješke',

    cl_noActionTaken: 'Nema akcije',
    cl_cleaningRequested: 'Zatraženo čišćenje',
    cl_stagedForCleaning: 'Spremno za čišćenje',
    cl_cleaningStarted: 'Čišćenje u tijeku',
    cl_cleaningFinished: 'Završeno',
    cl_cleaningPostponed: 'Odgođeno',
    cl_cleaningSkipped: 'Čišćenje preskočeno',
    cl_cleaningInspectionRequired: 'Zatražena provjera sobe',
    cl_stagedForDeepCleaning: 'Zatraženo dubinsko čišćenje',

    leave_guard_note: 'Imate nespremljene promjene, jeste li sigurni da želite napustiti stranicu?',
    leave_guard_note_visa: 'Imate nepotpune viza podatke o gostu(ima), jeste li sigurni da želite napustiti stranicu?',

    // no_accommodation_pricelist_defined:
    // 'Cijena smještaja za ovu rezervaciju nije definirana. Odaberite jedan od cjenika.',
    // remember_current_prices_note:
    // 'Kada zapamtite ponudu, trenutne cijene smještaja će biti ZAPAMĆENE i koristiti će se za izradu računa prilikom odjave. Bilo kakva promjena cjenika neće imati utjecaja na ponuđene cijene. Međutim, svaka razlika će biti prikazana pri izradi računa - tako da možete odabrati ponudu koji želite fakturirati.',
    // dont_forget_press_Save_note: 'Ne zaboravite kliknuti SPREMI na obrascu rezervacije nakon izmjene cijena ponude.',

    'Card code search results': 'Rezultati pretrage rezervacija prema kodiranim karticama',
    'POS Terminal': 'POS Terminal',
    // Params: 'Params',
    // 'POS Terminal Items': 'POS Terminal artikli',
    'Search by ID': 'Traži po ID-u',
    // 'GDPR Encrypted': 'Enkriptirano zbog GDPR-a',
    'Created at': 'Datum kreiranja',
    'Made by': 'Kreirao',
    'GROUP DETAILS': 'DETALJI GRUPE',
    selected: 'odabrano',
    customerDocumentIdConflictMessage: 'U bazi već postoji gost sa ovim brojem dokumenta, molim da promjenite unos!',
    'LOAD EXISTING DATA': 'UČITAJ POSTOJEĆE PODATKE',
    'POS Terminal Invoice': 'POS Terminal Račun',
    'OPEN TERMINAL': 'OTVORI TERMINAL',
    'ALL ITEMS': 'SVI ARTIKLI',
    'List of consumed items is empty...': 'Lista artikala je prazna...',
    'Search room...': 'Odaberi sobu...',
    // 'Issue invoice and preview': 'Izdaj račun i pregledaj',
    TERMINAL: 'TERMINAL',
    'Items from terminal': 'Terminal artikli',
    'Items added to reservation ': 'Artikli dodani na rezervaciju ',
    'Reservation holder: ': 'Nositelj rezervacije: ',
    'SUCCESSFULLY ADDED': 'USPJEŠNO DODANO',
    'Select room to save on reservation or issue invoice': 'Odaberi sobu za spremanje na rezervaciju ili izdaj račun!',
    // 'SAVE ON RES.': 'SPREMI NA REZ.',
    'Terminal is disabled... Enable it before use!': 'Terminal je onemogućen...morate ga omogućiti prije uporabe!',
    "OIB": "OIB",
    "Business space label": "Oznaka poslovnog prostora",
    "Toll device label": "Oznaka naplatnog uređaja",
    "Sequence marker": "Oznaka slijednosti",
    "VAT payer": "U sustavu PDV",
    "Tax 2": "PNP",
    "Tax 3": "Ostali porezi",
    "ATTACH TO GROUP RES.": "DODAJ GRUPNOJ REZ.",
    "Pin code": "Pin kod",
    "Invoice discount": "Popust na račun",
    "Enable fiscalisation": "Omogući fiskalizaciju računa",
    "FISCAL GENERAL SETTINGS": "POSTAVKE FISKALIZACIJE",
    // "Search price lists by tags...": "Pretraži cjenike po oznakama...",
    // "ADD PRICE LIST": "DODAJ CJENIK",
    // "HIDE PRICE LISTS": "SAKRIJ CJENIKE",
    "READ FROM SCANNER": "PROČITAJ IZ SKENERA",
    "Select document scan": "Odaberi skenirani dokument",
    "No document scans available": "",
    "noDocumentScansNote": "Nema dostupnih skenova dokumenata. Molim stavite dokument na skener i osigurajte da je aplikacija za sekniranje upaljena. ",
    'Enable document scanner for QR Self Check In': "Uključi skener dokumenata u proces samoprijave putem QR koda",
    // "Do you want to generate a new qr code for this access point?": "Želite li generirati novi QR kod za ovu pristupnu točku?",
    // "Do you want to proceed?": "Želite li nastaviti?",
    "Regenerate QR": "Ponovno generiraj QR",
    "Generate QR": "Generiraj QR",
    "After document scanner": "Iza skenera dokumenata",
    "Message after successfull scan": "Poruka nakon uspješnog skeniranja",
    'Slice pricelist by tags': 'Presjeci cjenike s oznakama',
    'Select accommodation pricelist': 'Odaberi cjenik smještaja',
    // 'The advance payment is disabled for the selected amount, issue regular invoice instead!': 'Avansna uplata je onemogućena za odabrani iznos, potrebno je izdati regularni račun!',
    // 'Allowed advance payment': 'Dozvoljena avansna uplata',
    // 'Tax value': 'Iznos PDV-a',
    'Value': "Vrijednost",
    'Value with discount': 'Vrijednost sa popustom',
    'Additional discount': 'Dodatni popust',
    // "EDIT GROUP RES.": "UREDI GRUPNU REZ.",
    "ADD MORE RESERVATIONS": "DODAJ JOŠ REZERVACIJA",
    'Partial payment': 'Parcijalna uplata',
    // 'Issue storno invoice': 'Storniranje računa',
    // 'The storno reason is required field': 'Razlog reklamacije je obavezno polje',
    'Total VAT': 'Iznos poreza',
    'Total delivered value': 'Ukupna isporučena vrijednost',
    "Valid documents for countries who don't need visa": "Validni dokumenti za zemlje koje ne trebaju vizu:",
    "Valid documents for countries requiring visa:": "Validni dokumenti za zemlje koje trebaju vizu:",
    "Select options...": "Odaberi opciju...",
    "Processing...": "Obrada",
    "HOME": "POČETNA",
    "Qty.": "KOL",
    "To pay": "Za platiti",
    "All": "Sve",
    "Search reservation by room or guest card code...": "Traži rezervaciju po nazivu sobe ili broju kartice",
    "ATTACH TO RES.": "PRIKAČI NA REZ.",
    "Discount on items": "Popust na stavke",
    "Operator": "Operater",
    "Attach proforma invoice to room": "Dodaj predračun na sobu",
    "room ": "soba ",
    'Update reservation': 'Ažuriraj rezervaciju',
    "Remove from group": "Ukloni iz grupe",
    'Invoice value': 'Iznos računa',
    'Advance payments': 'Avansne uplate',
    "DELETE RES.": "IZBRIŠI REZ.",
    "Do you really want to remove this reservation from group? Please confirm and reservation will become standalone!": "Jeste li sigurni da želite ukloniti rezervaciju iz grupe? Molimo potvrdite i rezervacija će postati samostalna!",
    "HOUSEHOLD REPORT": "IZVJEŠĆE DOMAĆINSTVA",
    'Fully': 'U potpunosti',
    'Partialy': 'Djelomično',
    "Rooms and pricelists": "Sobe i cjenici",
    "Tag added": "Oznaka dodana",
    "ROOMS": "SOBE",
    "PRICELISTS": "CJENICI",
    "Tag deleted": "Oznaka uklonjena",
    'Previous advance payments':'Prethodne avansne uplate',
    'Apply':'Primjeni',
    'Ignore':"Zanemari",
    'Check out now and move the next reservation.':'Odjavi sada i pomakni sljedeću rezervaciju.',
    'Check out before the start of the next reservation.':'Odjavi na početak sljedeće rezervacije',
    'Check out at initial checkout.':'Odjavi na inicijalni datum odjave',
    'Confirm the current date and move the next reservation.':'Potvrdi trenutni datum i pomakni sljedeću rezervaciju',
    'Set reservations manually.':'Ručno podesite rezervacije.',
    "Do you want to use same pricelist on new room or select new one?":"Želite li zadržati cjenik ili odabrati novi?",
    "Keep pricelist":"Zadrži cjenik",
    "Change pricelist":"Promijeni cjenik",
    "Given price offer":"Važeća cijenovna ponuda",
    "Given price offer change detected":"Detektirana je promjena vežeće cijenovne ponude",
    "person(s)":"osobu(e)",
    "per day":"po danu",
    "Apply new price":"Primjeni novu cijenu",
    "checkoutDateMissmatchNote":"Današnji datum se razlikuje od planiranog datuma odjave u rezervaciji ({X}). Želite li primjeniti današnji datum ({Z}) kao datum odjave rezervacije {Y}?",
    "Reservations from the group":"Rezervacije iz grupe",

    "NOTIFICATIONS":"OBAVIJESTI",
     "Notification subscriptions":"Pretplate na obavijesti",
    "Select the events you want to subscribe to":"Odaberite događaje na koje se želite pretplatiti",

    "Apply to all":"Primjeni na sve",
    "Reload page":"Ponovno učitaj",
    "reloadPageNote1": "Učitavanj podataka traje duže od očekivanja.",
    "reloadPageNote2": "Pričekajte još malo i probajte osvježiti stranicu.",
    "reloadPageNote3": "Ukoliko se problem ne ukloni, kontaktirajte podršku.",

    reservationsPanel:"Panel rezervacija ",
    invoicesPanel:"Panel računa",
    roomEventsPanel:"Panel događaja u sobi",
    accessControlPanel:"Panel kontrole pristupa",
    reservationsPanel_general:'Pregled izvješća o rezervacijama',
    reservationsPanel_reservationsConclusion: 'Zaključci rezervacija',
    reservationsPanel_registeredGuests: "Prijavljeni gosti",
    reservationsPanel_customerReports:'Pregled izvješća o gostima',
    invoicesPanel_general:'Izvješće o računima',
    invoicesPanel_financialReport:"Financijsko izvješće",
    invoicesPanel_cashRegistarsReport:"Blagajničko izvešće",
    invoicesPanel_cashRegistarPaymentsReport:"Blagajničke transkacije",
    roomEventsPanel_posTerminalReport:"Izvješće POS terminala",
    roomEventsPanel_householdReport:"Izvješće domaćinstva",
    goodsManagementReportPanel_goodsManagement: "Izvješće o upravljanju robom",
    goodsManagementReportPanel_entryCalculations: "Izvješće o ulaznim kalkulacijama",
    roomEventsPanel_general:'Pregled izvješća o sobama',
    roomEventsPanel_SOSLog:"SOS log",
    roomEventsPanel_minibarReport:"Minibar izvješće",
    accessControlPanel_systemAudit:'Revizija sustava',
    accessControlPanel_general:'Pregled izvješća za kontrolu pristupa',
    roomsPanel:"Panel soba",
    pricePanel:"Panel cjenika",
    foldersPanel:"Panel foldera",
    advancedSettingsPanel:"Panel naprednih postavki",
    licencePanel:"Panel licenci",
    businessConclusionPanel: "Zaključci poslovanja",
    goodsManagementPanel: 'Upravljanje robom',
    roomsPanel_rooms:"Sobe",
    roomsPanel_sosGroups:"SOS grupe",
    roomsPanel_controllers:"Kontroleri",
    roomsPanel_posTerminal:"POS Terminal",
    posConfigurationPanel_tablesLayout: "Raspored stolova",
    roomsPanel_cashRegistar: "Blagajna",
    businessConclusionPanel_posTerminal:"POS Terminal",
    posConfigurationPanel_posTerminal: "POS Terminal",
    businessConclusionPanel_cashRegistar: "Blagajna",
    goodsManagementPanel_documents: "Dokumenti",
    goodsManagement_entryCalculation: "Ulazna kalkulacija",
    goodsManagementPanel_warehouses: 'Skladišta',
    goodsManagementPanel_stockKeepingUnits: "SKU artikli",
    goodsManagementPanel_skuPluRate: "SKU PLU Rate",
    roomsPanel_SRCTemplates:"SRC predložak",
    roomsPanel_AlarmAndRoomMode: "Postavke alarma",
    pricePanel_taxes:"Porezi",
    pricePanel_accomodation:"Smještaj",
    pricePanel_currencies:"Valute",
    pricePanel_pricelistLookup:"Šifrarnik",
    businessConclusionPanel_reservationConclusion: "Zaključak rezervacija",
    posConfigurationPanel_posGoods:"POS artikli",
    pricePanel_fiscalPrinters:"Fiskalni pisači",
    foldersPanel_customers:"Gosti",
    foldersPanel_companies:"Tvrtke",
    foldersPanel_reservations:"Rezervacije",
    advancedSettingsPanel_settings:"Postavke",
    advancedSettingsPanel_users:"Korisnici",
    advancedSettingsPanel_notificationServiceSettings:"Postavke servisa notifikacija",
    advancedSettingsPanel_visaInfo:"Viza informacije",
    posConfigurationPanel_displayConfigInfo:"Postavke POS prikaza",
    advancedSettingsPanel_channelManager:"Channel manager",
    advancedSettingsPanel_selfCheckInAdministration:"Self check in administracija",        
    licencePanel_licence:"Licence",
    accessControlPanel_doorsAndRules:"Vrata i pravila",
    accessControlPanel_whitelists:"Liste dozvoljenih",
    accessControlPanel_documentScannerDevices:"Skeneri dokumenata",
    "Rooms linking":"Poveži sa sobama",
    "Pricelist linking":"Poveži sa cjenicima",
    "Goods management":"Upravljanje robom",
    "All about goods management process.":"Sve o procesu upravljanja robom.",
    "Goods management reports":"Izvješća o upravljanju robom",
    "All about goods management reports.":"Sve o izvješćima upravljanja robom",
    "Stock headers": "Dokumenti",
    "SKU Items": "Skladišni artikli",
    "Goods management report":"Izvješća o upravljanju robom",
    "Entry calculations report":"Izvješće o ulaznim kalkulacijama",
    "Warehouses": "Skladišta",
    "Entry calculation":"Ulazna kalkulacija",
    "Issue invoice":"Izdaj račun",
    "Issue advance invoice":"Izdaj avansni račun",
    "Issue proforma":"Izdaj predračun",
    "Close reservation":"Zatvori rezervaciju", 
    "REGULAR INVOICE":"FAKTURA",
    "ADVANCE":"AVANS",
    "PROFORMA":"PREDRAČUN",
    "of":"od",
    "Invoice":"Faktura",
    "More than one reservation in conflict, please adjust them manualy!":"Više rezervacija u sukobu, molimo podesite ručno!",
    "CLEAR ROOM CODES":"OČISTI KODOVE SOBE",
    "Fiscal certificate settings are not available!":"Postavke za pristup fiskalizaciji nisu unesene (fiskalni certifikat)!",
    "Toll device sellection list is empty!":"Lista naplatnih uređaja je prazna!",
    "Fiscal printer list is empty!":"Lista fiskalnih printera je prazna!",
    "Business space sellection list is empty!":"Lista poslovnih prostora je prazna",
    "Linked invoices":"Povezani računi",
    "click to expand":"Proširi",
    "Advance invoices":"Avansni računi",
    "Proforma invoices":"Predračuni",
    "POS terminal invoices":"Računi iz terminala",
    "Issue storno invoice":"Storniranje računa",
    "Storno invoices":"Stornirani računi",
    "(invoice)":"(račun)",
    "(advance)":"(avans)",
    "(proforma)":"(predračun)",
    "Genuine invoices":"Regularni računi",
    "Search for a customer or company name":"Traži gosta ili tvrtku",
    "CLOSED GROUP RESERVATIONS":"ZATVORENE GRUPNE REZERVACIJE",
    "No reservations":"Nema rezervacija",
    "Active":"Aktivna",
    "Checked out":"Odjavljen",
    "Unknown":"Nepoznato",
    "Guest arrival":"Dolazak gosta",
    "Due out":"Odjava gosta",
    "Stayover":"Zakašnjela odjava",
    "not paid":"nije plaćeno",
    "fully paid":"plaćeno sve",
    "Entry date":"Datum ulaska",
    "Nationality":"Nacionalnost",
    "Phone":"Telefon",
    "Document Id":"Dokument id",
    "Document Valid Period":"Datum valjanosti dokumenta",
    "Reclamation type":"Tip reklamacije",
    "Action":"Akcija",
    "Regular":"Regularni",
    "Advance":"Avans",
    "Proforma":"Predračun",
    "Event id":"id događaja",
    "Rule name":"Naziv alarma",
    "Start time":"Početak",
    "Day of month":"Dan",
    "Duration minutes":"Trajanje",
    "End time":"Kraj",
    "Time":"Vrijeme",
    "Source":"Izvor",
    "Event duration":"Trajanje događaja",
    "Assistance duration":"Trajanje pomoći",
    "Room entry after start (min)":"Ulazak u sobu nakon početka (min)",
    "Reaction time (min)":"Vrijeme reakcije (min)",
    "MinibarLog id":"Id zapisa",
    "Item name":"Naziv artikla",
    "Previous state":"Prethodno stanje",
    "New state":"Novo stanje",
    "Log type":"Tip zapisa",
    "Reservation":"Rezervacija",
    "Created At":"Zapisa kreiran",
    "Total price":"Ukupna cijena",
    "Cleaning Started":"Čišćenje započeto",
    "Cleaning Finished":"Čišćenje završeno",
    "Duration (m)":"Trajanje (min)",
    "Cleaning Status":"Status čišćenja",
    "Cleaned by":"Očišćeno",
    "Pricelists by tags":"Cjenici po oznakama",
    "*drag and drop tags to rooms or pricelists":"*povuci i ispusti oznaku na sobu ili cjenik",
    "Filter rooms and pricelists by tags":"Filtriraj sobe i cjenike po oznakama",
    "Rooms by tags":"Sobe po oznakama",
    

    "Initial setup required":"Potrebne početne postavke",
    "SAVE AND NEXT":"SPREMI I DALJE",
    "Does your hotel have multiple floors or buildings?":"Ima li Vaš hotel više katova ili objekata?",
    "Number of floors":"Broj katova",
    "Number of rooms ":"Broj soba ",
    "floor":"kat",
    "Accept or change rooms name, bed and spare bed count":"Prihvati predložene ili promijeni nazive soba, broj kreveta i pomoćnih ležaja",
    "Mark common attributes for all rooms":"Označi zajedničke atribute za sve sobe",
    atr_airConditioning:"Klima-uređaj",
    atr_kitchen_kitchenette:"Kuhinja/čajna kuhinja",
    atr_privateBathroom:"Vlastita kupaonica",
    atr_balcony:"Balkon",
    atr_privatePool:"Privatni bazen",
    atr_terrace:"Terasa",
    atr_washingMachine:"Perilica rublja",
    atr_flatScreenTV:"TV ravnog ekrana",
    atr_view:"Pogled",
    atr_coffeeTeaMaker:"Aparat za pripremu kave/čaja",
    atr_kettle:"Kuhalo za vodu",
    atr_bathtub:"Kada",
    atr_hydromassageBath:"Hidromasažna kupka",
    atr_bedForOnePerson:"Krevet za jednu osobu",
    atr_doubleBed:"Bračni krevet",
    atr_minibar: "Minibar",
    "Apply new checkout date":"Primjeni novi datum odjave",
    "Insert accomodation price for persons number":"Upiši cijenu smještaja za broj osoba",
    "Number of persons":"Broj osoba",
    "Price for ":"Cijena za ",
    "Select notification language":"Odaberite jezik notifikacije",
    "EMAIL SERVICE SETTINGS":"POSTAVKE EMAIL SERVISA",
    "SMS SERVICE SETTINGS":"POSTAVKE SMS SERVISA",
    IsEnabled:"Omogućeno",
    ApiPath:"API putanja",
    ApiObject:"API objekt",
    Sender:"Pošiljatelj",
    Title:"Naslov",
    Body:"Sadržaj",
    SELFSERVICECHECKINHARDWARE:"Predlošci poruka za hardverski check in",
    SELFSERVICECHECKINSOFTWARE:"Predlošci poruka za softverski check in",
    "Other languages":"Drugi jezici",
    "Financial report":"Financijsko izvješće",
    "taxGroup": "Porezna grupa",
    "base":"Osnovica",
    "taxValue":"Iznos poreza",
    "totalValueByTax":"Promet po stopi ",
    "totalBaseValueGenuine":"Ukupno osnovica",
    "totalTaxValueGenuine":"Ukupno porez",
    "totalValueGenuine":"Ukupno promet",
    "from":"Od",
    "to": "Do",
    "date":"Datum",
    "totalBaseValueAdvance":"Ukupno osnovica",
    "totalTaxValueAdvance":"Ukupno porez",
    "totalValueAdvance":"Ukupno promet",
    "totalGenuine":"Ukupno (Računi)",
    "totalAdvance":"Ukupno (Avans)",
    "invoiceNumberAdvance": "Broj izdanih avansnih računa",
    "invoiceNumberGenuine": "Broj izdanih računa",
    "Document Scanners":"Dokument skeneri",
    "Method":"Metoda",
    "Path":"Putanja",
    "DOCUMENT SCANNER DEVICES":"UREĐAJI ZA SKENIRANJE DOKUMENATA",
    "NEXT CHECKIN/CHECKOUT":"IDUĆA PRIJAVA/ODJAVA",
    "Everybody":"Sve razine",
    "Rookie":"Početnik",
    "Intermediate":"Srednja razina",
    "Experienced":"Iskusna razina",
    "Household report":"Izvješće domaćinstva",
    "Please, select a price list or apply custom price!":"Molimo odaberite cjenik ili unesite cijenu sobe!",
    "Please, select customer or company!":"Molimo odaberite gosta ili tvrtku!",
    "Please, select a room!":"Molimo odaberite sobu!",
    "Reservation is closed!":"Rezervacija je zatvorena!",
    "Upload a file":"Učitaj dokument",
    "Basic consent":"Osnovni pristanak",
    "Preferences":"Preference",
    "GDPR OPTIONS":"GDPR OPCIJE",
    "API keys access disabled":"Pristup API ključevima nije dozvoljen",
    "Enter room IDs separated with comma. Example: 1,2,12":"Upiši id-ove soba odvojene zarezom. Npr. 1,2,12",
    "Other rooms":"Ostale sobe",
    "Manual backup":"Ručno sigurnosno kopiranje",
    "WARNING: If using manual mode, make sure you have fast connection with server.":"UPOZORENJE: Ako koristite ručni način rada, provjerite da imate brzu vezu s poslužiteljem.",
    "Database restoring in progress. Please wait.":"Obnova baze podataka u tijeku. Molimo pričekajte.",
    "DOWNLOAD DATABASE":"PREUZMITE BAZU PODATAKA",
    "Database dumping in progress...":"U tijeku je izbacivanje baze podataka...",
    "active":"aktivan",
    "inactive":"neaktivan",
    "Rooms with requiring attention":"Sobe koje zahtijevaju pažnju",
    pricePanel_depositRegister:"Registracija gotovinskog depozita",
    "Deposit register":"Registracija gotovinskog depozita",
    "Undo last offer":"Vrati prethodnu ponudu",
    "Previous checkout date":"Prethodni datum odjave",
    "Discount value":"Vrijednost popusta",
    "After invoice discount applied":"Nakon primjenjenog popusta",
    "Current week":"Tekući tjedan",
    "Current month":"Tekući mjesec",
    "Today":"Danas",
    "Welcome to our hotel!":"Dobrodošli u naš hotel!",
    "We wish you a pleasant stay.":"Želimo Vam ugodan boravak.",
    "To enter, please enter the pin code first.":"Za ulazak, molimo prvo upišite pin kod.",
    "You have successfully checked in to the hotel!":"Uspješno ste prošli samoprijavu u hotel!",
    "You can proceed to your room ":"Možete nastaviti do Vaše sobe ",
    "Pleasant stay!":"Ugodan boravak!",
    "FINISH":"ZAVRŠI",
    "Self check in logs":"Logovi samoprijave", 
    "Tomorow":"Sutra",
    "Potential hotel occupancy":"Potencijalna popunjenost hotela",
    "days":"dana",
    "Next week":"Sljedeći tjedan",
    "Next month":"Sljedeći mjesec",
    "Accomplished":"Ostvareno",
    "Incomings":"Dolasci",
    "Departures":"Odlasci",
    "Occupied rooms":"Zauzete sobe",
    "Method successfully passed":"Metoda uspješno završila",
    "Error message":"Poruka greške",
    "SELF CHECK IN LOGS":"LOGOVI SAMOPRIJAVE",
    "Number of days for encripting guests":"Broj dana za enkriptiranje gosta",
    "Include accommodation tax on invoice":"Uračunati boravišnu pristojbu na fakturi",
    "Tax2":"PNP",
    "Daily report":"Dnevno izvješće",
    "Invoice notes (Tax free)":"Bilješke računa (oslobođenje poreza)",
    "Invoice number":"Broj računa",
    "Invoicing time":"Vrijeme izdavanja",
    "Method of payment":"Način plaćanja",
    "Search by door acces point name":"Traži po imenu ulazne točke",
    "NOTIFICATION SETTINGS":"POSTAVKE OBAVIJESTI",
    "Set email and sms service settings":"Postavi email i sms postavke usluge",
    "This is ":"Ovo je ",
    "primary step":"početni korak",
    " for any of self check in types":" za bilo koji tip samoprijave",
    "CARD SELF CHECK IN":"KARTIČNA SAMOPRIJAVA",
    "Set":"Postavi",
    "hardware notification template":"hardverski predložak obavijesti",
    "for card self check in":"za kartičnu samoprijavu",
    "DOCUMENT SCANNER SELF CHECK IN":"SAMOPRIJAVA SKENIRANJEM DOKUMENTA",
    "Initialize":"Inicijaliziraj",
    "document scanner device":"dokument skener uređaj",
    "for self check in":"za samoprijavu",
    "Connect document scanner device to the some":"Poveži dokument skener uređaj s određenom",
    "access point":"ulaznom točkom",
    "QR CODE SELF CHECK IN":"QR KOD SAMOPRIJAVA",
    "software notification template":"softverski predložak obavijesti",
    "for qr self check in":"za qr samoprijavu",
    "No assignments":"Nema zadataka",
    "Reservation in progress":"Rezervacija u tijeku",
    "Number of schedule days":"Broj dana u rasporedu domaćinstva",
    "Clear":"Očisti",
    "TAGS":"OZNAKE",
    "Clear tags":"Očisti oznake",
    "Select personel":"Odaberi osoblje",
    "Please select one or more rooms to make a cleaning schedule, or select personel to view their assigned cleaning schedule.":"Molimo odaberite jednu ili više soba kako biste napravili raspored čišćenja ili odaberite osoblje za pregled dodijeljenog rasporeda čišćenja.",
    "Now select a cleaning personel to make a schedule for selected rooms.":"Sada odaberite osoblje za koje ćete napraviti raspored čišćenja za odabrane sobe.",
    "Stay":"Boravak",
    "Res.":"Rez.",
    "Select rooms":"Odaberi sobe",
    "My reservations":"Moje rezervacije",
    "Occupancy overview":"Pregled popunjenosti",
    "Hotel name":"Naziv hotela",
    "Book reg.num.":"Knjiga reg.br.",
    "Reference Res.id":"Referenca res.id",
    "Birth town":"Grad rođenja",
    "Daily":"Dnevno",
    "Monthly":"Mjesečno",
    "Attributes":"Atributi",
    "Select Icon":"Odaberi ikonicu",
    "Reference to document scanner device":"Referenca na dokument skener uređaj",
    "Add tags":"Dodaj oznake",
    "Is Tax included in price":"Je li porez uključen u cijenu",
    "Tax is included in price":"Porez uključen u cijenu",
    "Tax not included in price":"Porez nije uključen u cijenu",
    "Personal days for CONDO user per year":"Osobni dani za CONDO korisnika po godini",
    "Select role...":"Odaberi ulogu",
    "Role selection":"Odabir uloge",
    "Group reservation name":"Naziv grupne rezervacije",
    "Group name":"Naziv grupe",
    "Acces control QR Generator":"KONTROLA PRISTUPA QR GENERATOR",
    "Self check-in type":"Tip samoprijave",
    "SELECT GROUP":"ODABERI GRUPU",
    "Please select group reservation holder!":"Molimo odaberite nosioca grupne rezervacije!",
    "Checkin date can't be greater than checkout date!":"Datum prijave ne može biti veći od datuma odjave!",
    "Reservations group does not exists!":"Grupa za rezervacije ne postoji!",
    "Group does not have any reservation.":"Grupa nema rezervacija.",
    "Reservation is not valid! Please ensure validity before sending to the server!":"Rezervacija nije validna! Molimo osigurajte validnost prije slanja na server!",
    "Condo user":"CONDO korisnik",
    "Admin":"Administrator",
    "Document scanner":"Dokument skener",
    "Maid":"Spremačica",
    "Security":"Osiguranje",
    "Are you sure you want to change room mode?":"Jeste li sigurni da želite promijeniti sobni način?",
    "DocumentId":"Dokument id",
    "Contact":"Kontakt",
    "First and last name":"Ime i prezime",
    "ENABLED Terminal!":"Omogućen terminal!",
    "DISABLED Terminal...":"Onemogućen terminal...",
    "Acc. price rules":"Cjenovna pravila",
    "Short name":"Kratica",
    "Reservation is not checked in or closed.":"Rezervacija nije prijavljena ili zatvorena.",
    "First add items in room minibar.":"Prvo dodajte stavke u minibar.",
    
    "Include occupied rooms":"Prikaži i zauzete sobe",

    "Access only":"Samo pristup",

    "Room successfully added!":"Soba uspješno dodana!",
    "Room deleted!":"Soba izbrisana!",
    "Terminal added!":"Terminal dodan!",
    "Whitelist added!":"Lista dozvoljenih dodana!",
    "Whitelist deleted!":"Lista dozvoljenih izbrisana!",
    "Document scanner device is deleted!":"Uređaj za skeniranje izbrisan!",
    "Document scanner device added!":"Uređaj za skeniranje dodan!",
    "User is deleted!":"Korisnik izbrisan!",
    "User successfully added!":"Korisnik uspješno dodan!",
    "Tax deleted!":"Taksa izbrisana!",
    "Tax added!":"Taksa dodana!",
    "Accomodation price list deleted!":"Cjenik izbrisan!",
    "Accomodation price list added!":"Cjenik dodan!",
    "Currency deleted!":"Valuta izbrisana!",
    "Currency added! Please set exchange rate for created currency!":"Valuta dodana! Molimo postavite tečaj za kreiranu valutu!",
    "PosItem inactivated!":"Pos artikal inaktiviran!",
    "PosItem added!":"Pos artikal dodan!",
    "Customer created!":"Gost kreiran!",
    "Company created!":"Tvrtka kreirana!",
    "Company deleted!":"Tvrtka izbrisana!",
    "Notification on failure":"Pošalji obavijest o ispadu",
    "Minibar invoices":"Minibar računi",
    "unknown":"nepoznato",
    "noReservation":"nema rezervacije",
    "arrival":"dolazak gosta",
    "dueOut":"odjava",
    "stay":"boravak",
    "Are you sure you want create proforma?":"Jeste li sigurni da želite kreirati predračun?",
    "Create proforma":"Kreiraj predračun",

    "Require household inspection after cleaning is complete":"Soba zahtjeva inspekciju nakon završetka čišćenja",
    "Fiscal General Settings":"Postavke fiskalizacije",
    "New item":"Novi artikal",
    "Edit item":"Izmijeni artikal",
    "Doc Type":"Tip dokumenta",
    "Document number":"Broj dokumenta",
    "Scan time":"Vrijeme skeniranja",
    "Bussiness manager":"Poslovni menadžer",
    "Restore default permissions":"Vrati na zadane dozvole",
    "Self check in administration":"Administracija samoprijave",
    noActionTaken: "Nema akcije",
    cleaningRequested: "Zatraženo čišćenje",
    stagedForCleaning: "Spremno za čišćenje",
    cleaningStarted: "Čišćenje u tijeku",
    cleaningFinished: "Završeno",
    cleaningPostponed: "Odgođeno", 
    cleaningSkipped: "Čišćenje preskočeno",
    cleaningInspectionRequired: 'Potrebna inspekcija sobe',
    cleaningInspectionStarted: 'Inspekcija sobe počela',
    cleaningInspectionFailed: 'Inspekcija sobe neuspješna',
    cleaningInspectionPassed: 'Inspekcija sobe prošla',
    "Filter by tag":"Filtriraj po oznaci",
    "Filter by attributes":"Filtriraj po atributima",
    "List empty...":"Lista prazna",
    "Daily info":"Dnevno izvješće",
    "Weekly and monthly info":"Tjedno i mjesečno izvješće",
    

    
    "Comercial reservation":"Komercijalna rezervacija",
    "Before check-out, you need to go to the reception for invoicing!":"Prije odjave potrebno je otići na recepciju radi fakturiranja potrošnje!",
    "Available days":"Dostupni dani",
    "Used days":"Iskorišteni dani",
    "Reservations total":"Broj rezervacija",
    "Events":"Događaji",
    "Room is NOT encoded with condo owner card code!":"Soba NIJE kodirana kodom kartice vlasnika stana!",
    "My apartments":"Apartmani",
    "Are you sure you want to close reservation?": "Jeste li sigurni da želite zatvoriti rezervaciju?",
    "any selected attribute":"bilo koji odabrani atribut",
    "all selected attributes":"svi odabrani atributi",

    "Cash Registars":"Blagajna",
    "Cash Registar":"Blagajna",
    "Open registar":"Otvori blagajnu",
    "payIn":"Uplata",
    "payOut": "Isplata",
    "Pay IN": "Uplata",
    "Pay OUT": "Isplata",
    "Prev state": "Prošlo stanje",
    "Purpose": "Svrha",
    "Cash registar report num. ":"Blagajnički izvještaj br. ",
    "Cash Registar ":"Blagajna ",
    "Id num.":"Id br.",
    "Num.":"R.br.",
    "New payment purpose":"Nova svrha plaćanja",
    "New report":"Novi izvještaj",
    "CREATE REPORT":"KREIRAJ NOVI IZVJEŠTAJ",
    "ADD NEW PAYMENT PURPOSE":"KREIRAJ NOVU SVRHU",
    "Are you sure you want make report from ":"Jeste li sigurni da želite napraviti izvještaj od ",
    " to ":" do ",
    "Type of purpose":"Vrsta svrhe",
    "Pay ins and pay outs":"Uplate i isplate",
    "New cash registar":"Nova blagajna",
    "Edit cash registar":"Uredi blagajnu",
    "Open cash registar":"Otvori blagajnu",
    "Cash registars report":"Blagajnička izvješća",
    "Cash registar payments":"Blagajničke transakcije",
    "Uncollected Bills":"Nenaplaćeni računi",
    "Change payment status":"Promijeni status plaćanja",
    "Are you sure you want to set payment status of selected invoices to PAID and create payments?":"Jeste li sigurni da želite promijeniti status odabranih računa u PLAĆENO i kreirati uplatnice?",
    "Select period":"Odaberi period",

    RESERVATIONCONFIRMATION:"Predlošci poruka za potvrdu rezervacije",
    ROOMFAILURE:"Predožak za ispad sobe",
    "Use html template":"Koristi html predložak",
    RESERVATIONCANCELLATION:"Predlošci poruka za otkazivanje rezervacije",

    "If not checked, body and title use system defined values":"Ako nije odabrano, sadržaj poruke i naslov se šalju iz predložaka definiranih u sustavu",
    "If checked, email is sent like html page" : "Ako je odabrano, poruka se šalje u obliku html stranice",
    "Message":"Poruka",
    "Map to room":"Poveži sa sobom",
    "Select room by name":"Odaberi sobu po nazivu",
    "Select Pricelist":"Odaberi cjenik",

    "Availability range":"Raspon dostupnosti",
    "Select a period on the dashboard toolbar!":"Odaberite razdoblje na alatnoj traci nadzorne ploče!",
    "Next 15 days":"Sljedećih 15 dana",
    "Next 60 days":"Sljedećih 60 dana",
    "Preferred language":"Preferirani jezik",
    "Room failure receiver email":"Email primatelja obavijesti o ispadu sobe",
    "Send confirmation email again": "Pošalji mail o potvdi ponovno",
    "Send self check in email again": "Pošalji check in mail ponvno",
    "Confirm reservation":"Potvrdi rezervaciju",
    "Other language":"Drugi jezik",
    "Room price":"Cijena sobe",
    "Price per night":"Cijena po noćenju",
    "Accommodation tax rate":"Stopa poreza za smještaj",
    "Accommodation Tax already exist!":"Boravišna pristojba već postoji",
    "discountedaccommodationtax":"Umanjena boravišna pristojba",
    "Apply footer (Max 2 lines)":"Ažuriraj podnožje (Maks. 2 retka)",
    "All guests":"Svi gosti",
    "Domestic guests":"Domaći gosti",
    "Foreign guests":"Strani gosti",
    "Total days": "Ukupno dana",
    "Maximum number of days for condo reservations in this room exceeded": "Prekoračen maksimalan broj dozvoljenih dana za CONDO rezervacije za ovu sobu. ",
    "Remaining number days for condo reservations": "Preostali broj dana za condo rezervacije",
    "Current room tags":"Tagovi trenutne sobe",
    "Current room attributes":"Atributi trenutne sobe",
    "Filter by statuses":"Filter po statusima",
    paymentbyInvoice:"Plaćanje po računu",
    travelExpenses: "Putni troškovi",
    maintenance:"Održavanje",
    procurementOfSmallInventory:"Nabavka sitnog inventara",
    invoiceStorno: "Račun storniran",
    invoiceIssued:"Račun izdat",
    "There are no controllers associated with this SRC proxy":"Nema kontrolera povezanih s ovim SRC proxyjem",
    "Select rooms for notification on failure":"Odaberi sobe za obavijesti o ispadu",
    "No connected rooms":"Nema povezanih soba",
    "Send room failure notification for the room ":"Pošalji notifikaciju za ispad sobe ",
    "Don't send room failure notification for the room ":"Ne šalji notifikaciju za ispad sobe ",
    "Slack service settings":"Slack postavke",
    "SLACK SERVICE SETTINGS":"SLACK POSTAVKE",
    "Accommodation invoice":"Račun za smještaj",
    "The cost of canceling a reservation":"Trošak otkazivanja rezervacije",
    "Cancellation fee":"Trošak otkaza",
    "Removed from group":"Uklonjena iz grupe",

    "Cash registar maximum: ":"Blagajnički maximum",
    "Cash registar: ":"Blagajna: ",
    "Restart device" : "Resetiraj uređaj",
    "Full price":"Puna cijena",
    "Partial fee":"Djelomična naknada",
    "No charge":"Bez naknade",
    "Invoicing method":"Način fakturiranja",
    "To: (Optionally, if skipped, only a copy of the entered document will be printed)":"Do: (Neobavezno, ako se preskoči, ispisati će se samo kopija unešenog dokumenta)",
    "Cash maximum":"Blagajnički maximum",
    "ADD NEW PAYMENT":"NOVO PLAĆANJE",
    "CONCLUDE CASH REGISTAR REPORT":"ZAKLJUČI BLAGAJNIČKI IZVJEŠTAJ",
    "Payment type":"Tip plaćanja",
    "Payment purpose":"Svrha plaćanja",
    "UNCOLLECTED BILLS":"NEPLAĆENI RAČUNI",
    "Guest/Res":"Gost/Res",
    "Payment status":"Status plaćanja",
    "Check to change status":"Označi za promjenu statusa",
    "Max value is invoice amount":"Max vrijednost je iznos računa",
    "Search by invoice id":"Traži po id-u računa",
    "PAY":"UPLATI",
    "New payment":"Novo plaćanje",
    "virman_cashRegistar":"Virman blagajna",
    "Business conclusion":"Zaključak poslovanja",
    

    "If the square is marked the price can be changed in the terminal":"Slobodan unos cijene kroz terminal ako je kvadratić označen",
    "Add new price or confirm current:":"Unesite novu cijenu ili potvrdite trenutnu:",
    "New price":"Nova cijena",
    "Select template for CSV download file":"Odaberi template za CSV preuzimanje dokumenta",
    "Report num.":"Br.izvješća",
    "EXPORT CSV":"IZVEZI CSV",
    "CONCLUDE RESERVATIONS":"ZAKLJUČI REZERVACIJE",
    "Search by item name":"Pretraga po imenu artikla",
    "Predefined notes":"Predefinirane zabilješke",
    "Please confirm.":"Molimo potvrdite.",
    "Remove invoice":"Ukloni račun",
    "Tax exemption":"Oslobođenje od poreza",

    "CONCLUDE FISCALS":"ZAKLJUČI KASE",
    "CONCLUDE CASH REGISTARS":"ZAKLJUČI BLAGAJNE",
    "SHOW RESERVATION REPORT":"PRIKAŽI REZERVACIJSKO IZVJEŠĆE",
    'Adults days': "Dani odraslih",
    'Child days': "Dani djece",
    'Acc.value': "Cijena smještaja",
    'Acc.VAT': "Porez smještaja",
    'AccTax.value': "Cijena turističke pristojbe",
    'AccTax.VAT': "Porez turističke pristojbe",
    'Other value': "Ostale cijene",
    'Other VAT': "Ostali porezi",
    "Are you sure you want to conclude reservations? Once you conclude you will not be able to get same reservations again, only in reports.":
        "Jeste li sigurni da želite zaključiti rezervacije? Nakon što zaključite, više nećete moći dobiti iste rezervacije, samo u izvještajima.",
    "Reservations conclusion report":"Izvješće zaključaka rezervacija",
    "Report Header id":"Id zaglavlja izvješća",
    "Accommodation value":"Cijena smještaja",
    "Accommodation VAT":"Porez smještaja",
    "Acc.Tax VAT":"Porez turističke pristojbe",
    "Persons":"Broj osoba",
    "Registered guests report":"Izvještaj o prijavljenim gostima",
    "Registered guests":"Prijavljeni gosti",
    "Reservation report items":"Izvješća o stavkama rezervacija",
    "UNINVOICED":"NEFAKTURIRANO",
    "Item":"Artikal",
    "Create invoice":"Kreiraj račun",
    "Calculate":"Obračunaj",
    "Select terminal...":"Odaberite terminal...",
    "Download csv":"Preuzmi CSV",
    "Get export data":"Dohvati podatke",
    "Attach items to pos terminal ":"Dodijeli artikle pos terminalu ",
    "Tables Layout":"Raspored stolova",
    "New table layout":"Novi prostor",
    "Edit table layout":"Uredi prostor",
    "Background image":"Pozadinska slika",
    "TABLE ARRANGEMENT":"RASPORED STOLOVA",
    "Table arrangement ":"Raspored stolova ",
    "Associated terminals":"Povezani terminali",
    "Select terminals":"Odaberi terminale",
    "Subscribed tags":"Pretplaćene oznake",
    "Select tags":"Odaberi oznake",
    'Create new layout': 'Kreiraj novi raspored',
    'CREATE NEW LAYOUT': 'KREIRAJ NOVI RASPORED',
    'Number of tables': 'Broj stolova',
    Table: 'Stol',
    'SHOW ARRANGEMENT': 'PRIKAŽI RASPORED',
    'Num. of seats': 'Br. mjesta',
    'Table layout': 'Raspored',
    'Background position': 'Pozicija',
    "Number of seats":"Broj mjesta",
    "Edit table":"Uredi stol",
    "SHOW TABLES LIST":"PRIKAŽI LISTU STOLOVA",
    
   
    "Search by invoice number or invoice id":"Traži po broju ili id-u računa",
    "INV. NUM.":"BR. RAČ.",
    "DATE":"DATUM",
    "TOTAL VALUE":"UKUPNA VRIJEDNOST",
    "Accommodation invoices":"Računi za smještaj",
    "Update customer":"Ažuriraj gosta",
    "Do you want to update the customer with new document id?":"Želite li ažurirati gosta s novim dokument id-om?",
    "Passport":"Putovnica",
    "Identity card":"Osobna iskaznica",
    "Visa":"Viza",
    "Driving licence":"Vozačka dozvola",
    "Other":"Ostalo",
    "Reservation should be checkouted!":"Rezervacija bi trebala biti odjavljena!",
    "Min. Stock":"Min. Zaliha",
    "Reservations conclusion":"Zaključci rezervacija",
    "FISCAL TOOLS":"FISKALNI ALATI",
    "CASH REGISTARS":"BLAGAJNE",
    "After click, ":"Nakon klika, ",
    "for every PosTerminal select ":"za svaki PosTerminal odaberite ",
    "for every Cash Registar select ":"za svaku Blagajnu odaberite ",
    "then click on ":"zatim kliknite na ",
    " to conclude":" da zaključite",
    "Conclude reservations":"Zaključi rezervacije",
    "Conclude cash registar report":"Zaključi blagajnički izvještaj",
    "select ":"odaberite ",   
    'Set min. stock':"Postavi min. zalihu",
    "Total currencies":"Iznos po valutama",
    "Exc.rate":"Tečaj",
    "Are you sure you want to delete this payment?":"Jeste li sigurni da želite izbrisati uplatu/isplatu?",
    "CREATE NEW GROUP":"KREIRAJ NOVU GRUPU",
    "Group Invoices":"Grupni računi",
    "Group View":"Grupne rezervacije",
    "CLOSED GROUP INVOICES":"ZATVORENI GRUPNI RAČUNI",
    "Group invoice holder":"Nositelj grupnog računa",
    "Group invoice name":"Naziv grupnog računa",
    "Search invoices":"Pretraži račune",
    "Search group by groupName...":"Pretraži grupe po nazivu grupe",
    "Attach proforma invoice to group":"Dodaj predračun na grupu",
    "Update group":"Ažuriraj grupu",
    "ATTACH TO GROUP": "PRIKAČI NA GRUPU",
    "Customer && Company":"Gost i Tvrtka",
    "Activate":"Aktiviraj",
    "Accommodation tax is calculated on the invoice and pro forma invoice as a separate item. It is not calculated on the advance invoice. (Sugested approach)":"Boravišna pristojba se obračunava na računu i predračunu kao zasebna stavka. Ne obračunava se na avansnom računu. (Predloženi pristup)",
    "Accommodation tax is calculated only on the invoice as a separate item. It is not calculated on the pro forma invoice and advance invoice. Note (on the pro forma invoice): Accommodation tax is not included in the price of accommodation and is charged at the reception, after the realization of the service.":"Boravišna pristojba se obračunava samo na računu kao posebna stavka. Ne obračunava se na predračunu i avansnom računu. Napomena (na predračunu): Boravišna pristojba nije uključena u cijenu smještaja i naplaćuje se na recepciji, nakon realizacije usluge.",
    "Accommodation tax is calculated on all types of invoices as a separate item.":"Boravišna pristojba se obračunava na svim vrstama računa kao posebna stavka",
    "Accommodation tax is calculated on all types of invoices within the room price. Note (on the invoice and pro forma invoice): Accommodation tax is included in the price of accommodation. Internal note: The hotel charges for the service at the net price, and the accommodation tax is treated as their internal cost. Therefore, the fee item is not charged directly to the client at all, and no invoice is issued for it at all. The hotel later registers the guests of the services for foreigners, on the basis of which they will receive an invoice for the accommodation tax.":"Boravišna pristojba se obračunava u sklopu cijene sobe, vrijedi za sve vrste računa. Napomena (na računu i predračunu): Boravišna pristojba je uključena u cijenu smještaja. Interna napomena: Hotel naplaćuje uslugu po neto cijeni, a boravišnu pristojbu tretiraju kao njihov interni trošak. Dakle, stavka takse se uopće ne naplaćaju direktno klijentu, te se za nju uopće ne izdaje račun. Hotel kasnije prijavljuje goste službi za strance temeljem čega će dobiti i račun za boravišne pristojbe.",
    "Invoicing settings":"Postavke fakturiranja",
    "Approach":"Pristup",
    "The expected (entered) price of the room includes the price of the accommodation tax. After entering the expected price, the price of accommodation will be adjusted depending on the price of the accommodation tax. Note: the estimated price of the room will be calculated in full and in case of charged unused overnight stay. (earlier check-out)":"U očekivanu (upisanu) cijenu sobe uključena je i cijena boravišne pristojbe. Nakon unosa očekivane cijene, cijena smještaja će se korigirati ovisno o cijeni boravišne pristojbe. Napomena: procijenjena cijena sobe bit će obračunata u cijelosti i u slučaju naplaćenog neiskorištenog noćenja. (ranija odjava) ",
    "The expected (entered) price of the room includes the price of the accommodation tax. After entering the expected price, the price of accommodation will be adjusted depending on the price of the accommodation tax. Note: the estimated price of the room will be reduced by the value of the accommodation tax in case of charged unused overnight stay (earlier check-out).":"U očekivanu (upisanu) cijenu sobe uključena je i cijena boravišne pristojbe. Nakon unosa očekivane cijene, cijena smještaja će se korigirati ovisno o cijeni boravišne pristojbe. Napomena: procijenjena cijena sobe bit će umanjena za vrijednos boravišne pristojbe u slučaju naplaćenog neiskorištenog noćenja (ranija odjava).",
    "The expected (entered) price of the room does not include the price of the accommodation tax.":"U očekivanu (upisanu) cijena sobe nije uključena cijena boravišne pristojbe.",
    "Pricing":"Formiranje cijene",
    "Checkout options":"Mogućnosti odjave",
    "Apply current time":"Primjeni trenutno vrijeme",
    "Apply the planned time":"Primjeni planirano vrijeme",
    "NOTE: Please select time that will be applyed as check in time":"NAPOMENA: Molimo odaberite vrijeme koje će se primijeniti kao vrijeme prijave",
    "Please select a check-out time":"Molimo odaberite vrijeme odjave",
    "Checkout confirmation":"Potvrda odjave",
    "Cash registar and business conclusion":"Blagajna i zaključci poslovanja",
    "All business conclusions in one place.":"Svi poslovni zaključci na jednom mjestu.",
    "Accom. value":"Smještaj",
    "Accom. VAT":"Porez na smještaj",
    "Accom. tax":"Boravišna",
    "Accom.Tax VAT":"Porez na boravišnu",
    "WARNING!!! You have some reservations that shoud be checked in or checked out. Please edit them if you want to include them in export. Remember - only checked in and checkouted reservations are included in csv export.":"UPOZORENJE!!! Imate neke rezervacije koje biste trebali prijaviti ili odjaviti. Uredite ih ako ih želite uključiti u izvoz. Zapamtite - samo prijavljene i odjavljene rezervacije uključene su u csv izvoz.",
    "Group invoice":"Grupni račun",
    "Group made by":"Grupu zabilježio",
    "Invoice header":"Zaglavlje računa",
    "Number of items on a one-sided invoice":"Broj artikala na jednostranom računu",
    "Number of items on the first page":"Broj stavki na prvoj stranici",
    "Number of items on the last page":"Broj stavki na posljednjoj stranici",
    "Number of items on the middle pages":"Broj stavki na srednjim stranicama",
    "Issuer company name":"Naziv tvrtke izdavatelja",
    "Set note":"Unesite bilješku",
    "Ref. invoice":"Ref. faktura",
    "Acommodation Tax Value":"Boravišna pristojba",
    "Are you sure you want to conclude reservations? Once you conclude you will not be able to get same reservations again.":"Jeste li sigurni da želite zaključiti rezervacije? Rezervacije koje jednom zaključite više nećete moći dobiti opet.",
    "View details":"Pogledaj detalje",
    "View guests":"Pogledaj goste",
    "Show codebook":"Prikaži šifrarnik",
    "Download edited csv":"Preuzmi uređeni csv",
    "genderTypes":"Spol",
    "visaTypes":"Viza",
    "documentTypes":"Dokument",
    "placesTypes":"Naseljena mjesta",
    "countryTypes":"Države",
    "Select codebook":"Odaberi šifrarnik",
    "Checked in guests":"Gosti za prijavu",
    "Checked out guests":"Gosti za odjavu",
    "Domestic":"Domaći",
    "Foreign":"Stranci",
    "Export":"Izvoz",
    "Select filter for export data":"Odaberi filter za preuzimanje podataka",
    "Show all":"Prikaži sve",
    "Enable POS print":"Omogući POS ispis",
    "Print width (Number of characters)":"Širina ispisa (broj znakova)",
    "Proxy name":"Naziv proksija",
    "SAVE EDITED DATA":"SPREMI UREĐENE PODATKE",
    "Customer(s) edited!":"Gost(i) uređeni!",
    "Nights":"Noćenja",
    "By countries":"Po zemljama",

    "password reset":"promjenu lozinke",
    "user":"korisnika",
    "api key":"api ključ",
    "Delete ":"Izbriši ",
    "Confirm ":"Potvrdi ",
    "src template":"src templete",
    "proxy restart":"proxy restart",
    "proxy reset":"proxy reset",
    "room attribute":"atribut sobe",
    "Restore":"Vrati",
    "Add room to reservation":"Dodaj sobu na rezervaciju",
    "reservation guest":"gosta s rezervacije",
    "minibar data":"minibar potrošnju",
    "reservation holder":"nositelja rezervacije",
    "reservation":"rezervaciju",
    "_tax":"taksu",
    "pos item":"pos artikal",
    "currency":"valutu",
    "exchange rate":"tečaj",
    "accommodation price list":"cjenik",
    "pos terminal":"pos terminal",
    "company":"tvrtku",
    "door access point":"pristupnu točku",
    "smart room controller":"kontroler",
    'Pos configuration': 'Pos postavke',
    'Set terminal settings.': 'Definirajte postavke terminala.',
    "Please add serial book number and foreign service username":"Molimo Vas upišite serijski broj knjige i korisničko ime s kojim prijavljujete goste",
    "Serial Book Number":"Serijski broj knjige",
    "Foreign service username":"Korisniško ime",
    "Enable side menu":"Prikaži izbornik sa strane",
    "Rooms and price lists":"Sobe i cjenici",
    "The guests should have been checked out":"Gosti su trebali biti odjavljeni",
    "The guests should have been checked in":"Gosti su trebali biti prijavljeni",
    "The arrival of guests is today":"Dolazak gostiju je danas",
    "The departure of guests is today":"Odlazak gostiju je danas",
    "Arrival":"Dolazak",
    "Departure":"Odlazak",
    "Journal":"Žurnal",
    REMINDEREMAILRESERVATION:"Predlošci poruka za podsjetnik rezervacije",
    "day before check in":"dan prije prijave",
    "days before check in":"dana prije prijave",
    "Don't send":"Nemoj slati",
    "Check in reminder email":"Podsjetnik na prijavu",
    
    "Guest data":"Podaci o gostu",
    "Minibar general":"Minibar općenito",
    "Select rooms and items to apply same minibar and add min stock":"Odaberite sobe i artikle da primjenite isti minibar i upišite minimalnu zalihu",
    "Clear access codes":"Očisti pristupne kodove",
    "Skipp cleaning":"Preskoči čišćenje",
    "Cleaning note":"Bilješka o čišćenju",
    "Inspection note":"Bilješka o inspekciji",
    "Order num.":"Redni br.",
    "Doc.id":"Dokument id",
    "Select export type":"Odaberi tip izvoza",
    "Default":"Zadani",
    "Guest book":"Knjiga gostiju",
    "Guest list":"Popis gostiju",
    "Acc.Tax Value":"Boravišna pristojba",
    "Acc.Tax Value Upon Reg.Guests":"Boravišna po prijavama",
    "Invoice num.":"Br.računa",
    "Group uuid":"Uuid grupe",
    "Created":"Kreirana",
    "Prev. state":"Prošlo stanje",
    "Cash registar":"Blagajna",
    "Registar max.":"Blagajnički max.",
    "Next":"Dalje",
    "Name to display":"Ime za prikaz",
    "False state label":"Oznaka false stanja",
    "True state label":"Oznaka true stanja",
    "False state icon color":"Boja ikonice false stanja",
    "True state icon color":"Boja ikonice true stanja",
    "False state animation blink":"Animacija blink false stanja",
    "True state animation blink":"Animacija blink true stanja",
    "Self check in test mode":"Samoprijava testni način",
    "Send reservation confirmation to guest":"Pošalji potvrdu rezervacije gostu",
    "Terminal type":"Tip terminala",
    "Your reservation is":"Vaša rezervacija je",
    "Please select one of the following guests first, then scann his document data.":"Molimo odaberite jednog od sljedeći gostiju, zatim skenirajte njegov dokument.",
    "Other guests":"Ostlai gosti",
    "Scanned data":"Skenirani podaci",
    "CHECK USER":"PROVJERI GOSTA",
    "There is a few similar guests. Click on card that match with your data or create new with scanned data if no data match.":"Postoje gosti sa sličnim podacima. Kliknite na karticu koja odgovara skeniranim podacima ili kreirajte novog ako se podaci ne podudaraju.",
    "DECLINE":"ODBIJ",
    "CANCEL ALL":"OTKAŽI SVE",
    "Cancel all reservations":"Otkaži sve rezervacije",
    "Do you really want to cancel all this reservations?":"Jeste li sigurni da želite otkazati sve rezervacije?",
    "Are you sure you want to reset room remotely" : "Jeste li sigurni da želite resetirati sobu?",
    "Remote room reset": "Udaljeni reset sobe",
    "Reset room": "Resetiraj sobu",
    "Never": "Nikad",
    "Select filter type":"Odaberi tip filtera",
    "Only registered guests":"Samo prijavljeni gosti",
    "Show reservation conclusions":"Prikaži zaključke rezervacija",
    "Show registered guests":"Prikaži prijavljene goste",
    "Doc.num.":"Br.dokumenta",
    "Doc.type":"Tip dokumenta",
    "Visa Type":"Tip vize",
    "Visa Num.":"Broj vize",
    'Z reports': 'Dnevni izvještaji',
    "Count day stay as night":"Računaj dnevni boravak kao noć",
    "New room":"Nova soba",
    "Edit room":"Uredi sobu",
    "New plan":"Novi plan",
    "Edit plan":"Uredi plan",
    "New virtual plan":"Novi virtualni plan",
    "Edit virtual plan":"Uredi virtualni plan",
    "Plans":"Planovi",
    "Room Type":"Tip sobe",
    "Save changes":"Spremi promjene",
    "Update to":"Ažuriraj do",
    "Update availability":"Ažuriraj dostupnost",
    "Map to room(s)":"Dodijeli sobe",
    "Select pricelist":"Odaberi cjenik",
    "Virtual rooms":"Virtualna soba",
    "Pricing plans":"Planovi cijena",
    "Apartment":"Apartman",
    "Bed":"Krevet",
    "Unit":"Stambena jedinica",
    "No board":"Samo noćenje",
    "Breakfast":"Doručak",
    "Half board":"Polu pansion",
    "Full board":"Puni pansion",
    "All inclusive":"Sve uključeno",
    "Shortname":"Kratica",
    "Number of guests":"Broj gostiju",
    "Default price":"Zadana cijena",
    "Default availability":"Zadana dostupnost",
    "Default board":"Usluga noćenja",
    "Fixed discount (- -)":"Fiksni popust (- -)",
    "Fixed Increase (+ +)" :"Fiksno povećanje (+ +)",
    "Percentage discount (- %)":"Postotak popusta (- %)",
    "Percentage Increase (+ %)":"Postotak povećanja (+ %)",
    "Variation":"Promjena",
    "Select variation":"Odaberi promjenu",
    "Closure":"Zatvaranje", 
    "Open":"Otvori",
    "Opened":"Otvorena",
    "Min. Stay":"Min. Boravak",
    "Max. Stay":"Max. Boravak",
    "Availability":"Dostupnost", 
    "No min stay restriction (1)":"Nema ograničenja min. boravka",
    "No max stay restriction (1)":"Nema ograničenja max. boravka",
    "From/To":"Od/Do",
    "Work on":"Tip radnje",
    "Restriction Plan":"Plan ograničenja",
    "mon":"pon",
    "tue":"uto",
    "wed":"sri",
    "thu":"čet",
    "fri":"pet",
    "sat":"sub",
    "sun":"ned",
    "DailyPlan":"Dnevni",
    "New daily plan":"Novi dnevni plan",
    "Manage restrictions":"Upravljaj ograničenjima",
    "Room type is for example a Double Room and it is not a real room. A hotel that wants to sell 10 doubles of same standard would need to create a typology Double Room and then specify its availability as 10, which would mean that you have 10 double rooms that you are selling.":"Tip sobe je na primjer Dvokrevetna soba i ona ne označava stvarnu sobu. Hotel koji želi prodati 10 dvokrevetnih istog standarda trebao bi kreirati tipologiju dvokrevetne sobe i zatim navesti njezinu dostupnost kao 10, što bi značilo da imate 10 dvokrevetnih soba koje prodajete.",
    "Map to rooms you want to sell online. If you map, for example, three rooms, the availability of the Room Type will automatically become 3. You can change the availability in the 'Manage' section of the interface. Note: if you set the number of guests to 2 when creating the Room Type, then you should map your rooms that are for two people.":"Dodijelite sobe koje zelite online prodavati. Ako dodijelite npr. tri sobe, automatski će dostupnost Tipa Sobe biti 3, bez obzira koliku dostupnost ste postavili prilikom kreiranja Tipa Sobe. Dostupnost možete promijeniti u dijelu sučelja 'Upravljaj'. Napomena: ako ste prilikom kreiranja Tipa Sobe postavili da je broj gostiju 2, onda bi trebali dodijeliti sobe koje su za dvije osobe (dvokrevetne).",
    "A virtual room is used when you want to sell an existing room with different options and price. A virtual room will share its availability with its `mother room`. A typical example is when you have already a `Double Room`: You can create a virtual room `Double room for single use` as a virtual room of the `Double room`.":"Virtualna soba se koristi kada želite prodati postojeću sobu s različitim opcijama i cijenom. Virtualna soba će dijeliti svoju dostupnost sa svojom `matičnom sobom`. Tipičan primjer je kada već imate kreiran Tip Sobe `Dvokrevetna soba`: možete stvoriti virtualnu sobu `Dvokrevetna soba za jednu osobu` kao virtualnu sobu `Dvokrevetne sobe`.",
    "Daily plan - When creating a daily plan, you only need to enter the name of the plan. We determine the prices for that plan in the 'Rooms' interface, where we select the price list for a specific plan. Virtual Plan - A Virtual Plan has the same prices of a 'parent plan', with a percentage variation.":"Dnevni plan - Kad kreiramo dnevni plan samo je potrebno unijeti naziv plana. Cijene za taj plan određujemo u sučelju 'Sobe' gdje odaberemo cjenik na određeni plan. Virtualni plan - Virtualni plan ima iste cijene kao 'matični plan', s varijacijom u postocima.",
    "Setup the same values for one or multiple rooms between a range of dates. You can choose to update: Closure, Availability or Restrictions.":"Postavite iste vrijednosti za jednu ili više soba između raspona datuma. Možete odabrati ažuriranja: Zatvaranje, Dostupnost ili Ograničenja.",
    "Guest = the occupancy of the room":"Gosti = zauzetost sobe",
    "Map to pricelist(s)":"Poveži cjenike",
    'Reservation holder': 'Nositelj rezervacije',

    "Edit warehouse":"Uredi skladište",
    "New warehouse":"Novo skladište",
    "warehouse":"skladište",
    "Barcode":"Barkod",
    "Measure code":"Mjerna jedinica",
    "Sales price":"Prodajna cijena",
    "Active item":"Artikal aktivan",
    "Add category":"Dodaj kategoriju",
    "Warehouse added!":"Skladište dodano!",
    "Item inactivated!":"Artikal neaktivan!",
    "item":"artikal",
    "Create a norm":"Kreiraj normativ",
    "Sku item":"Skladišni artikal",
    "Set multiplier":"Multiplikator",
    "Documents":"Dokumenti",
    "Items count":"Količina",
    "Items value":"Vrijednost",
    "Created by":"Kreirao",
    "Warehouse":"Skladište",
    "goodsReceivingNote":"Primitak robe",
    "goodsDespatchNote":"Otpremnica robe",
    "writeOffGoods":"Otpis robe",
    "Document header":"Podaci o dokumentu",
    "CONCLUDE DOCUMENT":"ZAKLJUČI DOKUMENT",
    "You can not change code!":"Ne možete promijeniti kod!",
    "Item does not exist!":"Artikal ne postoji!",
    'skuItem':'Artikal',
    'barcode':'Barkod',
    'quantity':'Količina',
    'price':'Cijena',
    'purchasePrice':'Nabavna cijena',
    "This document number already exist!":"Već postoji navedeni broj dokumenta!",
    "totall":"ZBROJ",
    "Purchase price":"Nabavna cijena",
    "Commited by":"Zaključio",
    "Commited date":"Datum zaključka",
    "Created date":"Datum kreiranja",
    "Show stock":"Prikaži zalihu",
    "There is no active documents.":"Nema aktivnih dokumenata.",
    "CONCLUDED DOCUMENTS":"ZAKLJUČENI DOKUMENTI",
    "Normative":"Normativ",
    "CREATE NORMATIVE":"KREIRAJ NORMATIV",
    "taxId1":"PDV",
    "taxId2":"PNP",
    "taxId3":"Ostali porezi",
    "skuId":"Id artikla",
    "Please add code first!":"Molimo upišite prvo kod!",
    "grossMargin":"Bruto marža",
    "New entry calculation":"Nova ulazna kalkulacija",
    "Edit entry calculation":"Uredi ulaznu kalkulaciju",
    "There is no active entry calculations.":"Nema aktivnih ulaznih kalkulacija.",
    "CONCLUDED ENTRY CALCULATIONS":"ZAKLJUČENE ULAZNE KALKULACIJE",
    'code':'Šifra',
    'name':'Naziv',
    'entryPrice':'Ulazna cijena',
    'entryVAT':'Ulazna taksa',
    'entryVATInfo':'Ulazna taksa',
    'grossMarginPercentage':'Bruto marža %',
    'grossMarginValue':'Bruto marža vrijednost',
    'tax1Total':'PDV ukupno',
    'tax2Total':'PNP ukupno',
    'tax3Total':'Ostali porezi ukupno',
    'exitPrice':'Izlazna cijena',
    'itemId':'Id artikla',
    "Select conclude type":"Odaberi tip zaključka",
    "CONCLUDE DOCUMENT AND CREATE ENTRY CALCULATION":"ZAKLJUČI DOKUMENT I KREIRAJ ULAZNU KALKULACIJU",
    "Edit document":"Uredi dokument",
    "New document":"Novi dokument",
    "Document is concluded!":"Dokument je zaključen!",
    "Please first add document header data!":"Molimo prvo upišite podatke o dokumentu!",
    "Entry calculation header":"Podaci o ulaznoj kalkulaciji",
    "Entry calculation is concluded!":"Ulazna kalkulacija je zaključena!",
    "Please first add entry calculation doc number data!":"Molimo prvo upišite broj dokumenta ulazne kalkulacije!",
    "CONCLUDE ENTRY CALCULATION":"ZAKLJUČI ULAZNU KALKULACIJU",
    "CONCLUDE ENTRY CALCULATION AND CREATE GOODS RECEIVING NOTE":"ZAKLJUČI ULAZNU KALKULACIJU I KREIRAJ PRIMKU",
    'HW LAN View': 'HW LAN uloga',
    'Barcode already exists!':'Barkod već postoji!',
    "Barcode is not valid!":"Barkod nije ispravan!",
    "tax1":"PDV",
    "tax2":"PNP",
    "tax3":"Ostali porezi",
    "Multiplier":"Koeficijent",
    "Name, code, measure code":"Naziv, kod, jedinica mjere",
    "measureUnit":"Jedinica mjere",
    "with entry calculation":"s ulaznom kalkulacijom",
    "Supplier":"Dobavljač",
    "Total transportation":"Prijevoz ukupno",
    "Total shipping":"Špedicija ukupno",
    "Total customs":"Carina ukupno",
    "Total other costs":"Ukupni ostali troškovi",
    "transportation":"Prijevoz",
    "shipping":"Špedicija", 
    "customs":"Carina",
    "otherCosts":"Ostali troškovi",
    "salePrice":"Prodajna cijena", 
    "Occupancy":"Popunjenost",
    'Home':'Nadzorna ploča',
    'Property Management':'',
    'Config':'Konfiguracija',
    'Details':'Detalji',
    'Reset password':'Resetiranje lozinke',
    'Subscriptions':'Pretplate',
    'Hotel guests': 'Gosti u hotelu',
    'Trebaš pomoć? Provjeri najčešće postavljena pitanja': '',
    'Frequently asked questions': 'Najčešće postavljena pitanja',
    'You still need help? Contact as at': 'Još uvijek trebaš pomoć? Kontaktiraj nas na',
    'Tools':'Alati',
    "The guest's stay does not match the duration of the reservation":"Boravak gosta ne podudara se s trajanjem rezervacije",
    "Res. status":"Status rezervacije",
    "accommodation":"Smještaj",
    "tax":"Pristojba",
    "posTerminal":"Artikal",
    "Split invoice":"Podijeli račun",
    'Next invoice':"Sljedeći račun",
    "Split to":"Podijeliti na",
    "Name, code or barcode":"Naziv, kod ili barkod",
    "interWarehouseTransfer":"Međuskladišnica",
    "stockMeasureUnit":"Jedinica mjere",
    "prevAggWarehouseGoodsQty":"Zadnje stanje",
    "goodsReceivingQty":"Primljena roba",
    "goodsDespatchQty":"Otpis robe",
    "tempInvoiceQty":"Fakturirana roba",
    "aggStockRecQty":"Zadnje stanje (presjek)",
    "tempStockRecQty":"Primljena roba",
    "totalQty":"Ukupna količina",
    "todaysState":"Stanje danas",
    "itemPrice":"Cijena artikla",
    "stockQuantity":"Količina (ukupno - danas)",
    "stockPrice":"Vrijednost",
    "inventoryState":"Inventurna razlika",
    "stockDocItemId":"Id dok.artikla",
    "orderToTheSupplier":"Narudžba dobavljaču",
    "offerNote":"Ponuda",
    "inventory":"Inventura",
    "adjustmentByInventory":"Usklađenje po inventuri",
    "inventoryGoodsReceivingNote":"Primka robe po inventuri",
    "REPLENISH":"NADOPUNITI",
    "BELOW THE MINIMUM":"ISPOD MINIMUMA",
    "ABOVE REPLENISHMENT":"IZNAD DOPUNE",
    "invoiceGoods":"Fakturirana roba",
    "aggregatedWarehouseGoodsData":"Zbirni podaci o skladišnoj robi",
    "Code":"Kod",
    "Stock measure code":"Jedinica mjere zalihe",
    "Minimum stock":"Minimalna zaliha",
    "Replenish stock":"Nadopuniti zalihu",
    "ACTIVE":"AKTIVAN",
    "INACTIVE":"NEAKTIVAN",
    "Is combo item":"Kombinirani artikal",
    "Warehouse stock":"Zaliha skladišta",
    "State":"Stanje",
    "SHOW WAREHOUSES":"PRIKAŽI SKLADIŠTA",
    "BELOW THE MIN.":"ISPOD MINIMUMA",
    "ABOVE REPL.":"IZNAD DOPUNE",
    "salesPrice":"Prodajna cijena",
    "purchaseMeasureUnit":"Nabavna jed. mjere",
    "purchaseMeasureUnit_stockUnitMultiplier":"Koeficijent",
    "Purchase measure unit not equal stock measure code":"Nabavna jedinica mjere nije jednaka skladišnoj",
    "Purchase measure unit":"Nabavna jedinica mjere",
    "Document":"Dokument",
    "piecesQuantity":"Količina (komad)",
    "Purchase unit to stock unit multiplier":"Umnožak jedinice nabave prema jedinici zalihe",
    "aggregatedWarehouseGoodsCounter":"Zbirni podaci za brojač robe",
    'Not paid':"Nije plaćeno",
    'Fully paid':"Plaćeno u potpunosti",
    'Duration':"Trajanje",
    'Connected terminals':"Povezani terminali",
    'Invoice type':"Vrsta računa",
    'Customers data report': 'Izvješće o gostima',
    'Search on page': 'Pretraži na stranici',
    'Client':"Komitent",
    'ID number': 'ID broj',
    'Search for company (by name, town, idNumber)':'Traži tvrtku (po nazivu, gradu, id broju)',
    'Search for customer (by first and last name)':'Traži gosta (po imenu i prezimenu)',
    "not configured":"Nije konfigurirano",
    "online":"Na mreži",
    "fading":"Slabljenje",
    "disconnected":"Odspojen",
    'Layers':"Nivoi",
    'There are no Pos Terminals connected':"Nema povezanih pos terminala",
    "Reservation table":"Tablica rezervacija",
    "By status":"Po statusu",
    "By tag":"Po oznaci",
    "By attributes":"Po atributima",
    "Group":"Grupa",
    "Notification info":"Informacije o notifikacijama",
    "change room":"promjenu sobe",
    "Groups":"Grupe",
    "No active group reservations":"Nema aktivnih grupnih rezervacija",
    "No group invoices":"Nema grupnih računa",
    "Group reservations":"Grupne rezervacije",
    "Group invoices":"Grupni računi",
    "Group reservation note":"Bilješka grupne rezervacije",
    "Holder":"Nositelj",
    "Group status":"Status grupe",
    "Pay. status":"Status plaćanja",
    admin:'Administrator',
    condoUser:'Condo korisnik',
    documentScanner:'Dokument scanner',
    gdprOfficer:'GDPR officer',
    hwAdmin:'HW Administrator',
    managerFull:'Generalni upravitelj',
    managerBussiness:'Psolovni menadžer',
    managerHousehold:'Upravitelj domaćinstva',
    managerSecurity:'Upravitelj osiguranja',
    receptionist:'Recepcionist',
    maid:'Spremačica',
    janitor:'Janitor',
    security:'Osiguranje',
    reporting:'Reporting',
    default:'Default',
    sos:'SOS',
    hwLANView:'HW LAN View',
    posBartender:'Pos konobar',
    posKitchen:'Pos kuhinja',
    posSupervisor:'Pos Administrator',
    "Document id":"id dokumenta",
    "Contacts":"Kontakt",
    "Page":"Stranica",
    "Default pricelist":"Zadani cjenici",
    "Cash registars payments":"Blagajničke transakcije",
    "About":"O sustavu",
    "You need help? Check some of frequently asked questions":"Trebate pomoć? Provjerite neka od često postavljenih pitanja",
    "Current software version":"Trenutna verzija softvera",
    "Frist and last name":"Ime i prezime",
    "Read":"Čitaj",
    "Cash":"Novčanice",
    "Card":"Kartica",
    "Cheque":"Ček",
    "Virman":"Virman",

};
