import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';

class PageTitle extends Component {
    render() {
        if (this.props.alternate) {
            return (
                <h1 className='page-title'>
                    {this.props.title || this.props.pageTitle}
                </h1>
            );
        } else {
            return (
                <h1 className='page-title'>
                    {this.props.title || this.props.pageTitle}
                </h1>
            );
        }
    }
}

export default branch(
    {
        pageTitle: ['pageTitle'],
    },
    PageTitle
);
