import React from 'react';
import _ from 'lodash';
import originalMoment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(originalMoment);

const SidebarDailyInfo = (props) => {
    return (
        <React.Fragment>
            {/* <div className="col-md-12 text-center">
                    <small>{window.translate('Guests')}</small>
                </div> */}
            <div className=" mt-3 neutral-bg-50 border-radius-4">
                <CurrentGuestNumber {...props} />
            </div>

            <div className=" mt-3 neutral-bg-50 border-radius-4">
                <div className=" text-center p-2">
                    <b>{window.translate('Today')}</b>
                </div>

                <HotelOccupancy {...props} from={moment().startOf('day')} to={moment().endOf('day')} />
                <IncomingsDeparturesHeader />
                <div className=" text-center p-0  mt-1 d-flex">
                    <CheckinsRooms {...props} from={moment().startOf('day')} to={moment().endOf('day')} />
                    <CheckoutsRooms {...props} from={moment().startOf('day')} to={moment().endOf('day')} />
                </div>
                <div className=" text-center p-0  mt-1 d-flex">
                    <CheckinsGuests {...props} from={moment().startOf('day')} to={moment().endOf('day')} />
                    <CheckoutsGuests {...props} from={moment().startOf('day')} to={moment().endOf('day')} />
                </div>
            </div>
            <div className=" mt-3 neutral-bg-50 border-radius-4">
                <div className=" text-center p-2">
                    <b>{window.translate('Tomorow')}</b>
                </div>
                <HotelOccupancy
                    {...props}
                    from={moment().add(1, 'days').startOf('day')}
                    to={moment().add(1, 'days').endOf('day')}
                />
                <IncomingsDeparturesHeader />
                <div className=" text-center p-0  mt-1 d-flex">
                    <CheckinsRooms
                        {...props}
                        from={moment().add(1, 'days').startOf('day')}
                        to={moment().add(1, 'days').endOf('day')}
                    />
                    <CheckoutsRooms
                        {...props}
                        from={moment().add(1, 'days').startOf('day')}
                        to={moment().add(1, 'days').endOf('day')}
                    />
                </div>
                <div className=" text-center p-0  mt-1 d-flex">
                    <CheckinsGuests
                        {...props}
                        from={moment().add(1, 'days').startOf('day')}
                        to={moment().add(1, 'days').endOf('day')}
                    />
                    <CheckoutsGuests
                        {...props}
                        from={moment().add(1, 'days').startOf('day')}
                        to={moment().add(1, 'days').endOf('day')}
                    />
                </div>
            </div>

            <div className=" mt-3 neutral-bg-50 border-radius-4">
                <div className=" text-center p-2">
                    <b>{window.translate('Rooms')}</b>
                </div>
                <div className=" p-0">
                    <div className="d-flex">
                        <AvailableRooms {...props} />
                        <RoomsTaken {...props} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default SidebarDailyInfo;

const CurrentGuestNumber = ({ reservations }) => {
    const allCheckedInReservations = reservations.filter((r) => r.statusEnum === 2);
    const adultsNumber = _.sumBy(allCheckedInReservations, (a) => {
        return Number(a.adultsNumber);
    });
    const childrenNumber = _.sumBy(allCheckedInReservations, (a) => {
        return Number(a.childrenNumber);
    });

    const totalGuests = adultsNumber + childrenNumber;
    return (
        <div className=" p-0">
            <div className="text-center pt-2 pb-2">{window.translate('Hotel guests')}: {totalGuests}</div>
        </div>
    );
};

const CheckinsRooms = ({ reservations, from, to }) => {
    const checkins = reservations.filter(
        (r) => (r.statusEnum === 1 || r.statusEnum === 2) && moment(r.checkInTimestamp).isBetween(from, to)
    );

    return (
        <div className="col-6">
            <div className="pl-3 pr-3">
                <small>
                    {window.translate('Rooms')}: {checkins.length}
                </small>
            </div>
        </div>
    );
};

const CheckinsGuests = ({ reservations, from, to }) => {
    const checkins = reservations.filter(
        (r) => (r.statusEnum === 1 || r.statusEnum === 2) && moment(r.checkInTimestamp).isBetween(from, to)
    );
    const adultsNumberIncoming = _.sumBy(checkins, (a) => {
        return Number(a.adultsNumber);
    });
    const childrenNumberIncoming = _.sumBy(checkins, (a) => {
        return Number(a.childrenNumber);
    });

    const totalGuestsIncoming = adultsNumberIncoming + childrenNumberIncoming;

    return (
        <div className="col-6">
            <div className="pl-3 pr-3">
                <small>
                    {window.translate('Guests')}: {totalGuestsIncoming}
                </small>
            </div>
        </div>
    );
};

const CheckoutsGuests = ({ reservations, from, to }) => {
    const checkouts = reservations.filter((r) => (r.statusEnum === 1 || r.statusEnum === 2) && moment(r.checkOutTimestamp).isBetween(from, to));
    const adultsNumberOngoing = _.sumBy(checkouts, (a) => {
        return Number(a.adultsNumber);
    });
    const childrenNumberOngoing = _.sumBy(checkouts, (a) => {
        return Number(a.childrenNumber);
    });

    const totalGuestsOngoing = adultsNumberOngoing + childrenNumberOngoing;
    return (
        <div className="col-6">
            <div className="pl-3 pr-3">
                <small>
                    {window.translate('Guests')}: {totalGuestsOngoing}
                </small>
            </div>
        </div>
    );
};

const CheckoutsRooms = ({ reservations, from, to }) => {
    const checkouts = reservations.filter((r) => (r.statusEnum === 1 || r.statusEnum === 2) && moment(r.checkOutTimestamp).isBetween(from, to));
    return (
        <div className="col-6">
            <div className="pl-3 pr-3">
                <small>
                    {window.translate('Rooms')}: {checkouts.length}
                </small>
            </div>
        </div>
    );
};

const AvailableRooms = ({ reservations, rooms }) => {
    const takenRoomInfoIds = [];
    for (const reservation of reservations) {
        if (reservation.statusEnum === 2 || reservation.statusEnum === 1) {
            takenRoomInfoIds.push(reservation.roomInfoId);
        }
    }

    const availableRooms = rooms.filter((r) => !takenRoomInfoIds.includes(r.id));

    return (
        <div className="col-md-6 p-1">
            <div className="border border-bottom-0 text-center bg-light uppercase text-truncate">
                <small>
                    <small>{window.translate('Available rooms')}</small>
                </small>
            </div>
            <div className="border text-center pt-2 pb-2">
                <small>{availableRooms.length}</small>
                <br />
                <small className="">{parseFloat((availableRooms.length * 100) / rooms.length).toFixed(2)}%</small>
            </div>
        </div>
    );
};

const RoomsTaken = ({ reservations, rooms }) => {
    const takenRoomInfoIds = [];
    for (const reservation of reservations) {
        if (reservation.statusEnum === 2 || reservation.statusEnum === 1) {
            takenRoomInfoIds.push(reservation.roomInfoId);
        }
    }

    const roomsTaken = rooms.filter((r) => takenRoomInfoIds.includes(r.id));

    return (
        <div className="col-md-6 p-1">
            <div className="border border-bottom-0 text-center bg-light uppercase text-truncate">
                <small>
                    <small>{window.translate('Occupied rooms')}</small>
                </small>
            </div>
            <div className="border text-center pt-2 pb-2">
                <small>{roomsTaken.length}</small>
                <br />
                <small className="">{parseFloat((roomsTaken.length * 100) / rooms.length).toFixed(2)}%</small>
            </div>
        </div>
    );
};

const IncomingsDeparturesHeader = () => {
    return (
        <div className=" text-center p-0  mt-1 d-flex">
            <div className="primary-bg-1 text-white col-6 border-radius-4">
                <small>
                    <b>{window.translate('Incomings')}</b>
                </small>
            </div>
            <div className="primary-bg-7 text-white col-6 border-radius-4">
                <small>
                    <b>{window.translate('Departures')}</b>
                </small>
            </div>
        </div>
    );
};

const HotelOccupancy = ({ from, to, reservations, rooms }) => {
    const numberOfRooms = rooms.length;
    const maxHotelOccupancyDays = moment(to).diff(moment(from), 'days') + 1;
    const maxCapacity = maxHotelOccupancyDays * numberOfRooms;

    const range = moment.range(from, to);

    const filteredReservations = reservations.filter((r) => r.statusEnum === 2 || r.statusEnum === 1);
    const daysTakenRangesArr = [];
    for (const reservation of filteredReservations) {
        const range1 = moment.range(moment(reservation.checkInTimestamp), moment(reservation.checkOutTimestamp));

        const res = range1.intersect(range);
        if (res) {
            daysTakenRangesArr.push(res);
        }
    }
    let daysTaken = 0;
    for (const dayTakenRanges of daysTakenRangesArr) {
        daysTaken += moment(dayTakenRanges.end).diff(moment(dayTakenRanges.start), 'days') + 1;
    }

    return (
        <React.Fragment>
            <div className=" text-center menu-item-selected border-radius-4 font-16" style={{padding:"4px"}}>
                <small>
                    <b>{window.translate('Potential hotel occupancy')}</b>
                </small>
            </div>
            <div className=" p-0">
                <div className="d-flex justify-content-between">
                    <div className="p-2">
                        <small>
                            {window.translate('Days')}: {daysTaken}
                        </small>
                    </div>
                    <div className="p-2">
                        <small>
                            {window.translate('Percentage')}: {parseFloat((daysTaken * 100) / maxCapacity).toFixed(2)}%
                        </small>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export { CheckoutsRooms, CheckoutsGuests, CheckinsGuests, CheckinsRooms, HotelOccupancy, IncomingsDeparturesHeader };
