import React, { Component } from 'react';
import _ from 'lodash';
import { formatDate, parseDate } from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { branch } from 'baobab-react/higher-order';
import { reservationEnums } from '../../data/reservationStatusEnums';
import FeatureFlag from '../../components/FeatureFlag';
import moment from 'moment/min/moment-with-locales';
import baobab from '../../data/state/index';
import { ReactComponent as ResList } from"../../assets/custom/icons/reports.svg";
import { ReactComponent as Calendar } from"../../assets/custom/icons/calendar.svg";

class FromToFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stayInDays: '',
            enableFilteringByDate: false,
            availableFrom: Date.now(),
            availableFrom2: '',
            availableTo: null, //Date.now() + 24*60*60*1000
        };
    }

    componentDidMount() {
        if (this.state.availableTo !== null) {
            this.calculateAvailableRoomsInRange();
        } else {
            if (this.props.filterOutRoomsById.length > 0) {
                this.props.setHideRoomByIdFilter([]);
            }
            //
        }
    }

    componentDidUpdate() {
        if (this.state.availableTo !== null) {
            this.calculateAvailableRoomsInRange();
        } else {
            if (this.props.filterOutRoomsById.length > 0) {
                this.props.setHideRoomByIdFilter([]);
            }
        }
    }

    calculateAvailableRoomsInRange() {
        if (this.props.reservations) {
            let roomIdWithReservationOverlapping = [];
            _.forEach(this.props.reservations, (r) => {
                let b = moment(this.state.availableFrom).toDate();
                b.setHours(22, 59, 59);
                const availableFrom_adjusted = b.getTime();

                let a = moment(this.state.availableTo).add(-1, 'd').toDate();
                a.setHours(22, 59, 59);
                const availableTo_adjusted = a.getTime();

                if (r.checkInTimestamp > availableFrom_adjusted && r.checkInTimestamp < availableTo_adjusted) {
                    //test checkin overlap
                    if (r.statusEnum !== reservationEnums.closed && r.statusEnum !== reservationEnums.canceled) {
                        roomIdWithReservationOverlapping.push(r.roomInfoId);
                    }
                }
                if (r.checkOutTimestamp > availableFrom_adjusted && r.checkOutTimestamp < availableTo_adjusted) {
                    //test checkout overlap
                    if (r.statusEnum !== reservationEnums.closed && r.statusEnum !== reservationEnums.canceled) {
                        roomIdWithReservationOverlapping.push(r.roomInfoId);
                    }
                }
                if (r.checkInTimestamp === availableFrom_adjusted && r.checkOutTimestamp === availableTo_adjusted) {
                    if (r.statusEnum !== reservationEnums.closed && r.statusEnum !== reservationEnums.canceled) {
                        roomIdWithReservationOverlapping.push(r.roomInfoId);
                    }
                }

                if (r.checkInTimestamp < availableFrom_adjusted && r.checkOutTimestamp > availableFrom_adjusted) {
                    if (r.statusEnum !== reservationEnums.closed && r.statusEnum !== reservationEnums.canceled) {
                        roomIdWithReservationOverlapping.push(r.roomInfoId);
                    }
                }
            });
            roomIdWithReservationOverlapping = _.uniq(roomIdWithReservationOverlapping);
            let hasChangedFromLastRender =
                this.props.filterOutRoomsById.join(',') !== roomIdWithReservationOverlapping.join(',');
            if (hasChangedFromLastRender) {
                if (this.props.setHideRoomByIdFilter) {
                    this.props.setHideRoomByIdFilter(roomIdWithReservationOverlapping);
                }
            }
        }
    }

    getHourAndMinuteObj(time) {
        let newTime = time ? time.split(':') : [12, 0]; //add default time if setting does not exist
        let hour = Number(newTime[0]);
        let minute = Number(newTime[1]);
        return { hour, minute };
    }

    setInputs(availableFrom, availableTo, stayInDays) {
        const { checkInTime = '14:00', checkOutTime = '10:00' } = this.props.otherSettings;
        let checkInHourAndMinute = this.getHourAndMinuteObj(checkInTime);
        let checkOutHourAndMinute = this.getHourAndMinuteObj(checkOutTime);
        const availableFromAdjusted = new Date(
            new Date(availableFrom).setHours(checkInHourAndMinute.hour, checkInHourAndMinute.minute, 0, 0)
        ).getTime();

        if (stayInDays === 0) {
            this.setState({ stayInDays: null, availableTo: null });
            this.props.setAvailableFromAndToTimestamps(availableFromAdjusted, null, null);
        } else {
            const availableToAdjusted = new Date(
                new Date(availableTo).setHours(checkOutHourAndMinute.hour, checkOutHourAndMinute.minute, 0, 0)
            ).getTime();

            this.setState({ availableFrom: availableFromAdjusted, stayInDays, availableTo: availableToAdjusted });
            this.props.setAvailableFromAndToTimestamps(availableFromAdjusted, availableToAdjusted, stayInDays);
        }
    }

    handleAvailableFromDatePick(pick) {
        const { stayInDays } = this.state;
        try {
            if (stayInDays === '') {
                let availableTo = pick.getTime() + 24 * 60 * 60 * 1000;
                this.setInputs(pick.getTime(), availableTo, 1);
            } else {
                let availableTo = pick.getTime() + 24 * 60 * 60 * 1000 * Number(stayInDays);
                this.setInputs(pick.getTime(), availableTo, this.state.stayInDays);
            }
        } catch (err) {
            console.warn(err);
        }
    }
    handleAvailableToDatePick(pick) {
        try {
            let stayInDays = Math.ceil((pick.getTime() - this.state.availableFrom) / (24 * 60 * 60 * 1000));
            this.setInputs(this.state.availableFrom, pick.getTime(), stayInDays);
        } catch (err) {
            console.warn(err);
        }
    }

    changeStayInDays(e) {
        let stayInDays = e.target.value;
        let availableTo = null;
        if (e.target.value !== '') {
            stayInDays = Number(e.target.value);
            availableTo = this.state.availableFrom + stayInDays * 24 * 60 * 60 * 1000;
        }
        this.setInputs(this.state.availableFrom, availableTo, stayInDays);
    }

    clearHandler() {
        this.setInputs(Date.now(), null, 0);
        this.props.toggleGroupReservations(false);
        this.props.setNumberOfBeds('');
        this.props.setNumberOfSpareBeds('')
    }

    toggleGroupReservations() {
        //this.props.toggleShowComponent("group_reservations")
        this.props.toggleGroupReservations();
        this.props.toggleSelectRoomForReservation(null);
    }

    makeGroupReservation() {
        const { availableFrom, availableTo, stayInDays } = this.state;
        const { selectedRoomsForReservations } = this.props;
        baobab.root.select('state', 'groupRes', 'groupReservation').set({});
        baobab.root.select('state', 'groupRes', 'reservations').set([]);

        this.props.history.push(
            `/reservations/${selectedRoomsForReservations[0]}/new?roomIds=${selectedRoomsForReservations.join(
                ','
            )}&from=${availableFrom}&to=${availableTo}&stayInDays=${stayInDays}`
        );
    }

    render() {
        let dayPicker_availableToStyles = { width: '110px' };
        let dayPicker_availableToStyles_wide = { width: '300px' };
        if (this.props.groupReservations && this.state.availableTo === null) {
            dayPicker_availableToStyles = { width: '110px', borderColor: 'rgb(220, 53, 69)' };
            dayPicker_availableToStyles_wide = { width: '300px', borderColor: 'rgb(220, 53, 69)' };
        }

        return (
            <div className="reservation-filters-wrapper">
                            <span>{window.translate('Availability')}:&ensp;</span>
                            <DayPickerInput /*display depending screen width*/
                                inputProps={{
                                    style: { width: '110px' },
                                    className: 'd-none d-sm-block form-control form-control-sm mt-1 mt-md-0',
                                    readOnly: true,
                                }}
                                format={'ll'}
                                formatDate={formatDate}
                                parseDate={parseDate}
                                value={this.state.availableFrom ? new Date(this.state.availableFrom) : ''}
                                onDayChange={this.handleAvailableFromDatePick.bind(this)}
                                dayPickerProps={{
                                    numberOfMonths: 1,
                                    showWeekNumbers: true,
                                    disabledDays: {
                                        before: new Date(),
                                    },
                                }}
                            />
                            <DayPickerInput /*display depending screen width*/
                                inputProps={{
                                    style: { width: '300px' },
                                    className: 'd-block d-sm-none form-control form-control-sm mt-1 mt-md-0',
                                    readOnly: true,
                                }}
                                format={'ll'}
                                formatDate={formatDate}
                                parseDate={parseDate}
                                value={this.state.availableFrom ? new Date(this.state.availableFrom) : ''}
                                onDayChange={this.handleAvailableFromDatePick.bind(this)}
                                dayPickerProps={{
                                    numberOfMonths: 1,
                                    showWeekNumbers: true,
                                    disabledDays: {
                                        before: new Date(),
                                    },
                                }}
                            />


                            <small>&ensp;{window.translate('-')}&ensp;</small>
                            <DayPickerInput /*display depending screen width*/
                                inputProps={{
                                    style: dayPicker_availableToStyles,
                                    className: 'd-none d-sm-block form-control form-control-sm mt-1 mt-md-0 mr-2',
                                    readOnly: true,
                                }}
                                format={'ll'}
                                formatDate={formatDate}
                                parseDate={parseDate}
                                value={
                                    this.state.availableTo
                                        ? new Date(this.state.availableTo)
                                        : this.state.availableFrom2 === ''
                                        ? ''
                                        : new Date(this.state.availableFrom2)
                                }
                                onDayChange={this.handleAvailableToDatePick.bind(this)}
                                dayPickerProps={{
                                    showWeekNumbers: true,
                                    numberOfMonths: 1,
                                    disabledDays: {
                                        before: this.state.availableFrom
                                            ? new Date(this.state.availableFrom)
                                            : Date.now(),
                                        after: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
                                    },
                                }}
                            />

                            <DayPickerInput /*display depending screen width*/
                                inputProps={{
                                    style: dayPicker_availableToStyles_wide,
                                    className: 'd-block d-sm-none form-control form-control-sm mt-1 mt-md-0 mr-2',
                                    readOnly: true,
                                }}
                                format={'ll'}
                                formatDate={formatDate}
                                parseDate={parseDate}
                                value={
                                    this.state.availableTo
                                        ? new Date(this.state.availableTo)
                                        : this.state.availableFrom2 === ''
                                        ? ''
                                        : new Date(this.state.availableFrom2)
                                }
                                onDayChange={this.handleAvailableToDatePick.bind(this)}
                                dayPickerProps={{
                                    showWeekNumbers: true,
                                    numberOfMonths: 1,
                                    disabledDays: {
                                        before: this.state.availableFrom
                                            ? new Date(this.state.availableFrom)
                                            : Date.now(),
                                        after: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
                                    },
                                }}
                            />

                            {/* <small>&ensp;{window.translate('')}&ensp;</small> */}
                            <input
                                style={{ width: '82px' }}
                                min={0}
                                value={this.state.stayInDays === null ? '' : this.state.stayInDays}
                                onChange={this.changeStayInDays.bind(this)}
                                className="input"
                                type="number"
                                placeholder={window.translate("Days")}
                                title={window.translate("Days")}
                            />
                            <input
                                onChange={this.props.setNumberOfBeds.bind(this)}
                                style={{ width: '82px' }}
                                className="input"
                                type="number"
                                title={window.translate('Beds')}
                                placeholder={window.translate('Beds')}
                                value={this.props.numberOfBeds}
                                min={0}
                            />

                            <input
                                onChange={this.props.setNumberOfSpareBeds.bind(this)}
                                style={{ width: '82px' }}
                                className="input"
                                type="number"
                                title={window.translate('Spare beds')}
                                placeholder={window.translate('Spare beds')}
                                value={this.props.numberOfSpareBeds}
                                min={0}
                            />

                            <FeatureFlag flag="groupInfos">
                                <div
                                    onClick={this.toggleGroupReservations.bind(this)}
                                    className={
                                        this.props.groupReservations
                                            ? 'button-light button-light-active'
                                            : 'button-light'
                                    }
                                >
                                    <i className="fa fa-plus pr-1" /> {window.translate('Group')}
                                </div>

                                {/* <div
                                    onClick={this.toggleGroupReservations.bind(this)}
                                    className={
                                        this.props.groupReservations
                                            ? 'button-light-w-icon '
                                            : 'button-light-w-icon '
                                    }
                                >
                                   <Konj/>
                                    <span>
                                    {window.translate('Group')}

                                    </span>
                                </div> */}

                                {this.props.groupReservations ? (
                                    <div
                                        onClick={this.makeGroupReservation.bind(this)}
                                        disabled={this.props.selectedRoomsForReservations.length <= 1}
                                        className="button-primary"
                                    >
                                        {window.translate('Make')}
                                    </div>
                                ) : null}
                            <div
                                onClick={this.clearHandler.bind(this)}
                                className="button-primary"
                            >
                                {window.translate("Clear")}
                            </div>
                            
                                {/* <div
                                    onClick={() => this.props.history.push('/groupReservations')}
                                    className="button-light"
                                >
                                    <i className="fa fa-list uppercase" /> {window.translate('Group View')}
                                </div> */}
                            </FeatureFlag>
                            {this.state.availableTo ? <div
                                    className="">
                                {/* <i
                                    value={this.props.showOccupiedRooms}
                                    className={
                                    this.props.showOccupiedRooms
                                        ? 'fa fa-check-square-o mt-md-0 mr-2 '
                                        : 'fa fa-square-o mt-md-0 mr-2 '
                                    }
                                    >
                                </i> */}
                                <div className='checkbox-with-label-wrapper'>
                                <input onChange={this.props.toogleOccupiedRooms.bind(this)} checked={this.props.showOccupiedRooms} id="occ-rooms-check" type="checkbox" className='checkbox-primary'></input>
                                <label htmlFor='occ-rooms-check'>{window.translate("Include occupied rooms")}</label>
                                </div>

                                

                            </div> : null}

                            <div
                                onClick={()=>this.props.setShowReservationsList(!this.props.showReservationsList)}
                                className="button-white-w-icon occupancy-icon"
                            >
                                {this.props.showReservationsList ? <ResList />  :  <Calendar /> }
                            </div>
            </div>
        );
    }
}

export default branch(
    {
        otherSettings: ['otherSettings'],
    },
    FromToFilter
);
