// import Tooltip from '../tooltip/tooltip';
import React, { useState, useEffect } from 'react';
import PermissionCheck from '../PermissionCheck';
import FeatureFlag from '../FeatureFlag';
import menuItems from './menuItems2';
import chevronUp from '../../assets/custom/icons/chevron_up.svg'
import chevronDown from '../../assets/custom/icons/chevron_down.svg'
import chevronLeft from '../../assets/custom/icons/chevron_left.svg'
import chevronRight from '../../assets/custom/icons/chevron_right.svg'
import { branch } from 'baobab-react/higher-order';
import {ReactComponent as Logo} from "../../assets/custom/logo/logo.svg";
// import logo from  "../../assets/custom/logo/logo.png";

 function SideMenu(props) {
    const [selectedMenuItems, setSelectedMenuItems] = useState(null)
    const [showMenu, setShowMenu] = useState(true)
    const [isMobileView, setIsMobileView] = useState(false)

    const toggleMenu = () => {
        setShowMenu(!showMenu)

        if(showMenu === false){
            selectItemOnRefresh()
        }else{
            setSelectedMenuItems(null)
        }
    }

    const selectMainMenuItem = (menuItem) => {

        if(menuItem.whenClicked){
            menuItem.whenClicked()
        }

        if (menuItem.link) {
            props.history.push(menuItem.link);
        }
        setSelectedMenuItems(selectedMenuItems === menuItem.id ? null : menuItem.id)
        let allSelectedMenuItems = selectedMenuItems ? [...selectedMenuItems] : []

        const index = allSelectedMenuItems.indexOf(menuItem);
        if (index > -1) { // only splice array when item is found
            allSelectedMenuItems.splice(index, 1); // 2nd parameter means remove one item only
        } else {
            allSelectedMenuItems.push(menuItem)
        }
        setSelectedMenuItems(allSelectedMenuItems)

        if(!showMenu){
            setShowMenu(true)
        }
    }

    const selectSubItem = (subItem) => {
        if(subItem.whenClicked){
            subItem.whenClicked()
        }

        if (subItem.link) {
            props.history.push(subItem.link);
        }
    }

    const selectItemOnRefresh = () => {
        Object.keys(menuItems).forEach((key) => {
            if (menuItems[key].items) {
                if(props.history.location.pathname === "/" && !props.history.location.search){
                    setSelectedMenuItems([menuItems["dashboard"]])
                }else{
                    menuItems[key].items.forEach((subItem) => {
                        if (subItem.link === props.history.location.pathname || subItem.link.substring(1) === props.history.location.search) {
                            // this.setState({ selectedMenuItems: menuItems[key].id });
                            setSelectedMenuItems([menuItems[key]])
                        }
                    });
                }
            }
        });
    }

    useEffect(() => {
        
        if (window.innerWidth <= 768) {
            setIsMobileView(true)
            setShowMenu(false)
            const target = document.querySelector('#myMainMenu')

            document.addEventListener('click', (event) => {
                const withinBoundaries = event.composedPath().includes(target)

                if (!withinBoundaries && showMenu) {
                    toggleMenu()
                }
            })

            document.addEventListener('touchmove', (event) => {
                const withinBoundariess = event.composedPath().includes(target)
                if (!withinBoundariess && showMenu) {
                    toggleMenu()
                }
            })
        }
        selectItemOnRefresh()

            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let menuHiddenClass = showMenu ? "" : "hidden"
    const sideMenuDiv = <div
        className={(isMobileView && showMenu ? ("position-absolute z-2000 "+menuHiddenClass) : ("position-relative "+menuHiddenClass))}
        id="myMainMenu"
    >
         <div className="side-menu-logo">
            <Logo onClick={() => {props.history.push("/")}} ></Logo>
            {/* <img style={{width:"160px"}} src={logo} alt="" /> */}
            {/* <h3>{props?.hotelName?.toUpperCase()}</h3> */}
             <img onClick={() => {
                toggleMenu();
            }} src={showMenu ? chevronLeft : chevronRight} alt="" /></div>
        <div
            style={{ zIndex: 20, right: showMenu ? '-3px' : '-10px', top: '20px' }}
            className="position-absolute"
        >

        </div>
        <div
            id="animatethis"
        >
            {Object.keys(menuItems).map((key, ind) => {
                let isItemSelected = false;
                if (menuItems[key].link === props.history.location.pathname) {
                    isItemSelected = true;
                }
                if(props.history.location?.search?.length > 0 ){
                    if (menuItems[key].link !== props.history.location?.search?.length) {
                        isItemSelected = false
                    }
                }   

                const items = [];

                if (menuItems[key]['items']) {
                    menuItems[key]['items'].forEach((subItem, ind2) => {
                        let isSubitemSelected = subItem.link === props.history.location.pathname || subItem.link.substring(1) === props.history.location.search;
                        if(isSubitemSelected && !showMenu){
                            isItemSelected = true
                        }
                        items.push(
                            <PermissionAndFFlagCHeck item={subItem} key={ind2}>
                                <div className={isSubitemSelected ? "menu-item-selected" : ""}
                                    onClick={() => {
                                        selectSubItem(subItem);
                                    }}
                                >
                                    <span className='item-label'
                                    >
                                        {window.translate(subItem.itemLabel)}
                                    </span>
                                </div>
                            </PermissionAndFFlagCHeck>
                        );
                    });
                }

                return (
                    <PermissionAndFFlagCHeck item={menuItems[key]} key={ind}>
                        <div className='side-menu-category'>
                            <div
                                key={menuItems[key]}
                                onClick={() => {
                                    selectMainMenuItem(menuItems[key]);
                                }}
                                className="category-item"
                            >
                                <div className={isItemSelected ? "menu-item-selected" : ""}>
                                    {/* <img src={menuItems[key].icon}></img> */}
                                    <span>
                                    {menuItems[key].icon}
                                    {' '}
                                    
                                    <span className='item-label'>
                                        {window.translate(menuItems[key].fullLabel)}
                                    </span>
                                    </span>
                                   <span>
                                   {menuItems[key]['items'] ? <img alt="" src={selectedMenuItems?.indexOf(menuItems[key]) > -1 ? chevronUp : chevronDown}></img> : null}
                                   </span>
                                </div>
                            </div>{' '}
                            <div className="side-menu-subitem-list ">
                                {selectedMenuItems?.indexOf(menuItems[key]) > -1 ? (
                                    items
                                ) : (
                                    <i></i>
                                )}{' '}
                            </div>
                        </div>
                    </PermissionAndFFlagCHeck>
                );
            })}
        </div>
        <div className='side-menu-user-data-wrapper'>
            <div className='horizontal-line'>
            </div>
            <div className='side-menu-user-data-center-wrapper'>
            <div className='side-menu-user-data-center'>
                <div >
                <span className='circle' >{props?.loggedUser?.email.substring(0,1).toUpperCase()}</span>
                <div className='flex-column hide-on-menu-hidden'>
                    <div>{props?.hotelName?.toUpperCase()}</div>
                <div><span onClick={()=>{props.history.push("/userProfile")}} className='user-email-data pointer'>{props?.loggedUser?.email}</span></div>
            
            </div>
                   
                    {/* <i className='fa fa-home'></i> */}
                    {/* <img alt="" className='user-more-actions' src={chevronDown}></img> */}
                </div>
            </div>
          

            </div>
        </div>
    </div>
    if (isMobileView && showMenu) {
        return (
            <div className='d-flex h-100 position-relative' style={{ marginRight: "5px", width: "15px", borderRight: "1px solid white" }}>{sideMenuDiv}</div>
        )
    } else {
        return sideMenuDiv
    }
}

function generateFflagRecursive(arrayOfFlags, menuItemsToShow) {
    if (arrayOfFlags.length > 0) {
        let shiftedArrayOfFlags = arrayOfFlags.slice(1);
        return (
            <FeatureFlag flag={arrayOfFlags[0]} key={arrayOfFlags[0]}>
                {generateFflagRecursive(shiftedArrayOfFlags, menuItemsToShow)}
                {arrayOfFlags.length === 1 ? menuItemsToShow : null}
            </FeatureFlag>
        );
    }
}

function PermissionAndFFlagCHeck(props) {

    let featureFlaggedItem = null
    if (props.item.featureFlags?.length > 0) {
        featureFlaggedItem = generateFflagRecursive(props.item.featureFlags, props.children)
    } else {
        featureFlaggedItem = props.children
    }

    let permissionCheckedItem = null
    if (props.item.permissionCheckName) {
        permissionCheckedItem = <PermissionCheck name={props.item.permissionCheckName}>
            {featureFlaggedItem}
        </PermissionCheck>
    } else {
        permissionCheckedItem = featureFlaggedItem
    }
    return (
        permissionCheckedItem
    )
}

export default branch(
    {
        loggedUser: ['authTokenInfo', 'user'],
    },
    SideMenu
);