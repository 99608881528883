import React, { Component } from 'react';
import _ from 'lodash';
import { reservationStatusColorsHEX, reservationStatusEnumsDesc } from '../../data/reservationStatusEnums';

class ReservationStatusLegend extends Component {
    clickHandler(i, e) {
        e.preventDefault();
        this.props.toggleHideReservationStatusEnums(i);
    }
    render() {
        let items = [];
        for (let i = 0; i <= 5; i++) {
            items.push(
                <div className="" key={'legend' + i}>
                    <label onClick={this.clickHandler.bind(this, i)} className="checkbox-container">
                        {reservationStatusEnumsDesc[i]}
                        <input
                            onChange={() => {}}
                            checked={!_.includes(this.props.hideReservationStatusEnums, i)}
                            type="checkbox"
                        />
                        <span style={{ backgroundColor: reservationStatusColorsHEX[i] }} className="checkmark"></span>
                    </label>
                </div>
            );
        }
        return (
            <div className='reservation-status-checkboxes'>
                {items}
            </div>
        );
    }
}

export default ReservationStatusLegend;
