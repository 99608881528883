import Baobab from 'baobab';
import SRCAlarmsAndAlertsBaobabTemplate from '../SRCToastPositionTemplates.js';
import monkeys from './monkey';
import moment from 'moment';
import idMaps from './idmaps';
import tags from './tags';

var initialStateObject = {
    pageTitle: null,
    monkeys,

    pkeyMap: {},
    SRCEnums: {}, //contains all relevant SRC enumerators
    model: {
        //WARNING: Do not supply the same models in whole and partial part. baobabFunnel would broke
        RoomInfo: [],
        SmartRoomController: [],
        SRCProxy: [],
        DoorAccessPoint: [],
        WhiteList: [],
        Reservation: [],
        Tax: [],
        PosItem: [],
        AccommodationPriceList: [],
        Currency: [],
        RoomAttribute: [],
        SosResponse: [],
        CleaningStatus: [],
        RoomEventTicket: [], //getActiveRoomEventTickets
        SosGroup: [],
        RequiredVisaMap: [],
        PosTerminal: [],
        Customer: [], //used rarely - only for condo mode for now
        CondoOwner: [],
        DocumentScan: [],
        FeatureFlag: [],
        CleaningStatusHeader: [], // include CleaningStatuses, RoomInfo, Maid1, Maid2, Maid3 - initialy get only today
        Role: [],
        PosDisplayConfig:[],
        ChannelManagerMap:[],
    },
    map: {
        ...idMaps,
    },
    tag: {
        ...tags,
    },
    modalData: {
        display: false,
        width: '', //modal-sm || modal-lg || modal-xl
        header: 'Modal window title',
        // content: null, //it may be an react class instance
        showBottomCloseButton: false,
        params: {}, //modal params used optionaly by modal specific modal internals
    },
    authTokenInfo: null, //it is an object containing token and user informations. it is obtained on logon!
    modelForms: {},
    SRCAlarmsAndAlerts: SRCAlarmsAndAlertsBaobabTemplate,
    socketConnection: {
        showConnectingScreen: true,
        connectingMessage: 'ESTABLISHING SERVER CONNECTION',
        heading: '...',
    },
    uiStates: {
        accommodationPriceListStack: {
            timelineYearsCount: 2, //or 2 - better always 1
            startFromYear: new Date().getFullYear(), //new Date(`${new Date().getFullYear()}`) //current or the next one....
        },
        dashboard: {
            showFromToFilter: false,
            showComponents: ['timeline', 'sidebar_dailyInfo'],
            tagsFilter: [],
        },
        reservationAdministration: {
            from: moment().add(-1, 'month').valueOf(),
            to: moment().add(1, 'month').valueOf(),
        },
        columnsNumber: 2,
    },
    about: {
        adminUsersCount: 0, //MORA BITI 0 S RAZLOGOM. Zbog prve instalacije!!!!
    },
    otherSettings: null,
    IOTatewayIntegration: null,
    locale: null,
    defaultCurrency: null,
    hiddenDocumentColumns: null,
    companyInfo: null,
    licence: null,
    alarmInterpreter: null,
    fiscal: {
        softwareFiscal: null,
        fiscalGeneralSettings: null,
        // registerENU: null,
    },
    version: {
        version: 'xxx',
        DOCKER_ENV: false,
    },
    state: {
        userPermissions: {
            models: null,
            features: null,
            dashboardRoute: '/',
            fixedRoute: '',
            externalDashboardRoute: "",
            externalFixedRoute: "",
        },
        globalLoading: 0,
        selectedPosTerminalId: null,
        posTerminal: {
            //samo za potrebe sučelja pos terminala
            invoice: {}, //ovo je povratna informacija sa backend invoice controlera
            invoiceItems: [], //ovo je povratna ifnormacija sa backend invoice controlera
            filteredItems: null, //ovdje ću stavljati rezultate filtriranje artiakala koje trebam priakzati u glavnom prozoru
            activeItemsSelection: [], //ovdje je lista trenutno odabranih stavki od kojih se sastavlja pending račun (key value par) key je posItemId, value je količina
            previousInvoices: [], //lista prethodno izdanih računa za taj terminal
        },
        checkoutInvoice: {
            //za izradu računa - samo jedan checkout na jednom računalu
            invoicePreview: {
                invoice: {},
                invoiceItems: [],
            },
            invoice: {},
            invoiceItems: [],
            advanceInvoices: [], //vezane uz rezervaciju na checkoutu
            proformaInvoices: [], //vezane uz rezervaciju na checkoutu
            stornoInvoices: [], //vezane uz rezervaciju na checkoutu
            groupReservationsArray: [],
            reservation: null,
            availableForAdvancePayment: 0,
            posTerminalInvoices: [],
            checkoutDateMismatchs: [],
            genuineInvoices: [], //vezane uz rezervaciju na checkoutu
            subsequentConsumption: false, // naknadna potrošnja
            stayoverOverlap: { overlapReservations: [], option: null, type: null },
            minibarInvoices: [],
            invoiceStornoPreview: null,
            accommodationInvoice: null,
        },
        groupRes: {
            //za izradu grupnih rezervacija u formi grupnih rezervacija
            groupReservation: {},
            reservations: [],
        },
        reservationForm: {
            reservationOnLoad: null, //comparison checking
            reservation: null,
            customer: null,
            company: null,
            reservationGuests: [], //customers are here
            reservationGuestReferences: [], //visa data are here
            invalidVisaIds: [],
        },
        reservationForm2: {
            activeReservationIndex: 0,
            reservations: [],
            groupReservation: {},
            invalidVisaIds: [],
            reloadUuid: null,
            selfCheckInInfo: null,
            selectedReservationIndex: 0,
        },
        invoicePresenter: {
            invoice: {},
            refInvoice: null,
            invoicePreview: {
                invoice: {},
                invoiceItems: [],
            },
            groupReservationsArray: [],
            stornoInvoices: [],
            groupInvoiceDetails: {},
            padding: 1,
        },
        generalSettings: {
            //za settingse
        },
        householdPlanForSelectedMaid: {},
        permissions: [],
        rolePermissions: [],
    },
    keycloak: {
        authenticated: false,
    },
    /*
  locale:"hr",
  defaultCurrency:"EUR",
  companyInfo:{ //OVO SU VLASNIČKI MATIČNI PODACI!!!!!!
     name:"Afrodita d.o.o.",
     hotelName:"some hotel",
     address:"Stjepana Radića 5",
     postOfficeNumber:"88440",
     town: "Prozor",
     idNumber:"4227712341233",
     notes:""
  }*/
};

var cachedStateObject = null;

try {
    cachedStateObject =
        window.localStorage.baobab !== undefined ? JSON.parse(window.localStorage.baobab) : initialStateObject;
} catch (err) {
    console.warn(err);
}

initialStateObject.authTokenInfo = cachedStateObject.authTokenInfo;
initialStateObject.locale = cachedStateObject.locale;
initialStateObject.licence = cachedStateObject.licence;
initialStateObject.keycloak = cachedStateObject.keycloak;

var tree = new Baobab(initialStateObject, { immutable: true });

const saveCachedBaobabData = (baobabTree) => {
    if (typeof Storage !== 'undefined') {
        if (baobabTree._data) {
            window.localStorage.baobab = JSON.stringify(baobabTree._data);
        } else {
            window.localStorage.baobab = JSON.stringify(baobabTree);
        }
    } else {
        //console.log("Sorry no WEB storage support.");
        // Sorry! No Web Storage support..
    }
};
window.baobab = tree;
export default tree;

export { saveCachedBaobabData, tree };
