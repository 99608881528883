// import sideMenuItems from './sideMenuItems'
import React  from "react";
import {ReactComponent as Home} from "../../assets/custom/icons/home.svg";
// import {ReactComponent as Status} from "../../assets/custom/icons/status.svg";
import {ReactComponent as PropertyManagement} from "../../assets/custom/icons/propertyManagement.svg";
import {ReactComponent as Bms} from "../../assets/custom/icons/building.svg";
// import {ReactComponent as Household} from "../../assets/custom/icons/broom.svg";
// import {ReactComponent as SituationCenter} from "../../assets/custom/icons/bell.svg";
import {ReactComponent as Reports} from "../../assets/custom/icons/reports.svg";
import {ReactComponent as Pos} from "../../assets/custom/icons/pos.svg";
import {ReactComponent as RoomsAndHardware} from "../../assets/custom/icons/hardware.svg";
// import {ReactComponent as PriceLists} from "../../assets/custom/icons/price.svg";
// import {ReactComponent as DataFolders} from "../../assets/custom/icons/folder.svg";
import {ReactComponent as Database} from "../../assets/custom/icons/database.svg";
import {ReactComponent as AdvancedSettings} from "../../assets/custom/icons/settings_cog.svg";
import {ReactComponent as BusinessConclusion} from "../../assets/custom/icons/tick_inside_circle.svg";
import {ReactComponent as Logout} from "../../assets/custom/icons/sign_out.svg";

import baobabTree from "../../data/state"
import _ from "lodash"
import { logout } from '../../data/actions';

function logMeOut () {
    logout()
}

function toggleMainViewButtons (mainViewType) {
    
    let temp = _.clone(baobabTree.root.select(['uiStates', 'dashboard', 'showComponents']).get());
    if (_.includes(temp, 'timeline')) {
        _.remove(temp, (t) => t === 'timeline');
    }
    if (_.includes(temp, 'tiles')) {
        _.remove(temp, (t) => t === 'tiles');
    }
    if (_.includes(temp, 'activity')) {
        _.remove(temp, (t) => t === 'activity');
    }
    if (_.includes(temp, 'journal')) {
        _.remove(temp, (t) => t === 'journal');
    }
    if (_.includes(temp, 'occupancy')) {
        _.remove(temp, (t) => t === 'occupancy');

    }
    if (_.includes(temp, 'jourWithAct')) {
        _.remove(temp, (t) => t === 'jourWithAct');

    }
// --   OVO OVDJE PROMIJENITI JOPA_-----
    if(mainViewType === "occupancy"){
        temp.push("sidebar_dailyInfo")
    }
    
    temp.push(mainViewType);
    baobabTree.root.select(['uiStates', 'dashboard', 'showComponents']).set(temp);
    // setTimeout(() => {
    //     clearReservationsFilterFields();
    // }, 10);
}

export default {
    home: {
        id:"home",
        permissionCheckName: "",
        featureFlags: [],
        fullLabel: 'Home',
        label: 'Home',
        icon: <Home/>,
        items:null,
        link:"/",
        whenClicked:()=>{toggleMainViewButtons("timeline")},
        description:"Create dynamic accommodation price list and bind them to rooms. Beside accommodation, you can create price lists for minibar, goods and services."
    },

    dashboard: {
        id:"dashboard",
        permissionCheckName: "dashboard",
        featureFlags: [],
        fullLabel: 'Property Management',
        label: 'Rooms',
        icon: <PropertyManagement/>,
        items: [
            { itemLabel: 'Reservations', link: '/?reservations', permissionCheckName:"roomsPanel_rooms", featureFlags:null, whenClicked:()=>{toggleMainViewButtons("timeline")} },
            { itemLabel: 'Group Reservations', link: '/groupReservations', permissionCheckName:"roomsPanel_rooms", featureFlags:null, whenClicked:()=>{toggleMainViewButtons("timeline")} },
            { itemLabel: 'Journal', link: '/?journal', permissionCheckName:"pricePanel_taxes", featureFlags:null, whenClicked:()=>{toggleMainViewButtons("journal")} },
            { itemLabel: 'Rooms', link:  '/?rooms', permissionCheckName:"pricePanel_accomodation", featureFlags:null, whenClicked:()=>{toggleMainViewButtons("tiles")} },
            { itemLabel: 'Activity', link:  '/?activity', permissionCheckName:"pricePanel_currencies", featureFlags:null, whenClicked:()=>{toggleMainViewButtons("activity")} },
            { itemLabel: 'Occupancy', link:  '/?occupancy', permissionCheckName:"roomsPanel_rooms", featureFlags:null, whenClicked:()=>{toggleMainViewButtons("occupancy")} },
            { itemLabel: 'Household', link:  '/household', permissionCheckName:"household", featureFlags:null, whenClicked:()=>{toggleMainViewButtons("household")} },
            { itemLabel: 'Situation center', link:  '/allRoomEvents', permissionCheckName:"situationCenter", featureFlags:null, whenClicked:()=>{toggleMainViewButtons("situationCenter")} },

            // { itemLabel: 'POS goods', link: '/priceLists/posItem', permissionCheckName:"pricePanel_posGoods", featureFlags:["minibar"] },
        ],
        // link:"/",
        description:"Create or update hotel rooms informations. Declare SRC proxyes and bind their controllers with designated room. Create predefined SRC configurations and download them to selected rooms."
    },
    
    dataFolders: {
        id:"dataFolders",
        permissionCheckName: "foldersPanel",
        featureFlags: ["dataFolders"],
        fullLabel: 'Database',
        label: 'Data',
        icon: <Database/>,
        items: [
            { itemLabel: 'Users', link: '/users', permissionCheckName:"advancedSettingsPanel_users", featureFlags:null },
            { itemLabel: 'Customers', link: '/customers', permissionCheckName:"foldersPanel_customers", featureFlags:null },
            { itemLabel: 'Companies', link: '/companies', permissionCheckName:"foldersPanel_companies", featureFlags:null },
            { itemLabel: 'Reservations', link: '/reservations', permissionCheckName:"foldersPanel_reservations", featureFlags:null },
            // { itemLabel: 'POS goods', link: '/priceLists/posItem', permissionCheckName:"pricePanel_posGoods", featureFlags:["minibar"] },
            { itemLabel: 'Rooms', link: '/roomsAdministration', permissionCheckName:"roomsPanel_rooms", featureFlags:null },
            { itemLabel: 'Taxes', link: '/priceLists/tax', permissionCheckName:"pricePanel_taxes", featureFlags:null },
            { itemLabel: 'Accommodation', link: '/priceLists/accommodation', permissionCheckName:"pricePanel_accomodation", featureFlags:null },
            { itemLabel: 'Currencies', link: '/priceLists/currencies', permissionCheckName:"pricePanel_currencies", featureFlags:null },
            { itemLabel: 'Pricelist lookup', link: '/priceLists/priceListLookup', permissionCheckName:"pricePanel_pricelistLookup", featureFlags:null },
            { itemLabel: 'POS goods', link: '/priceLists/posItem', permissionCheckName:"posConfigurationPanel_posGoods", featureFlags:["minibar"] },
            {
                itemLabel: 'Tables Layout',
                link: '/tablesLayout',
                permissionCheckName: 'posConfigurationPanel_tablesLayout',
                featureFlags: ['posDevelopment'],
            },
            {
                itemLabel: 'POS Display Config',
                link: '/posDisplayConfig',
                permissionCheckName: 'posConfigurationPanel_displayConfigInfo',
                featureFlags: ['posDevelopment'],
            },
            {
                itemLabel: 'POS Terminal',
                link: '/posTerminal',
                permissionCheckName: 'posConfigurationPanel_posTerminal',
                featureFlags: ['posDevelopment'],
            },
            ],
        description:"Browse and create customers and companies required by reservation system."
    },

    goodsManagement: {
        id:"goodsManagement",
        permissionCheckName: "goodsManagementPanel",
        featureFlags: [],
        fullLabel: 'Goods management',
        label: 'Goods management',
        icon: <Pos />,
        items: [  
            { itemLabel: 'SKU Items', link: '/goodsManagement/skuItems', permissionCheckName:"goodsManagementPanel_stockKeepingUnits", featureFlags:["goodsManagement" ]},
            { itemLabel: 'POS goods', link: '/priceLists/posItem', permissionCheckName:"pricePanel_posGoods", featureFlags:["minibar"] },
            { itemLabel: 'Warehouses', link: '/goodsManagement/warehouses', permissionCheckName:"goodsManagementPanel_warehouses", featureFlags:["goodsManagement"] },
            { itemLabel: 'Documents', link: '/goodsManagement/documents', permissionCheckName:"goodsManagementPanel_documents", featureFlags:["goodsManagement"] },
            { itemLabel: 'Entry calculation', link: '/goodsManagement/entryCalculation', permissionCheckName:"goodsManagement_entryCalculation", featureFlags:["goodsManagement"] },
        ],
        description:"All about goods management process."
    },

    roomsAndHardware: {
        id:"roomsAndHardware",
        permissionCheckName: "roomsPanel",
        featureFlags: [],
        fullLabel: 'Hardware',
        label: 'Rooms',
        icon: <RoomsAndHardware/>,
        items: [
            { itemLabel: 'SOS groups', link: '/sosGroups', permissionCheckName:"roomsPanel_sosGroups", featureFlags:["sosGroupsAndLogs"] },
            { itemLabel: 'Controllers', link: '/srcProxies', permissionCheckName:"roomsPanel_controllers", featureFlags:null },
            { itemLabel: 'SRC templates', link: '/srcTemplates', permissionCheckName:"roomsPanel_SRCTemplates", featureFlags:["hardware"] },
            { itemLabel: 'Alarm settings', link: '/alarmAndRoomModeInterpreter', permissionCheckName:"roomsPanel_AlarmAndRoomMode", featureFlags: null },
            { itemLabel: 'Doors & rules', link: '/accessControl', permissionCheckName:"accessControlPanel_doorsAndRules", featureFlags:null },
            { itemLabel: 'Whitelists', link: '/whiteLists', permissionCheckName:"accessControlPanel_whitelists", featureFlags:null },
            { itemLabel: 'Document Scanners', link: '/docScannerDevices', permissionCheckName:"accessControlPanel_documentScannerDevices", featureFlags:null },
        ],
        description:"Create or update hotel rooms informations. Declare SRC proxyes and bind their controllers with designated room. Create predefined SRC configurations and download them to selected rooms."
    },

    bms: {
        id:"bms",
        permissionCheckName: "gatewayIntegration",
        featureFlags: ["BMS"],
        fullLabel: 'BMS',
        label: 'Price lists',
        icon: <Bms/>,
        items:null,
        link:"/bms",
        description:"Create dynamic accommodation price list and bind them to rooms. Beside accommodation, you can create price lists for minibar, goods and services."
    },

    reports: {
        id:"reports",
        permissionCheckName: "reports",
        featureFlags: [],
        fullLabel: 'Reports',
        label: 'Reports',
        icon: <Reports/>,
        items:null,
        link:"/menu/reports",
        description:"Setup language, company informations, currency and modems configuration. Manage API keys."
    },

    businessConclusion: {
        id:"businessConclusion",
        permissionCheckName: "businessConclusionPanel",
        featureFlags: [],
        fullLabel: 'Business conclusion',
        label: 'Conclusion',
        icon: <BusinessConclusion/>,
        items: [
            { itemLabel: 'Cash Registar', link: '/cashRegistars', permissionCheckName:"businessConclusionPanel_cashRegistar", featureFlags:["cashRegistar"] },
            { itemLabel: 'Reservations conclusion', link: '/reservationsConclusion', permissionCheckName:"businessConclusionPanel_reservationConclusion", featureFlags:["development" ]},
            { itemLabel: 'POS Terminal', link: '/posTerminal', permissionCheckName:"businessConclusionPanel_posTerminal", featureFlags:["simplePosTerminal" ]}
        ],
        description:"All business conclusions in one place."
    },
    
    advancedSettings: {
        id:"advancedSettings",
        permissionCheckName: "advancedSettingsPanel",
        featureFlags: [],
        fullLabel: 'Config',
        label: 'Config',
        icon: <AdvancedSettings/>,
        items: [
            { itemLabel: 'Settings', link: '/generalSettings', permissionCheckName:"advancedSettingsPanel_settings", featureFlags:null },
            { itemLabel: 'Notification service settings', link: '/notificationTemplateSettings', permissionCheckName:"advancedSettingsPanel_notificationServiceSettings", featureFlags:["outgoingNotificationChannel"] },
            { itemLabel: 'Visa Info', link: '/visaInfo', permissionCheckName:"advancedSettingsPanel_visaInfo", featureFlags:null },
            { itemLabel: 'Self check in administration', link: '/selfCheckInAdministrative', permissionCheckName:"advancedSettingsPanel_selfCheckInAdministration", featureFlags:null },
            { itemLabel: 'Channel manager', link: '/channelManagerMapping/wuBook', permissionCheckName:"advancedSettingsPanel_channelManager", featureFlags:["channelManager"]},
            { itemLabel: 'Licence', link: '/licence', permissionCheckName:"licencePanel_licence", featureFlags:null },
            { itemLabel: 'About', link: '/about', permissionCheckName:"advancedSettingsPanel", featureFlags:null },
        ],
        description:"Setup language, company informations, currency and modems configuration. Manage API keys."
    },

    logout: {
        id:"logout",
        permissionCheckName: "",
        featureFlags: [],
        fullLabel: 'Log out',
        label: 'Log out',
        icon: <Logout/>,
        whenClicked:()=>{logMeOut()},
        // items: [],
        description:"All business conclusions in one place."
    },
};