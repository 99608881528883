import baobabTree, { saveCachedBaobabData } from '../state';
import { multipageLoad, rest } from '../restWrapper';
import _ from 'lodash';
import pubsub from '../pubsub';
import * as Sentry from '@sentry/react';
import socketClient from '@data/sockets';
import { isFeatureEnabled } from '@components/FeatureFlag';
import { reloadReservations } from './loaders';

pubsub.on('loginSuccess', async (email) => {
    await loadStartupModelData(socketClient);
    Sentry.configureScope((scope) => {
        scope.setUser({ email: email });
    });
});

const reloadInitialData = async () => {
    const user = baobabTree.root.select('authTokenInfo', 'user').get();
    const permissions = await rest('/getLoggedUserPerms');
    baobabTree.root.select(['state', 'userPermissions']).set(permissions);

    const pkeyMap = await rest('/pkeyMap');
    baobabTree.root.select('pkeyMap').set(pkeyMap);

    const licence = await rest('/licence');
    baobabTree.root.select('licence').set(licence);

    const FeatureFlag = await rest('/FeatureFlag?rows=300&page=0');
    baobabTree.root.select(['model', 'FeatureFlag']).set(FeatureFlag);

    resetCompanyNameFromLicenceInformation(licence);

    if (permissions?.readModels.includes('RoomInfo')) {
        const roomInfo = await rest('/RoomInfo?rows=1000&page=0');
        baobabTree.root.select(['model', 'RoomInfo']).set(roomInfo);
    }

    if (permissions?.readModels.includes('DoorAccessPoint')) {
        const doorAccessPoint = await rest('/DoorAccessPoint?rows=1000&page=0');
        baobabTree.root.select(['model', 'DoorAccessPoint']).set(doorAccessPoint);
    }

    if (permissions?.readModels.includes('RoomAttribute')) {
        const roomAttributes = await rest('/RoomAttribute?rows=1000&page=0');
        baobabTree.root.select(['model', 'RoomAttribute']).set(roomAttributes);
    }

    if (permissions?.readModels.includes('SmartRoomController')) {
        const SmartRoomController = await rest('/SmartRoomController?rows=1000&page=0');
        baobabTree.root.select(['model', 'SmartRoomController']).set(SmartRoomController);
    }

    if (permissions?.readModels.includes('WhiteList')) {
        const whitelist = await rest('/WhiteList?rows=1000&page=0');
        baobabTree.root.select(['model', 'WhiteList']).set(whitelist);
    }

    if (permissions?.readModels.includes('Tax')) {
        const tax = await rest('/Tax?rows=1000&page=0');
        baobabTree.root.select(['model', 'Tax']).set(tax);
    }

    if (permissions?.readModels.includes('Currency')) {
        const currency = await rest('/Currency?rows=1000&page=0');
        baobabTree.root.select(['model', 'Currency']).set(currency);
    }

    if (permissions?.readModels.includes('PosItem')) {
        const posItem = await rest("/getPosItems?page=0&rows=1000", "GET")
        baobabTree.root.select(['model', 'PosItem']).set(posItem);
    }

    if (permissions?.readModels.includes('SRCProxy')) {
        const SRCProxy = await rest('/SRCProxy?rows=1000&page=0');
        baobabTree.root.select(['model', 'SRCProxy']).set(SRCProxy);
    }

    if (permissions?.readModels.includes('AccommodationPriceList')) {
        const AccommodationPriceList = await rest('/AccommodationPriceList?rows=1000&page=0');
        baobabTree.root.select(['model', 'AccommodationPriceList']).set(AccommodationPriceList);
    }

    if (permissions?.readModels.includes('RoomEventTicket')) {
        const RoomEventTicket = await rest('/getActiveRoomEventTickets');
        baobabTree.root.select(['model', 'RoomEventTicket']).set(RoomEventTicket);
    }

    if (permissions?.readModels.includes('SosGroup')) {
        const SosGroup = await rest('SosGroup?rows=5000&page=0');
        baobabTree.root.select(['model', 'SosGroup']).set(SosGroup);
    }

    if (permissions?.readModels.includes('SosResponse')) {
        const activeSosResponses = await rest('/getActiveSosResponses');
        baobabTree.root.select(['model', 'SosResponse']).set(activeSosResponses);
    }

    if (permissions?.readModels.includes('RequiredVisaMap')) {
        const requiredVisaMap = await rest('/RequiredVisaMap');
        baobabTree.root.select(['model', 'RequiredVisaMap']).set(requiredVisaMap);
    }

    if (permissions?.readModels.includes('PosTerminal')) {
        const posTerminal = await rest('/PosTerminal?rows=1000&page=0');
        baobabTree.root.select(['model', 'PosTerminal']).set(posTerminal);
    }

    if (permissions?.readModels.includes('DocumentScan')) {
        const docScan = await rest('/DocumentScan?rows=50&page=0&by=id&order=desc');
        baobabTree.root.select(['model', 'DocumentScan']).set(docScan);
    }

    if (permissions?.readModels.includes('DocumentScannerDevice')) {
        const docScannerDevices = await rest('/DocumentScannerDevice?rows=50&page=0&by=uuid&order=desc');
        baobabTree.root.select(['model', 'DocumentScannerDevice']).set(docScannerDevices);
    }

    if (permissions?.readModels.includes('CashRegistar')) {
        const cashRegistars = await rest('/CashRegistar?rows=50&page=0');
        baobabTree.root.select(['model', 'CashRegistar']).set(cashRegistars);
    }
    
    if (permissions?.readModels.includes('PosDisplayConfig')) {
        const posDisplayConfig = await rest('/PosDisplayConfig?rows=1000&page=0');
        baobabTree.root.select(['model', 'PosDisplayConfig']).set(posDisplayConfig);
    }

    if (permissions?.readModels.includes('Warehouse')) {
        const warehouses = await rest('/Warehouse?rows=500&page=0');
        baobabTree.root.select(['model', 'Warehouse']).set(warehouses);
    }

    if (
        permissions?.readModels.includes('CleaningStatusHeader') &&
        permissions?.readModels.includes('CleaningStatus')
    ) {
        const presentDayCleaningStatusHeader = await rest('/getPresentDayCleaningStatusHeader');
        if (presentDayCleaningStatusHeader) {
            const newCleaningStatusHeader = { ...presentDayCleaningStatusHeader };
            const presentDayCleaningStatuses = presentDayCleaningStatusHeader.CleaningStatuses;
            delete newCleaningStatusHeader.CleaningStatuses;
            baobabTree.root.select(['model', 'CleaningStatusHeader']).set([newCleaningStatusHeader]);
            baobabTree.root.select(['model', 'CleaningStatus']).set(presentDayCleaningStatuses);
        }
    }
    if (permissions?.readModels.includes('ChannelManagerMap') && isFeatureEnabled('channelManager')) {
        const channelManagement = await rest('/getGeneralSettingByKey/channelManagement');
        const parsedChm = channelManagement?.params ? JSON.parse(channelManagement.params) : null;
        if (parsedChm && parsedChm['wuBook']?.enabled) {
            const chm = await rest('/ChannelManagerMap?rows=1000&page=0');

            let _chmProviderRooms = chm.filter(
                (c) => c.model === 'RoomInfo' && c.remoteId && c.provider === 'wuBook' && !c.parentId
            );

            if (_chmProviderRooms?.length === 0) {
                await rest('/fetchChmMappingData/wuBook', 'GET');
                const chmData = await rest('/ChannelManagerMap?rows=1000&page=0');
                baobabTree.root.select(['model', 'ChannelManagerMap']).set([...chmData]);
            } else {
                baobabTree.root.select(['model', 'ChannelManagerMap']).set(chm);
            }
        }
    }

    if (user.role === 'admin') {
        const roles = await rest('/getRoles');
        baobabTree.root.select(['model', 'Role']).set(roles);
    }

    const CondoOwner = await rest('/getCondoOwners');
    baobabTree.root.select(['model', 'CondoOwner']).set(CondoOwner);

    const version = await rest('/version');
    baobabTree.root.select('version').set(version);

    const SRCEnums = await rest('/SRCEnums');
    baobabTree.root.select('SRCEnums').set({ ...SRCEnums });

    await reloadReservations();
};

const resetCompanyNameFromLicenceInformation = (licence) => {
    if (licence && licence.licence && licence.licence.isValid) {
        const licencedCompanyName = licence.licence.decodedLicence.companyName;
        const companyInfo = _.clone(baobabTree.root.select(['companyInfo']).get());
        if (companyInfo !== null) {
            companyInfo.name = licencedCompanyName;
            baobabTree.root.select(['companyInfo']).set(companyInfo);
            saveCachedBaobabData(baobabTree);
        }
    }
};

const unprotectedLoadGeneralSettings = async () => {
    const locale = await rest('/getGeneralSettingByKey/locale');
    const otherSettings = await rest('/getGeneralSettingByKey/otherSettings');
    const companyInfo = await rest('/getGeneralSettingByKey/companyInfo'); //company name are contained in licence file also
    const defaultCurrency = await rest('/getGeneralSettingByKey/defaultCurrency');
    const IOTatewayIntegration = await rest('/getGeneralSettingByKey/IOTatewayIntegration'); ///getGeneralSettingByKey/IOTatewayIntegration
    const softwareFiscal = await rest('/getGeneralSettingByKey/fiscalCertificate'); ///getGeneralSettingByKey/IOTatewayIntegration
    const fiscalGeneralSettings = await rest('/getGeneralSettingByKey/fiscalGeneralSettings'); ///getGeneralSettingByKey/IOTatewayIntegration
    const invoicingSettings = await rest('/getGeneralSettingByKey/invoicingSettings'); ///getGeneralSettingByKey/IOTatewayIntegration
    const hiddenDocumentColumns = await rest('/getGeneralSettingByKey/hiddenDocumentColumns');

    // const registerENU = await rest('/getGeneralSettingByKey/registerENU'); ///getGeneralSettingByKey/IOTatewayIntegration

    if (locale) {
        if (baobabTree.root.select(['locale']).get() !== locale.params) {
            baobabTree.root.select(['locale']).set(locale.params);
            saveCachedBaobabData(baobabTree);
            // window.location = '/'; //this would force loading new language settings into runtime
        } else {
            //ignore
        }
    }
    if (companyInfo) {
        baobabTree.root.select(['companyInfo']).set(JSON.parse(companyInfo.params));
    }
    if (defaultCurrency) {
        baobabTree.root.select(['defaultCurrency']).set(defaultCurrency.params);
    }
    if (IOTatewayIntegration) {
        try {
            baobabTree.root.select(['IOTatewayIntegration']).set(JSON.parse(IOTatewayIntegration.params));
        } catch (err) {
            console.log(err);
        }
    }
    if (otherSettings) {
        baobabTree.root.select(['otherSettings']).set(JSON.parse(otherSettings.params));
    }
    if (softwareFiscal) {
        baobabTree.root.select(['fiscal', 'softwareFiscal']).set(JSON.parse(softwareFiscal.params));
    }
    if (fiscalGeneralSettings) {
        baobabTree.root.select(['fiscal', 'fiscalGeneralSettings']).set(JSON.parse(fiscalGeneralSettings.params));
    }
    if (invoicingSettings) {
        baobabTree.root.select(['invoicingSettings']).set(JSON.parse(invoicingSettings.params));
    }
    if (hiddenDocumentColumns) {
        baobabTree.root.select(['hiddenDocumentColumns']).set(JSON.parse(hiddenDocumentColumns.params));
    }
};

const loadStartupModelData = async (socketClient) => {
    try {
        try {
            await unprotectedLoadGeneralSettings();
        } catch (err) {
            console.log('general settings load error', err);
        }

        const about = await rest('/about');
        baobabTree.root.select(['about']).set(about);
        const user = baobabTree.root.select('authTokenInfo', 'user').get();
        const token = baobabTree.root.select('authTokenInfo', 'token').get();

        let isSessionAuthenticated = baobabTree.root.select('keycloak').get();
        if ((user && token) || isSessionAuthenticated?.authenticated === true) {
            socketClient.initSocket(token ? token : isSessionAuthenticated.token);
            socketClient.subscribeUser(user && user.uuid ? user.uuid : isSessionAuthenticated.sessionId);
            socketClient.on('socket_connected', async () => {
                //socket connection is mandatory
                console.log('SOCKET CONNECTED');
                await reloadInitialData();
                baobabTree.root.select(['socketConnection', 'connectingMessage']).set('CONNECTION ESTABLISHED');
                baobabTree.root.select(['socketConnection', 'heading']).set('please wait');
                setTimeout(() => {
                    baobabTree.root.select(['socketConnection', 'showConnectingScreen']).set(false);
                }, 500);
            });
        } else {
            baobabTree.root.select(['socketConnection', 'showConnectingScreen']).set(false);
        }

        return true;
    } catch (err) {
        throw err;
    }
};

//usage example: await loadBaobabModelData(["model","whole","CompetitionTeam"])
const loadBaobabModelData = async (baobabPath) => {
    //baobab path is array which ends with model name = restApiModelName
    if (_.isArray(baobabPath)) {
        await multipageLoad(baobabPath, baobabPath[baobabPath.length - 1]);
    }
    return true;
};

//export default loadBaobabModelData
export { loadStartupModelData, loadBaobabModelData };
