import React from 'react';
import { branch } from 'baobab-react/higher-order'; //DONT use baobab here
import _ from 'lodash';
import { reservationStatusBackgrounds } from '../../data/reservationStatusEnums';
import moment from 'moment/min/moment-with-locales';
import SidebarDailyInfo from './sidebarDailyInfo';
import SidebarWeeklyAndMonthlyInfo from './sidebarWeeklyAndMonthlyInfo';

function IncommingReservations(props) {
    const onReservationClick = (reservation) => {
        props.history.push('/reservations/' + reservation.id + '/edit');
    };
    const { reservations, uiStates, availableToTimestamp, availableFromTimestamp } = props;
    //0-earlyBird, 1-confirmed, 2-checkedIn 3-canceled, 4- closed, 5-autoblocked

    let filteredReservations = [...reservations];

    let orderBy = 'checkInTimestamp';
    if (uiStates.showComponents.includes('sidebar_incomming')) {
        filteredReservations = reservations.filter((r) => r.statusEnum === 0 || r.statusEnum === 1);
    } else if (uiStates.showComponents.includes('sidebar_checkedIn')) {
        filteredReservations = reservations.filter((r) => r.statusEnum === 2);
        orderBy = 'checkOutTimestamp';
    } else if (uiStates.showComponents.includes('sidebar_canceled')) {
        filteredReservations = reservations.filter((r) => r.statusEnum === 5 || r.statusEnum === 3);
    }
    const searchTerm = props.searchTerm.toLowerCase();
    filteredReservations = filteredReservations.filter((reservations) => {
        let nameByFirstName = reservations.Customer?.firstName + ' ' + reservations.Customer?.lastName;
        let nameByLastName = reservations.Customer?.lastName + ' ' + reservations.Customer?.firstName;
        let companyName = reservations.Company?.name;

        nameByFirstName = nameByFirstName ? nameByFirstName : '';
        nameByLastName = nameByLastName ? nameByLastName : '';
        companyName = companyName ? companyName : '';


        return (
            nameByLastName.toLowerCase().includes(searchTerm) ||
            nameByFirstName.toLowerCase().includes(searchTerm) ||
            companyName.toLowerCase().includes(searchTerm) ||
            reservations.guest1CodeCopy === searchTerm ||
            reservations.guest2CodeCopy === searchTerm ||
            reservations.guest3CodeCopy === searchTerm ||
            reservations.id === Number(searchTerm) ||
            reservations.RoomInfo?.name.toLowerCase().includes(searchTerm)
        );
    });

    let sortedReservationsByCheckInTimeStamp = filteredReservations.sort((a, b) => {
        return Number(a[orderBy]) - Number(b[orderBy]);
    });

    sortedReservationsByCheckInTimeStamp = _.orderBy(sortedReservationsByCheckInTimeStamp, ['statusEnum'], ['desc']);

    return !uiStates.showComponents.includes('sidebar_dailyInfo') &&
        !uiStates.showComponents.includes('sidebar_week/month') ? (
        <div>
            <ul className="list-group list-group-flush ">
                {sortedReservationsByCheckInTimeStamp.map((r) => {
                    const textColor = r.statusEnum === 3 ? ' text-dark ' : ' text-white ';

                    if (r.roomInfoId === null) {
                        return null;
                    }
                    if (!r.Customer && !r.Company) {
                        return 'UNKNOWN';
                    } else {
                        const checkOutDecorator =
                            r.statusEnum === 2 && r.checkOutTimestamp < Date.now() ? ' blink-animation ' : '';
                        const checkInDecorator =
                            r.statusEnum === 1 && r.checkInTimestamp < Date.now() ? ' blink-animation ' : '';
                        return (
                            <li
                                onClick={onReservationClick.bind(this, r)}
                                key={r.id}
                                className={
                                    'list-group-item rounded pointer mt-2 mt-md-1 p-1 ' +
                                    textColor +
                                    reservationStatusBackgrounds[r.statusEnum]
                                }
                            >
                                <small>
                                    {r.Customer ? (
                                        <div className="elipsis">
                                            {r.Customer.firstName} {r.Customer.lastName} - {r.RoomInfo.name}
                                        </div>
                                    ) : null}
                                    <small>
                                        {r.Company ? (
                                            <div className="elipsis">
                                                <i className="fa fa-building mr-1" aria-hidden="true"></i>
                                                {r.Company.name} - {r.RoomInfo.name}
                                            </div>
                                        ) : null}
                                    </small>
                                </small>
                                {r.invoicedStatus === 2 ? (
                                    <div className={'elipsis '}>
                                        <small>
                                            <div className="elipsis">{window.translate('Paid:Partialy')}</div>
                                        </small>
                                    </div>
                                ) : r.invoicedStatus === 1 ? (
                                    <div className={'elipsis '}>
                                        <small>
                                            <div className="elipsis">
                                                {window.translate('Paid')}:<b>{window.translate(' Fully')}</b>
                                            </div>
                                        </small>
                                    </div>
                                ) : null}
                                <div className={'elipsis '}>
                                    {' '}
                                    <small>
                                        <small>
                                            {' '}
                                            #{r.id} |{' '}
                                            <span className={checkInDecorator}>
                                                {moment(r.checkInTimestamp).format('L')}
                                            </span>{' '}
                                            -{' '}
                                            <span className={checkOutDecorator}>
                                                {moment(r.checkOutTimestamp).format('L')}
                                            </span>
                                        </small>
                                    </small>
                                </div>
                            </li>
                        );
                    }
                })}
                {filteredReservations.length === 0 ? (
                    <span className="pt-1 text-left">
                        <small>{window.translate('No reservations')}</small>
                    </span>
                ) : null}
            </ul>
        </div>
    ) : uiStates.showComponents.includes('sidebar_dailyInfo') ? (
        <SidebarDailyInfo reservations={sortedReservationsByCheckInTimeStamp} rooms={props.roomInfo} />
    ) : uiStates.showComponents.includes('sidebar_week/month') ? (
        <SidebarWeeklyAndMonthlyInfo
            reservations={sortedReservationsByCheckInTimeStamp}
            rooms={props.roomInfo}
            availableToTimestamp={availableToTimestamp}
            availableFromTimestamp={availableFromTimestamp}
        />
    ) : null;
}

export default branch(
    {
        uiStates: ['uiStates', 'dashboard'],
        roomInfo:['model', 'RoomInfo']
    },
    IncommingReservations
);
